import {
  createApp
} from 'vue'
import popup from '@/components/Popup.vue'

export const showAlert = (options) => {
  const {
    title,
    content,
    confirmFn
  } = options
  const div = document.createElement('div')
  div.id = 'popup_ui1'
  document.body.appendChild(div)
  const app = createApp(popup, {
    title,
    content,
    btns: [{
      text: '确定',
      style: '',
      click: () => {
        app.unmount(div)
        div.remove()
        confirmFn && confirmFn()
      }
    }],
    onSuccess: () => { },
    onEnd: () => {
      app.unmount(div)
      div.remove()
    }
  })
  app.mount(div)
}

export const showConfirm = (options) => {
  const {
    title,
    content,
    confirmFn,
    cancelFn,
    confirmTxt,
    cancelTxt
  } = options
  const div = document.createElement('div')
  div.id = 'popup_ui1'
  document.body.appendChild(div)
  const app = createApp(popup, {
    title,
    content,
    btns: [{
      text: cancelTxt ? cancelTxt : '取消',
      click: () => {
        app.unmount(div)
        div.remove()
        cancelFn && cancelFn()
      }
    },
    {
      text: confirmTxt ? confirmTxt : '确定',
      style: '',
      click: () => {
        app.unmount(div)
        div.remove()
        confirmFn && confirmFn()
      }
    }
    ],
    onSuccess: () => { },
    onEnd: () => {
      app.unmount(div)
      div.remove()
    }
  })
  app.mount(div)
}

export const closeDialog = () => {
  let dialog_ui = document.getElementById('popup_ui')
  let dialog_ui1 = document.getElementById('popup_ui1')

  if (dialog_ui) dialog_ui.remove()
  if (dialog_ui1) dialog_ui1.remove()
  dialog_ui = null
  dialog_ui1 = null
}