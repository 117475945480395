<template>
  <div class="ljSucc-page">
    <div class="pro-box">
      <div class="title">奖品信息</div>
      <div class="con">
        <img class="l" src="" alt="" />
        <div class="r">
          <p class="des">奖品名称：{{orderInfo.prizeName}}<br>
          中奖时间：{{orderInfo.createTime}}<br>
          <!-- 中奖人：{{orderInfo.prizeName}}<br> -->
          奖品数量：1
          </p>
        </div>
      </div>
    </div>

    <div class="btn" @click="getedAction">用户已领取</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import UserService from "@/api/common.js";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const no = route.query.no;
    const disabled = ref(false);

    const orderInfo = ref({});
    const { showAlert, showConfirm } = mixin();

    onMounted(() => {
      getOrder();
    });

    const getOrder = async () => {
      const data = await UserService.getScanDetailInfo({
        scanType: "draw",
        uid: localStorage.uid,
        bizNo: no,
      });
      console.log(data);
      if (!data) return;
      orderInfo.value = data.tbDrawRecord;
    };
    const getedAction = async () => {
      if (disabled.value) return;
      const data = await UserService.finishBiz({
        scanType: "draw",
        uid: localStorage.uid,
        bizNo: no,
      });
      //   console.log(data);
      if (!data) return;
      disabled.value = true;
      showAlert({ content: "确认成功",confirmFn:()=>{
          router.go(-1);
      } });
    };
    // const gomall = async () => {
    //   router.go(-1);
    // };
    return {
      orderInfo,
      disabled,
      getedAction,
    };
  },
});
</script>
<style lang="scss">
.ljSucc-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 0.63rem;
  .top-box {
    padding-top: 0.46rem;
    padding-bottom: 0.2rem;
    background: linear-gradient(0deg, #fb2e21 0%, #fe6538 100%);
    .succ-box {
        position: relative;
      .img {
        display: block;
        width: 6.57rem;
        height: 2.57rem;
        margin: 0 auto;
      }
      .detail {
        position: absolute;
        top: 0.81rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.57rem;
        height: 0.77rem;
        .l {
          width: 0.75rem;
          height: 0.77rem;
          margin-right: 0.28rem;
          img{
              width: 100%;
          }
        }

        .r {
          .des {
            @include fontSC(0.32rem, #000);
            margin-bottom: 0.16rem;
            line-height: 1.5em;
          }
          .memo {
            width: 4.9rem;
             overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
            @include fontSC(0.28rem, #999);
          }
        }
      }
      .order-no{
        position: absolute;
        bottom: 0.21rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
          .l{
              width: 0.27rem;
              height: 0.27rem;
              margin-right: 0.21rem;
          }
          .r{
            @include fontSC(0.28rem, #FB3E28);
          }
      }
    }
  }

  .total-bean{
      @include fontSC(0.32rem, #999);
       display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
      height: 1.04rem;
      .t{
          color: #FB3E28;
      }
  }

  .pro-box {
    margin: 0.21rem 0 0.2rem;
    padding: 0.26rem 0.25rem 0.26rem 0.21rem;
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
    //   padding-left: 0.29rem;
      &::before {
        content: "";
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l {
        width: 1.85rem;
        height: 1.86rem;
        border: 1px solid #e6e6e6;
        margin-right: 0.33rem;
      }

      .r {
        @include fontSC(0.3rem, #5f5f5f);
        .des {
          @include fontSC(0.32rem, #282828);
        //   margin-top: 0.7rem;
          line-height: 1.3em;
        }
        .memo {
          @include fontSC(0.3rem, #5f5f5f);
          .total {
            @include fontSC(0.32rem, #fb3e28);
          }
        }
      }
    }
  }

    .btn{
        @include fontSC(0.36rem, #fff);
        width: 6.91rem;
        line-height: 1rem;
        border-radius: 0.5rem;
        margin: 0.69rem auto 0.2rem;
                background: #F65341;
                text-align: center;
      }

}
</style>
