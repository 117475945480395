const rulesArr = {
  isNonEmpty: function (value, errorMsg) {
    if (!value) return errorMsg
  },
  isMobile: function (value, errorMsg) {
    if (!/^1\d{10}$/.test(value)) return errorMsg
  },
  isPassWord: function (value, errorMsg) {
    if (!/^[0-9A-Za-z!@#$%^&*()_+-]{6,20}$/.test(value)) return errorMsg
  },
  minLength: function (value, length, errorMsg) {
    if (value.length < length) return errorMsg
  }
}

class Validator {
  constructor() {
    this.cache = []
  }

  add(domVal, rules) {
    for (let i = 0; i < rules.length; i++) {
      const ruleItem = rules[i]
      const ary = ruleItem.ruleName.split(':')
      this.cache.push(function () {
        const rule = ary.shift()
        const errMsg = ruleItem.errMsg
        ary.unshift(domVal)
        ary.push(errMsg)
        return rulesArr[rule].apply(domVal, ary)
      })
    }
  }

  start() {
    for (let i = 0; i < this.cache.length; i++) {
      const msg = this.cache[i]()
      if (msg) return msg
    }
  }
}

export default Validator
