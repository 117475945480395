<template>
  <div class="message-page">
    <div class="bg"><img src="@/assets/images/liuyan_bg.png" alt="" /></div>
    <section style="padding: 0 0.29rem;">
      <div class="input-box">
        <div class="input-item">
          <div class="label">姓名*</div>
          <input v-model="name" placeholder="请输入您的姓名" />
        </div>
        <div class="input-item">
          <div class="label">手机*</div>
          <input v-model="phone" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="textarea-box">
        <div class="label">信息</div>
        <textarea v-model="info" placeholder=""></textarea>
      </div>

      <div class="btn" @click="sure">提交</div>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import { useRouter } from "vue-router";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const name = ref("");
    const phone = ref("");
    const info = ref("");

    const { showAlert, showConfirm } = mixin();

    onMounted(() => {
        initData()
    });

     const initData = async () => {
      const data = await UserService.qryLoveMemberInfo({
        uid: localStorage.uid,
      });
      if (!data) return;
      const { mobile} = data;
      if (mobile) phone.value = mobile;
      if (data.name) name.value = data.name;
    };

    const sure = async () => {
      const state = [name.value, phone.value, ];
      if (!Object.keys(state).every((item) => state[item])) {
        showAlert({ content: "必填项不能为空" });
        return;
      }
      let postData = {
        uid: localStorage.uid,
        name: name.value,
        mobie: phone.value,
        message: info.value
      };
      const data = await UserService.addMessageBoard(postData);
      if (!data) return;
      showAlert({
        content: "留言成功",
        confirmFn: () => {
          router.go(-1)
        },
      });
    };

    return {
      name,
      phone,
      info,
      sure,
    };
  },
});
</script>
<style lang="scss">
.message-page {
  min-height: 100vh;
  background: #f8f8f8;
  .bg {
    margin: 0 auto 0.5rem;
    width: 100%;
    height: 4.86rem;
    img {
      display: block;
      width: 100%;
    }
  }
  .input-box {
    padding: 0 0.21rem;
    border-radius: 0.2rem;
    background: #fff;
    .input-item {
      display: flex;
      align-items: center;
      height: 0.97rem;
      @include border-1px(#ddd, bottom);
      .label{
          @include fontSC(0.29rem, #3F3F3F);
          margin-right: 0.2rem;
          width: 50%;
      }
      .block {
        width: 0.32rem;
        height: 0.37rem;
      }
      input {
        flex: 1;
        height: 100%;
        padding-left: 0.55rem;
        // padding-right: 0.32rem;
        @include fontSC(0.3rem, #333);
        //   text-align: right;
        &::-webkit-input-placeholder {
            // text-align: right;
          color: #c4c4c4;
        }
      }
      &.valid-item {
        input {
          padding-left: 1rem;
        }
        .get-code {
          width: 1.6rem;
          text-align: center;
          line-height: 0.58rem;
          border: 1px solid #fb3e28;
          border-radius: 0.45rem;
          @include fontSC(0.24rem, #fb3e28);
        }
        .sec {
          @include fontSC(0.24rem, #fb3e28);
        }
      }
    }
  }
  .textarea-box{
      margin-top: 0.26rem;
      padding: 0.22rem 0.35rem 0;
      background: #fff;
      border-radius: 0.2rem;
      .label{
          @include fontSC(0.29rem, #3F3F3F);
      }
      textarea{
          margin-top: 0.16rem;
          height: 2.6rem;
          width: 100%;
      }
  }
  .btn {
    margin: 0.68rem auto 0;
    width: 6.9rem;
    text-align: center;
    line-height: 0.9rem;
    @include fontSC(0.34rem, #fff);
    background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
    box-shadow: 0px 0.26rem 0.59rem 0px rgba(255, 54, 81, 0.22);
    border-radius: 0.45rem;
  }
}
</style>
