<template>
  <div class="addinfo-page">
    <div class="title">完善个人信息</div>
    <div class="cell">
      <div class="item">
        <div class="label">姓名</div>
        <div class="right">
          <input v-model="name" type="text" placeholder="请填写真实姓名" />
        </div>
      </div>
      <div class="item">
        <div class="label">性别</div>
        <div class="right input-box">
          <input
            v-model="sex"
            readonly
            @click="checkAction('sex')"
            type="text"
            placeholder="请选择性别"
          />
        </div>
        <!-- <div class="right">
          <input v-model="sex" type="text" placeholder="请选择性别" />
        </div> -->
      </div>
      <div class="item">
        <div class="label">电话号码</div>
        <div class="right">
          <input
            v-model="mobile"
            type="text"
            placeholder="请填写真实电话号码"
          />
        </div>
      </div>
      <div class="item">
        <div class="label">身份证号</div>
        <div class="right">
          <input v-model="certId" type="text" placeholder="请输入身份证号" />
        </div>
      </div>
      <div class="item input-wrap">
        <div class="label">上传头像</div>

        <input
          class="uploadInput"
          v-if="resetFlag"
          @change="fileChange"
          accept="image/*"
          capture="camera"
          placeholder="请上传营业执照"
          type="file"
        />
        <img
          class="uploadImg"
          v-if="headImage || fileData"
          :src="headImage || fileData"
          alt=""
        />
        <span style="color: #f54029" v-else>去上传</span>
      </div>
      <div class="item">
        <div class="label">街道</div>
        <div class="right input-box">
          <input
            v-model="ownedStreet"
            readonly
            @click="checkAction('ownedStreet')"
            type="text"
            placeholder="请选择街道"
          />
        </div>
      </div>
      <div class="item">
        <div class="label">居委</div>
        <div class="right input-box">
          <input
            v-model="ownedCommunity"
            @click="checkAction('ownedCommunity')"
            type="text"
            readonly
            placeholder="请选择居委"
          />
        </div>
      </div>
    </div>

    <van-popup
      v-model:show="showInputPicker"
      position="bottom"
      @click-overlay="closePopUp"
    >
      <van-picker
        show-toolbar
        :columns="pickerCloumns"
        @cancel="closePopUp"
        @confirm="onInputPickerConfirm"
      />
    </van-popup>

    <div class="btn" @click="perfectInformation">提交</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import CenterService from '@/api/center.js'
import { Picker, Popup } from 'vant'
import mixin from '@/mixin/mixin.js'
import uploadAction from '../userCenter/upload'
import { checkIDCard } from '../../utils/tools'

export default defineComponent({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid

    const {
      fileChange,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation,
      liceseNo
    } = uploadAction()

    const state = reactive({
      name: '',
      sex: '',
      mobile: '',
      certId: '',
      ownedStreet: '',
      headImage: '',
      ownedCommunity: ''
    })
    const selectInfo = reactive({
      // sex: '',
      // ownedStreet: '',
      // ownedCommunity: '',
      curClickIput: '',
      pickerCloumns: [],
      showInputPicker: false,
      ownedStreetKey: '0',
      sexKey: '0',
      ownedStreetArr: [],
      ownedCommunityKey: '0',
      ownedCommunityArr: [],
      sexArr: ['男', '女']
    })

    onMounted(() => {
      perfectInformationReady()
    })

    const initData = async () => {
      const data = await CenterService.userDetail({
        uid
      })
      console.log(data)
      if (!data) return
      const { member } = data
      const {
        mobile,
        name,
        sex,
        certId,
        ownedStreet,
        ownedCommunity,
        headImage
      } = member
      if (mobile) state.mobile = mobile
      if (name) state.name = name
      if (sex) state.sex = sex
      if (certId) state.certId = certId
      if (ownedStreet) state.ownedStreet = ownedStreet
      if (ownedCommunity) state.ownedCommunity = ownedCommunity
      if (headImage) state.headImage = headImage
    }

    const perfectInformationReady = async () => {
      const data = await CenterService.perfectInformationReady({
        uid
      })
      console.log(data)
      if (!data) return
      const { communityList, streetList } = data
      selectInfo.ownedStreetArr = streetList
      selectInfo.ownedCommunityArr = communityList
      initData()
    }
    const onInputPickerConfirm = (value) => {
      //  console.log('value',value);
      selectInfo.showInputPicker = false
      const inputType = selectInfo.curClickIput

      state[inputType] = value

      selectInfo.curClickIput = '' // 重置,防止不能重复点击
    }
    const closePopUp = () => {
      selectInfo.showInputPicker = false
      selectInfo.curClickIput = '' // 重置,防止不能重复点击
    }
    const checkAction = (type) => {
      selectInfo.curClickIput = type
      selectInfo.showInputPicker = true
      selectInfo.pickerCloumns = selectInfo[`${type}Arr`]
    }
    watch(fileData, (newValue) => {
      state.headImage = newValue
    })
    const perfectInformation = async () => {
      if (
        ![
          'name',
          'sex',
          'mobile',
          'certId',
          'ownedStreet',
          'ownedCommunity'
        ].every((item) => state[item])
      ) {
        showAlert({ content: '选项不能为空' })
        return
      }

      const { name, sex, mobile, certId, ownedStreet, ownedCommunity } = state
      if (!checkIDCard(certId))
        return showAlert({ content: '请输入正确的身份证号码' })
      const data = await CenterService.perfectInformation({
        name,
        sex,
        mobile,
        certId,
        ownedStreet,
        ownedCommunity,
        headImage: state.headImage
      })
      console.log(data)
      if (!data) return
      localStorage.isComplete = 'TRUE'
      showAlert({
        content: '添加成功',
        confirmFn: () => {
          router.go(-1)
        }
      })
      //   router.go(-1)
    }
    return {
      ...toRefs(state),
      ...toRefs(selectInfo),
      perfectInformation,
      onInputPickerConfirm,
      closePopUp,
      checkAction,
      fileChange,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation,
      liceseNo
    }
  }
})
</script>
<style lang="scss">
.addinfo-page {
  padding: 0 0.3rem;
  .title {
    padding: 0.78rem 0 0.56rem;
    @include fontSC(0.48rem, #333);
    @include border-1px(#e9e9ff, bottom);
  }
  .btn {
    @include fontSC(0.36rem, #fd5431);
    width: 6.22rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 1rem auto 0.48rem;
    border: 1px solid #fd5431;
    text-align: center;
  }
  input {
    color: #333;
  }
  .uploadInput {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .uploadImg {
    width: 0.46rem;
    height: 0.42rem;
  }
  .cell .item .right.input-box {
    position: relative;
    input {
      padding-right: 0.44rem;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0.29rem;
      height: 0.19rem;
      background: url(../../assets/images/down.png);
      background-size: cover;
      //   opacity: 0.7;
    }
  }
}
</style>
