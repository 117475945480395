<template>
    <div class="privacy-page">
        <p style="margin:3.75pt 0pt; text-align:center"><span class="bold">善城平台</span><span
                class="bold">服务协议</span><span class="s">&#xa0;</span>
        </p>
  
        <p class="m"><span style=" font-size:10.5pt;">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">提示条款</span>
        </p>
        <p class="m"><span class="m">&#xa0;</span>
        </p>
        <p class="m"><span class="s">欢迎您与</span><span class="s">善城平台管理方</span><span class="s">（详见定义条款）共同签署本《</span><span
                class="s">善城平台</span><span class="s">服务协议》（下称“本协议”）并使用</span><span class="s">善城平台</span><span
                class="s">服务！</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">自本协议发布之日起，</span><span class="s">善城</span><span class="s">平台各处所称“</span><span
                class="s">善城</span><span class="s">服务协议”均指本协议。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【审慎阅读】</span><span class="s">您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。</span><span
                class="bold">请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</span><span
                class="s">如您对协议有任何疑问，可向</span><span class="s">善城</span><span class="s">平台客服咨询。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【签约动作】</span><span
                class="s">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与</span><span
                class="s">善城</span><span class="s">达成一致，成为</span><span class="s">善城</span><span
                class="s">平台“用户”。</span><span class="bold">阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">一、
                定义</span><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">善城</span><span class="s">：</span><span
                class="s">由上海新华慈善服务社委托上海颖源信息科技有限公司</span><span
                class="s">开发，一款体现上海市长宁区新华社区范围内居民或者企业行使公益活动、查询公益服务附加值、实现公益活动发布、公益物品兑换等的软件。</span>
        </p>
        <p class="m"><span class="bold">善城</span><span class="bold">平台服务</span><span class="s">：</span><span
                class="s">善城</span><span class="s">基于互联网，以</span><span class="s">善城</span><span class="s">平台</span><span
                class="s">客户端等</span><span class="s">在内</span><span class="s">的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">二、
                协议范围</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">2.1</span><span class="bold">&#xa0;</span><span class="bold">签约主体</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【平等主体】</span><span class="s">本协议由您与</span><span class="s">善城</span><span
                class="s">平台经营者共同缔结，本协议对您与</span><span class="s">善城</span><span class="s">平台经营者均具有合同效力。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【主体信息】</span><span class="s">善城</span><span class="s">平台经营者是指经营</span><span
                class="s">善城</span><span class="s">平台的各法律主体，您可随时查看</span><span class="s">善城</span><span
                class="s">平台各网站首页底部公示的证照信息以确定与您履约的</span><span class="s">善城</span><span class="s">主体。本协议项下，</span><span
                class="bold">善城</span><span class="bold">平台经营者可能根据</span><span class="bold">善城</span><span
                class="bold">平台的业务调整而发生变更，变更后的</span><span class="bold">善城</span><span
                class="bold">平台经营者与您共同履行本协议并向您提供服务，</span><span class="bold">善城</span><span
                class="bold">平台经营者的变更不会影响您本协议项下的权益。</span><span class="bold">善城</span><span
                class="bold">平台经营者还有可能因为提供新的</span><span class="bold">善城</span><span
                class="bold">平台服务而新增，如您使用新增的</span><span class="bold">善城</span><span
                class="bold">平台服务的，视为您同意新增的</span><span class="bold">善城</span><span
                class="bold">平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">2.2
                补充协议</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">由于互联网高速发展，您与</span><span class="s">善城</span><span
                class="s">签署的本协议列明的条款并不能完整罗列并覆盖您与</span><span class="s">善城</span><span
                class="s">所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，</span><span class="bold">善城</span><span
                class="bold">平台</span><a
                href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a2145.7268393.0.0.4b1c0162AoKvHi"
                target="https://terms.alicdn.com/legal-agreement/terms/TD/_blank"><span
                    class="bold">隐私权政策</span></a><span class="bold">及</span><span class="bold">善城</span><span
                class="bold">平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用</span><span class="bold">善城</span><span
                class="bold">平台服务，视为您同意上述补充协议。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">三、
                账户注册与使用</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.1</span><span class="bold">&#xa0;</span><span class="bold">用户资格</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">您确认，在您开始注册程序使用</span><span class="s">善城</span><span
                class="s">平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。</span><span
                class="bold">若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span>
        </p>
        <p class="m"><span class="s">此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，</span><span
                class="b">否则您应当停止使用</span><span class="b">善城</span><span
                class="b">平台服务，同时您理解违反前述要求可能会造成您无法正常注册及使用</span><span class="b">善城</span><span class="b">平台服务。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.2</span><span class="bold">&#xa0;</span><span class="bold">账户说明</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【账户获得】</span><span class="s">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得</span><span
                class="s">善城</span><span class="s">平台账户并成为</span><span class="s">善城</span><span class="s">平台用户。</span>
        </p>
        <p class="m"><span class="s">善城</span><span class="s">平台只允许每位用户使用一个</span><span class="s">善城</span><span
                class="s">平台账户。如有证据证明或</span><span class="s">善城</span><span class="s">根据</span><span
                class="s">善城</span><span class="s">平台规则判断您存在不当注册或不当使用多个</span><span class="s">善城</span><span
                class="s">平台账户的情形，</span><span class="s">善城</span><span
                class="s">平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给</span><span class="s">善城</span><span
                class="s">平台及相关方造成损失的，您还应承担赔偿责任。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【账户使用】</span><span class="s">您有权使用您设置或确认的</span><span class="s">善城</span><span
                class="s">会员名、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录</span><span class="s">善城</span><span
                class="s">平台。</span>
        </p>
        <p class="m"><span class="s">由于您的</span><span class="s">善城</span><span class="s">平台账户关联您的个人信息及</span><span
                class="s">善城</span><span class="s">平台</span><span class="s">公益服务</span><span class="s">信息，您的</span><span
                class="s">善城</span><span class="s">平台账户仅限您本人使用。未经</span><span class="s">善城</span><span
                class="s">平台同意，您直接或间接授权第三方使用您</span><span class="s">善城</span><span
                class="s">平台账户或获取您账户项下信息的行为无效。如</span><span class="s">善城</span><span class="s">根据</span><span
                class="s">善城</span><span class="s">平台规则中约定的违约认定程序及标准判断您</span><span class="s">善城</span><span
                class="s">平台账户的使用可能危及您的账户安全及/或</span><span class="s">善城</span><span class="s">平台信息安全的，</span><span
                class="s">善城</span><span class="s">平台可拒绝提供相应服务或终止本协议。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【账户转让】</span><span class="s">由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经</span><span
                class="s">善城</span><span class="s">同意，并符合</span><span class="s">善城</span><span
                class="s">平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。</span><span
                class="bold">除此外，您的账户不得以任何方式转让，否则</span><span class="bold">善城</span><span
                class="bold">平台有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【实名认证】</span><span class="s">作为</span><span class="s">善城</span><span
                class="s">平台经营者，为使您更好地使用</span><span class="s">善城</span><span class="s">平台的各项服务，保障您的账户安全，</span><span
                class="s">善城</span><span class="s">可要求您</span><span class="s">注册</span><span class="s">规定完成实名认证。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.3</span><span class="bold">&#xa0;</span><span class="bold">注册信息管理</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.3.1</span><span class="bold">&#xa0;</span><span class="bold">真实合法</span>
        </p>
        <p class="m"><span class="bold">【信息真实】</span><span class="s">在使用</span><span class="s">善城</span><span
                class="s">平台服务时，您应当按</span><span class="s">善城</span><span
                class="s">平台页面的提示准确完整地提供您的信息（包括您的姓名、联系电话、</span><span class="s">身份证号</span><span
                class="s">等），以便</span><span class="s">善城</span><span class="s">与您联系。</span><span
                class="bold">您了解并同意，您有义务保持您提供信息的真实性及有效性。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.3.2</span><span class="bold">&#xa0;</span><span class="bold">更新维护</span>
        </p>
        <p class="m"><span class="s">您应当及时更新您提供的信息，在法律有明确规定要求</span><span class="s">善城</span><span
                class="s">作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，</span><span class="s">善城</span><span
                class="s">将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</span>
        </p>
        <p class="m"><span class="bold">如</span><span class="bold">善城</span><span
                class="bold">按您最后一次提供的信息与您联系未果、您未按</span><span class="bold">善城</span><span
                class="bold">的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及</span><span
                class="bold">善城</span><span class="bold">造成的全部损失与不利后果。</span><span class="bold">善城</span><span
                class="bold">可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部</span><span class="bold">善城</span><span
                class="bold">平台服务，</span><span class="bold">善城</span><span class="bold">对此不承担责任。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">3.4</span><span class="bold">&#xa0;</span><span class="bold">账户安全规范</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【账户安全保管义务】</span><span class="s">您的账户为您自行设置并由您保管，</span><span
                class="s">善城</span><span
                class="s">任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开</span><span
                class="s">善城</span><span class="s">平台。</span>
        </p>
        <p class="m"><span class="bold">账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，</span><span class="bold">善城</span><span
                class="bold">并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【账户行为责任自负】</span><span class="s">除</span><span class="s">善城</span><span
                class="s">存在过错外，</span><span class="bold">您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、</span><span
                class="bold">查看</span><span class="bold">信息、</span><span class="bold">兑换</span><span
                class="bold">商品及服务等）负责。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【日常维护须知】</span><span class="s">如发现任何未经授权使用您账户登录</span><span
                class="s">善城</span><span class="s">平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知</span><span class="s">善城</span><span
                class="s">。</span><span class="bold">您理解</span><span class="bold">善城</span><span
                class="bold">对您的任何请求采取行动均需要合理时间，且</span><span class="bold">善城</span><span
                class="bold">应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除</span><span class="bold">善城</span><span
                class="bold">存在法定过错外，</span><span class="bold">善城</span><span class="bold">不承担责任。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">四、
            </span><span class="bold">善城</span><span class="bold">平台服务及规范</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【服务概况】</span><span class="s">您有权在</span><span class="s">善城</span><span
                class="s">平台上享受商品及/或服务的浏览、</span><span class="s">兑换</span><span class="s">与评价等服务。</span><span
                class="s">善城</span><span class="s">提供的服务内容众多，具体您可登录</span><span class="s">善城</span><span
                class="s">平台浏览。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.1</span><span class="bold">&#xa0;</span><span
                class="bold">商品及/或服务的浏览、收藏</span>
        </p>
        <p class="m"><span class="s">在您浏览我们客户端的过程中，</span><span class="s">善城</span><span class="s">为您提供了</span><span
                style="color:#ff0000; font-family:'Microsoft YaHei'; font-size:12pt; font-weight:bold;">信息分类、关键词检索、筛选、收藏及关注等功能</span><span
                class="s">，以更好地匹配您的需求。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.2商品及/或服务的</span><span class="bold">兑换</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【商品及/或服务的购买】</span><span class="s">当您在</span><span class="s">善城</span><span
                class="s">平台</span><span class="s">兑换</span><span class="s">商品及/或服务时，请您务必仔细确认所</span><span
                class="s">兑</span><span class="s">商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您</span><span
                class="s">的</span><span class="s">信息。</span>
        </p>
        <p class="m"><span class="s">您的</span><span class="s">兑换</span><span
                class="s">行为应当基于真实的需求，不得存在对商品及/或服务实施恶意</span><span class="s">兑换</span><span
                class="s">、恶意维权等扰乱</span><span class="s">善城</span><span class="s">平台正常秩序的行为。基于维护</span><span
                class="s">善城</span><span class="s">平台秩序及安全的需要，</span><span class="s">善城</span><span
                class="s">发现上述情形时可主动执行关闭相关交易订单等操作。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.3交易争议处理</span>
        </p>
        <p class="m"><span class="m">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【交易争议处理途径】</span><span class="s">您在</span><span class="s">善城</span><span
                class="s">平台</span><span class="s">兑换</span><span class="s">过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：</span>
        </p>
        <p class="m"><span class="s">（一）与争议相对方自主协商；</span>
        </p>
        <p class="m"><span class="s">（二）使用</span><span class="s">善城</span><span class="s">平台提供的争议调处服务；</span>
        </p>
        <p class="m"><span class="s">（三）向有关行政部门投诉；</span>
        </p>
        <p class="m"><span class="s">（</span><span class="s">四</span><span
                class="s">）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</span>
        </p>
        <p class="m"><span class="s">（</span><span class="s">五</span><span class="s">）向人民法院提起诉讼。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【平台调处服务】</span><span class="s">如您依据</span><span class="s">善城</span><span
                class="s">平台规则使用</span><span class="s">善城</span><span class="s">平台的争议调处服务，则表示您认可并愿意履行</span><span
                class="s">善城</span><span class="s">平台的客服或大众评审员（“调处方”）作为独立的第三方根据其所了解到的争议事实并依据</span><span
                class="s">善城</span><span class="s">平台规则所作出的调处决定（包括调整相关订单的</span><span class="s">兑换</span><span
                class="s">状态、判定将争议</span><span class="s">公益豆</span><span class="s">的全部或部分支付给交易一方或双方等）。在</span><span
                class="s">善城</span><span class="s">平台调处决定作出前，您可选择上述（三）、（四）、（五）途径（下称“其他争议处理途径”）解决争议以中止</span><span
                class="s">善城</span><span class="s">平台的争议调处服务。</span><span
                class="bold">如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。</span>
        </p>
        <p class="m"><span class="m">&#xa0;</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.</span><span class="bold">4</span><span class="bold">&#xa0;</span><span
                class="bold">贸易限制</span>
        </p>
        <p class="m"><span class="s">针对通过</span><span class="s">善城</span><span class="s">平台取得的商品、服务、软件及技术（包括</span><span
                class="s">善城</span><span class="s">平台提供的软件及技术），您承诺遵守所有适用的进出口管制、贸易限制与经济制裁相关法律法规。基于维护</span><span
                class="s">善城</span><span class="s">平台交易秩序及交易安全的需要，如您违反前述承诺的，</span><span class="bold">善城</span><span
                class="bold">可在发现上述情形时主动执行关闭相关</span><span class="bold">兑换</span><span class="bold">订单及作出账户处置等操作。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.</span><span class="bold">5</span><span class="bold">
                费用</span></p>
        <p class="m"><span class="s">善城</span><span class="s">为</span><span class="s">善城</span><span
                class="s">平台向您提供的服务付出了大量的成本，除</span><span class="s">善城</span><span class="s">平台明示的收费业务外，</span><span
                class="s">善城</span><span class="s">向您提供的服务目前是免费的。如未来</span><span class="s">善城</span><span
                class="s">向您收取合理费用，</span><span class="s">善城</span><span
                class="s">会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">4.</span><span class="bold">6</span><span class="bold">
                责任限制</span></p>
        <p class="m"><span class="bold">【不可抗力及第三方原因】</span><span class="bold">善城</span><span
                class="bold">依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，</span><span
                class="bold">善城</span><span class="bold">并不承担相应的违约责任：</span>
        </p>
        <p class="m"><span class="bold">（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</span>
        </p>
        <p class="m"><span class="bold">（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</span>
        </p>
        <p class="m"><span class="bold">（三）在</span><span class="bold">善城</span><span
                class="bold">已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【海量信息】</span><span class="s">善城</span><span class="s">仅向您提供</span><span
                class="s">善城</span><span class="s">平台服务，您了解</span><span class="s">善城</span><span
                class="s">平台上的信息系</span><span class="s">管理方</span><span class="s">发布，且可能存在瑕疵。</span><span
                class="s">善城</span><span class="s">将通过依法建立相关检查监控制度尽可能保障您在</span><span class="s">善城</span><span
                class="s">平台上的合法权益及良好体验。同时，</span><span class="bold">鉴于</span><span class="bold">善城</span><span
                class="bold">平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，</span><span class="bold">善城</span><span
                class="bold">无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【调处决定】</span><span class="s">您理解并同意，在争议调处服务中，</span><span
                class="s">善城</span><span class="s">平台的客服、大众评审员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，</span><span
                class="bold">除存在故意或重大过失外，调处方对争议调处决定免责。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">五、用户信息的保护及授权</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">5.1
                个人信息的保护</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="s">善城</span><span class="s">非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用</span><span
                class="s">善城</span><span class="s">提供的服务时，您同意</span><span class="s">善城</span><span
                class="s">按照在</span><span class="s">善城</span><span
                class="s">平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。</span><span class="s">善城</span><span
                class="s">希望通过隐私权政策向您清楚地介绍</span><span class="s">善城</span><span class="s">对您个人信息的处理方式，因此</span><span
                class="s">善城</span><span class="s">建议您完整地阅读隐私权政策（</span><a style="color:#3c99d8"
                href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html"
                target="https://terms.alicdn.com/legal-agreement/terms/TD/_blank"><span
                    style="color:#3c99d8; font-family:'Microsoft YaHei'; font-size:12pt; text-decoration:underline;">点击此处</span></a><span
                class="s">），以帮助您更好地保护您的隐私权。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">六、
                用户的违约及处理</span></p>
        <p class="m"><span class="bold">6.1</span><span class="bold">&#xa0;</span><span class="bold">违约认定</span>
        </p>
        <p class="m"><span class="s">发生如下情形之一的，视为您违约：</span>
        </p>
        <p class="m"><span class="s">（一）使用</span><span class="s">善城</span><span class="s">平台服务时违反有关法律法规规定的；</span>
        </p>
        <p class="m"><span class="s">（二）违反本协议或本协议补充协议（即本协议第2.2条）约定的。</span>
        </p>
        <p class="m"><span class="s">为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，</span><span class="bold">善城</span><span
                class="bold">可在</span><span class="bold">善城</span><span class="bold">平台规则中约定违约认定的程序和标准。如：</span><span
                class="bold">善城</span><span
                class="bold">可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">6.2</span><span class="bold">&#xa0;</span><span class="bold">违约处理措施</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【信息处理】</span><span class="s">您在</span><span class="s">善城</span><span
                class="s">平台上发布的信息构成违约的，</span><span class="bold">善城</span><span
                class="bold">可根据相应规则立即对相应信息进行删除、屏蔽处理。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【行为限制】</span><span class="s">您在</span><span class="s">善城</span><span
                class="s">平台上实施的行为，或虽未在</span><span class="s">善城</span><span class="s">平台上实施但对</span><span
                class="s">善城</span><span class="s">平台及其用户产生影响的行为构成违约的，</span><span class="s">善城</span><span
                class="bold">可依据相应规则对您执行账户扣分、中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，</span><span class="bold">善城</span><span
                class="bold">可查封您的账户，终止向您提供服务。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">6.3
                赔偿责任</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">如您的行为使</span><span class="bold">善城</span><span
                class="bold">及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿</span><span
                class="bold">善城</span><span class="bold">及/或其关联公司上述全部损失。</span>
        </p>
        <p class="m"><span class="bold">如您的行为使</span><span class="bold">善城</span><span
                class="bold">及/或其关联公司遭受第三人主张权利，</span><span class="bold">善城</span><span
                class="bold">及/或其关联公司在对第三人承担金钱给付等义务后就全部损失向您追偿。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">6.4
                特别约定</span><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【关联处理】如您因严重违约导致</span><span class="bold">善城</span><span
                class="bold">终止本协议时，出于维护平台秩序及保护消费者权益的目的，</span><span class="bold">善城</span><span
                class="bold">及/或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第八条约定的方式通知您。</span>
        </p>
        <p class="m"><span class="bold">如</span><span class="bold">善城</span><span class="bold">与您签署的其他协议及</span><span
                class="bold">善城</span><span class="bold">及/或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则</span><span
                class="bold">善城</span><span class="bold">出于维护平台秩序的目的，可在收到指令时中止甚至终止协议，并以本协议第八条约定的方式通知您。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">七、
                协议的变更</span></p>
        <p class="m"><span class="m">&#xa0;</span>
        </p>
        <p class="m"><span class="s">善城</span><span
                class="s">可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。</span>
        </p>
        <p class="m"><span class="s">如您不同意变更事项，您有权于变更事项确定的生效日前联系</span><span class="s">善城</span><span
                class="s">反馈意见。如反馈意见得以采纳，</span><span class="s">善城</span><span class="s">将酌情调整变更事项。</span>
        </p>
        <p class="m"><span class="bold">如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用</span><span class="bold">善城</span><span
                class="bold">平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用</span><span class="bold">善城</span><span
                class="bold">平台服务，则视为您同意已生效的变更事项。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">八、
                通知</span></p>
        <p class="m"><span class="bold">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">8.1有效联系方式</span>
        </p>
        <p class="m"><span class="b">您在注册成为</span><span class="b">善城</span><span class="b">平台用户，并接受</span><span
                class="b">善城</span><span class="b">平台服务时，您应该向</span><span class="b">善城</span><span
                class="b">提供真实有效的联系方式（包括您的</span><span class="b">姓名</span><span
                class="b">、联系电话等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</span>
        </p>
        <p class="m"><span class="b">善城</span><span
                class="b">将向您的上述联系方式向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</span>
        </p>
        <p class="m"><span class="b">您有权通过您注册时填写的手机号码获取您感兴趣的商品广告信息、促销优惠等商业性信息；</span><span
                class="b">您如果不愿意接收此类信息，您有权通过</span><span class="b">善城</span><span class="b">提供的相应的退订功能进行退订。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">8.2</span><span class="bold">&#xa0;</span><span class="bold">通知的送达</span>
        </p>
        <p class="m"><span class="b">善城</span><span class="b">通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在</span><span
                class="b">善城</span><span class="b">平台公告，向您提供的联系电话发送手机短信，系统消息以及站内信信息，在发送成功后即视为送达。</span>
        </p>
        <p class="m"><span class="b">对于在</span><span class="b">善城</span><span
                class="b">平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信等现代通讯方式送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码为您在</span><span
                class="b">善城</span><span class="b">平台注册、更新时提供的手机号码</span><span class="b">，</span><span
                class="b">司法机关向上述联系方式发出法律文书即视为送达。您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</span>
        </p>
        <p class="m"><span class="b">您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</span>
        </p>
        <p class="m"><span
                class="b">你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">九、
                协议的终止</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">9.1</span><span class="bold">&#xa0;</span><span class="bold">终止的情形</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【用户发起的终止】</span><span class="s">您有权通过以下任一方式终止本协议：</span>
        </p>
        <p class="m"><span class="s">（一）您通过</span><a
                href="https://service.taobao.com/support/main/selfHelpTools/cancelAccount/cancelAnnounce.htm?spm=1.231242.247651.5"
                target="https://terms.alicdn.com/legal-agreement/terms/TD/_blank"><span class="s">网站自助服务</span></a><span
                class="s">注销您的账户的；</span>
        </p>
        <p class="m"><span class="s">（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</span>
        </p>
        <p class="m"><span class="s">（三）您明示不愿继续使用</span><span class="s">善城</span><span class="s">平台服务，且符合</span><span
                class="s">善城</span><span class="s">平台终止条件的。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【</span><span class="bold">善城</span><span class="bold">发起的终止】</span><span
                class="s">出现以下情况时，</span><span class="s">善城</span><span class="s">可以本协议第八条的所列的方式通知您终止本协议：</span>
        </p>
        <p class="m"><span class="s">（一）您违反本协议约定，</span><span class="s">善城</span><span class="s">依据违约条款终止本协议的；</span>
        </p>
        <p class="m"><span class="s">（二）您盗用他人账户、扰乱</span><span class="s">正常</span><span
                class="s">秩序、采取不正当手段谋利等行为，</span><span class="s">善城</span><span class="s">依据</span><span
                class="s">善城</span><span class="s">平台规则对您的账户予以查封的；</span>
        </p>
        <p class="m"><span class="s">（三）除上述情形外，因您多次违反</span><span class="s">善城</span><span
                class="s">平台规则相关规定且情节严重，</span><span class="s">善城</span><span class="s">依据</span><span
                class="s">善城</span><span class="s">平台规则对您的账户予以查封的；</span>
        </p>
        <p class="m"><span class="s">（四）您的账户被</span><span class="s">善城</span><span class="s">依据本协议回收的；</span>
        </p>
        <p class="m"><span class="s">（</span><span class="s">五</span><span class="s">）其它应当终止服务的情况。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">9.2</span><span class="bold">&#xa0;</span><span class="bold">协议终止后的处理</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【用户信息披露】本协议终止后，除法律有明确规定外，</span><span class="bold">善城</span><span
                class="bold">无义务向您或您指定的第三方披露您账户中的任何信息。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【</span><span class="bold">善城</span><span class="bold">权利】</span><span
                class="s">本协议终止后，</span><span class="s">善城</span><span class="s">仍享有下列权利：</span>
        </p>
        <p class="m"><span class="s">（一）继续保存您留存于</span><span class="s">善城</span><span class="s">平台的本协议第五条所列的各类信息；</span>
        </p>
        <p class="m"><span class="s">（二）对于您过往的违约行为，</span><span class="s">善城</span><span
                class="s">仍可依据本协议向您追究违约责任。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【交易处理】本协议终止后，对于您在本协议存续期间产生的交易订单，</span><span class="bold">善城</span><span
                class="bold">可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">十、
                法律适用、管辖与其他</span></p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span
                class="bold">【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【管辖】您因使用</span><span class="bold">善城</span><span
                class="bold">平台服务所产生及与</span><span class="bold">善城</span><span class="bold">平台服务有关的争议，由</span><span
                class="bold">善城</span><span class="bold">与您协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。</span>
        </p>
        <p class="m"><span class="s">&#xa0;</span>
        </p>
        <p class="m"><span class="bold">【可分性】</span><span
                class="s">本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</span>
        </p>
    </div> 
</template>

<script>
import { ref, reactive, onMounted } from "vue";

export default {
  name: "agreement",
  components: {
  
  },
  setup(props) {
   
  },
};
</script>

<style lang="scss">
.privacy-page {
    padding: 0.3rem;
    @include fontSC(0.3rem, #333);
    .bold{
        font-weight:bold;
    }
    .b{
        font-weight:bold;
        text-decoration:underline;
    }
    .m{
        margin:0.06rem 0;
    }
}

</style>