<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">烈士遗属抚恤优待政策问答</div>
            <div class="content-page">
                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList"></div> -->

                <van-collapse v-model="activeNames" accordion>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="1">

                        <template #title>
                            <div class="titles">烈士遗属享受定期抚恤金的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士的父母或者抚养人、配偶无劳动能力、无生活来源，或者收入水平低于当地居民的平均生活水平的；烈士子女未满18周岁或者已满18周岁但因残疾或者正在上学而无生活来源的；由烈士生前供养的兄弟姐妹未满18周岁或者已满18周岁但因正在上学而无生活来源的。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="2">
                        <template #title>
                            <div class="titles">烈士遗属如何申领定期抚恤金？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            符合享受定期抚恤金条件的烈士遗属向户籍所在街道（乡镇）民政部门办理定期抚恤金申领手续，需提供下列材料：申请人的书面申请（写明申请人身份、申请理由）、申请人身份证、户口簿、无劳动能力无收入证明或丧失劳动能力无收入相关证明（劳动部门出具的无工作证明或失业就业登记证、丧劳鉴定等）、征地、里弄生产组补差对象提供收入证明、已满18周岁但因残疾或者正在上学而无生活来源的相关证明。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="3">
                        <template #title>
                            <div class="titles">烈士生前的配偶再婚后可否享受补助？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士生前的配偶再婚后继续赡养烈士父母，继续抚养烈士未满18周岁或者已满18周岁但无劳动能力、无生活来源且由烈士生前供养的兄弟姐妹的，由其户口所在地的区县民政局参照烈士遗属定期抚恤金标准给予补助。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="4">
                        <template #title>
                            <div class="titles">烈士遗属享受定期抚恤金差额补助的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            按照上海市政策，对超过退休年龄且收入水平低于相应定期抚恤金标准的烈士的父母（抚养人）、配偶以及子女，由其户籍所在地区县民政局按照烈士遗属定期抚恤金标准给予差额补助。
                            “超过退休年龄”是指按规定已领取养老金的；按规定不能或尚未领取社会养老金，且男满60周岁、女满55周岁的。
                            “收入水平”是指各类养老金（城镇企业基本养老金按原办法计算）、退休退养金、共享费与残疾抚恤金、定期定量补助、定期抚慰金等各种固定收入的合计。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="5">
                        <template #title>
                            <div class="titles">烈士遗属如何申领定期抚恤金差额补助？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            符合享受定期抚恤金差额补助条件的烈士遗属向户籍所在街道（乡镇）民政部门办理定期抚恤金差额补助申领手续，需提供下列材料：申请人的书面申请（写明申请人身份、申请理由）、申请人身份证、户口簿、个人收入相关证明。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="6">
                        <template #title>
                            <div class="titles">烈士遗属定期抚恤金差额补助如何进行调整？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市定期抚恤金标准调整后，对照新定期抚恤金标准和烈士遗属现养老金等各项收入进行差额补助，一是对原纳入的差额补助对象进行调整发放；二是对超过定期抚恤金标准的对象停止发放。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="7">
                        <template #title>
                            <div class="titles">享受定期抚恤金的烈士遗属户籍农转非的，定期抚恤金如何发放？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            原享受农村定期抚恤金的烈士遗属，因征用土地等原因转为非农业户口后，领取的征地或镇保养老金低于城镇定期抚恤金的，按城镇标准给予补足。
                            原享受农村定期抚恤金的烈士遗属户籍农转非后，仍符合享受定期抚恤金条件的，按城镇定期抚恤金标准发放。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="8">
                        <template #title>
                            <div class="titles">享受定期抚恤金的烈士遗属死亡后，丧葬补助费如何计发？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期抚恤金的烈士遗属死亡的，增发6个月其原享受的定期抚恤金作为丧葬补助费。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="9">
                        <template #title>
                            <div class="titles">烈士遗属定期抚慰金的享受条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            根据上海市政策，有本市常住户口的烈士在职（含离退休）的父母、配偶、子女和不在职的烈士已成年子女可享受定期抚慰金。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="10">
                        <template #title>
                            <div class="titles">烈士遗属的医疗优待有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期抚恤金的烈士遗属，按照属地原则相应参加本市居民医疗保险和新型农村合作医疗，个人缴费部分由所在区县财政承担。其当年内发生的医疗费用经相应医疗保障体系报销，并已实施各类医疗救助、互助、减负后，其剩余医疗费用中符合城镇职工基本医疗保险报销范围，但个人负担部分仍较重的，可申请优抚对象医疗补助。医疗补助的给付比例应不低于50%。
                            烈士遗属到医疗机构就医时，可享受优先挂号、优先就诊、优先化验、优先付费、优先取药、优先住院等待遇。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="11">
                        <template #title>
                            <div class="titles">烈士遗属租赁公有住房有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士遗属租赁公有住房的，可按现行公有住房标准租金的50%缴付房租。已按本市“公有住房租金调整办法”享受减免，每月实付租金低于现行标准租金50%，仍执行原实付租金。
                            烈士遗属在承租户购买公有住房时，可按控制标准增加10平方米的建筑面积，并可按购房当年的公有住房出售政策核定实际出售总价后享受10%的一次性减免购房款的优惠。
                            烈士遗属在动迁分房时，应将烈士计为分房人口。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="12">
                        <template #title>
                            <div class="titles">烈士子女在入学方面有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士子女在国家举办的学校上学，免交学杂费；入国家举办的的托儿所、幼儿园，托费中由家长承担的部分给予减免；入学入托的，在同等条件下优先接收；在公办学校学习期间免交学费、杂费，对其中寄宿学生酌情给予生活补助。符合本市中考报名条件，参加本市中考报考普通高中的，降20分录取；符合本市高考条件，参加本市高考的烈士子女，可以降20分，由报考学校审核录取。
                            上海市拥军优属基金会对烈士子女给予一定的资助，小学和初中期间每学年2000元；高中（含中专）期间每学年4000元；大学（含大专）期间每学年8000元；对去国外读研究生的对象暂按15000元发放。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="13">
                        <template #title>
                            <div class="titles">烈士遗属在税收上有什么优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士遗属所取得的劳动所得，实行按定额减征个人所得税办法。减征项目为：工资、薪金所得；个体工商户的生产、经营所得；对企事业单位的承包经营、承租经营所得；劳务报酬所得；稿酬所得；特许权使用费所得。符合条件的烈属可向主管税务机关书面提出减征个人所得税的申请，同时附报由民政部门出具的烈属证明（原件和复印件）。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="14">
                        <template #title>
                            <div class="titles">烈士遗属集中供养有哪些条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            孤老烈属、未满十六岁的烈士遗孤和患有残病生活不能自理、家中无人照顾的烈士子女。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="15">
                        <template #title>
                            <div class="titles">如何办理烈士遗属关系转移？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士遗属户籍地发生变化，应办理关系转移。本人应向原户籍所在街道（乡镇）提出书面申请，并提供迁入地户籍证明材料，按规定办理关系转移手续。
                            享受抚恤补助的烈士遗属跨省市转移的，户口迁出地的县级人民政府民政部门发放当年的定期抚恤补助，户口迁入地的县级人民政府民政部门凭抚恤补助关系转移证明，从第二年1月起发放定期抚恤补助。本市范围内转移的，户口迁出地的区县民政部门发放当月的定期抚恤补助，从下个月起由迁入地的区县民政部门发放定期抚恤补助。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="16">
                        <template #title>
                            <div class="titles">《上海市烈属优待证》的优待范围？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            ①乘坐市域范围内公共汽（电）车线路和轨道交通线路的车辆（机场线、磁悬浮专线除外）实行免费；
                            ②本市各类向公众开放的收费观瞻场所参观和游览实行优待；
                            ③在本市各类向公众开放的文化和体育场所观看电影、演出和体育比赛实行优惠；
                            ④在本市各医疗机构享受优先挂号、优先就诊、优先化验、优先付费、优先取药、优先住院的优待服务；
                            ⑤本市有关单位规定的其他优待内容。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="17">
                        <template #title>
                            <div class="titles">《上海市烈属优待证》遗失如何办理补证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            如何办理烈士遗属关系转移？
                            《上海市烈属优待证》遗失，持证人需在《解放日报》或《文汇报》或《新民晚报》上刊登遗失作废声明（费用自理），本人向街道（乡镇）提交书面补证申请，提供两张近期1寸彩照和刊登遗失作废声明的报纸原件。区县民政局核定后，送市民政局复核后办理。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="18">
                        <template #title>
                            <div class="titles">《上海市烈属优待证》损坏如何办理换证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            《上海市烈属优待证》损坏或者不能正常使用的，持证人需向街道（乡镇）提交书面换证申请，交验损坏的《上海市烈属优待证》。区（县）民政局核定后，送市民政局复核后办理。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="19">
                        <template #title>
                            <div class="titles">春节期间烈士遗属有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            每年春节期间，上海市人民政府向烈士遗属按户赠送慰问品；为烈士遗属按户张贴“光荣人家”。烈士遗属病故后，不再张贴“光荣人家”。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="20">
                        <template #title>
                            <div class="titles">烈士遗属抚恤补助是否计入家庭收入？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在建立和实施最低生活保障制度中，烈士遗属的抚恤补助不计入家庭收入。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="21">
                        <template #title>
                            <div class="titles">烈士遗属异地祭扫有何规定？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士在我国境内（不含港、澳、台等地区）有明确安葬地的，其遗属户籍在本市的，可向所在区县民政部门申请异地祭扫。
                            异地祭扫原则上每三年一次，前往祭扫的亲属及陪护人员按每位烈士限三人以内计算，由烈士遗属户籍所在地的民政部门按规定给予补助。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="22">
                        <template #title>
                            <div class="titles">烈士遗属的法律责任有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            烈士遗属被判处有期徒刑、剥夺政治权利或者被通缉期间，中止其抚恤优待；被判处死刑、无期徒刑的，取消其抚恤优待资格。
                            有下列行为之一的，由区县民政局给予警告，限期退回非法所得；情节严重的，停止其享受的抚恤、优待；构成犯罪的，依法追究刑事责任：
                            （一）冒领抚恤金、优待金、补助金的；
                            （二）虚报病情骗取医药费的；
                            （三）出具假证明，伪造证件、印章骗取抚恤金、优待金、补助金的。 </div>
                    </van-collapse-item>
                    
                </van-collapse>


            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon, Collapse, CollapseItem } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const ArrList = ref([
            {
                id: '1',
                title: '1',
                name: '2'
            }
        ])

        const activeNames = ref('1');


        return {
            ArrList,
            activeNames,
        }
    }
})
</script>
<style lang="scss" scoped>
.titles {
    height: 15vw;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.35rem;
    font-weight: 700;
}

.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.8rem;
    // width: 80vw;
    padding: 0.26rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  