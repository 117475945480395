<template>
    <div class="updatePw-page">
        <iframe id="myIframe" src="/static/xinhua/page/DengLu/index.html" frameborder="0"></iframe>
    </div>
</template>
  
<script>
import {
    defineComponent,
    createApp,
    computed,
    reactive,
    ref,
    onMounted,
    nextTick,
    provide
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import { useRouter } from 'vue-router'
import mixin from "@/mixin/mixin.js";
import axios from 'axios'
import moment from 'moment';


export default defineComponent({
    components: {
    },
    props: {},
    // beforeRouteEnter: (to, form, next) => {
    //     //to 到哪里去
    //     //form 从哪里来
    //     // let usname = localStorage.getItem('username');
    //     // let word = localStorage.getItem('password');
    //     // console.log(to, form)
    //     next()

    // },
    setup(props) {
        let usname = localStorage.getItem('username');
        let word = localStorage.getItem('password');

        onMounted(() => {
            console.log(usname, word)
            console.log( localStorage.token1)
            localStorage.token1 == undefined ? window.location = 'https://www.shuzhixinhua.cn/' : ''
        });

        return {

        };
    }
});
</script>
<style lang="scss">
#myIframe {
    // position: fixed;
    //   top: 0;
    //   left: 0;
    width: 100%;
    height: 100vh;
    border: none;
    // margin-top: -10px;
}
</style>
  