import { showAlert, showConfirm } from '@/utils/popup.js'

export default {
  install(app) {
    // vue.config.globalProperties.showAlert = showAlert
    // vue.config.globalProperties.showConfirm = showAlert
    app.provide('showAlert', showAlert)
    app.provide('showConfirm', showConfirm)
  }
}
