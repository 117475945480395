<template>
  <div class="soldierDetailAgreement-page">
    <div class="con">
      <p class="title"><span class="bold">可兑换门店地址</span><div class="close" @click="cancel"><img class="img" src="@/assets/images/close_sd.png" alt="" /></div></p>
      <div class="m">
        <span>番禺路店：上海市长宁区新华路街道番禺路390号F1室 福福饼店<br />延平路店：上海市静安区曹家渡街道延平路249号 福福饼店<br />苗圃路店：上海市浦东新区洋泾街道苗圃路230号一层 福福饼店<br />甜爱路店：上海市虹口区四川北路街道四川北路2058号底层 福福饼店<br />南翔印象城店：上海市嘉定区南翔镇陈翔路2299号 南翔印象城B1-K15 福福饼店<br />千树商场店：上海市普陀区长寿路街道莫干山路600号天安千树W-B132 福福饼店<br />吴江路店：上海市静安区南京西路街道吴江路198号 福福饼店<br />蒙自路店：上海市黄浦区打浦桥街道蒙自路169号103，104 福福饼店<br />闻喜路店：上海市静安区彭浦新村街道闻喜路972号 福福饼店<br />大学路（五角场）店：上海市杨浦区大学路258号 福福饼店<br />环球港店：上海市普陀区中山北路3300号环球港B1068室 福福饼店<br />合生汇店：上海市杨浦区黄兴路2228号B2层B2-K-11号 福福饼店<br />武夷路店：上海市长宁区武夷路401号沿街底楼 福福饼店<br />乌鲁木齐中路店：上海市徐汇区乌鲁木齐中路243号-4 福福饼店<br />天空之城店：上海市青浦区徐泾镇崧泽大道2229弄66号L2-44（天空之城）福福饼店<br />仲盛店：上海市闵行区都市路5001号地下1层01-G03 福福饼店<br />LCM店：上海市浦东新区张杨路2389弄1-2号B1层46室（旭辉广场）福福饼店</span>
        <div class="tip">
         （适用门店除芮欧百货店外，均可适用，附近门店可通过美团/大众点评搜索）
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'

export default {
  name: 'soldierDetailAgreement',
  components: {},
  emits: ['confirm', 'cancel'],
  setup(props, context) {
    const confirm = () => {
      context.emit('confirm')
    }
    const cancel = () => {
      context.emit('cancel')
    }
    return {
      confirm,
      cancel
    }
  }
}
</script>

<style lang="scss">
.soldierDetailAgreement-page {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  padding-bottom: 2.71rem;
  @include fontSC(0.28rem, #333);
  font-size: 0.28rem;
  padding: 0.97rem 0.3rem;
  .con {
    position: relative;
    background: #FFFFFF;
    border-radius: 0.2rem;
    padding-top: 1.27rem;
  }
  .title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-size: 0.38rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    padding: 0.48rem 0 0.42rem;
    border-bottom: 1px solid #E0E0E0;
  }
  .bold {
    font-weight: bold;
  }
  .m {
    line-height: 0.48rem;
    margin: 0.06rem 0;
    padding: 0.36rem;
    max-height: calc(100vh - 3.2rem);
    overflow-y: scroll;
  }
  .tip {
    color: #FF2C01;
    margin-top: 0.5rem;
  }
  .close {
    position: absolute;
    width: 0.38rem;
    height: 0.38rem;
    top: 0.25rem;
    right: 0.25rem;
    .img {
      display: block;
      width: 100%;
    }
  }
}
</style>
