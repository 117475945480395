<template>
  <div class="orderDetail-page">
    <div class="detail">
      <div class="item">订单编号：{{ orderInfo.orderNo }}</div>
      <div class="item">下单时间：{{ orderInfo.createTime }}</div>
      <div class="item">
        交易状态：{{ orderInfo.stauts == 'FETCHED' ? '已领取' : '未领取' }}
      </div>
      <div v-if="orderInfo.stauts == 'FETCHED'" class="item">
        使用时间： {{ orderInfo.fetchTime }}
      </div>
    </div>

    <div class="pro-box">
      <div class="title">商品信息</div>
      <div class="con">
        <img class="l" :src="orderInfo.productUrl" alt="" />
        <div class="r">
          <p class="des">{{ orderInfo.productName }}</p>
          <p class="memo">
            <span class="total">{{ orderInfo.price }}公益豆</span>X{{
              orderInfo.num
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="pay-box">
      <div class="title">支付信息</div>
      <div class="con">
        <div class="memo">商品总额：{{ orderInfo.orderAmount }}公益豆</div>
        <div class="num">交易数量：X{{ orderInfo.num }}</div>
        <div class="price">
          实付：<span class="total">{{ orderInfo.paidAmount }}公益豆</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import OrderService from '@/api/order.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const no = route.query.no

    const state = reactive({
      orderInfo: {}
    })

    const initData = async () => {
      const data = await OrderService.loadOrderDetail({
        orderNo: no
      })
      console.log(data)
      if (!data) return
      const { order } = data
      state.orderInfo = order
    }

    onMounted(() => {
      initData()
    })

    const tabChange = ({ name }) => {}
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style lang="scss">
.orderDetail-page {
  min-height: 100vh;
  padding-top: 0.21rem;
  background: #f8f8f8;
  .detail {
    padding: 0.36rem 0.28rem;
    background: #fff;
    .item {
      @include fontSC(0.28rem, #868686);
      margin-bottom: 0.34rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pro-box {
    margin: 0.21rem 0 0.2rem;
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      padding-left: 0.29rem;
      &::before {
        content: '';
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      padding: 0.26rem 0.21rem 0.26rem 0.56rem;
      .l {
        width: 1.85rem;
        height: 1.86rem;
        border: 1px solid #e6e6e6;
        margin-right: 0.33rem;
      }

      .r {
        @include fontSC(0.3rem, #5f5f5f);
        .des {
          @include fontSC(0.32rem, #282828);
          margin-bottom: 0.7rem;
          line-height: 1.3em;
        }
        .memo {
          @include fontSC(0.3rem, #5f5f5f);
          .total {
            @include fontSC(0.32rem, #fb3e28);
          }
        }
      }
    }
  }

  .pay-box {
    background: #fff;
    @include fontSC(0.3rem, #000);
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      padding-left: 0.29rem;
      &::before {
        content: '';
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      padding: 0.25rem 0.29rem 0.42rem 0.31rem;
      @include fontSC(0.28rem, #868686);
      .memo {
        margin-bottom: 0.33rem;
      }
      .num {
        margin-bottom: 0.42rem;
      }

      .price {
        text-align: right;
        @include fontSC(0.3rem, #5f5f5f);
        .total {
          @include fontSC(0.32rem, #fb3e28);
        }
      }
    }
  }
}
</style>
