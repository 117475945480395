<template>
  <div class="bean-page">
    <div class="top-box">
      <div class="title">赚豆攻略</div>
      <!-- <div class="rule">公益豆规则</div> -->
      <div class="my-bean">
        <div class="l">
          <!-- <img src="@/assets/images/bean/bean.png" alt="" /> -->
          <!-- 我的公益豆 -->特别提示：凭兑换商品二维码在新华慈善超市（地址：法华镇路712号），每兑换一个商品需任意金额（本次消费额不计入公益豆）现金消费一次，即可兑换，购买成功后，需1个月内到慈善超市领取，过期自动作废。
        </div>
        <span class="t"></span>
        <!-- <span class="t">{{ balance }}</span> -->
      </div>
    </div>

    <div class="list">
      <div
        class="item"
        v-for="item in rateListData"
        :key="item.id"
        @click="showRule(item)"
      >
        <div class="l" :class="typeMap[item.instructType]"></div>
        <div class="r">
          <div class="top">
            <div class="des" style="width: 88%">
              <p class="title">{{ item.instructTypeDesc }}</p>
              <p class="memo">{{ item.activityDesc }}</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">
            每{{
              item.instructType == 'DONATE_BLOOD' ? '100ml' : item.unit
            }}兑换<span class="t"
              >{{
                item.instructType == 'DONATE_BLOOD' ? '50' : item.rate
              }}个</span
            >公益豆
          </div>
        </div>
      </div>
      <!-- <div class="item">
        <div class="l l2"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">慈善超市购物</p>
              <p class="memo">前往慈善超市购物，赚取更多公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">每10元<span class="t">+1</span>个</div>
        </div>
      </div>
      <div class="item">
        <div class="l l3"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">献血</p>
              <p class="memo">参加献血活动，赚取更多公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">每1ml<span class="t">+2.5</span>个</div>
        </div>
      </div>
      <div class="item">
        <div class="l l4"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">现金捐赠</p>
              <p class="memo">参加现金捐赠活动，赚取更多公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">每1元<span class="t">+2.5</span>个</div>
        </div>
      </div>
      <div class="item">
        <div class="l l5"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">其他志愿服务</p>
              <p class="memo">参加活动，赚取更多公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">1活力值<span class="t">+0.5</span>个</div>
        </div>
      </div>
      <div class="item">
        <div class="l l6"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">惠老通</p>
              <p class="memo">前往惠老通，获得活力值，兑换公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">1活力值<span class="t">+0.5</span>个</div>
        </div>
      </div>
      <div class="item">
        <div class="l l7"></div>
        <div class="r">
          <div class="top">
            <div>
              <p class="title">个人捐物</p>
              <p class="memo">前往慈善超市捐物，赚取更多公益豆</p>
            </div>
            <div class="arrow">
              <img class="img" src="@/assets/images/w-right-arrow.png" alt="" />
            </div>
          </div>
          <div class="bot">每1元<span class="t">+1</span>个</div>
        </div>
      </div> -->
    </div>

    <div class="dialog" v-if="showDiaFlag">
      <div class="con">
        <div class="title">{{ currDialogData.instructTypeDesc }}</div>
        <div class="mid">
          {{ currDialogData.activityDetail }}
        </div>
        <div class="btn" v-if="currDialogData.instructType === 'TIME_BANK'">
          <a
            class="tel"
            style="color: #f54029"
            :href="`tel:${currDialogData.mobile}`"
            >拨打电话了解详情</a
          >
        </div>
        <div
          class="btn"
          @click="goTimeBank"
          style="margin-top: 0.38rem"
          v-if="currDialogData.instructType === 'TIME_BANK'"
        >
          <span class="tel" style="color: #f54029">我要报名</span>
        </div>
        <div
          class="btn"
          @click="goTimeBank"
          style="margin-top: 0.38rem"
          v-if="currDialogData.instructType === 'VITALITY_OLD'"
        >
          <wx-open-launch-weapp
            class="opentag"
            username="gh_60c39ac2d973"
            path=""
          >
            <div v-is="'script'" type="text/wxtag-template">
              <span class="tel" style="color: #f54029">打开惠老通</span>
            </div>
          </wx-open-launch-weapp>
        </div>

        <!-- <div class="btn" @click="goXcx" style="margin-top:0.38rem;" v-if="currDialogData.instructType==='VITALITY_OLD'"><span class="tel" style="color:#F54029;" >打开惠老通</span></div> -->

        <div class="close" @click="closeRule">
          <img src="@/assets/images/bean/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import UserService from '@/api/common.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const typeMap = {
      TIME_BANK: 'l1',
      CJARITY_SUPERMARKET: 'l2',
      DONATE_BLOOD: 'l3',
      CASH_DONATION: 'l4',
      PERSION_DONATION: 'l7',
      VITALITY_OLD: 'l6',
      ENTRY_DONATION: 'l7',
      OTHER_VOLUNTEERS_SERVER: 'l8',
      REFUSE_CLASSIFICATION: 'l9'
    }

    const rateListData = ref([])
    const currDialogData = ref({})
    const wxConfig = ref({})
    const showDiaFlag = ref(false)
    const balance = sessionStorage.balance
    const initData = async () => {
      const data = await UserService.makeMoneyStrategy({
        uid: localStorage.uid
      })
      if (!data) return
      const { rateList } = data
      rateListData.value = rateList.filter((item) => {
        return (
          item.instructType !== 'CASH_DONATION_PERSION' &&
          item.instructType !== 'CASH_DONATION_ENTRY'
        )
      })
    }

    onMounted(() => {
      initData()
      getWxConfig()
    })

    const getWxConfig = async () => {
      const data = await UserService.getWxConfig({
        // uid: localStorage.uid,
        // jsApiList: ['wx-open-launch-weapp'],
        url: window.location.href
      })
      if (!data) return
      const { signature, timestamp, nonceStr } = data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: 'wx267b6224a7e9475c', // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ['wx-open-launch-weapp'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      })
      // wxConfig.value = rateList
    }

    const goTimeBank = () => {
      router.push({
        path: '/timeBank'
      })
    }
    const goXcx = () => {
      wx.ready(function () {})
    }
    const showRule = (item) => {
      currDialogData.value = item
      showDiaFlag.value = true
    }
    const closeRule = () => {
      showDiaFlag.value = false
    }
    return {
      goTimeBank,
      goXcx,
      currDialogData,
      showDiaFlag,
      showRule,
      closeRule,
      rateListData,
      typeMap,
      balance
    }
  }
})
</script>
<style lang="scss">
.bean-page {
  min-height: 100vh;
  padding-bottom: 1.2rem;
  background: #f8f8f8;
  .top-box {
    width: 100%;
    height: 5.16rem;
    text-align: center;
    background: url(../../assets/images/bean/bg.png);
    background-size: cover;
    padding-top: 0.98rem;
    .title {
      @include fontSC(0.62rem, #fff);
      margin-bottom: 0.42rem;
    }
    .rule {
      @include fontSC(0.18rem, #fff);
      width: 1.41rem;
      line-height: 0.43rem;
      background: rgba($color: #fff, $alpha: 0.2);
      border-radius: 0.22rem;
      margin: 0 auto 0.28rem;
    }
    .my-bean {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .l {
        // display: flex;
        // align-items: center;
        @include fontSC(0.18rem, #fff);
        margin-right: 2px;
        padding: 0 0.5rem;
        text-align: left;
        line-height: 1.8em;
        img {
          width: 0.18rem;
          height: 0.17rem;
          margin-right: 2px;
        }
      }
      .t {
        @include fontSC(0.3rem, #fff);
      }
    }
  }

  .list {
    padding: 0 0.25rem;
    margin: -1.45rem auto 0;
    .item {
      border-radius: 0.2rem;
      height: 2.96rem;
      padding: 0 0.26rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.28rem;
      background: #fff;
      .l {
        width: 1.3rem;
        height: 1.4rem;
        margin-right: 0.34rem;
        &.l1 {
          background: url(../../assets/images/love/l1.png);
          background-size: cover;
        }
        &.l2 {
          background: url(../../assets/images/love/l2.png);
          background-size: cover;
        }
        &.l3 {
          background: url(../../assets/images/love/l3.png);
          background-size: cover;
        }
        &.l4 {
          background: url(../../assets/images/love/l4.png);
          background-size: cover;
        }
        &.l5 {
          background: url(../../assets/images/love/l5.png);
          background-size: cover;
        }
        &.l6 {
          background: url(../../assets/images/love/l6.png);
          background-size: cover;
        }
        &.l7 {
          background: url(../../assets/images/love/l7.png);
          background-size: cover;
        }
        &.l8 {
          background: url(../../assets/images/love/l8.png);
          background-size: cover;
        }
        &.l9 {
          background: url(../../assets/images/love/l9.png);
          background-size: cover;
        }
      }

      .r {
        flex: 1;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            @include fontSC(0.4rem, #333);
            margin-bottom: 0.2rem;
          }
          .memo {
            @include fontSC(0.22rem, #999);
            line-height: 1.3em !important;
          }
          .arrow {
            width: 0.49rem;
            height: 0.49rem;
            background: #f65341;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            img {
              width: 0.116rem;
              height: 0.21rem;
            }
          }
        }
        .bot {
          margin-top: 0.45rem;
          .t {
            @include fontSC(0.22rem, #f65341);
          }
        }
      }
    }
  }

  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      background: #fff;
      width: 6.3rem;
      border-radius: 0.2rem;

      padding: 0.65rem 0.45rem 0.92rem;
      .title {
        @include fontSC(0.38rem, #333);
        font-weight: bold;
        text-align: center;
      }
      .mid {
        @include fontSC(0.3rem, #666);
        line-height: 1.5em;
        margin: 0.3rem 0;
      }
      .btn {
        @include fontSC(0.3rem, #f65341);
      }
      .close {
        position: absolute;
        top: 0.27rem;
        right: 0.27rem;
        width: 0.39rem;
        height: 0.39rem;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
