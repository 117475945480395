<template>
  <div class="gyb-qrcode-page">
    <qrcode-vue :value="urlData"></qrcode-vue>
    <!-- <div class="des">
      <span class="l">物品名称：</span>
      <span class="r">{{ info.productName }}</span>
    </div> -->
    <div class="pro-box">
      <div class="title">公益包信息</div>
      <div class="con">
        <div class="txt">公益包名称： {{ info.productName }}</div>
        <div class="txt">适用人群：{{ info.memberLevel }}</div>
        <div class="txt">使用时间：{{ info.lastUpdateTime }}</div>
      </div>
    </div>
    <!-- <div class="des">
      <span class="l">兑换人姓名：</span><span class="r">{{ info.name }}</span>
    </div>
    <div class="des">
      <span class="l">兑换时间：</span><span class="r">{{ info.time }}</span>
    </div>
    <div class="des">
      <span class="l">兑换公益豆数量：</span
      ><span class="r">{{ info.num }}</span>
    </div> -->
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { defineComponent, computed, onMounted, ref } from 'vue'
import QrcodeVue from 'qrcode.vue'
import LuckyService from '@/api/lucky.js'
import { useRouter, useRoute } from 'vue-router'

export default {
  components: {
    QrcodeVue
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const no = route.query.no
    const urlData = ref(`Gyb_${no}`)
    const dialogInfo = computed(() => store.state.dialogInfo)
    const uid = localStorage.uid
    const info = JSON.parse(sessionStorage.lqInfo)
    console.log(info)
    const recordListData = ref([])
    if (!info.exprieTime && info.isGyb == 'true') urlData.value = no
    onMounted(() => {
      //   initData();
    })
    const initData = async () => {
      const data = await LuckyService.myDrawRecord({
        uid: uid,
        pagesize: 100,
        startRow: 0
      })
      console.log(data)
      if (!data) return
      const { recordList } = data
      recordListData.value = recordList
    }

    return {
      recordListData,
      dialogInfo,
      urlData,
      info
    }
  }
}
</script>

<style lang="scss" scoped>
.gyb-qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.93rem 0 0;
  .des {
    margin-bottom: 0.3rem;
    @include fontSC(0.34rem, #999);
    display: flex;
    //   justify-content: space-between;
    .l {
      width: 45%;
    }
    .r {
      flex: 1;
      line-height: 1.2em;
    }
  }
  canvas {
    display: block;
    margin: 0 auto 0.65rem;
    width: 4.5rem !important;
    height: 4.5rem !important;
  }
  .pro-box {
    background: #fff;
    margin-bottom: 0.29rem;
    @include fontSC(0.28rem, #000);
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      padding-left: 0.29rem;
      &::before {
        content: '';
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      padding: 0.26rem 0.29rem 0.37rem 0.31rem;
      @include fontSC(0.28rem, #868686);
      .txt {
        margin-bottom: 0.14rem;
      }
    }
  }
}
</style>
