import {
  post_http, zucan
} from './axios'

class OrderService {
  static createOrder(params) {
    return post_http('/order/create', params)
  }
  static loadOrderDetail(params) {
    return post_http('/order/loadOrderDetail', params)
  }

  static queryOrder(params) {
    return post_http('/order/qryOrder', params)
  }
  static qryWelfarePackage(params) {
    return post_http('/order/qryWelfarePackage', params)
  }
  static qryMyCombo(params) {
    return zucan('/mananger/order/orderManagement/page', params)
  }
  static qryWelfarePackageDetail(params) {
    return post_http('/order/qryWelfarePackageDetail', params)
  }
  static qryMyGyb(params) {
    return post_http('/order/qryMyGyb', params)
  }
  static qryGybDetail(params) {
    return post_http('/order/qryGybDetail', params)
  }
  static qryMyGybDetail(params) {
    return post_http('/order/qryMyGybDetail', params)
  }
  static qryMyFlb(params) {
    return post_http('/order/qryMyWelfare', params)
  }
  static qryFlbDetail(params) {
    return post_http('/order/qryWelfareDetail', params)
  }
  static qryMyFlbDetail(params) {
    return post_http('/order/qryMyWelfareDetail', params)
  }
  static fillCheck(params) {
    return post_http('/order/fillCheck', params)
  }
}

export default OrderService