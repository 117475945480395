<template>
  <teleport to='body'>
    <div class="popup_ui" id="popup_ui" ref="elRef" v-show="opened">
      <div class="overlay"></div>
      <div class="wrap">
        <div class="wrap-section">
          <div class="wrap-title" v-if="title" v-html="title"></div>
          <!-- 判断插槽是否存在 -->
          <div class="wrap-con" v-html="content"></div>
          <div v-if="btns" class="btns-box">
            <div class="aaa"></div>
            <div style="height: 1px;color: red;"></div>
            <span v-for="(btn, index) in btns" :class="{ 'btns-box': btns.length >= 2 }"
              @click="btnClicked($event, index)" :key="index" class="btn" v-html="btn.text" :style="btn.style"></span>
          </div>

        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watch, toRefs, nextTick } from 'vue'

export default defineComponent({
  name: "popup",
  props: {
    modelValue: { type: Boolean, default: false },
    title: String,
    content: String,
    type: String,
    btns: {
      type: Array, default: null
    },
    onSuccess: { type: Function, default: null },
    onEnd: { type: Function, default: null },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const elRef = ref('')
    const data = reactive({
      opened: true,
    })

    // 监听弹层v-model
    watch(() => props.modelValue, (val) => {
      if (val) {
        open()
      } else {
        close()
      }
    })

    const open = () => {
      if (data.opened) return
      data.opened = true
      typeof props.onSuccess === 'funtion' && props.onSuccess()
      const dom = elRef.value
      dom.style.zIndex = getZIndex() + 1

    }
    const close = () => {
      if (!data.opened) return
      data.opened = false
      setTimeout(() => {
        context.emit('update:modelValue', false)
        typeof props.onEnd === 'funtion' && props.onEnd()
      })

    }

    const btnClicked = (e, index) => {
      let btn = props.btns[index];
      if (!btn.disabled) {
        typeof btn.click === 'function' && btn.click(e)
      }
    }



    return {
      ...toRefs(data),
      elRef,
      close,
      btnClicked,
    };
  }
});
</script>
<style lang="scss">
.popup_ui {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
    background: #fff;
    width: 6.3rem;
    border-radius: 0.2rem;
    overflow: hidden;
    padding: 0.67rem 0 0;

    .wrap-section {}

    .wrap-title {
      color: #333;
      font-size: 0.38rem;
      font-weight: bold;
      text-align: center;
      padding-bottom: 0.42rem;
    }

    .wrap-con {
      text-align: center;
      color: #101010;
      font-size: 0.3rem;
      padding: 0.1rem 0 0.5rem;
    }

    .btns-box {
      display: flex;

      .btn {
        flex: 1;
        height: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #101010;
        font-size: 0.3rem;
        position: relative;


        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          top: 0;
          height: 1px;
          background: #eee;
          transform: scaleY(0.5);
        }

        &.confirm {
          /* background: #0086f3;
          color: #fff; */
        }

        &.cancel {
          color: #101010;
          // border-top: thin solid #737373;

        }
      }

      &.btns-box {

        .btn {
          border-top: 0.1vw solid #b4afaf;
          // background-color: red;
          border-radius: 0rem;
          color: #f65341;
          background-color: white;
          border-width: 0.5%;
          margin: 0.69rem auto 0rem;
        //  border-left: 1px solid red;
          // border-right: 1px solid red;
        }
        .btn:first-of-type{
          border-right: 0.1vw solid #b4afaf;
        }

      }
    }
  }
}
</style>
