<template>
  <div class="loveperson-page">
    <div class="top-box">
      <img class="bg" src="@/assets/images/love/loveperson-bg.png" alt="" />
      <div class="avatar">
        <img class="img" :src="headImg" />
      </div>
      <div class="sort">
        <div class="l">第{{ mySort }}名</div>
        <div class="m"></div>
        <div class="r">
          {{ userType === 'persion' ? 1319 + allSort : allSort }}个
        </div>
      </div>
    </div>
    <div class="sort-list">
      <div class="item" v-for="(item, index) in currListData" :key="item.sort">
        <div class="l">
          <p class="n">{{ index > 2 ? item.sort : '' }}</p>
          <img class="img" :src="item.headImage || avatarImg" alt="" />
          <span class="txt">{{ item.name }}</span>
        </div>
        <div class="r">
          <span class="total">{{ item.balance }}</span
          >公益豆
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import UserService from '@/api/common.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const images = ref([])
    const store = useStore()
    const router = useRouter()

    const persionData = ref([])
    const mySort = ref('-')
    const allSort = ref('-')
    const currTab = ref('1')
    const siwperData = ref([])
    const userType = ref(localStorage.userType)
    const headImg = ref(
      localStorage.headImg || require('@/assets/images/gongyi/avatar.png')
    )
    const avatarImg = ref(require('@/assets/images/avatar.png'))

    onMounted(() => {
      initInfo('persion')
    })

    const currListData = computed(() => {
      return persionData.value.map((item) => {
        let name = item.name
        name = !name ? '***' : name[0] + (name.length <= 2 ? '*' : '**')
        return {
          ...item,
          name
        }
      })
    })

    const initInfo = async (type) => {
      const data = await UserService.queryAllSort({
        uid: localStorage.uid,
        userType: type
      })
      console.log(data)
      if (!data) return
      const { sortList } = data
      persionData.value = sortList ? sortList : []
      allSort.value = data.allSort
      mySort.value = data.mySort
    }
    const checkTab = (n) => {
      if (currTab.value === n) return
      currTab.value = n
    }
    const goPerson = (id) => {
      router.push({
        path: '/loveMan',
        query: { id }
      })
    }
    const goenterprise = (id) => {
      router.push({
        path: '/QyProductDetail',
        query: { id }
      })
    }
    return {
      persionData,
      siwperData,
      headImg,
      avatarImg,
      currListData,
      images,
      mySort,
      allSort,
      currTab,
      userType,
      checkTab,
      goPerson,
      goenterprise
    }
  }
})
</script>
<style lang="scss">
.loveperson-page {
  min-height: 100vh;
  padding-bottom: 1.2rem;
  background: #f8f8f8;
  .sort-list {
    width: 6.9rem;
    margin: -1rem auto 0;
    background: #ffffff;
    box-shadow: 0px 0.05rem 0.05rem 0px rgba(252, 55, 37, 0.03);
    border-radius: 0.1rem;
    position: relative;
    z-index: 9;
    min-height: 50vh;
    /* padding: 0.22rem 0; */
    .item {
      position: relative;
      height: 1.2rem;
      @include border-1px(#e6e6e6, bottom);
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* padding: 0 0.3rem; */
      padding-right: 0.39rem;
      margin: 0 0.22rem;
      &:nth-child(-n + 3) .l .n::before {
        content: '';
        position: absolute;
        left: 0;
        top: -0.05rem;
        width: 0.3rem;
        height: 0.5rem;
        background: url(../../assets/images/love/win1.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(1) .l .n::before {
        background: url(../../assets/images/love/win1.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(2) .l .n::before {
        background: url(../../assets/images/love/win2.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(3) .l .n::before {
        background: url(../../assets/images/love/win3.png) 100% 100% no-repeat;
        background-size: cover;
      }
      .l {
        display: flex;
        align-items: center;
        @include fontSC(0.28rem, #8d7575);
        position: relative;
        .n {
          min-width: 0.3rem;
          line-height: 0.51rem;
          text-align: center;
          @include fontSC(0.3rem, #999);
        }
        .img {
          width: 0.65rem;
          height: 0.65rem;
          margin: 0 0.45rem 0 0.24rem;
          border-radius: 0.6rem;
        }
      }

      .r {
        @include fontSC(0.24rem, #fb3e28);
        .total {
          font-size: 0.34rem;
        }
      }
    }
  }

  .top-box {
    position: relative;
    margin-bottom: 0.31rem;
    .bg {
      display: block;
      width: 100%;
      height: 4.39rem;
    }
    .avatar {
      position: absolute;
      top: 0.96rem;
      left: 50%;
      transform: translateX(-50%);
      width: 1.19rem;
      height: 1.19rem;
      border-radius: 0.6rem;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 0.76rem;
        height: 0.83rem;
      }
    }
    .sort {
      position: absolute;
      bottom: 1.53rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      @include fontSC(0.34rem, #fff);
      .m {
        margin: 0 0.64rem;
        width: 1px;
        height: 0.36rem;
        background: #fff;
      }
    }
  }
}
</style>
