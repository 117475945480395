<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">残疾军人抚恤优待政策问答</div>
            <div class="content-page">
                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList"></div> -->

                <van-collapse v-model="activeNames" accordion>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="1">

                        <template #title>
                            <div class="titles">残疾军人的性质、等级？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人按致残的性质分为因战、因公和因病。
                            残疾等级，根据劳动功能障碍程度和生活自理程度确定，由重到轻分为一至十级。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="2">
                        <template #title>
                            <div class="titles">优残疾抚恤金是如何组成的？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市残疾军人的抚恤金是由国家标准和地方增发部分组成。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="3">
                        <template #title>
                            <div class="titles">残疾军人的残疾抚恤金如何发放？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人从被批准残疾等级评定（调整）后的第二个月起，由建立残疾抚恤关系的区县民政局按照规定发给残疾抚恤金。
                            残疾军人残疾抚恤关系由部队或者跨省市转移的，其当年的抚恤金由部队或者迁出地的民政部门负责发给，从第二年起由迁入地的民政部门按当地标准发给。本市范围内转移的，残疾抚恤金次月起由迁入地区县民政局发给。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="4">
                        <template #title>
                            <div class="titles">何种情况下可以调整残疾等级，应提交哪些相关材料？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            调整残疾等级是指对已经评定残疾等级，因残疾情况变化与所评定的残疾等级明显不符的人员，可申请调整残疾等级。
                            调整残疾等级时，本人向区县民政局提出调整残疾等级的书面申请，并提交与原残情鉴定结论有明显变化的医疗诊断证明，主要是其本人6个月以内就相关残疾部位在二级甲等以上医院的就诊病历及医疗检查报告、诊断结论等。
                            区县民政局审核后认为符合调整残疾等级规定的，报市民政局审批。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="5">
                        <template #title>
                            <div class="titles">残疾军人护理费的发放范围？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            对分散安置的一级至四级残疾军人发给护理费。
                            对集中供养的一级至四级残疾军人不再发给护理费。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="6">
                        <template #title>
                            <div class="titles">残疾军人需要集中供养有哪些条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            退出现役的一级至四级残疾军人，由国家供养终身；对需要长年医疗或者独身一人不便分散安置的，经市民政局批准，可以集中供养。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="7">
                        <template #title>
                            <div class="titles">残疾军人证件遗失如何办理补证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人证件遗失，应尽力查找，确实查找不到的，可以办理补证。
                            ①本人应及时在《解放日报》或《文汇报》或《新民晚报》上登报声明作废（费用自理），声明内容应包括姓名、证件名称和伤残证件编号；
                            ②本人向所在单位（街道或乡镇）提出补证书面申请（说明遗失时间、地点、原因），提供刊登声明作废的报纸，本人近期2寸免冠蓝底彩照4张；
                            ③所在单位（街道或乡镇）审核后，向申请人户籍所在地的区县民政局提出补证书面报告（提出审核意见），并将申请人提供的材料报区县民政局；
                            ④区县民政局审核后认为符合补证规定的，报市民政局审批。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="8">
                        <template #title>
                            <div class="titles">残疾军人证件破损如何办理换证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人证件破损严重不能继续使用的，可以办理换证。
                            ①本人应向单位（街道或乡镇）提出换证书面申请（说明损坏时间、地点、原因），并提供旧证和本人近期2寸免冠蓝底彩照4张；
                            ②所在单位（街道或乡镇）审核后，向申请人户籍所在地的区县民政局提出换证书面报告（提出审核情况），并将申请人提供的材料报区县民政局；
                            ③区县民政局审核后认为符合换证规定的，报市民政局审批。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="9">
                        <template #title>
                            <div class="titles">残疾军人的医疗优待有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            一级至六级残疾军人在医院就医时，符合城镇职工基本医疗保险范围内的医疗费用应予保障。
                            七级至十级残疾军人旧伤复发的医疗费用，已经参加工伤保险的，由工伤保险基金支付，未参加工伤保险的，有工作的由工作单位解决，没有工作的由民政部门负责解决。
                            七级至十级残疾军人旧伤复发以外的医疗费用，经相应医疗保障体系报销，并已实施各类医疗救助、互助、减负后，其剩余医疗费用中符合城镇职工基本医疗保险报销范围，但个人负担部分仍较重的，可申请优抚对象医疗补助。医疗补助的给付比例应不低于50%。
                            残疾军人参加本市居民医疗保险和新型农村合作医疗的，个人缴费部分由所在区县财政承担。
                            凭本人《残疾军人证》到医疗机构就医时，可享受优先挂号、优先就诊、优先化验、优先付费、优先取药、优先住院等待遇。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="10">
                        <template #title>
                            <div class="titles">残疾军人能否享受工伤人员待遇？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在国家机关、社会团体、企业事业单位工作的残疾军人，享受与所在单位工伤人员同等的生活福利和医疗待遇。所在单位不得因其残疾将其辞退、解聘或者解除劳动关系。残疾军人残疾等级与工伤职工伤残等级可视为对应。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="11">
                        <template #title>
                            <div class="titles">残疾军人如何申请配置、更新和维修辅助器械？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人伤残情形符合配置、更新和维修辅助器械条件的，可向户籍所在地的街道（乡镇）提出书面申请，并提供相关鉴定材料,对符合条件的,由区县民政部门按规定进行报销。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="12">
                        <template #title>
                            <div class="titles">残疾军人乘坐交通工具有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本人凭《残疾军人证》优先购票乘坐境内运行的火车、轮船、长途公共汽车以及民航班机，享受减收正常票价50%的优待。在本市乘坐公共汽车、电车、专线车（含各种车型）和轨道交通工具，享受免费待遇。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="13">
                        <template #title>
                            <div class="titles">一级至四级残疾军人租赁、购买公有住房方面有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            一级至四级残疾军人租赁公有住房的，可按现行公有住房标准租金的50%缴付房租。已按本市“公有住房租金调整办法”享受减免，每月实付租金低于现行标准租金50%，仍执行原实付租金。
                            一至四级残疾军人在承租户购买公有住房时，可按控制标准增加10平方米的建筑面积，并可按购房当年的公有住房出售政策核定实际出售总价后享受10%的一次性减免购房款的优惠。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="14">
                        <template #title>
                            <div class="titles">残疾军人本人、一级至四级残疾军人子女在入学方面有何优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人本人、一级至四级残疾军人的子女报考普通高中、中等职业学校的，招生时降10分录取。报考高等学校的，在同等条件下优先录取。残疾军人在校学习期间免交学杂费。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="15">
                        <template #title>
                            <div class="titles">残疾军人在个人所得税政策上有什么优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">

                            残疾军人变更国籍后残疾抚恤金可否享受？
                            对于变更为外国国籍的残疾军人，其原由我国有关部门发给的残疾军人证件自然失效（可注明作废留作纪念），有关残疾抚恤待遇也不再享受。
                            在国内异地居住的残疾军人，残疾抚恤金如何申请和办理？
                            在国内异地居住的残疾军人，应当每年向负责支付其残疾抚恤金的民政部门提供一次居住地公安机关出具的居住证明。当年未提交证明的，区县民政部门应当经过公告或者通知其家属提交证明；经过公告或者通知其家属后60日内，残疾军人仍未提供上述居住证明的，从第二年起停发残疾抚恤金。
                            前往港澳台定居或出国定居的残疾军人，残疾抚恤金如何申请和办理？
                            前往香港、澳门、台湾定居或者出国定居的残疾军人，应当每年向负责支付其残疾抚恤金的民政部门提供一次由我国驻外使领馆或者当地公证机关出具的居住证明，由当地公证机关出具的证明书，须经我驻外使领馆认证。香港地区由内地认可的公证人出具居住证明，澳门地区由内地认可的公证人或者澳门地区政府公证部门出具居住证明，台湾地区由当地公证机构出具居住证明。当年未提供上述居住证明的，从第二年起停发残疾抚恤金。
                            在国内异地居住或者前往港澳台定居或出国定居的残疾军人，如何领取残疾抚恤金？
                            在国内异地居住或者前往港澳台定居或出国定居的残疾军人，经向区县民政局申请并办理相关手续后，其残疾抚恤金可以委托他</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="16">
                        <template #title>
                            <div class="titles">残疾军人变更国籍后残疾抚恤金可否享受？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            对于变更为外国国籍的残疾军人，其原由我国有关部门发给的残疾军人证件自然失效（可注明作废留作纪念），有关残疾抚恤待遇也不再享受。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="17">
                        <template #title>
                            <div class="titles">在国内异地居住的残疾军人，残疾抚恤金如何申请和办理？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在国内异地居住的残疾军人，应当每年向负责支付其残疾抚恤金的民政部门提供一次居住地公安机关出具的居住证明。当年未提交证明的，区县民政部门应当经过公告或者通知其家属提交证明；经过公告或者通知其家属后60日内，残疾军人仍未提供上述居住证明的，从第二年起停发残疾抚恤金。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="18">
                        <template #title>
                            <div class="titles">前往港澳台定居或出国定居的残疾军人，残疾抚恤金如何申请和办理？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            前往香港、澳门、台湾定居或者出国定居的残疾军人，应当每年向负责支付其残疾抚恤金的民政部门提供一次由我国驻外使领馆或者当地公证机关出具的居住证明，由当地公证机关出具的证明书，须经我驻外使领馆认证。香港地区由内地认可的公证人出具居住证明，澳门地区由内地认可的公证人或者澳门地区政府公证部门出具居住证明，台湾地区由当地公证机构出具居住证明。当年未提供上述居住证明的，从第二年起停发残疾抚恤金。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="19">
                        <template #title>
                            <div class="titles">在国内异地居住或者前往港澳台定居或出国定居的残疾军人，如何领取残疾抚恤金？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在国内异地居住或者前往港澳台定居或出国定居的残疾军人，经向区县民政局申请并办理相关手续后，其残疾抚恤金可以委托他人代领，也可以委托民政部门邮寄给本人，或者存入其指定的金融机构账户，所需费用由本人负担。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="20">
                        <template #title>
                            <div class="titles">残疾军人如何办理配偶和未成年子女的异地入户和就地农转非，需提供哪些材料？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市民政部门管理的残疾军人，其配偶和未成年子女均可提出户籍异地迁入或就地农转非的申请。
                            残疾军人向户口所在区县民政局提出书面申请，填写《上海市残疾军人配偶（未成年子女）申报常住户口审核表》，区县民政局审核后需报市民政局审核同意，报市公安局批准办理入户手续。
                            异地投靠配偶需提供的材料：1、本人申请书；2、户主同意接收的书面意见；3、当地村委会、乡（镇）政府出具的证明；4、当地派出所出具的申报人员全家的户籍证明；5、随迁子女在校读书的学校证明；6、沪地配偶单位证明；7、结婚证复印件；8、独生子女证复印件；9、申报人身份证复印件；10、沪地配偶户籍证明并注明户口性质。
                            就地投靠配偶需提供的材料：1、本人申请书；2、户主同意接收的书面意见；3、村委会、乡（镇）政府出具的证明；4、子女在校读书的学校证明；5、配偶单位证明；6、结婚证复印件；7、独生子女证复印件；8、申报人身份证复印件；9、申报农转非户口人员的户籍证明；10、被投靠人配偶户籍证明并注明户口性质。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="21">
                        <template #title>
                            <div class="titles">残疾军人死亡后，其遗属有何待遇？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            退出现役的因战、因公致残的残疾军人因旧伤复发死亡的，由区县民政局按照因公牺牲军人的抚恤金标准发给其遗属一次性抚恤金，其遗属享受因公牺牲军人遗属抚恤待遇。
                            退出现役的残疾军人因病死亡的，对其遗属增发12个月的残疾抚恤金，作为丧葬补助费。
                            因战因公致残的一级至四级残疾军人因病死亡的，其遗属享受病故军人遗属抚恤待遇。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="22">
                        <template #title>
                            <div class="titles">中止抚恤的残疾军人如何恢复残疾抚恤？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            中止抚恤的残疾军人在刑满释放并恢复政治权利或者取消通缉后，经本人申请，并经民政部门审查符合条件的，从第二个月起恢复抚恤，原停发的抚恤金不予补发。办理恢复抚恤手续应当提供下列材料：本人申请书、户口簿、司法部门的相关证明。需要重新办证的，按照证件遗失补证规定办理。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="23">
                        <template #title>
                            <div class="titles">残疾军人如何办理残疾抚恤关系转移？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人户籍地发生变化，应办理残疾抚恤关系转移。本人应向原户籍所在街道（乡镇）提出书面申请，并提供迁入地户籍证明材料，按规定办理残疾抚恤关系转移手续。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="24">
                        <template #title>
                            <div class="titles">为残疾军人家庭张贴“光荣人家”有何规定？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            每年春节期间，为残疾军人家庭按户张贴“光荣人家”。 残疾军人病故后，不再张贴“光荣人家”。 </div>
                        <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    </van-collapse-item> <van-collapse-item name="25">
                        <template #title>
                            <div class="titles">残疾抚恤金是否计入家庭收入？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在建立和实施最低生活保障制度中，残疾抚恤金不计入家庭收入。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="26">
                        <template #title>
                            <div class="titles">残疾军人的法律责任有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            残疾军人被判处有期徒刑、剥夺政治权利或者被通缉期间，中止其残疾抚恤优待；被判处死刑、无期徒刑的，取消其残疾抚恤优待资格。
                            有下列行为之一的，由区县民政局给予警告，限期退回非法所得；情节严重的，停止其享受的残疾抚恤、优待；构成犯罪的，依法追究刑事责任：
                            （一）冒领残疾抚恤金、优待金、补助金的；
                            （二）虚报病情骗取医药费的；
                            （三）出具假证明，伪造证件、印章骗取抚恤金的。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="27">
                        <template #title>
                            <div class="titles">之前领了地方退役军人事务部门发的拥军卡等，这次还可以申请优待证吗？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            可以申请。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="28">
                        <template #title>
                            <div class="titles">退伍证丢失是否影响申领？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            找到原部队进行补办；如在档案中能查询到其相关退役信息的可以申领。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="29">
                        <template #title>
                            <div class="titles">优待证申领期间，是否影响现有残疾军人证和优待证的使用？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证的发放不影响《中华人民共和国残疾军人证》的使用；现有《上海市烈属优待证》《上海市因公牺牲军人遗属优待证》《上海市病故军人遗属优待证》《上海市重点优抚对象医疗优待证》至2022年12月31日前继续有效。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="30">
                        <template #title>
                            <div class="titles">优待证是否需要进行年审？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证没有使用年限。但是，由于优待证以银行借记卡为载体，按照银行规定，借记卡有一定的有效期。有效期到期后，持证人应前往合作银行营业网点提出换领申请。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="31">
                        <template #title>
                            <div class="titles">使用优待证应该注意什么？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证是荣誉的象征，持证人应予以爱惜。对违规使用优待证的，或者做出有损退役军人和其他优抚对象形象的行为，且经批评、教育仍不改正的，我市退役军人事务部门可以依规取消其优待资格，收回优待证。发现涉嫌违法犯罪的，依法协调相关部门处理。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="32">
                        <template #title>
                            <div class="titles">本市退役军人、其他优抚对象优待证合作银行有哪些?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市首批合作银行包括中国工商银行、中国农业银行。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="33">
                        <template #title>
                            <div class="titles">合作银行是否设置专门窗口及金融功能电话咨询服务?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            合作银行为持证退役军人、其他优抚对象提供全行网点优先服务，持证人可优先办理各类业务(包括激活业务)。同时，还提供优待证申领咨询、金融功能咨询服务，服务咨询电话是：
                            工商银行服务咨询电话：95588-8
                            农业银行服务咨询电话：95599-8
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="32">
                        <template #title>
                            <div class="titles">申请人选择制证银行时需要注意哪些事项?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            申请人应注意自己在准备选择的制证银行是否有4张及以上的借记卡。
                            对申请人已持有所选制证银行4张及以上借记卡的，需要由申请人自行联系相关银行，将现持有的借记卡数量减少至3张及以下，方可申请优待证成功。否则，将无法激活优待证金融账户功能或不能通过银行核验程序完成制证。
                            目前，中国工商银行、中国农业银行可先制卡，在持证人激活优待证金融账户功能时进行审核，如持证人持卡数量超过4张，可通过旧卡换新卡或将持卡数量减少至3张及以下，方可激活优待证金融账户功能。
                        </div>
                    </van-collapse-item>
                </van-collapse>


            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon, Collapse, CollapseItem } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()


        const activeNames = ref('1');


        return {
            activeNames,
        }
    }
})
</script>
<style lang="scss" scoped>
.titles {
    height: 15vw;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.35rem;
    font-weight: 700;
}

.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.8rem;
    // width: 80vw;
    padding: 0.26rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  