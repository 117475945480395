<template>
  <div class="blank-page">
    <img class="welcome" src="@/assets/images/welcome.gif" />
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "@/api/common.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const router = useRouter()
    onMounted(() => {
        // const isIos =  navigator.userAgent.match(/iPhone|iPad|iPod/i)
        setTimeout(()=>{
          localStorage.userType === "persion"
            ? router.replace("/Home")
            : router.replace("/QYHome")
           
        },3000)
    });

    return {
    };
  },
});
</script>
<style lang="scss">
.blank-page {
  min-height: 100vh;
  .welcome{
      display: block;
      width: 100%;
      min-height: 100vh;
  }
}
</style>
