<template>
  <div class="register-page">
    <div class="top">
      <img class="img" src="@/assets/images/login/logo.png" />
    </div>
    <div class="input-box">
      <div class="item">
        <input v-model="entryName" placeholder="请输入单位名称" type="text" />
      </div>
      <div class="item zhizhao">
        请上传营业执照
        <!-- <input placeholder="请上传营业执照" type="file"> -->
        <input
          class="uploadInput"
          v-if="resetFlag"
          @change="fileChange"
          accept="image/*"
          capture="camera"
          placeholder="请上传营业执照"
          type="file"
        />
        <img :src="fileData" alt="" />
      </div>
      <div class="item">
        <input v-model="certId" placeholder="请输入营业执照统一社会信用代码" type="text" />
      </div>
      <div class="item">
        <input v-model="address" placeholder="请输入公司地址" type="text" />
      </div>
      <div class="item">
        <input v-model="mobile" placeholder="请输入联系方式" type="text" />
      </div>
      <div class="item">
        <input v-model="username" placeholder="请设置用户名" type="text" />
      </div>
      <div class="item">
        <input v-model="password" placeholder="请输入密码" type="text" />
      </div>
      <div class="item">
        <input v-model="password1" placeholder="请再次输入密码" type="text" />
      </div>
    </div>
    <div class="btn" @click="register">立即注册</div>
    <div class="footer">
      <div class="checkbox" :class="{ checked }" @click="checkAction"></div>
      我已阅读并同意<b @click="goPage('agreement')">《用户协议》</b>和<b @click="goPage('privacy')">《隐私政策》</b>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import Validator from "@/utils/validate.js";
import uploadAction from "./upload.js";
import mixin from "@/mixin/mixin.js";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore();
    const router = useRouter();
    const {
      fileChange,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation,
      liceseNo,
    } = uploadAction();

    const state = reactive({
      address: "",
      entryName: "",
      mobile: "",
      password: "",
      username: "",
      certId: "",
      password1: "",
      checked: true,
    });

    onMounted(() => {});

    const register = async () => {
      const { address, entryName, mobile, password, username, password1, certId } =
        state;
      const validAction = function () {
        const validator = new Validator();
        validator.add(entryName, [
          {
            ruleName: "isNonEmpty",
            errMsg: "单位名称不能为空",
          },
        ]);
        validator.add(address, [
          {
            ruleName: "isNonEmpty",
            errMsg: "地址不能为空",
          },
        ]);
        validator.add(certId, [
          {
            ruleName: "isNonEmpty",
            errMsg: "营业执照统一社会信用代码不能为空",
          },
        ]);

        validator.add(mobile, [
          {
            ruleName: "isNonEmpty",
            errMsg: "联系方式不能为空",
          },
          {
            ruleName: "isMobile",
            errMsg: "联系方式格式不正确",
          },
        ]);
        validator.add(username, [
          {
            ruleName: "isNonEmpty",
            errMsg: "用户名不能为空",
          },
        ]);
        validator.add(password, [
          {
            ruleName: "isNonEmpty",
            errMsg: "密码不能为空",
          },
          {
            ruleName: "minLength:6",
            errMsg: "密码长度不能小于6位",
          },
        //   {
        //     ruleName: "isPassWord",
        //     errMsg: "密码格式不正确",
        //   },
        ]);
        validator.add(password1, [
          {
            ruleName: "isNonEmpty",
            errMsg: "再次输入密码不能为空",
          },
          {
            ruleName: "minLength:6",
            errMsg: "再次输入密码长度不能小于6位",
          },
        //   {
        //     ruleName: "isPassWord",
        //     errMsg: "再次输入密码格式不正确",
        //   },
        ]);

        return validator.start();
      };
      let errMsg = validAction();
      if (errMsg) {
        showAlert({content: errMsg})
        return;
      }
      if (!state.checked) {
        errMsg = "请确认阅读协议";
      } else if (password !== password1) {
        errMsg = "两次密码不一致";
      } else if (!liceseNo.value) {
        errMsg = "请上传营业执照";
      }
      if (errMsg) {
        showAlert({content: errMsg})
        return;
      }

      const data = await UserService.register({
        address,
        entryName,
        certId,
        mobile,
        password,
        username,
        liceseNo: liceseNo.value,
      });
      if (!data) return
      router.replace('/login')
    };
    const checkAction = () => {
      state.checked = !state.checked;
    };
     const goPage = (name) => {
      router.push({name});
    };

    return {
      ...toRefs(state),
      register,
      checkAction,
      fileChange,
      goPage,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation,
      liceseNo,
    };
  },
});
</script>
<style lang="scss">
.register-page {
  padding: 0 0 0.58rem;
  .top {
    padding: 0.62rem 0 0.38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      width: 2rem;
      height: 2rem;
    }
  }
  .input-box {
    padding: 0 0.29rem;
    .item {
      display: flex;
      height: 1.04rem;
      @include border-1px(#ddd, bottom);
      &.zhizhao {
        position: relative;
        align-items: center;
        justify-content: space-between;
        padding-right: 0.21rem;
        img {
          width: 0.46rem;
          height: 0.42rem;
        }
      }
      .uploadInput {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      .img {
        width: 0.32rem;
        height: 0.37rem;
        margin-right: 0.2rem;
      }
      .block {
        width: 0.32rem;
        height: 0.37rem;
      }
      input {
        flex: 1;
        height: 100%;
        padding-right: 0.32rem;
        @include fontSC(0.3rem, #333);
        &::-webkit-input-placeholder {
          color: #666;
        }
      }
      &.valid-item {
        input {
          padding-left: 1rem;
        }
        .get-code {
          width: 1.6rem;
          text-align: center;
          line-height: 0.58rem;
          border: 1px solid #fb3e28;
          border-radius: 0.45rem;
          @include fontSC(0.24rem, #fb3e28);
        }
        .sec {
          @include fontSC(0.24rem, #fb3e28);
        }
      }
    }
  }
  .btn {
    margin: 0.93rem auto 1.2rem;
    width: 6.9rem;
    text-align: center;
    line-height: 0.9rem;
    @include fontSC(0.34rem, #fff);
    background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
    box-shadow: 0px 0.26rem 0.59rem 0px rgba(255, 54, 81, 0.22);
    border-radius: 0.45rem;
  }
  .footer {
    // position: absolute;
    // left: 50%;
    // bottom: 0.48rem;
    // transform: translateX(-50%);
    margin: 0 auto;
    min-width: 5.7rem;
    @include flexCenter();
    @include fontSC(0.22rem, #969696);
    .checkbox {
      width: 0.22rem;
      height: 0.22rem;
      border: 1px solid #ddd;
      border-radius: 0.02rem;
      margin: 0.1rem;
      &.checked {
        background: url(../../assets/images/login/checked.png) center no-repeat;
        background-size: contain;
      }
    }
    .cb {
      width: 0.22rem;
      height: 0.22rem;
      margin-right: 0.14rem;
    }
    b {
      font-weight: 500;
      color: #fb3e28;
    }
  }
}
</style>
