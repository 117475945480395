<template>
  <div class="shop-page">
    <section class="sticky">
      <!-- <van-tabs v-model:active="active" @click-tab="tabChange" background="#f8f8f8" line-height="0" title-inactive-color="#666" title-active-color="#FB3E28">
        <van-tab :title="item.typeName" v-for="(item, index) in typeListData" :key = 'item.id' :name="index"></van-tab>
      </van-tabs> -->

      <div class="tab-list list1">
        <div @click="checkOneTab(index)" class="item" v-for="(item, index) in typeListData" :key = 'item.id' :class="{ curr: currOneTab === index }" >
          {{item.typeName}}
        </div>
        <!-- <div
          @click="checkOneTab('1')"
          class="item"
          :class="{ curr: currOneTab === '1' }"
        >
          全部
        </div>
        <div
          @click="checkOneTab('3')"
          class="item"
          :class="{ curr: currOneTab === '3' }"
        >
          未领取
        </div> -->
      </div>
      <div class="tab-list list2">
        <div
          @click="checkTwoTab('1')"
          class="item"
          :class="{ curr: currTwoTab === '1' }"
        >
          销量
        </div>
        <!-- <div @click="checkTwoTab('2')" class="item" :class="{curr:currTwoTab==='2'}">价格区间</div> -->

        <van-dropdown-menu>
          <van-dropdown-item title="价格区间" ref="item" @close="closeDropDown">
            <div class="dropdowm-item">
              起始价：<input type="number" v-model="lowerPrice" placeholder="起始价" name="" id="" />
            </div>
            <div class="dropdowm-item">
              结束价：<input type="number" v-model="hightPrice" placeholder="结束价" name="" id="" />
            </div>

            <div style="padding: 5px 16px">
              <van-button type="danger" block round @click="onConfirm">
                确认
              </van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <span class="price" v-show="showPriceFlag">{{lowerPrice}}-{{hightPrice}}公益豆</span>
      </div>
      
      <!-- <van-dropdown-menu class="dropdown">
        <van-dropdown-item title="销量" />
        <van-dropdown-item :title="state.priceTitle" @change="priceChange" v-model="state.priceRange" :options="priceOption" />
      </van-dropdown-menu> -->
    </section>
    <ProductList
      @getNewData="getData"
      :listData="productListData"
      class="list-box"
    ></ProductList>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import ProductList from "@/components/ProductList.vue";
import { Tab, Tabs, DropdownMenu, DropdownItem, Button, Cell } from "vant";
import { useRoute, useRouter } from "vue-router";
import UserService from "@/api/common.js";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    ProductList,
  },
  props: {},
  setup(props) {
     const { showAlert, showConfirm } = mixin()
    const store = useStore();
    const route = useRoute();
    const originalTypeId = route.query.id;
    const uid = localStorage.uid;
    const typeId = ref(originalTypeId);
    const productListData = ref([]);
    const typeListData = ref([]);
    const active = ref("");
    const activeName = ref("1");

    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
      lowerPrice: '',
      hightPrice: '',
      salesSort: false,
    });


    const currOneTab = ref("");
    const currTwoTab = ref("");

    onMounted(() => {
      initInfo()
    });

    const resetState = () => {
      pageInfo.pagesize = 10;
      pageInfo.startRow = 1;
      pageInfo.totalPage = 1;
      pageInfo.curr = 0;
      pageInfo.hightPrice = "";
      pageInfo.lowerPrice = "";
      currTwoTab.value = "";
      pageInfo.salesSort = false;
      // typeId.value = originalTypeId
      productListData.value = [];
    };

    const initInfo = async () => {
      const postData = {
        hightPrice: pageInfo.hightPrice,
        lowerPrice: pageInfo.lowerPrice,
        salesSort: pageInfo.salesSort,
        typeId: typeId.value,
        uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize,
      };
      const data = await UserService.qryProdcutByType(postData);
      if (!data) return;
      const { productList, typeList, total } = data;
      if (!typeListData.value.length) typeListData.value = typeList;
      if (productList)
        productListData.value = [...productListData.value, ...productList];
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize);
    };

    const getData = async (isRefresh) => {
      pageInfo.curr += 1;
      if(isRefresh) {
          productListData.value = [];
          pageInfo.curr = 0;
      }
      
      if (pageInfo.curr >= pageInfo.totalPage)
        return;
      initInfo();
    };

    const checkOneTab = (n) => {
      resetState();
      
      if (currOneTab.value === n) {
        currOneTab.value = "";
        typeId.value = originalTypeId;
      } else {
        currOneTab.value = n;
        typeId.value = typeListData.value[parseInt(n)].id;
      }

      initInfo();
    };
    const checkTwoTab = (n) => {
      if (currTwoTab.value === n) {
        currTwoTab.value = "";
      } else {
        currTwoTab.value = n;
      }
      pageInfo.salesSort = !pageInfo.salesSort
      initInfo();
    };

    const tabChange = ({ name }) => {
      resetState();
      // console.log(productListData.value);
      if (activeName.value !== name) {
        activeName.value = name;
        typeId.value = typeListData.value[parseInt(name)].id;
        // initInfo()
      } else {
      }
      initInfo();
    };


    const showPriceFlag = computed(()=>{
       return pageInfo.lowerPrice && pageInfo.hightPrice && pageInfo.lowerPrice < pageInfo.hightPrice
    })

    const onConfirm = () => {
      if(pageInfo.lowerPrice>=pageInfo.hightPrice){
        showAlert({content: '开始价不能大于等于结束价'})
        return
      }
      initInfo();
    };
    const closeDropDown = () => {
      
    };

    return {
     ...toRefs(pageInfo),
      productListData,
      typeListData,
      activeName,
      active,
      currOneTab,
      currTwoTab,
      showPriceFlag,
      getData,
      onConfirm,
      tabChange,
      checkOneTab,
      checkTwoTab,
      closeDropDown,
    };
  },
});
</script>
<style lang="scss">
.shop-page {
  min-height: 100vh;
  background: #f8f8f8;
  .tab-list {
    display: flex;
    // padding-bottom: 0.23rem;
    background: #f8f8f8;
    padding-left: 0.3rem;
    &.list2 {
        display: flex;
        align-items: center;
        background: #fff;
        .price{
            margin-left: 0.2rem;
        }
      .dropdowm-item{
          padding: 0 0.5rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
           @include border-1px(#EEEEEE, bottom);
           input{

           }
      }
      .van-dropdown-menu__bar{
          box-shadow: none;
      }
    }
    .item {
      @include fontSC(0.3rem, #666);
      line-height: 0.9rem;
      margin-right: 0.67rem;
      &:last-of-type {
        margin-right: 0;
      }
      &.curr {
        font-weight: bold;
        color: #fb3e28;
      }
    }
  }
  .van-tabs__nav--line {
    &::after {
      content: "";
      width: 2.5rem;
    }
  }
  .list-box {
    .list-wrap {
      padding: 0.2rem 0.3rem;
    }
  }
  .dropdown {
    .van-dropdown-menu__bar {
      box-shadow: none;
      &::after {
        content: "";
        width: 3.8rem;
      }
      .van-dropdown-menu__item {
        &:first-child {
          .van-dropdown-menu__title {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    .van-cell {
      padding: 0.3rem;
      &::after {
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
