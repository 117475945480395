<template>
  <div class="soldierDetail-page">
    <Detail :images="imagesList"></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.welfareName }}</div>

      <div class="memo">
        <div class="des">
          <div class="l">已领取{{ proInfo.selledStock }}份<i class="t"></i></div>
          <div class="r">剩余{{ proInfo.validStock }}份</div>
        </div>
      </div>
    </div>
    <div class="detail-box">
      <div class="title">商品详情</div>
      <div class="item" v-html="proInfo.welfareDesc"></div>
      <div class="addr" v-if="proInfo.welfareName=='福福拥军面包'"><span @click="showSoldierPop">点击查看门店地址</span></div>
    </div>

    <div class="btn-wrap">
      <div
        class="btn"
        :class="[isCanShop?'':'disabled']"
        @click="exchange"
      >
        我要领取
      </div>
    </div>
    <soldierDetailAgreement v-if="showSoldierAgreement" @cancel="soldierAgreementClose"></soldierDetailAgreement>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Detail from '@/components/Detail.vue'
import soldierDetailAgreement from '@/components/soldierDetailAgreement.vue'
import mixin from '@/mixin/mixin.js'
import { useRouter, useRoute } from 'vue-router'
import SoldierService from '@/api/soldier.js'

export default defineComponent({
  components: {
    Detail,
    soldierDetailAgreement,
  },
  props: {},
  setup(props) {
    const store = useStore()
    const { showAlert, showConfirm } = mixin()
    const isZulin = ref(false)
    const router = useRouter()
    const route = useRoute()
    const id = route.query.pid
    const isGyb = route.query.isGyb
    isZulin.value = route.query.isZulin == '1'
    const proInfo = ref({})
    const isCanShop = ref(true)
    const limitCondition = ref([]) // 公益包限制使用条件
    const showSoldierAgreement = ref(false)

    const imagesList = ref([])
    const getData = async () => {
      const data = await SoldierService.queryCamouflageParkProductDetail({
        productId: id
      })
      console.log(data)
      if (!data) return
      const { detal, canShop } = data
      proInfo.value = detal
      isCanShop.value = canShop && sessionStorage.btnToUnSolider !== 'Y'
      imagesList.value = [detal.welfareUrl]
      limitCondition.value = JSON.parse(detal.limitCondition || '[]')
    }

    const showSoldierPop = () => {
      showSoldierAgreement.value = true
    }

    const soldierAgreementClose = () => {
      showSoldierAgreement.value = false
    }

    const exchange = () => {
      if(!isCanShop.value) return
      if (proInfo.value.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      showConfirm({
        title: '确认领取',
        content: `是否领取${proInfo.value.welfareName}`,
        confirmFn: createOrder,
        cancelFn: () => {},
        confirmTxt: '确认',
        cancelTxt: '取消'
      })
    }
    const createOrder = async () => {
      const data = await SoldierService.createCamouflagePark({
        productId: id,
        num: 1
      })
      if (!data) return
      if (data.errorCode === 'GREATE_LIMIT_GREATE ') {
        showConfirm({
          title: '领取失败',
          content: `<p style="padding: 0 0.3rem;line-height: 0.5rem;">每个用户领取产品数量不能超过3次<br/>可点击<span style="color:#F54029;">“我的善城-我的迷彩包”</span>查看领取的福利产品进行申领<p>`,
          confirmFn: goMyflb,
          cancelFn: () => {},
          confirmTxt: '立即前往',
          cancelTxt: '取消'
        })
        return
      }
      const { orderNo, welfare } = data
      sessionStorage.lqInfo = JSON.stringify(welfare)
      router.push({
        path: '/soldierOrderQrCode',
        query: {
          no: orderNo,
        }
      })
    }

    const goMyflb = () => {
        router.push({
          path: '/myWelFare',
          query: {
          }
        })
      }

    onMounted(() => {
      getData()
    })

    return {
      proInfo,
      isCanShop,
      isZulin,
      isGyb,
      limitCondition,
      imagesList,
      showSoldierAgreement,
      exchange,
      showSoldierPop,
      soldierAgreementClose,
    }
  }
})
</script>
<style lang="scss" scoped>
.soldierDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 1.85rem;
  .info-box {
    background: #fff;
    padding: 0.45rem 0.31rem 0.45rem;
    .title {
      @include fontSC(0.28rem, #333);
      margin-bottom: 0.24rem;
    }
    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;
      &.amt-gyb {
        display: flex;
        align-items: center;
      }
      .t {
        @include fontSC(0.5rem, #f54029);
      }
      .m {
        margin-left: 0.25rem;
        @include fontSC(0.18rem, #999);
      }
    }
    .description {
      @include fontSC(0.18rem, #999);
      display: flex;
      flex-wrap: wrap;
      .t {
        margin-right: 0.3rem;
        margin-bottom: 0.2rem;
        // width: 50%;
      }
    }
    .memo {
      display: block;
      &.memo-gyb {
        display: block;
      }
      .des {
        display: flex;
        align-items: flex-end;
      }
      .l {
        margin-right: 0.54rem;
      }
      .t {
        // @include fontSC(0.5rem, #f54029);
      }
      @include fontSC(0.24rem, #999);
    }
  }
  .detail-box {
    background: #fff;
    margin-top: 0.21rem;
    padding: 0.49rem 0.3rem 0.52rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }
    .item {
      @include fontSC(0.28rem, #666);
      line-height: 1.5em;
      margin-bottom: 0.1rem;
      & > p {
        line-height: 1.5em;
      }
    }
    .addr {
      color: #F65341;
      font-size: 0.28rem;
      line-height: 0.46rem;
      span {
        border-bottom: 1px solid #F65341;
      }
    }
  }
  .btn-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    @include border-1px(#f2f2f2, top);
    padding: 0.25rem 0 0.2rem;
    position: fixed;
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.9rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    background: #f65341;
    text-align: center;
    &.disabled{
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}
</style>
