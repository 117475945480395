<template>
  <div class="publicWelfareActivity-page">
    <div class="act-list">
      <div class="title">
        <img
          class="img"
          src="@/assets/images/gongyi/gift.png"
          alt=""
        />社区微公益
      </div>
      <div class="item" v-for="item in data1" :key="item.id">
        <img class="img" :src="item.activityImg" alt="" />
        <div class="detail">
          <div class="l">
            <div class="name">{{item.advertName}}</div>
            <div class="memo">发布时间：{{item.createTime}}</div>
          </div>
          <div class="r"  @click="goDetail(item.id)">查看详情</div>
        </div>
      </div>
      <!-- <div class="item">
        <img class="img" src="" alt="" />
        <div class="detail">
          <div class="l">
            <div class="name">活动名称活动名称</div>
            <div class="memo">发布时间：2021-08-15 12 : 00 : 30</div>
          </div>
          <div class="r">查看详情</div>
        </div>
      </div> -->
    </div>
    <div class="act-list">
      <div class="title">
        <img
          class="img"
          src="@/assets/images/gongyi/heart.png"
          alt=""
        />公益动态
      </div>
     <div class="item" v-for="item in data2" :key="item.id">
        <img class="img" :src="item.activityImg" alt="" />
        <div class="detail">
          <div class="l">
            <div class="name">{{item.advertName}}</div>
            <div class="memo">发布时间：{{item.createTime}}</div>
          </div>
          <div class="r"  @click="goDetail(item.id)">查看详情</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "@/api/common.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      priceRange: "",
      priceTitle: "价格区间",
    });

    const listData = ref([])

    onMounted(() => {
        initInfo()
    });


    const initInfo = async () => {
    //   const data = await UserService.qryActivityDetail({
    //     uid: localStorage.uid,
    //   });
    //   console.log(data); if (!data) return;
    //   const { sortList, LOVE_FIRST } = data;
    //   listData.value = sortList

      const data = await UserService.qryActivity({
        uid: localStorage.uid,
      });
      console.log(data); if (!data) return;
      const { activityList } = data;
      listData.value = activityList
    };

    const goDetail = (id) => {
        router.push({
            path: '/publicWelfareDetail',
            query: {
                id
            }
        })
    }
    const data1 = computed(()=>{
      return listData.value.filter(item=>{
            return item.showMethod ==='LOCAL'
        })
    })
    const data2 = computed(()=>{
       return listData.value.filter(item=>{
            return item.showMethod ==='JUMP'
        })
    })
    
    onMounted(() => {});

    return {
      state,
      listData,
      goDetail,
      data1,
      data2,
    };
  },
});
</script>
<style lang="scss">
.publicWelfareActivity-page {
  min-height: 100vh;
  background: #fff;
  padding: 0 0.3rem 0.2rem;
  .act-list {
    margin-top: 0.5rem;
    .title {
      display: flex;
      align-items: center;
      .img {
        width: 0.44rem;
        height: 0.4rem;
      }
      @include fontSC(0.34rem, #323232);
      margin-bottom: 0.33rem;
    }
    .item {
      position: relative;
      margin-bottom: 0.2rem;
      background: #fff;
      border-radius: 0.1rem;
      overflow: hidden;
      .img {
        width: 6.9rem;
        height: 3.18rem;
      }
       &::after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.3);
      }
      .detail {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0.37rem 0.24rem 0.34rem;
        width: 100%;
        z-index: 6;
        .l {
          .name {
            @include fontSC(0.34rem, #fff);
            margin-bottom: 0.16rem;
          }
          .memo {
            line-height: 1.5em;
            @include fontSC(0.24rem, #fff);
          }
        }
        .r {
          width: 1.56rem;
          line-height: 0.5rem;
          border: 1px solid #ffffff;
          border-radius: 0.05rem;
          @include fontSC(0.26rem, #fff);
          text-align: center;
        }
      }
    }
  }
}
</style>
