<template>
  <van-pull-refresh class="qy-wrap" v-model="refreshing" @refresh="onRefresh">
    <van-list :finished="finished" finished-text="已经到底了" class="list-page" @load="onLoad">
      <div class="qy-list">
        <div class="item" v-for="item in lists" :key="item.uid">
          <div class="l" @click="goDetail(item.id)">
            <img class="img" :src="item.picUrl" alt="" />
            <div class="des">
              <p class="type">{{ typeName || item.typeName }}</p>
              <p class="txt">{{ item.productName }}</p>
              <a class="total">{{ item.price }}公益豆/次</a>
            </div>
          </div>
          <div class="r"><img class="img" src="@/assets/images/home/tel.jpg" alt="" /><a class="tel"
              :href="`tel:${item.linkPhone}`"></a></div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { List, PullRefresh } from "vant";


export default defineComponent({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  props: {
    listData: {
      type: Array,
      default: []
    },
    typeName: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 1
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { listData, type, total } = toRefs(props);

    const state = reactive({
      lists: [],
      loading: false,
      finished: false,
      refreshing: false
    });

    const onLoad = () => {
      // 异步更新数据
      emit("getNewData");
    };

    const setDataList = async data => {
      if (!data.length) {
        state.lists = [];
        return
      }

      if (state.refreshing) {
        state.lists = [];
        state.refreshing = false;
      }
      state.lists = [...state.lists, ...data]
      // console.log(state.lists)
      // 加载状态结束
      state.loading = false;

      // 数据全部加载完成
      if (state.lists.length >= total) {
        state.finished = true;
      }
    };

    onMounted(() => {
      setDataList(listData.value);
    });

    watch(
      () => props.listData,
      (newVal, oldVal) => {
        // console.log('--',newVal)
        setDataList(newVal);
      }
    );

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      emit("getNewData", true);
      //   onLoad();
    };
    const goDetail = async (id) => {
      router.push({
        path: '/QyProductDetail',
        query: {
          pid: id
        }
      })
    };

    return {
      ...toRefs(state),
      onRefresh,
      onLoad,
      goDetail,
      type,
    };
  }
});
</script>
<style lang="scss">
.qy-wrap {
  background: #f8f8f8;
  position: relative;

  .qy-list {
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.17rem;
      margin-bottom: 0.2rem;
      background: #fff;
      border-radius: 0.1rem;

      .l {
        display: flex;
        align-items: center;

        .img {
          display: block;
          width: 2.28rem;
          height: 2.24rem;
          border-radius: 0.1rem;
          margin-right: 0.23rem;
        }

        .des {
          .type {
            // width: 1rem;
            line-height: 0.3rem;
            text-align: center;
            border-radius: 0.05rem;
            background: #FB3E28;
            @include fontSC(0.24rem, #fff);
            margin-bottom: 0.2rem;
            padding: 0.03rem;
            display: inline-block;
          }

          .txt {
            @include fontSC(0.3rem, #333);
            margin-bottom: 0.73rem;
          }

          .total {
            @include fontSC(0.3rem, #F54029);
          }
        }
      }

      .r {
        position: relative;
        width: 0.9rem;
        height: 0.9rem;

        .img {
          width: 0.9rem;
          height: 0.9rem;
        }

        .tel {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}</style>
