<template>
  <van-pull-refresh
    class="soldierlist-wrap"
    v-model="refreshing"
    @refresh="onRefresh"
  >
    <van-list
      :finished="finished"
      finished-text="已经到底了"
      class="list-page"
      @load="onLoad"
    >
      <div class="qy-list">
        <div
          class="item"
          v-for="item in lists"
          :key="item.id"
          @click="goDetail(item.id)"
        >
          <div class="l">
            <img class="img" :src="item.welfareUrl" alt="" />
            <div class="des">
              <span class="txt">{{ item.welfareName }}</span
              ><br />
              <span v-if="item.fetchNum<=5" class="type">可领{{ item.fetchNum }}份</span><br />
              <span v-if="item.fetchNum>5" class="type">无限制</span><br />
              <span class="memo">{{ item.selledStock }}人已领 <i style="margin-left: 0.56rem;">剩余{{ item.validStock }}份</i></span>
              <div class="btn" @click.stop="exchange(item)" :class="[!canShop ? 'disabled' : '']">立即领取</div>
            </div>
            
          </div>
          <div class="r"></div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { List, PullRefresh } from 'vant'
import SoldierService from '@/api/soldier.js'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  props: {
    listData: {
      type: Array,
      default: []
    },
    canShop: {
      type: Boolean,
      default: true
    },
    total: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { listData, type, total } = toRefs(props)
    const { showAlert, showConfirm } = mixin()

    const state = reactive({
      lists: [],
      loading: false,
      finished: false,
      refreshing: false,
      currId: '',
    })

    const onLoad = () => {
      // 异步更新数据
      emit('getNewData')
    }

    const exchange = (item) => {
      if(!props.canShop) return
      if (item.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      state.currId = item.id
      showConfirm({
        title: '确认领取',
        content: `是否领取${item.welfareName}`,
        confirmFn: createOrder,
        cancelFn: () => {},
        confirmTxt: '确认',
        cancelTxt: '取消'
      })
    }

    const createOrder = async () => {
      const data = await SoldierService.createCamouflagePark({
        productId: state.currId,
        num: 1
      })
      if (!data) return

      if (data.errorCode === 'GREATE_LIMIT_GREATE ') {
        showConfirm({
          title: '领取失败',
          content: `<p style="padding: 0 0.3rem;line-height: 0.5rem;">每个用户领取产品数量不能超过3次<br/>可点击<span style="color:#F54029;">“我的善城-我的迷彩包”</span>查看领取的福利产品进行申领<p>`,
          confirmFn: goMyflb,
          cancelFn: () => {},
          confirmTxt: '立即前往',
          cancelTxt: '取消'
        })
        return
      }
      const { orderNo, welfare } = data
      sessionStorage.lqInfo = JSON.stringify(welfare)
      router.push({
        path: '/soldierOrderQrCode',
        query: {
          no: orderNo,
        }
      })
    }

    const goMyflb = () => {
        router.push({
          path: '/myWelFare',
          query: {
          }
        })
      }


    const setDataList = async (data) => {
      if (!data.length) {
        state.lists = []
        return
      }

      if (state.refreshing) {
        state.lists = []
        state.refreshing = false
      }
      state.lists = [...state.lists, ...data]
      // console.log(state.lists)
      // 加载状态结束
      state.loading = false

      // 数据全部加载完成
      if (state.lists.length >= total) {
        state.finished = true
      }
    }

    onMounted(() => {
      setDataList(listData.value)
    })

    watch(
      () => props.listData,
      (newVal, oldVal) => {
        // console.log('--',newVal)
        setDataList(newVal)
      }
    )

    const onRefresh = () => {
      state.finished = false
      state.loading = true
      emit('getNewData', true)
      //   onLoad();
    }
    const goDetail = async (id) => {
      router.push({
        path: '/soldierDetail',
        query: {
          pid: id
        }
      })
    }

    return {
      ...toRefs(state),
      onRefresh,
      onLoad,
      goDetail,
      exchange,
      type
    }
  }
})
</script>
<style lang="scss">
.soldierlist-wrap {
  // background: #f8f8f8;
  position: relative;
  .qy-list {
    width: 100%;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.21rem 0.3rem;
      margin-bottom: 0.2rem;
      background: #fff;
      border-radius: 0.1rem;
      .l {
        display: flex;
        align-items: center;
        .img {
          display: block;
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 0.1rem;
          margin-right: 0.23rem;
        }
        .des {
          // display: flex;
          // flex-direction: column;
          .type {
            border: 1px solid #f54029;
            border-radius: 0.06rem;
            background: #fff;
            @include fontSC(0.24rem, #fb3e28);
            margin-bottom: 0.3rem;
            padding: 0.06rem 0.1rem;
            display: inline-block;
            margin-top: 0.3rem;
          }
          .txt {
            font-weight: bold;
            @include fontSC(0.3rem, #333);
          }
          .memo {
            @include fontSC(0.2rem, #ccc);
          }
          .total {
            display: inline-block;
            @include fontSC(0.25rem, #f54029);
            margin-top: 0.29rem;
            .amt {
              font-size: 0.36rem;
            }
          }
        }
        .btn {
          width: 1.52rem;
          line-height: 0.56rem;
          text-align: center;
          background: #ffebe8;
          border-radius: 0.28rem;
          margin-top: 0.3rem;
          @include fontSC(0.26rem, #fb3e28);
          &.disabled {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
          }
        }
      }
      .r {
        position: absolute;
        top: 50%;
        right: 0.4rem;
        transform: translateY(-50%);
        width: 0.19rem;
        height: 0.35rem;
        margin-left: 0.11rem;
        background: url(../assets/images/right-arrow1.png) 100% 100% no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>
