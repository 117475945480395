import { post_http } from './axios'

class UserService {
  static myActivity(params) {
    return post_http('/center/querySystemActivity', params)
  }
  static getWxConfig(params) {
    return post_http('/member/getWxConfig', params)
  }
  static getHD(params) {
    return post_http('/member/showActivity', params)
  }
  static getSmsCode(params) {
    return post_http('/member/getSmsCode', params)
  }
  static login(params) {
    return post_http('/member/login', params)
  }
  static queryMember(params) {
    return post_http('/member/queryMember', params)
  }
  static swapUserData(params) {
    return post_http('/member/swapUserData', params)
  }
  static isJumpAdvert(params) {
    return post_http('/center/isJumpAdvert', params)
  }
  static register(params) {
    return post_http('/member/registerByEntry', params)
  }
  static findPassword(params) {
    return post_http('/member/findPassword', params)
  }
  static updatePassword(params) {
    return post_http('/center/updatePassword', params)
  }
  static homepage(params) {
    return post_http('/center/homepage', params)
  }
  static homepageEntry(params) {
    return post_http('/center/homepageEntry', params)
  }
  static qryProductDetail(params) {
    return post_http('/center/qryProductDetail', params)
  }
  static queryAd(params) {
    return post_http('/advert/qryAdvertDetail', params)
  }
  static searchProduct(params) {
    return post_http('/center/searchProduct', params)
  }
  static fileUpload(params) {
    return post_http('/file/upload', params)
  }
  static qryAdvertDetail(params) {
    return post_http('/advert/qryAdvertDetail', params)
  }
  static qryProdcutByType(params) {
    return post_http('/center/qryProdcutByType', params)
  }
  static qryActivity(params) {
    return post_http('/center/qryActivity', params)
  }
  static qryActivityDetail(params) {
    return post_http('/center/qryActivityDetail', params)
  }
  static queryAllSort(params) {
    return post_http('/center/queryAllSort', params)
  }
  static qryLoveSort(params) {
    return post_http('/center/qryLoveSort', params)
  }
  static qryLoveMemberInfo(params) {
    return post_http('/center/qryLoveMemberInfo', params)
  }
  static welfareRecord(params) {
    return post_http('/order/welfareRecord', params)
  }
  static makeMoneyStrategy(params) {
    //赚取积分
    return post_http('/center/makeMoneyStrategy', params)
  }
  static getHomepacketAcceptUser(params) {
    //获取派发用户
    return post_http('/center/getHomepacketAcceptUser', params)
  }

  static redPacketMoney(params) {
    //派发
    return post_http('/order/redPacketMoney', params)
  }
  static volunteerActivityScan(params) {
    //志愿者活动扫码
    return post_http('/scan/volunteerActivityScan', params)
  }
  static productScan(params) {
    //会员商品扫码
    return post_http('/scan/productScan', params)
  }
  static activityScan(params) {
    //活动扫码信息
    return post_http('/scan/activityScan', params)
  }
  static getScanDetailInfo(params) {
    //兑换码或者抽奖码对应的具体信息
    return post_http('/scan/getScanDetailInfo', params)
  }
  static getMemberInfo(params) {
    //会员码-获取会员信息
    return post_http('/scan/getMemberInfo', params)
  }
  static finishBiz(params) {
    //兑换码或者抽奖码-已领取
    return post_http('/scan/finishBiz', params)
  }
  static timeBankAdd(params) {
    //添加时间银行
    return post_http('/timeBank/add', params)
  }
  static addMessageBoard(params) {
    //添加留言板
    return post_http('/center/addMessageBoard', params)
  }
}

export default UserService