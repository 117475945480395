<template>
  <div class="pro-box" v-if="info.productId == 98 || info.productId == 200">
    <div class="title">商品信息</div>
    <div class="con">
      <img class="l" :src="info.productUrl" alt="" />
      <div class="r">
        <p class="des">{{ info.productName }}</p>
        <p class="memo">
          实付：<span class="total">{{ info.num }}</span>
        </p>
      </div>
    </div>

    <div class="pro-box">
      <div class="title">券码信息</div>
      <div class="con" style="display: flex; justify-content: space-between">
        <div>{{ info.scanUrl }}</div>
        <div class="r" @click="copyText" style="color: #f65341">点击复制</div>
      </div>
    </div>
    <div class="btn" @click="goApp">点击进入小程序</div>
    <div class="address" v-if="info.productId == 98">
      复制停车券券码，“点击进入小程序”按钮并前往i百联微信小程
      序（需先在i百联微信小程序授权注册/登录），在指定页面输入券
      码进行兑换，兑换成功后，点击右上角“我的优惠券”，即可在i
      百联账户的优惠券卡包中找到该停车券。
    </div>
    <div class="address" v-if="info.productId == 200">
      1、使用公益豆兑换抽奖券后，按系统提示进行抽奖券兑换，奖券名称为“公益新华-慈善节专属奖券”<br />
      2、点击抽奖券券面“立即抽奖”文字，进入抽奖转盘界面<br />
      3、抽奖得到优惠券后，点击“使用规则”，可查看说明以及进行相应折扣进行购买优惠<br />
      （若兑换后找不到抽奖券以及抽奖得到的优惠券，可进入i百联APP/小程序（我的--优惠券）查看；也按识别本页面商品图上的二维码在“我的优惠券”页面找到中奖权益）
    </div>
  </div>
  <div class="lq-qrcode-page" v-else>
    <qrcode-vue :value="urlData"></qrcode-vue>
    <div class="des">
      <span class="l">物品名称：</span>
      <span class="r">{{ info.productName }}</span>
    </div>
    <div class="des">
      <span class="l">兑换人姓名：</span><span class="r">{{ info.name }}</span>
    </div>
    <div class="des">
      <span class="l">兑换时间：</span><span class="r">{{ info.time }}</span>
    </div>
    <div class="des">
      <span class="l">兑换公益豆数量：</span
      ><span class="r">{{ info.num }}</span>
    </div>
    <div class="description" v-if="info.productId == '100'">
      使用公益豆兑换成功后凭兑换二维码到墨笛植造所门店兑换并激活使用，所在门店包括：M.LIFE店（浦东新区成山路1088号）；国际舞蹈中心店（长宁区虹桥路1650号上海国际舞蹈中心A栋南门一层）；南京东路店（黄浦区南京东路139号美伦大楼M402）；诺丁花园店（徐汇区建国西路581弄小区西北门旁）；外滩源店（黄浦区南苏州路76号全幢）；武康大楼店（长宁区兴国路408号）；武康路密丹店（徐汇区武康路115号）；长风大悦城店（普陀区云岭东路88-2号东区(长风公园地铁站3号口步行160米）
    </div>
    <div class="description" v-else-if="info.productId == '105'">
      使用公益豆兑换成功后凭兑换二维码到bake no
      title（番禺路232号）兑换并激活使用； 抵用券兑换时间为周二至周日 10：00 —
      17：00（周一店休），请在兑换时间内使用；
      抵用券使用有效期即日直到2023年12月31日，请在有效期内使用；
    </div>
    <div class="description" v-else-if="info.productId == 201 || info.productId == 202">
      1、使用公益豆兑换成功后凭兑换二维码到无碍理想（新华路店）兑换并激活使用; <br />
      2、抵用券兑换时间为周一至周日11:00-17:00，请在兑换时间内使用;<br />
      3、抵用券使用有效期即日直到2024年9月6日，请在有效期内使用;<br />
      4、抵用券不找零，不兑换现金、不退款，不与店内其它优惠同享。
    </div>
    <div class="description" v-else>
      凭兑换商品二维码在新华慈善超市（地址：法华镇路712号），每兑换一个商品需任意金额（本次消费额不计入公益豆）现金消费一次，即可兑换，购买成功后，需1个月内到慈善超市领取，过期自动作废。
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import QrcodeVue from "qrcode.vue";
import LuckyService from "@/api/lucky.js";
import { useRouter, useRoute } from "vue-router";
import Clipboard from "clipboard";
import { Toast } from "vant";
import SoldierService from "@/api/soldier.js";

export default {
  components: {
    QrcodeVue,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const no = route.query.no;
    const urlData = ref(no);
    const dialogInfo = computed(() => store.state.dialogInfo);
    const uid = localStorage.uid;
    const info = sessionStorage.lqInfo ? JSON.parse(sessionStorage.lqInfo) : {};
    const recordListData = ref([]);

    onMounted(() => {
      console.log(route.query);
      //   initData();
    });
    //跳转小程序路径
    const goApp = async () => {
      console.log(no);
      const data = await SoldierService.camouflageParkUseOrder({
        orderNo: no,
        orderType: "ORDER",
      });
      console.log(data);
      window.location.href =
        "https://mh5.bl.com/h5/getTicket?hidebar=true&cm_mmc=YXTF-_-CRM_3002-_-MD1689583184649-_-3000,-1&batch_id=MD1689583184649&utp=0_-_YXTF-_-1_-_CRM_3002-_-2_-_MD1689583184649-_-3_-_3000-_-4_-_-1&batchId=MD1689583184649";
    };
    //点击复制方法
    const copyText = async () => {
      const text = info.scanUrl; // 将要复制的文本内容存储在一个变量中

      const tempInput = document.createElement("input");
      tempInput.value = text;

      // 将 input 元素添加到 DOM 中
      document.body.appendChild(tempInput);

      // 选中 input 元素中的文本
      tempInput.select();

      // 执行复制命令
      document.execCommand("copy");

      // 从 DOM 中移除临时 input 元素
      document.body.removeChild(tempInput);
      Toast({
        message: "复制成功",
        position: "top",
      });
      console.log("文本已成功复制");
    };
    const initData = async () => {
      const data = await LuckyService.myDrawRecord({
        uid: localStorage.uid,
        pagesize: 100,
        startRow: 0,
      });
      console.log(data);
      if (!data) return;
      const { recordList } = data;
      recordListData.value = recordList;
    };

    return {
      recordListData,
      dialogInfo,
      urlData,
      info,
      goApp,
      copyText,
    };
  },
};
</script>

<style lang="scss">
.pro-box {
  margin: 0.21rem 0 0.2rem;
  background: #fff;

  .title {
    display: flex;
    align-items: center;
    height: 0.73rem;
    @include fontSC(0.3rem, #000);
    padding-left: 0.29rem;

    &::before {
      content: "";
      width: 0.08rem;
      height: 0.42rem;
      background: #f65341;
      margin-right: 0.21rem;
    }
  }

  .con {
    display: flex;
    align-items: center;
    //   justify-content: space-between;
    padding: 0.26rem 0.21rem 0.26rem 0.56rem;

    .l {
      width: 1.85rem;
      height: 1.86rem;
      border: 1px solid #e6e6e6;
      margin-right: 0.33rem;
    }

    .r {
      @include fontSC(0.3rem, #5f5f5f);

      .des {
        @include fontSC(0.32rem, #282828);
        margin-bottom: 0.7rem;
        line-height: 1.3em;
      }

      .memo {
        @include fontSC(0.3rem, #5f5f5f);

        .total {
          @include fontSC(0.32rem, #fb3e28);
        }
      }
    }
  }
}

.address {
  padding: 0 0.38rem;
  line-height: 1.5em;
  @include fontSC(0.24rem, #666);

  .t {
    @include fontSC(0.24rem, #f65341);
  }
}

.btn {
  @include fontSC(0.36rem, #fff);
  width: 6.91rem;
  line-height: 1rem;
  border-radius: 0.5rem;
  margin: 0.69rem auto 0.2rem;
  background: #f65341;
  text-align: center;
}

.lq-qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.93rem 0.68rem 0;

  .des {
    margin-bottom: 0.3rem;
    @include fontSC(0.34rem, #999);
    display: flex;

    //   justify-content: space-between;
    .l {
      width: 45%;
    }

    .r {
      flex: 1;
      line-height: 1.2em;
    }
  }

  canvas {
    display: block;
    margin: 0 auto 0.65rem;
    width: 4.5rem !important;
    height: 4.5rem !important;
  }

  .description {
    margin-top: 0.9rem;
    @include fontSC(0.28rem, #666);
    line-height: 1.5em;
  }
}
</style>
