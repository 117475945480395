<template>
  <div class="loveqydetail-page">
    <Detail></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.productName }}</div>
      <div class="amt">
        <span class="t">{{ proInfo.price }}</span
        >公益豆/次
      </div>
      <div class="memo">
        <div class="l">
          已兑换<span class="t">{{ proInfo.selledStock }}</span
          >份
        </div>
        <div class="r">
          剩余<span class="t">{{ proInfo.validStock }}</span
          >份
        </div>
      </div>
    </div>
    <div class="detail-box">
      <div class="title">商品详情</div>
      <div class="item" v-html="proInfo.productDetail"></div>
      <!-- <div class="item">
        <b>商品详情：</b><br />
        物品详情物品详情物品详情物品详情物品详情物品详情
        物品详情物品详情物品详情物品详情物品详情物品详情 物品详情物品详
      </div>
      <div class="item">
        <b>保质期时长： </b><br />
        2021-9-15日过期
      </div> -->
      <div class="item">
        <b>温馨提示： </b><br />
        前往慈善超市购买物品
      </div>
    </div>

    <div
      class="btn"
      v-if="proInfo.isSupportOnlinePay == 'true'"
      @click="exchange"
    >
      我要兑换
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import Detail from '@/components/Detail.vue'
import UserService from '@/api/common.js'
import OrderService from '@/api/order.js'
import mixin from '@/mixin/mixin.js'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore()
    const { showAlert, showConfirm } = mixin()
    const router = useRouter()
    const route = useRoute()
    const id = route.query.pid
    const proInfo = ref({})
    const getData = async () => {
      const data = await UserService.qryLoveMemberInfo({
        productId: id
      })
      console.log(data)
      if (!data) return
      const { product } = data
      proInfo.value = product
    }

    const exchange = () => {
      if (proInfo.value.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      if (localStorage.userType === 'persion') {
        showConfirm({
          title: '确认兑换',
          content: `本次购买需要消耗${proInfo.value.price}公益豆<p style="color:#F65341;margin-top:0.2rem;padding-bottom:0.23rem;">一经购买，不予退回</p>`,
          confirmFn: createOrder,
          cancelFn: () => {},
          confirmTxt: '确认',
          cancelTxt: '取消'
        })
      } else {
        showConfirm({
          title: '',
          content:
            '请先拨打联系电话确认<p style="margin-top:0.2rem;padding-bottom:0.38rem;">场地空余情况再支付公益豆</p>',
          confirmFn: createOrder,
          cancelFn: () => {},
          confirmTxt: '已联系',
          cancelTxt: `<a class="tel" href="tel:${proInfo.value.linkPhone}">去拨打</a>`
        })
      }
    }
    const createOrder = async () => {
      const data = await OrderService.createOrder({
        productId: id,
        num: 1
      })
      console.log(data)
      if (!data) return
      const { orderNo } = data

      router.replace({
        path: './exchangeSucc',
        query: {
          no: orderNo
        }
      })
    }
    onMounted(() => {
      // getData()
    })

    return {
      proInfo,
      exchange
    }
  }
})
</script>
<style lang="scss">
.loveqydetail-page {
  .info-box {
    padding: 0.45rem 0.31rem 0.45rem;
    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.24rem;
    }
    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;
      .t {
        @include fontSC(0.5rem, #f54029);
      }
    }
    .memo {
      display: flex;
      .l {
        margin-right: 0.54rem;
      }
      .t {
        // @include fontSC(0.5rem, #f54029);
      }
      @include fontSC(0.24rem, #999);
    }
  }
  .detail-box {
    padding: 0.49rem 0.3rem 0.52rem;
    @include border-1px(#f2f2f2, bottom);
    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }
    .item {
      @include fontSC(0.28rem, #333);
      line-height: 1.5em;
      margin-bottom: 0.15rem;
      & > p {
        line-height: 1.5em;
      }
    }
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.91rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0.25rem auto 0.2rem;
    background: #f65341;
    text-align: center;
  }
}
</style>
