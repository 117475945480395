<template>
  <div class="searchProduct-page">
    <ProductList
      @getNewData="getData"
      :listData="productListData"
      class="list-box"
      v-if="productListData.length"
    ></ProductList>
    <div v-else class="nodata">没查找到数据~</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import ProductList from "@/components/ProductList.vue";
// import { Tab, Tabs, DropdownMenu, DropdownItem, Button, Cell } from "vant";
import { useRoute, useRouter } from "vue-router";
import UserService from "@/api/common.js";

export default defineComponent({
  components: {
    ProductList,
  },
  props: {},
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const searchTxt = route.query.q;
    const uid = localStorage.uid;
    const productListData = ref([]);

    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
    });

    onMounted(() => {
      initInfo()
    });

    // const resetState = () => {
    //   pageInfo.pagesize = 10;
    //   pageInfo.startRow = 1;
    //   pageInfo.totalPage = 1;
    //   pageInfo.curr = 0;
    //   pageInfo.salesSort = false;
    //   productListData.value = [];
    // };

    const initInfo = async () => {
      const postData = {
        search: searchTxt,
        uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize,
      };
      const data = await UserService.searchProduct(postData);
      console.log(data); if (!data) return;
      const { productList, total } = data;

      if (productList)
        productListData.value = [...productListData.value, ...productList];
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize);
    };

    const getData = async () => {
      pageInfo.curr += 1;
      if (pageInfo.curr > pageInfo.totalPage || pageInfo.totalPage === 1) return;
      initInfo();
    };


    return {
     ...toRefs(pageInfo),
      productListData,
      getData,
    };
  },
});
</script>
<style lang="scss">
.searchProduct-page {
  min-height: 100vh;
  background: #f8f8f8;
 
 
  .list-box {
    .list-wrap {
      padding: 0.2rem 0.3rem;
    }
  }
  .nodata{
          padding-top: 1rem;
      text-align: center;
      @include fontSC(0.3rem, #666);
  }
}
</style>
