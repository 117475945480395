<template>
  <div class="gybDetail-page">
    <Detail :images="imagesList"></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.productName }}</div>

      <div v-if="!limitCondition.length" class="memo">
        <div class="amt">
          <span class="t">{{ proInfo.price }}</span
          >{{ proInfo.isSupportOnlinePay == 'true' ? '公益豆' : '元'
          }}{{ unitData }}
        </div>
        <div class="des">
          <div class="l">已领取{{ proInfo.selledStock }}份</div>
          <div class="r">剩余{{ proInfo.validStock }}份</div>
        </div>
      </div>
      <div v-else class="memo memo-gyb">
        <div class="amt amt-gyb">
          剩余<span class="t">{{ proInfo.validStock }}</span
          >份
          <span class="m">已领取{{ proInfo.selledStock }}份</span>
        </div>
        <div class="description">
          <!-- <span v-for="item in limitCondition" :key="item.desc" class="t"
            >{{ item.desc }}：{{ item.val }}</span> -->
          <span v-if="limitCondition[0]" class="t">
            适用人群：{{ limitCondition[0].desc }}</span
          >
          <span v-if="limitCondition[1]" class="t">
            使用时间：{{ limitCondition[1].desc }}</span
          >
          <span v-if="proInfo.supplier" class="t">
            发起商户：{{ proInfo.supplier }}</span
          >
          <span v-if="proInfo.supplyAddress" class="t">
            商户地址：{{ proInfo.supplyAddress }}</span
          >
          <span v-if="proInfo.linkPhone" class="t">
            联系方式：{{ proInfo.linkPhone }}</span
          >
        </div>
      </div>
    </div>
    <div class="detail-box">
      <div class="title">商品详情</div>
      <div class="item" v-html="proInfo.productDetail"></div>

      <div class="item" v-if="!limitCondition.length">
        <b>使用说明： </b
        ><br />1、每个账户限兑换一次，公益豆不退、不换、不可兑现；<br />
        2、领取成功后，工作人员会联系您到凭领取二维码慈善
        超市（地址：法华镇路712号新华社区慈善超市）领取物
        品，交易成功后支付公益豆
      </div>
      <div class="item" v-else>
        <b>使用说明： </b><br />1、符合条件的账户限领取一次；<br />
        2、领取成功后，请您于服务商户地址出示领取二维码享 受服务。
      </div>
    </div>

    <div class="btn-wrap">
      <div
        class="btn"
        v-if="proInfo.isSupportOnlinePay == 'true'"
        @click="exchange"
      >
        我要领取
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Detail from '@/components/Detail.vue'
import UserService from '@/api/common.js'
import OrderService from '@/api/order.js'
import mixin from '@/mixin/mixin.js'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore()
    const { showAlert, showConfirm } = mixin()
    const isZulin = ref(false)
    const router = useRouter()
    const route = useRoute()
    const id = route.query.pid
    const isGyb = route.query.isGyb
    isZulin.value = route.query.isZulin == '1'
    const proInfo = ref({})
    const unitData = ref('')
    const limitCondition = ref([]) // 公益包限制使用条件

    const imagesList = ref([])
    const getData = async () => {
      const data = await UserService.qryProductDetail({
        productId: id
      })
      console.log(data)
      if (!data) return
      const { product, unit } = data
      proInfo.value = product
      unitData.value = unit
      imagesList.value = [product.bigPicUrl]
      limitCondition.value = JSON.parse(product?.limitCondition || [])
    }

    const exchange = () => {
      if (proInfo.value.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      if (!limitCondition.value.length) {
        showConfirm({
          title: '确认领取',
          content: `领取公益包需要消耗${proInfo.value.price}公益豆`,
          confirmFn: createOrder,
          cancelFn: () => {},
          confirmTxt: '确认',
          cancelTxt: '取消'
        })
      } else {
        showConfirm({
          title: '确认领取',
          content: `是否领取领取商户公益包`,
          confirmFn: createOrder,
          cancelFn: () => {},
          confirmTxt: '确认',
          cancelTxt: '取消'
        })
      }
    }
    const createOrder = async () => {
      const data = await OrderService.createOrder({
        productId: id,
        num: 1
      })
      console.log(data)
      if (!data) return
      const { orderNo } = data

      router.replace({
        path: './exchangeSucc',
        query: {
          no: orderNo,
          isGyb: 'true'
        }
      })
    }
    onMounted(() => {
      getData()
    })

    return {
      proInfo,
      unitData,
      isZulin,
      isGyb,
      limitCondition,
      exchange,
      imagesList
    }
  }
})
</script>
<style lang="scss" scoped>
.gybDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 1.85rem;
  .info-box {
    background: #fff;
    padding: 0.45rem 0.31rem 0.45rem;
    .title {
      @include fontSC(0.28rem, #333);
      margin-bottom: 0.24rem;
    }
    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;
      &.amt-gyb {
        display: flex;
        align-items: center;
      }
      .t {
        @include fontSC(0.5rem, #f54029);
      }
      .m {
        margin-left: 0.25rem;
        @include fontSC(0.18rem, #999);
      }
    }
    .description {
      @include fontSC(0.18rem, #999);
      display: flex;
      flex-wrap: wrap;
      .t {
        margin-right: 0.3rem;
        margin-bottom: 0.2rem;
        // width: 50%;
      }
    }
    .memo {
      display: block;
      &.memo-gyb {
        display: block;
      }
      .des {
        display: flex;
        align-items: center;
      }
      .l {
        margin-right: 0.54rem;
      }
      .t {
        // @include fontSC(0.5rem, #f54029);
      }
      @include fontSC(0.24rem, #999);
    }
  }
  .detail-box {
    background: #fff;
    margin-top: 0.21rem;
    padding: 0.49rem 0.3rem 0.52rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }
    .item {
      @include fontSC(0.28rem, #666);
      line-height: 1.5em;
      margin-bottom: 0.35rem;
      & > p {
        line-height: 1.5em;
      }
    }
  }
  .btn-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    @include border-1px(#f2f2f2, top);
    padding: 0.25rem 0 0.2rem;
    position: fixed;
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.9rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    background: #f65341;
    text-align: center;
  }
}
</style>
