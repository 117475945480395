<template>
  <div class="soldierlist-page">
    <div class="notice">
      <img class="img" src="@/assets/images/soldier_notice.png" alt=""/>
      迷彩公园内所有公益商品均为企业赞助，先到先得, 领完即止。
    </div>
    <div class="my-right" @click="showRightHanler">我的领取权限</div>
    <div class="notice2">
      <div @click="extrameal">
        <div><img class="img" src="../../assets/images/zcfw.png" alt=""></div>
        <div>助餐服务</div>
      </div>
      <div @click="consult">
        <div><img class="img" src="../../assets/images/znzx.png" alt=""></div>
        <div>智能咨询</div>
      </div>
    </div>
    <soldierlists @getNewData="getData" :listData="productListData" :canShop="isCanShop"
                  class="list-box"></soldierlists>

    <div v-if="showRight" class="popup_soldier_right" ref="elRefRight">
      <div class="overlay"></div>
      <div class="wrap">
        <div class="title"><span>我的领取权限{{ level == '3' ? rightsData.rightsCount : 0 }}次</span><span>已用{{
            level == '3' ?
                rightsData.usedrightsCount : 0
          }}次</span></div>
        <div class="wrap-section">
          <div class="sub-title">·明细</div>
          <ul class="right-list" v-if="level == '3' && rightsData.orderDescList">
            <li class="right-item" v-for="item in rightsData.orderDescList" :key="item.time">
              <span>{{ item.time }}</span><span>{{ item.desc }}</span>
            </li>
          </ul>
          <div class="no-right" v-if="level != '3'">
            经系统匹配你不是新华街道在册退役军人，如有疑惑可点击我要申诉进行申诉
          </div>
          <div v-if="level != '3'">
            <div class="sec1-btn1" @click="appeal">我要申诉</div>
            <div class="sec1-btn2" @click="close">取消</div>
          </div>
        </div>
        <div class="close" @click="close" v-if="level == '3'">
          <img class="img" src="@/assets/images/soldier_close_w.png" alt=""/>
        </div>
      </div>
    </div>
    <!--    <div class="consult" @click="consult">-->
    <!--      <img src="../../assets/images/fixed_zn.png" alt="">-->
    <!--    </div>-->
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs
} from 'vue'
import {useStore} from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import {Tab, Tabs, DropdownMenu, DropdownItem, Button, Cell} from 'vant'
import {useRoute, useRouter} from 'vue-router'
import SoldierService from '@/api/soldier.js'
import mixin from '@/mixin/mixin.js'
import axios from "axios";

export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    soldierlists
  },
  props: {},
  setup(props) {
    const {showAlert, showConfirm} = mixin()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const originalTypeId = route.query.id
    const isGyb = route.query.isGyb || 'false'
    const uid = localStorage.uid
    const typeId = ref(originalTypeId)
    const productListData = ref([])
    const typeListData = ref([])
    const rightsData = ref({})
    const active = ref('')
    const activeName = ref('1')
    const isCanShop = ref(true)
    const showRight = ref(false)
    const level = localStorage.level

    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
      lowerPrice: '',
      hightPrice: '',
      salesSort: false
    })

    const currOneTab = ref('all')
    const currTwoTab = ref('')

    onMounted(() => {
      initInfo()
      camouflageParkQueryRights()
      getUser()
    })
    // 获取用户信息
    const getUser = async (type = "") => {
      try {
        const response = await axios.post('http://www.yingyuantech.com:10004/mananger/person/personBaseInfo/page', {
          "pagesize": 1,
          "startRow": 1000,
          "phone": localStorage.phone
        });
        const data = response.data.data;
        console.log(data);
        if (!data) return;

        const {records} = data;
        localStorage.id = records[0].id;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };
    const initInfo = async () => {
      const postData = {
        hightPrice: pageInfo.hightPrice,
        lowerPrice: pageInfo.lowerPrice,
        salesSort: pageInfo.salesSort,
        typeId: typeId.value,
        uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize
      }
      const data = await SoldierService.queryCamouflageParkProduct(postData)
      if (!data) return
      const {productList, canShop, total} = data
      isCanShop.value = canShop && sessionStorage.btnToUnSolider !== 'Y'
      if (productList)
        productListData.value = [...productListData.value, ...productList]
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize)
    }
    const camouflageParkQueryRights = async () => {
      const postData = {}
      const data = await SoldierService.camouflageParkQueryRights(postData)
      if (!data) return
      rightsData.value = data

    }

    const getData = async (isRefresh) => {
      pageInfo.curr += 1
      if (isRefresh) {
        productListData.value = []
        pageInfo.curr = 0
      }

      if (pageInfo.curr >= pageInfo.totalPage) return
      initInfo()
    }

    const appeal = () => {
      router.push({path: '/appeal'})
    }

    const showRightHanler = () => {
      showRight.value = true
    }
    const close = () => {
      showRight.value = false
    }

    const showPriceFlag = computed(() => {
      return (
          pageInfo.lowerPrice &&
          pageInfo.hightPrice &&
          pageInfo.lowerPrice < pageInfo.hightPrice
      )
    })

    const onConfirm = () => {
      if (pageInfo.lowerPrice >= pageInfo.hightPrice) {
        showAlert({content: '开始价不能大于等于结束价'})
        return
      }
      initInfo()
    }

    const consult = () => {
      router.push('/consultHome')
    }
    const extrameal = () => {
      const personId = localStorage.id;
      console.log(personId);
      const redirectUrl = `https://hkjw.yingyuantech.com?personId=${personId}&response_type=code&scope=snsapi_base#wechat_redirect`;
      window.location.href = redirectUrl;
    }

    return {
      ...toRefs(pageInfo),
      productListData,
      typeListData,
      isCanShop,
      activeName,
      active,
      level,
      isGyb,
      currOneTab,
      currTwoTab,
      showRight,
      showPriceFlag,
      rightsData,
      getData,
      onConfirm,
      appeal,
      close,
      showRightHanler,
      consult,
      extrameal
    }
  }
})
</script>
<style lang="scss" scoped>
.consult {
  position: fixed;
  right: 10px;
  bottom: 20px;

  img {
    width: 1.5rem;
  }
}

.soldierlist-page {
  min-height: 100vh;
  padding-top: 0.26rem;
  background: #f8f8f8;

  .notice {
    width: 6.9rem;
    height: 1.02rem;
    background: #ffffff;
    border: 1px solid #ff4c3c;
    border-radius: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.38rem 0 0.28rem;
    font-size: 0.24rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fb3e28;
    line-height: 0.34rem;
    margin: 0 auto;

    .img {
      width: 0.47rem;
      height: 0.41rem;
      margin-right: 0.32rem;
    }
  }

  .notice2 {
    width: 6.9rem;
    border-radius: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.38rem 0 0.28rem;
    font-size: 0.38rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fb3e28;
    margin: 0.2rem auto;

    > div {
      display: flex;
      align-items: center;
      background: #ffffff;
      //border: 1px solid #ff4c3c;
      padding: 0.15rem;
    }

    .img {
      width: 1rem;
      height: 1rem;
    }
  }

  .my-right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.31rem 0;
    font-size: 0.28rem;
    color: #ff4c3c;

    &::after {
      content: '';
      width: 0.14rem;
      height: 0.24rem;
      margin-left: 0.11rem;
      background: url(../../assets/images/red-right.png) 100% 100% no-repeat;
      background-size: cover;
    }
  }

  .list-box {
    padding: 0 0.29rem;

    .list-wrap {
      padding: 0.2rem 0.3rem;
    }
  }

  .popup_soldier_right {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;

    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }

    .wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      width: 6.3rem;
      background: #ffffff;
      border-radius: 0.2rem;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.45rem 0.6rem 0.28rem;
        font-size: 0.38rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        border-bottom: 1px solid #CBCBCB;
      }

      .wrap-section {
        line-height: 1;
        padding: 0.52rem 0.38rem 0.49rem 0.6rem;

        .sub-title {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ff4c3c;
          margin-bottom: 0.35rem;
        }

        .right-list {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 0.48rem;

          .right-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.1rem;
          }
        }

        .no-right {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 0.48rem;
        }

        .sec1-btn1 {
          width: 3.12rem;
          height: 0.9rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #fff;
          line-height: 0.9rem;
          text-align: center;
          background: #ff4c3c;
          margin: 0.5rem auto 0;
          margin-bottom: 0.37rem;
          border-radius: 0.45rem;
        }

        .sec1-btn2 {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          text-align: center;
          color: #ff4c3c;
        }
      }

      .close {
        position: absolute;
        left: 50%;
        bottom: -1.31rem;
        transform: translateX(-50%);
        overflow: hidden;
        width: 0.78rem;
        height: 0.78rem;
        font-size: 0;

        img {
          width: 0.78rem;
          height: 0.78rem;
        }
      }
    }
  }
}
</style>
