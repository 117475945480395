<template>
  <div class="linbaoDetail-page">
    <Detail :images="imagesList"></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.productName }}</div>
      <div class="amt"><span class="t">{{ proInfo.price }}</span>{{ proInfo.isSupportOnlinePay == 'true' ? '公益豆' :
        '元' }}{{ unitData }}</div>
      <div class="memo">
        <div class="l">已兑换<span class="t">{{ proInfo.selledStock }}</span>份</div>
        <div class="r">剩余<span class="t">{{ proInfo.validStock }}</span>份</div>
      </div>
    </div>
    <div class="detail-box">
      <div class="title">商品详情</div>
      <div class="item" v-html="proInfo.productDetail"></div>
      <div class="item">
        <b>温馨提示：</b><br />
        前往慈善超市购买物品
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import Detail from "@/components/Detail.vue";
import UserService from "@/api/common.js";
import OrderService from "@/api/order.js";
import mixin from "@/mixin/mixin.js";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const id = route.query.pid
    const proInfo = ref({});
    const { showAlert, showConfirm } = mixin()
    const getData = async () => {
      console.log(id);
      const data = await UserService.qryProductDetail({
        productId: id,
      });
      console.log(data); if (!data) return;
      const { product } = data;
      proInfo.value = product
    };
    const exchange = async () => {
      if (proInfo.value.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      const data = await OrderService.createOrder({
        productId: id,
        num: 1,
      });
      console.log(data); if (!data) return;

      router.push({
        path: './exchangeSucc',
        //    path: './orderDetail',
        query: {
          no: id
        }
      })
    };
    onMounted(() => {
      showConfirm({
        title: '确认兑换', content: `本次购买需要消耗${proInfo.value.price}公益豆<br>一经购买，不予退回`,
        confirmFn: exchange,
        cancelFn: () => { },
        confirmTxt: '确认',
        cancelTxt: '取消'
      })

      // getData()
    });


    return {
      proInfo,
      exchange,
    };
  }
});
</script>
<style lang="scss">
.linbaoDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 0.3rem;

  .van-swipe-item {
    height: 6.6rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }

  .info-box {
    padding: 0.45rem 0.31rem 0.45rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.24rem;
    }

    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;

      .t {
        @include fontSC(0.5rem, #f54029);
      }
    }

    .memo {
      line-height: 1.5em;
      @include fontSC(0.24rem, #999);
    }
  }

  .detail-box {
    padding: 0.49rem 0.3rem 0.52rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }

    .item {
      @include fontSC(0.28rem, #333);
      line-height: 1.5em;
      margin-bottom: 0.15rem;

      &>p {
        line-height: 1.5em;
      }
    }
  }
}</style>
