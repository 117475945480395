<template>
  <div class="csyy">
    <div class="product-list">
      <!-- <div class="title">
        <img :src="typeData.length&&typeData[0].url" alt="" />
        {{QyListData.length&&QyListData[0].typeName}}
      </div> -->
      <div class="type-list" style="margin-top: 0.2rem">
        <div
          class="item"
          :class="{ curr: currTab === 'all' }"
          @click="checkTab('all')"
        >
          全部
        </div>
        <div
          class="item"
          :class="{ curr: currTab === item.id }"
          v-for="item in typeListData"
          :key="item.sort"
          @click="checkTab(item.id)"
        >
          {{ item.typeName }}
        </div>
      </div>
      <csyyList
        :typeName="currTypeData"
        @getNewData="getData"
        :total="currTypeTotal"
        :listData="QyListData"
      ></csyyList>
    </div>
  </div>
</template>

<script>
import UserService from '@/api/common.js'
import CenterService from '@/api/common.js'
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import csyyList from '@/components/csyyList.vue'
import mixin from '@/mixin/mixin.js'

export default {
  name: 'Home',
  components: {
    csyyList
  },
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const uid = localStorage.uid
    const showDiaFlag = ref(false)
    const infos = ref({})
    const searchTxt = ref('')
    const QyListData = ref([])
    const typeData = ref([])
    const typeListData = ref([])
    const advertMapData = ref([])
    const siwperData = ref([])
    const advertData = ref([])
    const router = useRouter()
    const route = useRoute()
    const id = route.query.id
    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
      currTypeTotal: 1,
      lowerPrice: '',
      hightPrice: '',
      salesSort: false
    })
    const currTab = ref('all')
    const typeId = ref('')

    const resetState = () => {
      pageInfo.pagesize = 10
      pageInfo.startRow = 1
      pageInfo.totalPage = 1
      pageInfo.curr = 0
      QyListData.value = []
    }

    const currTypeData = computed(() => {
      typeListData.value.forEach((item) => {
        if (typeId.value === item.id) return item.typeName
      })
      return ''
    })

    const checkTab = (id) => {
      if (currTab.value === id) return
      // currTab.value = "all";
      // typeId.value = "";
      // initInfo();

      resetState()
      currTab.value = id
      typeId.value = id
      if (id === 'all') return initInfo()
      getTypeData()
    }

    const isComplete = localStorage.isComplete
    if (isComplete !== 'TRUE' && !sessionStorage.qydialog) {
      showDiaFlag.value = true
      sessionStorage.qydialog = 'y'
    }

    const initInfo = async () => {
      const postData = {
        typeId: id,
        uid,
        pagesize: 999,
        startRow: 0
      }
      const data = await UserService.qryProdcutByType(postData)
      if (!data) return
      const { productList, typeList, total } = data
      if (productList) QyListData.value = productList
      if (!typeData.value.length) {
        typeListData.value = typeList
        typeData.value = typeList
      }
    }

    const getTypeData = async () => {
      const postData = {
        typeId: typeId.value,
        uid,
        pagesize: 999,
        startRow: 0
      }
      const data = await UserService.qryProdcutByType(postData)
      if (!data) return
      const { productList, typeList, total } = data
      if (!typeData.value.length) {
        typeListData.value = typeList
        typeData.value = typeList
      }
      if (productList) QyListData.value = productList
      //   pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize);
      //   pageInfo.currTypeTotal = total;
    }

    const getData = async (isRefresh) => {
      pageInfo.curr += 1
      if (isRefresh) {
        QyListData.value = []
        pageInfo.curr = 0
      }
      if (pageInfo.curr > pageInfo.totalPage) return
      if ((currTab.value = 'all')) {
        initInfo()
      } else {
        getTypeData()
      }
    }

    onMounted(() => {
      const isCashier = localStorage.isCashier
      const ua = window.navigator.userAgent.toLowerCase()
      if (isCashier === 'true' && ua.indexOf('micromessenger') === -1) {
        router.replace('/hyCenter')
        return
      }
      initInfo()
    })

    return {
      infos,
      QyListData,
      typeData,
      typeListData,
      siwperData,
      advertData,
      searchTxt,
      showDiaFlag,
      advertMapData,
      initInfo,
      getData,
      currTab,
      typeId,
      resetState,
      checkTab,
      currTypeData
    }
  }
}
</script>
<style lang="scss">
.csyy {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.18rem 0.3rem 1rem;
  .input-box {
    padding-left: 0.36rem;
    height: 0.9rem;
    border-radius: 0.45rem;
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 0.2rem;
    .img {
      width: 0.25rem;
      height: 0.27rem;
      margin-right: 0.19rem;
    }
    .input {
      @include fontSC(0.28rem, #333);
      height: 100%;
      &::-webkit-input-placeholder {
        color: #999;
      }
    }
  }
  .swiper-box {
    border-radius: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }
  .van-swipe-item {
    height: 3.8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }
  .sec {
    position: relative;
    height: 1.56rem;
    border-radius: 0.04rem;
    overflow: hidden;
    margin-bottom: 0.22rem;
    padding: 0.22rem 0.37rem 0 0.19rem;
    background: #fff;
    .con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l {
        display: flex;
        align-items: center;
        // width: 4.78rem;
        .notice {
          width: 0.73rem;
          height: 0.55rem;
          margin-right: 0.25rem;
        }
        .title {
          @include fontSC(0.28rem, #111);
          line-height: 1.3em;
          width: 3.1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .r {
        width: 1.56rem;
        line-height: 0.5rem;
        text-align: center;
        background: #fb3e28;
        border-radius: 0.05rem;
        @include fontSC(0.26rem, #fff);
      }
    }
    .img {
      width: 100%;
      height: 100%;
    }
    .more {
      position: absolute;
      left: 50%;
      bottom: 0.23rem;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      @include fontSC(0.24rem, #666);
      img {
        margin-left: 0.09rem;
        width: 0.1rem;
        height: 0.18rem;
      }
    }
  }
  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      background: #fff;
      width: 4.37rem;
      border-radius: 0.2rem;
      .img {
        position: absolute;
        width: 2.94rem;
        height: 2.67rem;
        @include position-middle(-0.55rem);
        z-index: 1;
      }
      .top {
        position: relative;
        width: 100%;
        height: 2.71rem;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        overflow: hidden;
        background: url(../../assets/images/home/dialog-bg.png) 100% 100%
          no-repeat;
        background-size: cover;
      }
      .bot {
        padding: 0.43rem 0.3rem 0.32rem;
        text-align: center;
        .title {
          @include fontSC(0.3rem, #333);
          line-height: 1.5em;
        }
        .memo {
          @include fontSC(0.25rem, #999);
          line-height: 1.5em;
        }
        .btn {
          margin: 0.38rem auto 0;
          width: 2.35rem;
          text-align: center;
          line-height: 0.68rem;
          @include fontSC(0.34rem, #fff);
          background: #fe5631;
          box-shadow: 0px 0.06rem 0px 0px rgba(253, 100, 76, 0.3);
          border-radius: 0.34rem;
        }
      }
      .close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.74rem;
        width: 0.42rem;
        height: 0.42rem;
        img {
          width: 100%;
        }
      }
    }
  }

  .product-list {
    .title {
      display: flex;
      align-items: center;
      @include fontSC(0.34rem, #323232);
      margin-bottom: 0.42rem;
      img {
        width: 0.35rem;
        height: 0.33rem;
        margin-right: 0.16rem;
      }
    }
    .type-list {
      overflow-x: scroll;
      display: flex;
      margin-bottom: 0.34rem;
      white-space: nowrap;
      .item {
        margin-right: 0.48rem;
        &:last-child {
          margin-right: 0;
        }
        &.curr {
          color: #fb3e28;
        }
        @include fontSC(0.28rem, #666);
      }
    }
  }
}
</style>
