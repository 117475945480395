<template>
  <div class="addinfoPy-page">
    <div class="title">请完善单位信息</div>
    <div class="cell">
      <div class="item">
        <div class="label">单位名称</div>
        <div class="right">
          <input
            v-model="name"
            readonly
            type="text"
            placeholder="请填写单位名称"
          />
        </div>
      </div>
      <div class="item">
        <div class="label">营业执照</div>
        <div class="right img-box">
          <div class="img-wrap" @click="imgReview">
            <div class="bg"></div>
            <img class="big" src="@/assets/images/home/big.png" alt="" />
            <img class="img" :src="liceseNo" alt="" />
          </div>
        </div>
      </div>

      <div class="item">
        <div class="label">联系电话</div>
        <div class="right">
          <input v-model="mobile" type="text" placeholder="请填写联系电话" />
        </div>
      </div>
      <div class="item">
        <div class="label">公司地址</div>
        <div class="right">
          <input v-model="address" type="text" placeholder="请输入公司地址" />
        </div>
      </div>
      <div class="item">
        <div class="label">公司介绍</div>
        <div class="right">
          <textarea v-model="detail" type="text" placeholder="请输入公司介绍" />
        </div>
      </div>

      <div class="item input-wrap">
        <div class="label">公司图片</div>

        <input
          class="uploadInput"
          v-if="resetFlag"
          @change="fileChange"
          accept="image/*"
          capture="camera"
          placeholder="请上传营业执照"
          type="file"
        />
        <img
          v-if="headImage || fileData"
          class="uploadImg"
          :src="headImage || fileData"
          alt=""
        />
        <span style="color: #f54029" v-else>去上传</span>
      </div>
    </div>

    <van-popup
      v-show="showInputPicker"
      position="bottom"
      @click-overlay="closePopUp"
    >
      <van-picker
        show-toolbar
        :columns="pickerCloumns"
        @cancel="closePopUp"
        @confirm="onInputPickerConfirm"
      />
    </van-popup>

    <div class="btn" @click="perfectInformation">提交</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import CenterService from '@/api/center.js'
import { Picker, Popup, ImagePreview } from 'vant'
import mixin from '@/mixin/mixin.js'
import uploadAction from '../userCenter/upload'

export default defineComponent({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
    // [ImagePreview.name]: ImagePreview,
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid
    const memberInfo = ref({})

    const {
      fileChange,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation
      //   liceseNo,
    } = uploadAction()

    const state = reactive({
      name: '',
      sex: '',
      address: '',
      liceseNo: '',
      mobile: '',
      certId: '',
      ownedStreet: '',
      ownedCommunity: '',
      headImage: '',
      detail: ''
    })
    const selectInfo = reactive({
      curClickIput: '',
      pickerCloumns: [],
      showInputPicker: false,
      ownedStreetKey: '0',
      sexKey: '0',
      ownedStreetArr: [],
      ownedCommunityKey: '0',
      ownedCommunityArr: [],
      sexArr: ['男', '女']
    })

    onMounted(() => {
      initData()
    })

    const initData = async () => {
      const data = await CenterService.userDetail({
        uid
      })
      console.log(data)
      if (!data) return
      const { member } = data
      memberInfo.value = member
      const { mobile, name, address, liceseNo, headImage, detail } = member
      if (mobile) state.mobile = mobile
      if (name) state.name = name
      if (address) state.address = address
      if (liceseNo) state.liceseNo = liceseNo
      if (headImage) state.headImage = headImage
      if (detail) state.detail = detail
    }
    const onInputPickerConfirm = (value) => {
      //  console.log('value',value);
      selectInfo.showInputPicker = false
      const inputType = selectInfo.curClickIput

      state[inputType] = value

      selectInfo.curClickIput = '' // 重置,防止不能重复点击
    }
    const closePopUp = () => {
      selectInfo.showInputPicker = false
      selectInfo.curClickIput = '' // 重置,防止不能重复点击
    }
    const checkAction = (type) => {
      selectInfo.curClickIput = type
      selectInfo.showInputPicker = true
      selectInfo.pickerCloumns = selectInfo[`${type}Arr`]
    }
    const imgReview = () => {
      ImagePreview([state.liceseNo])
    }

    watch(fileData, (newValue) => {
      state.headImage = newValue
    })
    const perfectInformation = async () => {
      if (
        !['name', 'address', 'mobile', 'detail', 'headImage'].every(
          (item) => state[item]
        )
      ) {
        showAlert({ content: '选项不能为空' })
        return
      }
      const { name, sex, mobile, certId, address, detail, liceseNo } = state
      const data = await CenterService.perfectInformation({
        name,
        address,
        mobile,
        detail,
        headImage: state.headImage
      })
      console.log(data)
      if (!data) return
      localStorage.isComplete = 'TRUE'
      showAlert({
        content: '添加成功',
        confirmFn: () => {
          router.go('-1')
        }
      })
    }
    return {
      ...toRefs(state),
      ...toRefs(selectInfo),
      memberInfo,
      imgReview,
      perfectInformation,
      onInputPickerConfirm,
      closePopUp,
      checkAction,
      fileChange,
      imgBase64Data,
      fileData,
      resetFlag,
      Orientation
      //   liceseNo,
    }
  }
})
</script>
<style lang="scss">
.addinfoPy-page {
  padding: 0 0.3rem;
  .title {
    padding: 0.78rem 0 0.56rem;
    @include fontSC(0.48rem, #333);
    @include border-1px(#e9e9ff, bottom);
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    background: #f65341;
    width: 6.22rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 1rem auto 0.48rem;
    border: 1px solid #fd5431;
    text-align: center;
  }
  input {
    color: #333;
  }
  textarea {
    width: 100%;
    height: 100%;
    color: #333;
    text-align: right;
    padding: 0.1rem 0.05rem;
  }
  .cell .item .right.input-box {
    position: relative;
    input {
      padding-right: 0.44rem;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0.29rem;
      height: 0.19rem;
      background: url(../../assets/images/down.png);
      background-size: cover;
      //   opacity: 0.7;
    }
  }
  .cell .item.input-wrap {
    display: flex;
    height: 1.04rem;
    @include border-1px(#ddd, bottom);
    &.zhizhao {
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.21rem;
      img {
        width: 0.46rem;
        height: 0.42rem;
      }
    }
    .uploadInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .img {
      width: 0.32rem;
      height: 0.37rem;
      margin-right: 0.2rem;
    }
    .block {
      width: 0.32rem;
      height: 0.37rem;
    }
    input {
      flex: 1;
      height: 100%;
      padding-right: 0.32rem;
      @include fontSC(0.3rem, #333);
      &::-webkit-input-placeholder {
        color: #666;
      }
    }
    &.valid-item {
      input {
        padding-left: 1rem;
      }
      .get-code {
        width: 1.6rem;
        text-align: center;
        line-height: 0.58rem;
        border: 1px solid #fb3e28;
        border-radius: 0.45rem;
        @include fontSC(0.24rem, #fb3e28);
      }
      .sec {
        @include fontSC(0.24rem, #fb3e28);
      }
    }
  }
  .uploadImg {
    width: 0.46rem;
    height: 0.42rem;
  }
  .cell .item .img-box {
    position: relative;
    .img-wrap {
      width: 1.09rem;
      height: 0.82rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.3);
      }
      .big {
        position: absolute;
        width: 0.29rem;
        height: 0.29rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .img {
        display: block;
        width: 1.09rem;
        height: 0.82rem;
      }
    }
  }
}
</style>
