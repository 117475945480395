<template>
  <div class="soldier-orderqrcode-page">
    <div class="pro-box">
      <img class="title" src="@/assets/images/soldier_qr_title.png" alt="" />
      <div class="qr-box" v-if="scanType=='HTTP'"  @click="clickGet">
        <div class="qr-wrap" id="qrcode"></div>
        <img class="logo" src="@/assets/images/soldier_logo.png" alt="" />
      </div>
      <div class="qr-box" v-if="scanType=='DEFAUL'">
        <div class="qr-wrap" id="qrcode"></div>
        <img class="logo" src="@/assets/images/soldier_logo.png" alt="" />
      </div>
      <div v-if="scanType=='PIC'">
        <img class="qr-img" :src="scanUrl" alt="" />
      </div>
      <div class="con">
        <div class="txt"><span class="l">产品名称：</span><span>{{ info.welfareName }}</span></div>
        <div class="txt"><span class="l">姓名：</span><span>{{ info.userName }}</span></div>
        <div class="txt"><span class="l">领取时间：</span><span>{{ info.createTime }}</span></div>
        <div class="desc">{{scanType!='HTTP'?info.welfareDesc:`点击上方迷彩码进入${info.welfareName.includes('电影票')?'订票选座' : '服务申请'}通道`}}</div>
      </div>

      <div class="con con-t" v-if="!fromMyFlb">
        <div class="desc">可到我的”我的善城-我的迷彩包“查阅具体情况</div>
      </div>
      <!-- <div class="btn-wrap">
      <div
        v-if="scanType=='HTTP'"
        class="btn"
        @click="clickGet"
      >
        点击领取
      </div> 
    </div>-->
    </div>
    <usedFlb v-if="showSoldierAgreement" @cancel="soldierAgreementClose"></usedFlb>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { defineComponent, computed, onMounted, ref, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import usedFlb from '@/components/usedFlb.vue'
const QRCode = require('../../utils/qrcode')
import SoldierService from '@/api/soldier.js'
import OrderService from '@/api/order.js'

export default {
  components: {
    usedFlb,
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const no = route.query.no
    const fromMyFlb = route.query.from == 'myFlb'
    const uid = localStorage.uid
    const urlData = ref(`${no}`)
    const dialogInfo = computed(() => store.state.dialogInfo)
    const info = ref(JSON.parse(sessionStorage.lqInfo || '{}'))
    const scanType = ref('')
    const scanUrl = ref('')
    const showSoldierAgreement = ref(false)
    const { scanUrl: scanUrlData, scanType: scanTypeData } = info.value
    scanUrl.value = scanUrlData
    scanType.value = scanTypeData

    onMounted(() => {
      if(fromMyFlb) {
       return  initData()
      }
      try {
        const dom = document.getElementById('qrcode')
        if(dom) dom.innerHTML = ""
        dom && new QRCode(dom, {
            text: urlData.value,
            // width: 128,
            // height: 128,
            colorDark : "transparent",
            colorLight : "#fff",
            correctLevel : QRCode.CorrectLevel.H
        });
       
      } catch (error) {}
    })

    const showSoldierPop = () => {
      showSoldierAgreement.value = true
    }

    const soldierAgreementClose = () => {
      showSoldierAgreement.value = false
    }

    const initData = async () => {
      const data = await OrderService.qryWelfarePackageDetail({
        uid: uid,
        id: no
      })
      console.log(data)
      if (!data) return
      const { welfare, supplier } = data
      info.value = welfare
      const { scanUrl: scanUrlData, scanType: scanTypeData } = info.value
      scanUrl.value = scanUrlData
      scanType.value = scanTypeData
      nextTick(() => {
        const dom = document.getElementById('qrcode')
        dom.innerHTML = ""
        dom && new QRCode(dom, {
            text: urlData.value,
            // width: 128,
            // height: 128,
            colorDark : "transparent",
            colorLight : "#fff",
            correctLevel : QRCode.CorrectLevel.H
        });
      });
    }
    const clickGet = async () => {
      if(info.value.welfareName.includes('电影票')) {
        window.location.replace(scanUrl.value)
        return
      }

      if(info.value.status=='USED') {
        showSoldierPop()
        return
      }
      const postData = {
        orderNo: no
      }
      const data = await SoldierService.camouflageParkUseOrder(postData)
      if (!data) return
      window.location.replace(scanUrl.value)
    }

    return {
      dialogInfo,
      urlData,
      info,
      scanType,
      scanUrl,
      fromMyFlb,
      clickGet,
      showSoldierAgreement,
      showSoldierPop,
      soldierAgreementClose,
    }
  }
}
</script>

<style lang="scss">
.soldier-orderqrcode-page {
  min-height: 100vh;
  background: #cfc7bc;
  background-image: url(../../assets/images/soldier_qr_bg1.png),
    url(../../assets/images/soldier_qr_bg2.png);
  background-position: top center, bottom center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;
  padding: 0.98rem 0 0;

  .pro-box {
    margin-bottom: 0.29rem;
    @include fontSC(0.34rem, #000);
    .title {
      margin: 0 auto 0.39rem;
      width: 4.47rem;
      height: 1.17rem;
      display: block;
    }
    .qr-box {
      position: relative;
      width: 4.78rem;
      margin: 0 auto;
      padding: 0.28rem;
      background-color: #fff;
      border-radius: 0.3rem;
    }
    .qr-img{
      display: block;
      width: 4.22rem;
      height: 4.22rem;
      margin: 0 auto;
    }
    .qr-wrap {
      width: 4.22rem;
      height: 4.22rem;
      background: url(../../assets/images/micai.png) no-repeat;
      background-size: cover;
      img {
        display: block;
        width: 4.22rem !important;
        height: 4.22rem !important;
        &:last-of-type{
          display: block;
        }
      }
    }
    .logo{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
    }
    canvas {
      width: 4.22rem !important;
      height: 4.22rem !important;
      // &:last-of-type{
      //   display: block!important;
      // }
    }
    .con {
      padding: 0.46rem 0.48rem;
      background: #F5F4F1;
      background: linear-gradient(to bottom, rgba(247, 245, 242, 1), rgba(247, 245, 242, .5));
      @include fontSC(0.34rem, #000);
      border-radius: 0.1rem;
      margin: 0.4rem 0.3rem 0.2rem;
      .txt {
        margin-bottom: 0.2rem;
        .l {
          display: inline-block;
          width: 2.6rem;
        }
      }
      .desc{
        font-size: 0.34rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #B40020;
        line-height: 0.48rem;
        margin-top: 0.3rem;
      }
      &.con-t {
        background: rgba(247, 245, 242, .7);
        margin-top: 0;
        .desc {
          margin-top: 0;
        }
      }
    }
  }
  .btn-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    @include border-1px(#f2f2f2, top);
    padding: 0.25rem 0 0.2rem;
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.9rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    background: #f65341;
    text-align: center;
    &.disabled{
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}
</style>
