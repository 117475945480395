<template>
  <div class="center-page">
    <div class="top">
      <div class="l">
        <img src="@/assets/images/center/my-icon.png" alt="" />{{
          info.userName
        }}
      </div>
      <div class="r" @click="goQrcode">
        <img src="@/assets/images/center/qrcode.png" alt="" />
      </div>
    </div>
    <div class="info">
      <div class="total">
        <div class="t">
          <b class="b">{{ info.balance }}</b>公益豆
          <div class="bImg" @click="enterSay()"><img src="../../assets/images/wenhao.png" alt="">
          </div>
          <div v-if="flagDo1"
            style="width: 2.5rem;position: absolute;border: 1px dashed #B7B7B7;top: 55px;right: 50px;padding: 10px;color: #333333;font-size: 15px;background-color: white;">
            每年1月1日账户所有 公益豆将被清空，请 及时使用哦~</div>
        </div>
        <div class="btn" @click="getPayoutData">派发</div>
      </div>
      <div class="sort">
        <div class="item">
          <p class="b">{{ info.totalSend }}</p>
          <p class="txt">个人总产值</p>
        </div>
        <div class="line"></div>
        <div class="item" @click="goLoveMan(info.sort)">
          <p class="b" :class="{ t: !info.sort }">
            {{ info.sort || '暂无排名' }}
          </p>
          <p class="txt">我的社区排名</p>
        </div>
      </div>
    </div>

    <!-- <div class="tip" @click="setLoveAction">
      <div class="check-box" :class="{ checked: checkedFlag }"><i></i></div>
      愿意参与爱心人士排名
    </div> -->
    <div class="cell">
      <div class="item" @click="goPage('myOrder')">
        <div class="label">我的订单</div>
        <div class="arrow"></div>
      </div>
      <!-- <div
        class="item"
        v-if="userType === 'persion' && inWhiteList"
        @click="goPage('myOrder', { type: 'true' })"
      >
        <div class="label">我领取的公益包</div>
        <div class="arrow"></div>
      </div> -->
      <div class="item" v-if="userType === 'persion' && inWhiteList" @click="goPage('myWelFare')">
        <div class="label">我的迷彩包</div>
        <div class="arrow"></div>
      </div>
      <div class="item" @click="goDangAn">
        <div class="label">我的公益档案</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType === 'persion'" @click="goPage('award')">
        <div class="label">我的奖品</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType === 'persion'&& levelMes==2 ||levelMes==3 " @click="goPage('myActivity')">
        <div class="label">我的活动</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType === 'persion'" @click="goInfo('PackageOrder')">
        <div class="label">双拥套餐订单</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType === 'persion'" @click="goInfo('addInfo')">
        <div class="label">个人信息</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType !== 'persion'" @click="goInfo('addInfo')">
        <div class="label">商户信息</div>
        <div class="arrow"></div>
      </div>
      <!-- <div
        class="item"
        v-if="userType !== 'persion'"
        @click="goPage('myGyb')"
      >
        <div class="label">我发布的公益包</div>
        <div class="arrow"></div>
      </div> -->
      <div class="item" v-if="userType !== 'persion'" @click="goPage('myFlb')">
        <div class="label">我发布的迷彩包</div>
        <div class="arrow"></div>
      </div>
      <div class="item" v-if="userType === 'enterprise'" @click="goInfo('addInfoQy')">
        <div class="label">单位信息</div>
        <div class="arrow"></div>
      </div>
    </div>
    <div class="cell">
      <div class="item" @click="goPage('kefu')">
        <div class="label">智能客服</div>
        <div class="arrow"></div>
      </div>
      <div class="item" @click="goPage('message')">
        <div class="label">留言板</div>
        <div class="arrow"></div>
      </div>
      <!-- <a class="item" href="tel:68823060"
        ><div class="label">联系我们</div>
        <div class="arrow"></div
      ></a> -->
      <div class="item" v-if="userType === 'enterprise'" @click="goPage('updatePw')">
        <div class="label">修改密码</div>
        <div class="arrow"></div>
      </div>
      <div class="item" @click="changeModeFlag = true">
        <div class="label">切换模式</div>
        <div class="arrow"></div>
      </div>
    </div>

    <div class="dialog" v-if="showDiaFlag">
      <div class="con">
        <div class="con-top">请选择你要派发的人群</div>
        <div class="check-list">
          <div class="item" @click="checkPayoutBox('dbCheck')" :class="{ checked: dbCheck }">
            <i></i>低保低收入（{{ subsistenceAllowance }}人）
          </div>
          <div class="item" @click="checkPayoutBox('yfCheck')" :class="{ checked: yfCheck }">
            <i></i>优抚对象（{{ preferentialTreatment }}人）
          </div>
        </div>
        <div class="bot">
          <div class="title">请输入派发数量</div>
          <input type="text" v-model="payoutNum" />
        </div>
        <div class="btn-box">
          <div class="l" @click="closeDialog">取消</div>
          <div class="r" @click="payoutAction">确定</div>
        </div>
      </div>
    </div>
    <div class="change-mode-box" v-if="changeModeFlag">
      <div class="con">
        <img class="bg" src="@/assets/images/mode.png" alt="" />
        <div class="btn" @click="changeMode">
          {{ `开启${userType === 'persion' ? '单位' : '个人'}模式` }}
        </div>
        <img class="close" @click="closeMode" src="@/assets/images/close.png" alt="" />
      </div>
    </div>
    <div class="bthButton" @click="goLogins('login')">退出</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
  toRefs
} from 'vue'
import { useStore } from 'vuex'
import UserService from '@/api/common.js'
import CenterService from '@/api/center.js'
import { useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

import { Field, CellGroup, Checkbox, CheckboxGroup } from 'vant'

export default defineComponent({
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const router = useRouter()
    const checkedFlag = ref(false)
    const changeModeFlag = ref(false)
    const showDiaFlag = ref(false)
    const userType = localStorage.userType
    const info = ref({})
    const levelMes = localStorage.getItem('level');
    const uid = localStorage.uid

    let flagDo1 = ref(false)


    const payOutInfo = reactive({
      preferentialTreatment: 0,
      subsistenceAllowance: 0,
      dbCheck: false,
      yfCheck: false,
      payoutNum: ''
    })

    onMounted(() => {
      console.log(levelMes)
      myCenter()
    })


    const enterSay = async () => {
      flagDo1.value = !flagDo1.value
    }

    const myCenter = async () => {
      const data = await CenterService.myCenter({
        uid
      })
      console.log(data)
      if (!data) return
      info.value = data
      checkedFlag.value = data.isJoinSort === 'true'
      sessionStorage.balance = data.balance
    }
    const goLogins = (routerName, query) => {
      localStorage.clear();
      router.push({
        path: '/' + routerName,
        query: query || {}
      })
    }
    const goPage = (routerName, query) => {
      router.push({
        path: '/' + routerName,
        query: query || {}
      })
    }
    const setLoveAction = async () => {
      const data = await CenterService.updateJoinLoveSort({
        uid,
        join: !checkedFlag.value
      })
      console.log(data)
      if (!data) return
      checkedFlag.value = !checkedFlag.value
    }
    const getPayoutData = async () => {
      const data = await UserService.getHomepacketAcceptUser({
        uid
      })
      console.log(data)
      if (!data) return
      payOutInfo.preferentialTreatment = data.preferentialTreatment
      payOutInfo.subsistenceAllowance = data.subsistenceAllowance
      showDialog()
      // if(payOutInfo.preferentialTreatment<=0&&payOutInfo.subsistenceAllowance<=0){
      //      showAlert({content: '没有要派发的对象'})
      // } else{
      //     showDialog()
      // }
    }

    const inWhiteList = computed(() => {
      return true
      // return store.state.whiteList.includes(uid)
    })
    const randomTxt = () => {
      return 'xxxxxxxx-xxxx-xxx-yxxx-xxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
    const payoutAction = async () => {
      if (
        payOutInfo.preferentialTreatment <= 0 &&
        payOutInfo.subsistenceAllowance <= 0
      ) {
        showAlert({ content: '没有要派发的对象' })
        return
      }

      if (payOutInfo.payoutNum > info.value.balance) {
        showAlert({ content: '已超出您的公益豆总数' })
        return
      }
      if (!payOutInfo.payoutNum) {
        showAlert({ content: '请输入派发数量' })
        return
      }
      if (!payOutInfo.yfCheck && !payOutInfo.dbCheck) {
        showAlert({ content: '请至少选择一个派发对象' })
        return
      }

      const data = await UserService.redPacketMoney({
        uid,
        bizNo: randomTxt(),
        preferentialTreatment: payOutInfo.preferentialTreatment,
        preferentialTreatmentFlag: payOutInfo.yfCheck,
        subsistenceAllowance: payOutInfo.subsistenceAllowance,
        subsistenceAllowanceFlag: payOutInfo.dbCheck,
        redPacketAmount: Number(payOutInfo.payoutNum)
      })
      console.log(data)
      if (!data) return
      showAlert({
        content: '派发成功',
        confirmFn: () => {
          myCenter()
        }
      })
    }
    const checkPayoutBox = async (item) => {
      payOutInfo[item] = !payOutInfo[item]
    }
    const changeMode = () => {
      localStorage.isComplete = ''
      localStorage.uid = ''
      localStorage.tokenId = ''
      localStorage.userType = ''
      localStorage.level = ''
      localStorage.hasConfirmAgree = ''
      localStorage.isSoldier = ''
      localStorage.headImage = ''
      sessionStorage.balance = ''
      sessionStorage.linkeyUserId = ''
      changeModeFlag.value = false
      router.push({
        path: '/login',
        query: {
          type: userType === 'persion' ? 2 : 1
        }
      })
    }
    const closeMode = () => {
      changeModeFlag.value = false
    }
    const goInfo = (name) => {
      router.push({
        name: name
      })
    }
    const goQrcode = () => {
      router.push({
        name: localStorage.level == '3' ? 'soldierQrCode' : 'hyQrCode'
      })
    }

    const goLoveMan = (sort) => {
      router.push({
        name: 'lovePerson',
        query: {
          id: sort
        }
      })
    }
    const goDangAn = () => {
      const name =
        userType === 'persion' ? 'publicWelfareRecord' : 'qyPublicWelfareRecord'
      router.push({
        name
      })
    }
    const showDialog = () => {
      showDiaFlag.value = true
    }
    const closeDialog = () => {
      showDiaFlag.value = false
    }
    return {
      ...toRefs(payOutInfo),
      myCenter,
      info,
      flagDo1,
      checkedFlag,
      userType,
      inWhiteList,
      showDiaFlag,
      changeModeFlag,
      changeMode,
      checkPayoutBox,
      goDangAn,
      goLoveMan,
      goInfo,
      goQrcode,
      closeMode,
      setLoveAction,
      showDialog,
      getPayoutData,
      payoutAction,
      closeDialog,
      goPage,
      enterSay,
      goLogins
    }
  }
})
</script>
<style lang="scss">
.bthButton {
  @include fontSC(0.36rem, #fd5431);
  width: 6.22rem;
  line-height: 1rem;
  border-radius: 0.5rem;
  margin: 0.4rem auto 0.48rem;
  border: 1px solid #fd5431;
  text-align: center;
}

.center-page {
  min-height: 100vh;
  background: linear-gradient(0deg, #fb2e21 0%, #fe6538 100%) no-repeat;
  background-size: 100% 3.6rem;
  padding: 0.31rem 0.3rem 1rem;
  background-color: #f8f8f8;

  .change-mode-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      background: #fff;
      width: 4.2rem;
      height: 5.4rem;
      border-radius: 0.2rem;

      .bg {
        display: block;
        width: 100%;
      }

      .btn {
        position: absolute;
        left: 50%;
        bottom: 0.48rem;
        transform: translateX(-50%);
        width: 3rem;
        line-height: 0.88rem;
        @include fontSC(0.36rem, #fff);
        background: #fe5631;
        box-shadow: 0px 0.1rem 0px 0px rgba(253, 100, 76, 0.3);
        border-radius: 0.5rem;
        text-align: center;
      }

      .close {
        position: absolute;
        left: 50%;
        bottom: -0.7rem;
        transform: translateX(-50%);
        width: 0.52rem;
        height: 0.52rem;
      }
    }
  }

  .top {
    @include fontSC(0.31rem, #fff);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .l {
      display: flex;
      align-items: center;

      img {
        width: 0.46rem;
        margin-right: 0.16rem;
      }
    }

    .r {
      width: 1.1rem;
      height: 0.42rem;

      img {
        width: 100%;
      }
    }
  }

  .info {
    box-shadow: 0px 5px 5px 0px rgba(252, 55, 37, 0.03);
    padding: 0 0.22rem;
    @include w-box();
    margin: 0.42rem auto 0.2rem;
    @include fontSC(0.28rem, #666);

    .b {
      @include fontSC(0.52rem, #333);
      margin-bottom: -0.08rem;

      &.t {
        font-size: 0.26rem;
      }
    }

    .total {
      position: relative;
      padding: 0.55rem 0 0.34rem;
      text-align: center;
      @include border-1px(#f2f2f2, bottom);

      .t {
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .bImg {
        margin-left: 0.08rem;
        margin-top: 2px;

        img {
          width: 0.4rem;
          height: 0.4rem;
        }
      }

      .btn {
        margin: 0.18rem auto 0;
        width: 1.12rem;
        line-height: 0.44rem;
        @include fontSC(0.28rem, #f54029);
        background: #ffeeeb;
        border: 1px solid #f54029;
        border-radius: 0.22rem;
      }
    }

    .sort {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 1.7rem;

      .line {
        width: 0.02rem;
        height: 0.7rem;
        background: #f1f1f1;
      }

      .item {
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .b {
          margin-bottom: 0.22rem;
        }
      }
    }
  }

  .tip {
    width: 6.9rem;
    height: 0.44rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffeeeb;
    box-shadow: 0px 5px 5px 0px rgba(252, 55, 37, 0.03);
    border-radius: 0.2rem;
    @include fontSC(0.24rem, #fd5431);
    margin-bottom: 0.22rem;

    .check-box {
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 0.25rem;
      border: 1px solid #fd5431;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.08rem;

      i {
        width: 0.13rem;
        height: 0.13rem;
        border-radius: 0.25rem;
        background: #fd5431;
        display: none;
      }

      &.checked {
        i {
          display: block;
        }
      }
    }
  }

  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      background: #fff;
      width: 6.58rem;
      border-radius: 0.2rem;
      text-align: center;
      padding-top: 0.65rem;

      .con-top {
        @include fontSC(0.38rem, #333);
        margin-bottom: 0.5rem;
      }

      .check-list {
        margin-bottom: 0.8rem;

        .item {
          @include fontSC(0.32rem, #666);
          margin-bottom: 0.21rem;
          display: flex;
          align-items: center;
          padding-left: 1.44rem;

          i {
            width: 0.3rem;
            height: 0.29rem;
            background: url(../../assets/images/center/uncheck.png);
            background-size: cover;
            margin-right: 0.28rem;
          }

          &.checked {
            color: #fd5431;

            i {
              background: url(../../assets/images/center/checked.png);
              background-size: cover;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .bot {
        padding: 0 0.68rem;
        margin-bottom: 0.89rem;

        .title {
          @include fontSC(0.38rem, #333);
          margin-bottom: 0.31rem;
        }

        input {
          width: 4.74rem;
          height: 0.9rem;
          border: 1px solid #dbdbdb;
          font-size: 0.38rem;
          text-align: center;
        }
      }

      .btn-box {
        display: flex;
        @include border-1px(#e0e0e0, top);
        @include fontSC(0.3rem, #f54029);

        .l {
          flex: 1;
          line-height: 0.99rem;
        }

        .r {
          @include border-1px(#e0e0e0, left);
          flex: 1;
          line-height: 0.99rem;
        }
      }
    }
  }
}
</style>
