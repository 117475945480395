<template>
  <div class="qyhome">
    <div class="input-box" :class="[inWhiteList ? 'qyGyb' : '']">
      <div class="left">
        <img class="img" src="@/assets/images/search.png" alt="" />
        <input type="search" v-model="searchTxt" @keypress="searchAction" placeholder="请输入搜索内容" />
      </div>
      <img v-if="inWhiteList" class="right" @click="doScan" src="@/assets/images/home/qyscan.png" alt="" />
    </div>
    <van-swipe v-if="siwperData" :autoplay="5000" class="swiper-box" lazy-render indicator-color="">
      <van-swipe-item v-for="item in siwperData" :key="item.id">
        <img :src="item.advertUrl" @click="goCompany(item)" />
      </van-swipe-item>
    </van-swipe>
    <div class="sec" v-if="advertData.length">
      <van-swipe :autoplay="5000" class="swiper-box" lazy-render indicator-color="">
        <van-swipe-item v-for="item in advertData" :key="item.id">
          <div class="con" @click="goDetail(item.id)">
            <div class="l">
              <img class="notice" :src="item.activityImg" />
              <div class="title">{{ item.advertName }}</div>
            </div>
            <div class="r">查看详情</div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <p class="more" @click="goMore">
        查看更多<img src="@/assets/images/right-arrow.png" />
      </p>
    </div>

    <div class="product-list" v-if="typeData.length">
      <div class="title">
        <img :src="typeData.length && typeData[0].url" alt="" />
        {{ typeData.length && typeData[0].typeName }}
      </div>
      <div class="type-list">
        <div class="item" :class="{ curr: currTab === 'all' }" @click="checkTab('all')">
          全部
        </div>
        <div class="item" :class="{ curr: currTab === item.id }" v-for="item in typeListData" :key="item.sort"
          @click="checkTab(item.id)">
          {{ item.typeName }}
        </div>
      </div>
      <QyList :typeName="currTypeData" @getNewData="getData" :total="currTypeTotal" :listData="QyListData"></QyList>

    </div>

    <div class="dialog" v-if="showDiaFlag">
      <div class="con">
        <img class="img" src="@/assets/images/home/dialog-icon.png" alt="" />
        <div class="top"></div>
        <div class="bot">
          <div class="title">请完善单位信息</div>
          <div class="memo">
            信息不完整，请完善<br />个人信息，开启公益之旅！
          </div>
          <div class="btn" @click="addInfo">马上完善</div>
        </div>
        <div class="close" @click="closeDialogAction">
          <img src="@/assets/images/close.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <van-cell is-link >展示弹出层</van-cell> -->
    <div>


      <van-popup v-model:show="show" :style="{ height: '53%' }" style="border-radius: 5px;">
        <div class="windowAct">
          <div class="windowAct-title">举办活动通知！</div>
          <div class="windowAct-cont">
            <div>
              <div>活动名称：</div>
              <div>{{ pageHuo.activityName }}</div>
            </div>
            <div>
              <div>活动地点：</div>
              <div>{{ pageHuo.activyAddress }}</div>
            </div>
            <div>
              <div>活动时间：</div>
              <div>{{ pageHuo.activyStartTime }}</div>
            </div>

            <div>
              <div>活动发起方：</div>
              <div>{{ pageHuo.ownedCommunity }}</div>
            </div>

            <div>
              <div>活动内容：</div>
              <div class="tableg">
                {{ pageHuo.activyContent }}</div>
            </div>
          </div>
        </div>
        <div style="position: absolute;top: 10px;right: 10px;" @click="clickfalse()">
          <van-icon name="close" size="30" color="white" />
        </div>

      </van-popup>
    </div>
  </div>
</template>

<script>
import UserService from '@/api/common.js'
import OrderService from '@/api/order.js'
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import QyList from '@/components/QyList.vue'
import { Swipe, SwipeItem, Popup, Icon } from 'vant'
import mixin from '@/mixin/mixin.js'
import { useStore } from 'vuex'

export default {
  name: 'qyHome',
  components: {
    QyList,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },

  setup(props) {
    const show = ref(false);
    const showList = ref({})
    const aaa = ref({})
    const showPopup = () => {
      // show.value = true;
    };


    const { showAlert } = mixin()
    const store = useStore()
    const routerMap = {
      GYSC: 'productList',
      ZLKT: 'productList',
      ZYZFW: 'productList',
      LBSP: 'productList',
      YMP: 'productList',
      CJT: 'luckyWheel'
    }

    const uid = localStorage.uid
    const showDiaFlag = ref(true)
    const infos = ref({})
    const searchTxt = ref('')
    const QyListData = ref([])
    const typeData = ref([])
    const typeListData = ref([])
    const advertMapData = ref([])
    const siwperData = ref([])
    const advertData = ref([])
    const router = useRouter()
    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
      currTypeTotal: 1,
      lowerPrice: '',
      hightPrice: '',
      salesSort: false
    })
    const currTab = ref('all')
    const typeId = ref('')

    const resetState = () => {
      pageInfo.pagesize = 10
      pageInfo.startRow = 1
      pageInfo.totalPage = 1
      pageInfo.curr = 0
      QyListData.value = []
    }

    const currTypeData = computed(() => {
      typeListData.value.forEach((item) => {
        if (typeId.value === item.id) return item.typeName
      })
      return ''
    })

    const inWhiteList = computed(() => {
      return true
      // return store.state.whiteList.includes(uid)
    })

    const levelMes = localStorage.getItem('level');
    const pageHuo = reactive({
      activityName: '',
      activyAddress: '',
      activyStartTime: '',
      ownedCommunity: '',
      activyContent: ""

    })
    const activity = async () => {

      if (levelMes == 2 || levelMes == 3) {
        const leveluid = localStorage.getItem('uid');
        const data = await UserService.getHD({
          "pagesize": 1,
          "startRow": 0,
          "uid": leveluid
        })
        console.log(data)

        show.value = data.showActivity;

        showList.value = data.activity;

        show.value = true;
        pageHuo.activityName = showList.value.activityName
        pageHuo.activyAddress = showList.value.activyAddress
        pageHuo.activyStartTime = showList.value.activyStartTime
        pageHuo.ownedCommunity = showList.value.ownedCommunity
        pageHuo.activyContent = showList.value.activyContent
      }
      console.log(pageHuo.curr)
    }
    const clickfalse = async () => {
      show.value = false;
    }

    const getWxConfig = async () => {
      const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false
      const data = await UserService.getWxConfig({
        url: isIos ? localStorage.firstUrl : window.location.href
      })
      if (!data) return
      const { signature, timestamp, nonceStr } = data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: 'wx267b6224a7e9475c', // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
      })
    }

    const checkTab = (id) => {
      if (currTab.value === id) return
      // currTab.value = "all";
      // typeId.value = "";
      // initInfo();

      resetState()
      currTab.value = id
      typeId.value = id
      if (id === 'all') return initInfo()
      getTypeData()
    }

    const isComplete = localStorage.isComplete
    if (isComplete !== 'TRUE' && !sessionStorage.qydialog) {
      showDiaFlag.value = true
      sessionStorage.qydialog = 'y'
    }

    const initInfo = async () => {
      const data = await UserService.homepageEntry({
        uid: localStorage.uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize
      })

      const { advertMap, productList, showTypeList, total } = data
      // console.log(data);
      if (!data) return
      if (productList) QyListData.value = [...QyListData.value, ...productList]
      if (!typeData.value.length) {
        typeListData.value = showTypeList[0].subTypeList
        typeData.value = showTypeList
      }

      siwperData.value = advertMap.HOME_FIRST || []
      advertData.value = advertMap.HOME_SECOND || []
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize)
      pageInfo.currTypeTotal = total
    }

    const getLevel = async () => {
      const data = await UserService.queryMember({
        uid: localStorage.uid,
      })

      if (!data) return
      const { level } = data
      localStorage.level = level
    }

    const getTypeData = async () => {
      const postData = {
        hightPrice: pageInfo.hightPrice,
        lowerPrice: pageInfo.lowerPrice,
        salesSort: pageInfo.salesSort,
        typeId: typeId.value,
        uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize
      }
      const data = await UserService.qryProdcutByType(postData)
      if (!data) return
      const { productList, showTypeList, total } = data
      if (!typeData.value.length) {
        typeListData.value = showTypeList[0].subTypeList
        typeData.value = showTypeList
      }
      if (productList) QyListData.value = [...QyListData.value, ...productList]
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize)
      pageInfo.currTypeTotal = total
    }
    const goDetail = async (id) => {
      router.push({
        path: '/publicWelfareDetail',
        query: {
          id
        }
      })
    }
    const searchAction = async (e) => {
      if (e.keyCode !== 13) return

      if (!searchTxt.value) {
        showAlert({ content: '搜索内容不能为空' })
        return
      }
      router.push({
        path: '/searchQiye',
        query: {
          q: searchTxt.value
        }
      })
    }
    const getData = async (isRefresh) => {
      pageInfo.curr += 1
      if (isRefresh) {
        QyListData.value = []
        pageInfo.curr = 0
      }
      if (pageInfo.curr > pageInfo.totalPage) return
      if (currTab.value == 'all') {
        initInfo()
      } else {
        getTypeData()
      }
    }
    const goSwiper = async (id) => {
      router.push({
        path: '/publicWelfareActivity',
        query: {
          id
        }
      })
    }
    const goMore = async (id) => {
      router.push({
        path: '/publicWelfareActivity'
        //   query: {
        //       id
        //   }
      })
    }
    const goPublicWelfare = async (id) => {
      router.push({
        path: '/publicWelfareActivity',
        query: {
          id
        }
      })
    }
    const addInfo = () => {
      router.push('/addInfoQy')
    }
    const closeDialogAction = () => {
      showDiaFlag.value = false
    }
    const goTypePage = (item) => {
      const key = item.typeKey
      router.push({
        path: `/${routerMap[key]}`,
        query: {
          id: item.id
        }
      })
    }
    const doScan = async () => {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: async function (res) {
          const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          console.log(result)
          if (localStorage.level == '4') {
            // data = await UserService.activityScan({
            //   uid: localStorage.uid,
            //   scanVal: result
            // })
            const [type, val] = result.split('_')
            if (type == 'hy') {
              showAlert({ content: '暂不支持该类型二维码' })
              return
            }
            const data = await UserService.volunteerActivityScan({
              uid: localStorage.uid,
              sweptUid: val,
            })
            if (!data) return
            showAlert({ content: '扫码成功' })
          } else if (localStorage.level == '0' && localStorage.userType == 'enterprise') {
            const [type, orderNo] = result.split('_')
            if (type == 'Gyb' || type == 'Flb') {
              const detail = type === 'Flb' ? 'qryFlbDetail' : 'qryGybDetail'
              const data = await OrderService[detail]({
                id: result,
                uid,
                orderNo: result
              })
              if (!data) return
              router.push({
                path: `/Qr${type}Detail`,
                query: {
                  no: result
                }
              })
            } else if (type == 'Act') {
            } else {
              showAlert({ content: '暂不支持该类型二维码' })
            }
          } else {
            showAlert({ content: '暂不支持该类型二维码' })
          }
        }
      })
    }
    const goCompany = async (detail) => {
      const { advertUrl, advertDetail } = detail
      console.log(
        JSON.stringify({
          advertUrl: advertUrl,
          advertDetail: advertDetail
        })
      )
      sessionStorage.advertInfo = JSON.stringify({
        advertUrl: advertUrl,
        advertDetail: advertDetail
      })
      router.push({
        path: '/loveCompany'
      })
    }

    onMounted(() => {


      const isCashier = localStorage.isCashier
      const ua = window.navigator.userAgent.toLowerCase()
      if (isCashier === 'true' && ua.indexOf('micromessenger') === -1) {
        router.replace('/hyCenter')
        return
      }
      initInfo()
      getWxConfig()
      getLevel()
      activity()
    })




    return {
      pageHuo,
      aaa,
      show,
      infos,
      QyListData,
      typeData,
      typeListData,
      siwperData,
      advertData,
      searchTxt,
      showDiaFlag,
      advertMapData,
      inWhiteList,
      goTypePage,
      goPublicWelfare,
      goMore,
      goSwiper,
      addInfo,
      goCompany,
      initInfo,
      searchAction,
      getData,
      currTab,
      typeId,
      closeDialogAction,
      resetState,
      checkTab,
      currTypeData,
      doScan,
      goDetail,
      clickfalse,
      activity
    }
  }
}
</script>
<style lang="scss" scoped>
.windowAct {
  width: 6rem;
  height: 7.0rem;

  .windowAct-title {
    text-align: center;
    line-height: 2.5rem;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.4rem;
    height: 2.5rem;
    background: #FF512F;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FF512F #F09819);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FF512F, #F09819, );
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .windowAct-cont {
    padding: 0.4rem;
    height: 200px;

    >div {
      height: 0.8rem;
      // line-height: 80px;
      // line-height: 2rem;
      display: flex;
      color: #333333;
      font-size: 0.33rem;

      >div:first-of-type {
        width: 2.08rem;
      }
    }


  }

}

.tableg {
  word-wrap: break-word;
  width: 245px;
}

.qyhome {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.18rem 0.3rem 1rem;

  .input-box {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    justify-content: space-between;

    &.qyGyb .left {
      flex: inherit;
    }

    .left {
      padding-left: 0.36rem;
      display: flex;
      align-items: center;
      width: 5.84rem;
      height: 0.9rem;
      border-radius: 0.45rem;
      background: #fff;
      flex: 1;
    }

    .right {
      width: 0.7rem;
      height: 0.7rem;
    }

    .img {
      width: 0.25rem;
      height: 0.27rem;
      margin-right: 0.19rem;
    }

    .input {
      @include fontSC(0.28rem, #333);
      height: 100%;

      &::-webkit-input-placeholder {
        color: #999;
      }
    }
  }

  .swiper-box {
    border-radius: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }

  .van-swipe-item {
    height: 3.8rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }

  .sec {
    position: relative;
    height: 1.56rem;
    border-radius: 0.04rem;
    overflow: hidden;
    margin-bottom: 0.22rem;
    padding: 0.22rem 0.37rem 0 0.19rem;
    background: #fff;

    .con {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        align-items: center;

        // width: 4.78rem;
        .notice {
          width: 0.73rem;
          height: 0.55rem;
          margin-right: 0.25rem;
        }

        .title {
          @include fontSC(0.28rem, #111);
          line-height: 1.3em;
          width: 3.1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .r {
        width: 1.56rem;
        line-height: 0.5rem;
        text-align: center;
        background: #fb3e28;
        border-radius: 0.05rem;
        @include fontSC(0.26rem, #fff);
      }
    }

    .img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .more {
      position: absolute;
      left: 50%;
      bottom: 0.23rem;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      @include fontSC(0.24rem, #666);

      img {
        margin-left: 0.09rem;
        width: 0.1rem;
        height: 0.18rem;
      }
    }
  }

  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      background: #fff;
      width: 4.37rem;
      border-radius: 0.2rem;

      .img {
        position: absolute;
        width: 2.94rem;
        height: 2.67rem;
        @include position-middle(-0.55rem);
        z-index: 1;
      }

      .top {
        position: relative;
        width: 100%;
        height: 2.71rem;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        overflow: hidden;
        background: url(../assets/images/home/dialog-bg.png) 100% 100% no-repeat;
        background-size: cover;
      }

      .bot {
        padding: 0.43rem 0.3rem 0.32rem;
        text-align: center;

        .title {
          @include fontSC(0.3rem, #333);
          line-height: 1.5em;
        }

        .memo {
          @include fontSC(0.25rem, #999);
          line-height: 1.5em;
        }

        .btn {
          margin: 0.38rem auto 0;
          width: 2.35rem;
          text-align: center;
          line-height: 0.68rem;
          @include fontSC(0.34rem, #fff);
          background: #fe5631;
          box-shadow: 0px 0.06rem 0px 0px rgba(253, 100, 76, 0.3);
          border-radius: 0.34rem;
        }
      }

      .close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.74rem;
        width: 0.42rem;
        height: 0.42rem;

        img {
          width: 100%;
        }
      }
    }
  }

  .product-list {
    .title {
      display: flex;
      align-items: center;
      @include fontSC(0.34rem, #323232);
      margin-bottom: 0.42rem;

      img {
        width: 0.35rem;
        height: 0.33rem;
        margin-right: 0.16rem;
      }
    }

    .type-list {
      overflow-x: scroll;
      display: flex;
      margin-bottom: 0.34rem;
      white-space: nowrap;

      .item {
        margin-right: 0.48rem;

        &:last-child {
          margin-right: 0;
        }

        &.curr {
          color: #fb3e28;
        }

        @include fontSC(0.28rem, #666);
      }
    }
  }
}
</style>
