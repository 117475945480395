<template>
  <div class="myWelFare-page">
    <div class="tab-list">
      <div
        @click="checkTab('1')"
        class="item"
        :class="{ curr: currTab === '1' }"
      >
        全部
      </div>
      <div
        @click="checkTab('2')"
        class="item"
        :class="{ curr: currTab === '2' }"
      >
        已使用
      </div>
      <div
        @click="checkTab('3')"
        class="item"
        :class="{ curr: currTab === '3' }"
      >
        未使用
      </div>
    </div>
    <!-- <div class="tips">
      凭兑换记录于慈善超市领取物品，7天内不去兑换，订单失效。
    </div> -->
    <div class="welfale-list">
      <div
        class="item"
        :class="[
          item.expireDay > 0 && item.status !== 'USED' ? '' : 'disabled',
        ]"
        v-for="item in listData"
        :key="item.id"
      >
        <div class="l-r-circle"><div class="line"></div></div>
        <div class="top">
          <div class="title">{{ item.welfareSimpleName }}</div>
          <div class="des">
            <div class="name">{{ item.welfareName }}</div>
            <div class="use">请于{{ item.exprieTime }}之前使用</div>
          </div>
          <!-- <div class="price">
            <span class="amt">{{ item.welfarePrice }}</span
            >元
          </div> -->
          <div class="used" v-if="item.status === 'USED'">已使用</div>
        </div>
        <div class="bot">
          <div
            class="expires"
            v-if="item.expireDay > 0 && item.status !== 'USED'"
          >
            <i></i><span class="date">{{ item.expireDay }}天后过期</span>
          </div>
          <div v-else></div>
          <div class="detail" @click="goQrcode(item)">查看详情<i></i></div>
        </div>

        <!-- <div
          class="btn"
          v-show="item.stauts !== 'FETCHED'"
          @click="goQrcode(item)"
        >
          查看领取二维码
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs,
} from "vue";
import mixin from "@/mixin/mixin.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import OrderService from "@/api/order.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const uid = localStorage.uid;
    const route = useRoute();
    const orderInfo = ref([]);
    const currTab = ref("1");
    const { showAlert } = mixin();
    const state = reactive({
      listData: [],
      searchTxt: "",
    });

    onMounted(() => {
      getOrder();
    });
    const getOrder = async (type = "") => {
      const data = await OrderService.qryWelfarePackage({
        uid,
        status: type,
      });
      console.log(data);
      if (!data) return;
      const { packageList } = data;
      state.listData = orderInfo.value = packageList || [];
    };
    const unGetData = computed(() => {
      return orderInfo.value.filter((item) => item.stauts === "CREATE");
    });
    const getedData = computed(() => {
      return orderInfo.value.filter((item) => item.stauts === "FETCHED");
    });
    const checkTab = (n) => {
      if (currTab.value === n) return;
      currTab.value = n;
      switch (n) {
        case "1":
          state.listData = orderInfo.value;
          break;
        case "2":
          state.listData = orderInfo.value.filter(
            (item) => item.status === "USED"
          );
          break;
        case "3":
          state.listData = orderInfo.value.filter(
            (item) => item.status === "VALID"
          );
          break;
      }
    };

    const goQrcode = (item) => {
      if (item.expireDay <= 0) {
        return showAlert({ content: "已过期" });
      }
      sessionStorage.flbInfo = JSON.stringify(item);
      router.push({
        path: item.memberLevel == "3" ? "/soldierOrderQrCode" : "/flbQrCode",
        query: {
          no: item.id,
          from: "myFlb",
        },
      });
    };

    const searchAction = async (e) => {
      if (e.keyCode !== 13) return;
      state.listData = orderInfo.value.filter(
        (item) => item.productName.indexOf(state.searchTxt) > -1
      );
    };
    const inputChange = async () => {
      if (!state.searchTxt) state.listData = orderInfo.value;
    };

    return {
      ...toRefs(state),
      orderInfo,
      currTab,
      unGetData,
      getedData,
      checkTab,
      inputChange,
      searchAction,
      goQrcode,
    };
  },
});
</script>
<style lang="scss">
.myWelFare-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.2rem 0.3rem;
  .tab-list {
    display: flex;
    padding-bottom: 0.23rem;
    .item {
      @include fontSC(0.3rem, #666);
      margin-right: 0.49rem;
      &.curr {
        font-weight: bold;
        color: #fb3e28;
      }
    }
  }
  .tips {
    display: flex;
    align-items: center;
    padding: 0.39rem;
    margin-bottom: 0.29rem;
    @include fontSC(0.24rem, #fd5431);
    line-height: 1.3em;
    height: 0.82rem;
    background: #ffeeeb;
    box-shadow: 0px 0.05rem 0.05rem 0px rgba(252, 55, 37, 0.03);
    border-radius: 0.41rem;
  }

  .welfale-list {
    .item {
      position: relative;
      padding: 0.23rem 0.25rem 0.39rem;
      margin-bottom: 0.2rem;
      background: #fff;
      overflow: hidden;
      .l-r-circle {
        position: absolute;
        width: 100%;
        height: 0.26rem;
        left: 0;
        top: 1.93rem;
        .line {
          position: absolute;
          width: 100%;
          height: 0.13rem;
          border-bottom: 2px dashed #f7f5f6;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -0.13rem;
          width: 0.26rem;
          height: 0.26rem;
          background: #f7f5f6;
          border-radius: 50%;
          z-index: 3;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -0.13rem;
          width: 0.26rem;
          height: 0.26rem;
          background: #f7f5f6;
          border-radius: 50%;
          z-index: 3;
        }
      }
      &.disabled {
        .top {
          .title {
            color: #999;
          }
          .des {
            .name {
              color: #999;
            }
            .use {
              color: #999;
            }
          }
          .price {
            color: #999;
            .amt {
              color: #999;
            }
            .unit {
              color: #999;
            }
          }
        }
        .bot {
          .expires {
            color: #999;
            i {
              background: #999;
              border-radius: 50%;
              left: 0.19rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .detail {
            color: #999;
            i {
              border-color: transparent transparent transparent #999;
            }
          }
        }
      }
      .top {
        display: flex;
        flex-direction: column;
        .title {
          margin-bottom: 0.46rem;
          @include fontSC(0.24rem, #999);
        }
        .des {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.56rem;
          .name {
            font-weight: bold;
            margin-bottom: 0.14rem;
            line-height: 1.3em;
            @include fontSC(0.36rem, #141313);
          }
          .use {
            margin-bottom: 0.3rem;
            line-height: 1.3em;
            @include fontSC(0.2rem, #999);
          }
        }
        .price {
          position: absolute;
          top: 0.74rem;
          right: 0.29rem;
          @include fontSC(0.32rem, #fb432e);
          display: flex;
          align-items: flex-end;
          .amt {
            font-size: 0.72rem;
            color: #fb3e28;
            margin-bottom: -0.1rem;
            font-weight: bold;
          }
          .unit {
            font-size: 0.3rem;
            color: #fb3e28;
          }
        }
      }
      .used {
        position: absolute;
        top: 0.89rem;
        right: 0.51rem;
        width: 1rem;
        line-height: 0.4rem;
        text-align: center;
        background: #d4d4d4;
        border-radius: 0.06rem;
        color: #565656;
        font-size: 0.22rem;
      }
      .bot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .expires {
          min-width: 1.76rem;
          height: 0.33rem;
          padding-right: 0.1rem;
          background: url(../../assets/images/home/left-arrrow.png) 100% 100%
            no-repeat;
          background-size: cover;
          padding-left: 0.48rem;
          display: flex;
          align-items: center;
          position: relative;
          @include fontSC(0.2rem, #fff);
          i {
            position: absolute;
            width: 0.06rem;
            height: 0.06rem;
            background: #f7f5f6;
            border-radius: 50%;
            left: 0.19rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .detail {
          position: relative;
          @include fontSC(0.2rem, #fb3e28);
          padding-right: 0.42rem;
          i {
            width: 0;
            height: 0;
            border: 0.1rem solid;
            position: absolute;
            right: 0.15rem;
            // transform: rotate(45deg);
            top: 0;
            // background: #fb3e28;
            border-color: transparent transparent transparent #fb3e28;
            border-radius: 3px;
          }
        }
      }
      .btn {
        @include fontSC(0.36rem, #fd5431);
        width: 6.22rem;
        line-height: 1rem;
        border-radius: 0.5rem;
        margin: 0.4rem auto 0.48rem;
        border: 1px solid #fd5431;
        text-align: center;
      }
    }
  }
}
</style>
