<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">复员军人补助优待政策问答</div>
            <div class="content-page">
                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList"></div> -->

                <van-collapse v-model="activeNames" accordion>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="1">

                        <template #title>
                            <div class="titles">复员军人享受定期定量补助的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            1954年10月31日以前入伍，持有复员军人证件或经组织批准复员，并已确定其身份，生活困难的， 1、退役后未参加过工作；2、孤老或年老体弱、丧失劳动能力，可以享受定期定量补助。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="2">
                        <template #title>
                            <div class="titles">复员军人享受定期荣誉金的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            对在部队荣立二等功以上的享受定期定量补助的复员军人，经所在区县民政局审核批准后，发给定期荣誉金。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="3">
                        <template #title>
                            <div class="titles">享受定期定量补助的复员军人户籍农转非的，定期定量补助如何发放？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            原在农村享受定期定量补助的复员军人，因国家征用土地，转为城镇户口后，领取的征地或镇保养老金低于城镇定期定量补助的，补足到同时期入伍的城镇复员军人定补标准。
                            原在农村享受定期定量补助的复员军人户籍农转非后，仍符合享受定期定量补助条件的，按城镇定期定量补助标准发放。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="4">
                        <template #title>
                            <div class="titles">已领取精简下放职工补助费的复员军人，定期定量补助如何发放？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            对原领取精简下放职工补助费的复员军人，他们的补助费低于复员军人定期定量补助标准的，采取差额补助的办法，补足到同时期入伍的复员军人定补标准。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="5">
                        <template #title>
                            <div class="titles">复员军人定期定量差额补助的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            对超过退休年龄且收入水平低于相应定期定量补助标准的复员军人，由其户籍所在地区县民政部门按照相应定期定量补助标准给予差额补助。
                            “超过退休年龄”是指按规定已领取养老金的；按规定不能或尚未领取社会养老金，且男满60周岁、女满55周岁的。
                            “收入水平”是指各类养老金（城镇企业基本养老金按原办法计算）、退休退养金、共享费与残疾抚恤金、定期定量补助、定期抚慰金等各种固定收入的合计。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="6">
                        <template #title>
                            <div class="titles">复员军人如何申领定期定量差额补助？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            符合享受差额补助条件的复员军人向户籍所在街道（乡镇）民政部门办理差额补助申领手续，需提供下列材料：申请人书面申请（写明申请人身份、申请事由）、身份证、户口簿、个人收入相关证明、复员军人登记表、复员军人证件。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="7">
                        <template #title>
                            <div class="titles">复员军人定期定量差额补助如何进行调整？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市定期定量补助标准调整后，对照新定期定量补助标准和复员军人现养老金等各项收入进行差额补助：一是对原纳入的差额补助对象进行调整发放；二是对超过定期定量补助标准的对象停止发放。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="8">
                        <template #title>
                            <div class="titles">享受定期定量补助的复员军人死亡后，丧葬补助费如何计发？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期定量补助的复员军人死亡后，增发6个月其原享受的定期定量补助作为丧葬补助费，次月起停发定期定量补助。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="9">
                        <template #title>
                            <div class="titles">享受定期定量补助复员军人的医疗优待有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期定量补助的复员军人，按照属地原则相应参加本市居民医疗保险和新型农村合作医疗，个人缴费部分由所在区县财政承担。其当年内发生的医疗费用经相应医疗保障体系报销，并已实施各类医疗救助、互助、减负后，其剩余医疗费用中符合城镇职工基本医疗保险报销范围，但个人负担部分仍较重的，可申请优抚对象医疗补助。医疗补助的给付比例应不低于50%。
                            享受定期定量补助的复员军人到医疗机构就医时，可享受优先挂号、优先就诊、优先化验、优先付费、优先取药、优先住院等各项待遇。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="10">
                        <template #title>
                            <div class="titles">如何办理复员军人关系转移？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期定量补助、补差的复员军人户籍发生变化，应办理关系转移。本人应向原户籍所在街道（乡镇）书面提出申请，并提供迁入地户籍证明材料，按规定办理关系转移手续。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="11">
                        <template #title>
                            <div class="titles">复员军人定期定量补助是否计入家庭收入？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在建立和实施最低生活保障制度中，复员军人的定期定量补助不计入家庭收入。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="12">
                        <template #title>
                            <div class="titles">复员军人的法律责任有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            复员军人被判处有期徒刑、剥夺政治权利或者被通缉期间，中止其补助优待；被判处死刑、无期徒刑的，取消其补助优待资格。
                            有下列行为之一的，由区县民政局给予警告，限期退回非法所得；情节严重的，停止其享受的补助、优待；构成犯罪的，依法追究刑事责任：
                            （一）冒领抚恤金、优待金、补助金的；
                            （二）虚报病情骗取医药费的；
                            （三）出具假证明，伪造证件、印章骗取优待金、补助金的。 </div>
                    </van-collapse-item>
                   
                </van-collapse>


            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon, Collapse, CollapseItem } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()


        const activeNames = ref('1');


        return {
            activeNames,
        }
    }
})
</script>
<style lang="scss" scoped>
.titles {
    height: 15vw;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.35rem;
    font-weight: 700;
}

.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.8rem;
    // width: 80vw;
    padding: 0.26rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  