import { ref } from 'vue'
import { showAlert } from '@/utils/popup.js'
import UserService from '@/api/common.js'

export default function sms(phone) {
  const sendAuthCode = ref(true)
  const auth_time = ref(60)
  const smscode = ref('')
  const checkPhone = () => {
    console.log(phone.value)
    if (!phone.value) {
      showAlert({
        content: '请输入手机号'
      })
      return false
    }
    const reg = /^1\d{10}$/
    if (!reg.test(phone.value)) {
      showAlert({
        content: '手机号格式错误，请重新输入'
      })
      return false
    }
    return true
  }
  // const checkCode = () => {
  //   if (!validCode) {
  //     showAlert({ content: '请输入短信验证码' })
  //     return false
  //   }
  //   const reg = /^[0-9]{6}$/
  //   if (!reg.test(!phone.value)) {
  //     showAlert({ content: '短信验证码为6位数字，请重新输入' })
  //     return false
  //   }
  //   return true
  // }
  const getAuthCode = () => {
    sendAuthCode.value = false
    auth_time.value = 60
    var auth_timetimer = setInterval(() => {
      auth_time.value--
      if (auth_time.value <= 0) {
        sendAuthCode.value = true
        clearInterval(auth_timetimer)
      }
    }, 1000)
  }

  const getCode = async () => {
    console.log(checkPhone())
    if (checkPhone()) {
      getAuthCode()
      const data = await UserService.getSmsCode({
        mobile: phone.value
      })
      console.log(data)
      if (!data) return
    }
  }

  return {
    getCode,
    auth_time,
    sendAuthCode,
    smscode
  }
}