<template>
  <div class="hy-qrcode-page">
    <qrcode-vue :value="urlData"></qrcode-vue>
    <div class="des">出示会员码完成公益豆交易</div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import QrcodeVue from 'qrcode.vue'
import LuckyService from "@/api/lucky.js";

export default {
  components: {
    QrcodeVue
  },
  setup(props) {
    const store = useStore();
    const dialogInfo = computed(() => store.state.dialogInfo);
    const uid= localStorage.uid
    const recordListData = ref([]);
    const urlData = ref(`hy_${uid}`)
    onMounted(() => {
      initData();
    });
    const initData = async () => {
      const data = await LuckyService.myDrawRecord({
        uid: localStorage.uid,
        pagesize: 100,
        startRow: 0,
      });
      console.log(data); if (!data) return;
      const { recordList } = data;
      recordListData.value = recordList
    };

    return {
      recordListData,
      dialogInfo,
      urlData,
    };
  },
};
</script>

<style lang="scss">
.hy-qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.93rem 0.3rem 0;
  text-align: center;
  .des{
      margin-top: 0.65rem;
      @include fontSC(0.34rem, #999);
  }
  canvas{
      display: block;
      margin: 0 auto;
      width: 4.5rem!important;
      height: 4.5rem!important;
  }

}
</style>
