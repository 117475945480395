import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routerList = []

const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
const routerTitleMap = {
  Home: '首页',
  QYHome: '首页',
  userCenter: '我的',
  forget: '忘记密码',
  login: '登录',
  updatePw: '修改密码',
  loveCompany: '企业详情',
  luckyWheel: '抽奖台',
  hyQrCode: '我的会员码',
  lqQrCode: '领取二维码',
  ljQrCode: '我的奖品',
  lovePerson: '新华街道排名',
  kefu: '善城',
  linbaoList: '临保商品',
  soldierList: '迷彩公园',
  appeal: '在线申诉',
  soldierOrderQrcode: '领取二维码',
  soldierQrcode: '我的二维码',
}

function importAll(r) {
  r.keys().forEach((key) => {
    // const name = key.substring(2, key.length-4)
    const namePathArr = key.match(/\.(.+)\.vue$/)[1].split('/')
    const name = namePathArr[namePathArr.length - 1]
    // console.log(name)
    routerList.push({
      path: `/${name}`,
      name,
      component: r(key).default
    })
  })
}
importAll(require.context('../views', true, /.vue$/))
// console.log(routerList)

const routes = [{
  path: '/',
  redirect: 'Home'
},
...routerList
]

const router = createRouter({
  BASE_URL: '/shanbao/',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to, from)
  const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false
  // let token1 = JSON.parse(localStorage.res).token;
  // console.log(token1)s
  if (isIos) {
    console.log("beforeEach1 ios");
    if (from.path === "/") {
      localStorage.firstUrl = location.href.split("#")[0]
    }
  }
  // if(to.fullPath == '/login') next()


  if (
    to.path === '/login' ||
    to.path === '/register' ||
    to.path === '/agreement' ||
    to.path === '/privacy' ||
    to.path === '/forget' ||
    to.path === '/updatePw' ||
    to.path === '/reuseLogin'
  ) {
    console.log(2222)

    next()
  } else {
    console.log(111)
    let token = localStorage.tokenId
    let token1 = localStorage.token1
    const id = to.query.id || ''
    console.log(to.path)
    if (id && to.path == '/Home') {
      console.log(90)
      sessionStorage.linkeyUserId = id
      return next('/login')
    }
    if (token) {
      if (to.path === '/Home' && from.path !== '/blank') {
        if (!sessionStorage.jumpWindow) {
          sessionStorage.jumpWindow = 'true'
          return next('/blank')
        }
      }

      if (to.path === '/Home' && localStorage.userType === 'enterprise') {
        return next('/QyHome')
      }
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach((to) => {
  setTimeout(() => {
    const title =
      routerTitleMap[to.name] ||
      routerTitleMap[to.query.t] ||
      to.query.t ||
      '善城'

    if (title) document.title = title // 设置title
    if (isiOS) {
      const _iframe = document.createElement('iframe')
      _iframe.style.display = 'none'
      _iframe.onload = function () {
        setTimeout(() => {
          document.body.removeChild(_iframe)
        }, 0)
      }
      document.body.appendChild(_iframe)
    }
  }, 0)
})

export default router