<template>
  <div class="hyCenter-page">
    <div class="list">
      <div class="item" @click="doScan('JP')">
        <img src="@/assets/images/app/list3.png" alt="">
      </div>
      <div class="item" @click="doScan('SP')">
        <img src="@/assets/images/app/list1.png" alt="">
      </div>
      <div class="item" @click="doScan('GW')">
        <img src="@/assets/images/app/list2.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "@/api/common.js";
import CashierService from "@/api/cashier.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const uid = localStorage.uid;

    const state = reactive({
      type: 'SP',
      listDatas: [],
    });

    onMounted(() => {
      // scanQrLogin('hy_494498072639414272')
    });

    const doScan = (type)=> {
      state.type = type
      android.doScan();
    }

     const goPage = (path, no) => {
      router.push({
        path,
        query:{
            no
        }
      })
    }

     const scanQrLogin = async (code) => {
      if(code.indexOf('_') !== -1) {
        const [ type, val ] = code.split('_')
        code = val
      }
      
      const data = await CashierService.scanQrLogin({
        memberUid: code,
      });
      if (!data) return;
      goPage('/pay', code)
    };

    window.setScanResult = (result) => {
      console.log("扫码结果:"+result);
      if(state.type==='JP'){
         goPage('/ljSucc', result)
      }else if(state.type==='SP'){
        goPage('/lqSucc', result)
      }else{ 
        scanQrLogin(result)
      }
    }
    

    return {
      ...toRefs(state),
      doScan,
    };
  },
});
</script>
<style lang="scss">
.hyCenter-page {
  min-height: 100vh;
  background: #f8f8f8;
//   padding-bottom: 3.6rem;
 .item{
     img{
         display: block;
         width: 100%;
     }
 }
}
</style>
