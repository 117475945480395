import { inject } from 'vue'

export default function name() {
  const showAlert = inject('showAlert')
  const showConfirm = inject('showConfirm')
  return {
    showAlert,
    showConfirm
  }
}
