<template>
  <div class="pay-page">
    <div class="title">请输入购买金额</div>
    <div class="input-wrap">
        <div class="memo">金额</div>
        <div class="input-box">￥<input v-model="amount" type="number" placeholder="请输入金额" /></div>
    </div>
    <div class="btn" @click="superMarketpayMoneyToMember">用户已付款</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import CashierService from "@/api/cashier.js";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin();
    
    const route = useRoute()
    const router = useRouter()
    const no = route.query.no;
    const amount = ref('');
    onMounted(() => {
       
    });
    const superMarketpayMoneyToMember = async () => {
      if(!amount.value) {
        showAlert({ content: "请先输入金额"});
        return;
      }
      if(!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(amount.value)){
        showAlert({ content: "请输入正确的金额"});
        return;
      }
      const data = await CashierService.superMarketpayMoneyToMember({
        amount: amount.value,
        uid: no,
      });
      console.log(data);
      if (!data) return;
      showAlert({ content: "付款成功",confirmFn:()=>{
          router.go(-1);
      } });
    };
    return {
        amount,
        superMarketpayMoneyToMember
    };
  },
});
</script>
<style lang="scss">
.pay-page {
  min-height: 100vh;
  padding: 0 0.33rem;
  .title{
     padding: 0.87rem 0 0.58rem;
     color: #000;
     font-size: 0.3rem;
  }
  .input-wrap {
      padding-bottom: 0.27rem;
      position: relative;
      .memo{
        padding: 0.23rem 0 0.54rem;
        color: #999999;
        font-size: 0.24rem;
      }
      .input-box{
          font-size: 0.54rem;
          display: flex;
          align-items: center;
      }
      input{
          color: #333;
          font-size: 0.54rem;
           &::-webkit-input-placeholder {
              color: #666;
              font-size: 0.3rem;
           }
      }
      &::before, &::after{
          content: "";
          height: 1px;
          position: absolute;
          left: 0;
          background: #E6E6E6;
          transform: scaleY(0.5);
          width: 100%;
      }
      &::before{
          top: 0;
      }
      &::after{
          bottom: 0;
      }
  }
    .btn {
        position: absolute;
        left: 50%;
        bottom: 2.2rem;
        transform: translateX(-50%);
          width: 6.9rem;
          text-align: center;
          line-height: 1rem;
          @include fontSC(0.36rem, #fff);
          background: #F65341;
          border-radius: 0.5rem;
        }
}
</style>
