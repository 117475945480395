<template>
  <div class="loveCompany-page">
    <van-swipe
      :autoplay="5000"
      class="swiper-box"
      lazy-render
      indicator-color=""
    >
      <van-swipe-item v-for="image in images" :key="image">
        <img :src="image" />
      </van-swipe-item>
    </van-swipe>
    <div class="detail-list">
      <!-- <div class="item">
          <div class="title">公司名称</div>
        <div class="memo">{{proInfo.name}}</div>
      </div>
      <div class="item">
          <div class="title">公司地址</div>
        <div class="memo">{{proInfo.address}}</div>
      </div> -->
      <div class="item">
        <div class="title">公司详情</div>
        <div class="memo" v-html="proInfo.advertDetail"></div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import UserService from "@/api/common.js";
import { Swipe, SwipeItem } from "vant";


export default defineComponent({
  components: {
       [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  props: {},
  setup(props) {
    
    const store = useStore();
    const route = useRoute();
    const id = route.query.id
    const advertInfo = JSON.parse(sessionStorage.advertInfo)
    const images = ref([advertInfo.advertUrl]);
    const proInfo = ref(advertInfo);
    const getData = async() => {
      const data = await UserService.qryLoveMemberInfo({
        uid: id,
      });
      console.log(data); if (!data) return;
      proInfo.value = data
      images.value = data.advertUrl?[data.advertUrl]:[]
    };

    onMounted(() => {
        // getData()
    });

    const tabChange = ({ name }) => {};
    return {
      proInfo,
      images
    };
  },
});
</script>
<style lang="scss">
.loveCompany-page {
  min-height: 100vh;
  background: #f8f8f8;
   .van-swipe-item {
    height: 4.16rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }
   .detail-list {
    .item {
      position: relative;
      margin-bottom: 0.2rem;
      padding: 0.45rem 0.3rem;
      background: #fff;
      .title{
          @include fontSC(0.38rem, #333);
          margin-bottom: 0.22rem; 
      }
      .memo{
          line-height: 1.5em;
          @include fontSC(0.34rem, #999);
          p{
            line-height: 1.5em;
          }
      }
    }
  }
}
</style>
