<template>
  <div class="cart-page">
    <div class="pro-box">
      <div class="title">商品信息</div>
      <div class="wrap" v-for="item in listInfo" :key="item.id" >
        <div class="con">
          <img class="l" :src="item.pic" alt="" />
          <div class="r">
            <p class="des">{{item.productName}}</p>
            <p class="memo">
              商品价格： <span class="total">{{ item.price }}</span>
            </p>
            <p class="detail">交易数量： X{{item.num}}</p>
          </div>
        </div>
        <img class="del" src="@/assets/images/app/del.png" @click="deleteShopCard(item.barcode)" alt="" />
      </div>
      <!-- <div class="wrap">
        <div class="con">
          <img class="l" src="" alt="" />
          <div class="r">
            <p class="des">轮椅车</p>
            <p class="memo">
              商品总额： <span class="total">{{ listInfo.orderAmount }}</span>
            </p>
            <p class="detail">交易数量： X1</p>
          </div>
        </div>
        <img class="del" src="@/assets/images/app/del.png" alt="" />
      </div> -->
    </div>
    <div class="scan" @click="doScan">
        <img class="img" src="@/assets/images/app/qrcode.png" alt="" />
    </div>
    <div class="footer">
        <div class="left">总计：<span class="t">￥{{amount}}</span></div>
        <div class="btn" @click="shopCardCounter">用户已结算</div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import CashierService from "@/api/cashier.js";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const no = route.query.no;
    const disabled = ref(false);
    const state = reactive({
      type: 'SP',
      amount: 0,
    });
    const listInfo = ref({});
    const { showAlert, showConfirm } = mixin();

    onMounted(() => {

    });

     const doScan = (type)=> {
    //   state.type = type
      android.doScan();
    }

    window.setScanResult = (result) => {
      console.log("扫码结果:"+result);
      addShopCard(result)
    }

    const addShopCard = async (code) => {
      const data = await CashierService.addShopCard({
        barcode: code,
        memberUid: no,
        num: 1,
      });
      console.log(data);
      if (!data) return;
      listInfo.value = data.shopcardList || []
      state.amount = data.amount || 0
    };
    const deleteShopCard = async (code) => {
      const data = await CashierService.deleteShopCard({
        barcode: code,
        memberUid: no,
        num: 1,
      });
      console.log(data);
      if (!data) return;
      listInfo.value = data.shopcardList || []
      state.amount = data.amount || 0
    };
    const shopCardCounter = async () => {
      if (disabled.value) return;
      const data = await CashierService.shopCardCounter({
        uid: no,
        memberUid: no,
      });
      if (!data) return;
      disabled.value = true;
      showAlert({ content: "结算成功",confirmFn:()=>{
          router.go(-1);
      } });
    };
  
    return {
        ...toRefs(state),
      listInfo,
      disabled,
      addShopCard,
      deleteShopCard,
      shopCardCounter,
      doScan,
    };
  },
});
</script>
<style lang="scss">
.cart-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-top: 0.33rem;
  padding-bottom: 0.63rem;
  .scan{
       position: fixed;
       width: 0.53rem;
       height: 0.53rem;
       bottom: 2.6rem;
       right: 0.33rem;
       img{
           width: 100%;
           height: 100%;
       }
  }

  .pro-box {
    margin: 0.21rem 0 0.2rem;
    background: #fff;
    
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      padding-left: 0.29rem;
      &::before {
        content: "";
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.26rem 0.25rem 0.26rem 0.21rem;
        margin-bottom: 0.2rem;
        .del{
            width: 0.36rem;
            height: 0.36rem;
        }
    }
    .con {
      display: flex;
      align-items: center;
      
      .l {
        width: 1.85rem;
        height: 1.86rem;
        border: 1px solid #e6e6e6;
        margin-right: 0.33rem;
      }
      .r {
        flex: 1;
        @include fontSC(0.3rem, #5f5f5f);
        .des {
          @include fontSC(0.32rem, #282828);
          margin-bottom: 0.33rem;
          line-height: 1.3em;
        }
        .memo {
          @include fontSC(0.28rem, #868686);
          margin-bottom: 0.33rem;
          .total {
            @include fontSC(0.28rem, #F65341);
          }
        }
        .detail{
            @include fontSC(0.28rem, #868686); 
        }
      }
    }
  }

  .footer{
      position: fixed!important;;
      display: flex;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0.25rem 0.33rem 0.25rem 0.29rem;
      align-items: center;
      justify-content: space-between;
      @include border-1px (#E0E0E0, top);
      background: #fff;
      .left{    
          .t{
              @include fontSC(0.36rem, #F65341);
          }
      }
  }

  .btn {
    @include fontSC(0.36rem, #fff);
    width: 3.08rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    background: #f65341;
    text-align: center;
  }
}
</style>
