<template>
  <div class="mall-page">
    <section class="sticky">
      <van-tabs v-model:active="active" @click-tab="tabChange" background="#f8f8f8" line-height="0" title-inactive-color="#666" title-active-color="#FB3E28">
        <van-tab title="推荐" name="1"></van-tab>
        <van-tab title="服饰" name="2"></van-tab>
        <van-tab title="洗护" name="3"></van-tab>
        <van-tab title="其他" name="4"></van-tab>
      </van-tabs>
      <van-dropdown-menu class="dropdown">
        <van-dropdown-item title="销量" />
        <van-dropdown-item :title="state.priceTitle" @change="priceChange" v-model="state.priceRange" :options="priceOption" />
      </van-dropdown-menu>
    </section>
    <ProductList @getNewData="getData" :listData="listData"></ProductList>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import ProductList from "@/components/ProductList.vue";
import { Tab, Tabs, DropdownMenu, DropdownItem } from "vant";

const datas = [
  {
    title: "律师咨询服务",
    memo: "4000",
    l: "20",
    r: "30"
  },
  {
    title: "律师咨询服务律师咨询服务律师咨询服务",
    memo: "4000",
    l: "20",
    r: "30"
  },
  {
    title: "律师咨询服务律师咨询服务",
    memo: "4000",
    l: "20",
    r: "30"
  },
  {
    title:
      "律师咨询服务律师咨询服务律师咨询服务律师咨询服务律师咨询服务律师咨询服务",
    memo: "4000",
    l: "20",
    r: "30"
  }
];
export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    ProductList
  },
  props: {},
  setup(props) {
    const store = useStore();
    const listData = ref(JSON.parse(JSON.stringify(datas)));
    const activeName = ref("1");

    const state = reactive({
      priceRange: "",
      priceTitle: "价格区间"
    });

    const priceOption = [
      { text: "50-100公益豆", value: 0 },
      { text: "100-300公益豆", value: 1 },
      { text: "300-500公益豆", value: 3 },
      { text: "500-700公益豆", value: 4 },
      { text: "700-900公益豆", value: 5 },
    ];

    onMounted(() => {});

    const getData = () => {
      setTimeout(() => {
        listData.value = JSON.parse(JSON.stringify(datas));
      }, 1000);
    };
    const tabChange = ({ name }) => {
      if (activeName.value !== name) {
        activeName.value = name;
        console.log(name);
      } else {
      }
    };
    const priceChange = value => {
      state.priceTitle = priceOption.find(item => item.value == value).text;
    };

    return {
      activeName,
      listData,
      getData,
      tabChange,
      priceOption,
      state,
      priceChange
    };
  }
});
</script>
<style lang="scss">
.mall-page {
  .van-tabs__nav--line {
    &::after {
      content: "";
      width: 2.5rem;
    }
  }
  .dropdown {
    .van-dropdown-menu__bar {
      box-shadow: none;
      &::after {
        content: "";
        width: 3.8rem;
      }
      .van-dropdown-menu__item {
        &:first-child {
          .van-dropdown-menu__title {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    .van-cell {
      padding: 0.3rem;
      &::after {
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
