<template>
  <div class="flbDetail-page">
    <Detail :images="imagesList"></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.welfareName }}</div>

      <div class="memo">
        <div class="l">
          剩余<span class="t">{{ product.validStock }}</span
          >份
        </div>
        <div class="r">
          已领取<span class="t">{{ product.selledStock }}</span
          >份
        </div>
      </div>
    </div>

    <div class="list-title">福利包核销记录<span class="tit-des">使用数量： {{ selledStock }}</span>
    </div>
    <div class="flb-list" v-for="list in productList" :key="list.productId">
      <div class="top">
        <div class="l">核销时间：{{ list.createTime }}</div>
      </div>
      <div class="bot">
        <span
          >使用人： {{ list.uid
          }}<i class="tag">{{ list.memberLevel }}</i></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import OrderService from '@/api/order.js'
import mixin from '@/mixin/mixin.js'
import { useRouter, useRoute } from 'vue-router'
import Detail from '@/components/Detail.vue'

export default defineComponent({
  components: { Detail },
  props: {},
  setup(props) {
    const store = useStore()
    const { showAlert, showConfirm } = mixin()
    const router = useRouter()
    const route = useRoute()
    const id = route.query.id
    const proInfo = ref({})
    const state = {
      product: {},
      stock: route.query.stock || 0,
      selledStock: route.query.selledStock || 0,
      productList: [],
      imagesList: []
    }

    const getData = async () => {
      const data = await OrderService.qryMyFlbDetail({
        welfareId: id,
        uid: localStorage.uid
      })
      console.log(data)
      if (!data) return
      const { welfareList, product } = data
      state.product = product
      proInfo.value = welfareList[0]
      state.productList = welfareList
      state.imagesList = [welfareList[0]?.welfareUrl]
    }

    onMounted(() => {
      getData()
    })

    return {
      ...toRefs(state),
      proInfo
    }
  }
})
</script>
<style lang="scss">
.flbDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 1.85rem;
  .info-box {
    background: #fff;
    padding: 0.45rem 0.31rem 0.45rem;
    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.24rem;
    }

    .memo {
      display: flex;
      align-items: flex-end;
      &.memo-f l b {
        display: block;
      }
      .l {
        display: flex;
        align-items: flex-end;
        @include fontSC(0.24rem, #f54029);
        line-height: 1;
        .t {
          @include fontSC(0.5rem, #f54029);
          line-height: 1;
          margin-bottom: -0.06rem;
        }
        margin-right: 0.54rem;
      }
      .t {
        // @include fontSC(0.5rem, #f54029);
      }
      @include fontSC(0.24rem, #999);
    }
  }
  .list-title {
    @include fontSC(0.34rem, #333);
    font-weight: bold;
    padding: 0.42rem 0.28rem 0;
    background: #fff;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tit-des {
      font-size: 0.26rem;
      font-weight: 500;
      color: #999;
    }
  }
  .flb-list {
    background: #fff;
    margin-bottom: 0.2rem;
    padding: 0 0.3rem;
    .top {
      display: flex;
      height: 0.85rem;
      align-items: center;
      justify-content: space-between;
      @include fontSC(0.24rem, #999);
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #e6e6e6;
        transform: scaleY(0.5);
      }
    }
    .bot {
      @include fontSC(0.3rem, #333);
      padding: 0.2rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        align-items: center;
        .tag {
          margin-left: 0.1rem;
          @include fontSC(0.18rem, #f65341);
          // width: 0.97rem;
          padding: 0 0.1rem;
          line-height: 0.34rem;
          border: 1px solid #fb3e28;
          border-radius: 0.16rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
