<template>
  <van-pull-refresh
    class="gyblist-wrap"
    v-model="refreshing"
    @refresh="onRefresh"
  >
    <van-list
      :finished="finished"
      finished-text="已经到底了"
      class="list-page"
      @load="onLoad"
    >
      <div class="qy-list">
        <div
          class="item"
          v-for="item in lists"
          :key="item.id"
          @click="goDetail(item.id)"
        >
          <div class="l">
            <img class="img" :src="item.picUrl" alt="" />
            <div class="des">
              <span class="txt">{{ item.productName }}</span
              ><br />
              <span class="type">限量{{ item.stock }}份</span><br />
              <span class="memo">{{ item.selledStock }}人已领</span><br />
              <span
                class="total"
                v-if="!(item.limitCondition && item.limitCondition.length)"
                ><i class="amt">{{ item.price }}</i
                >公益豆
              </span>
              <span class="total" v-else>{{ item.memberLevel }}可领取</span>
            </div>
          </div>
          <div class="r">立即领取</div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { List, PullRefresh } from 'vant'

export default defineComponent({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  props: {
    listData: {
      type: Array,
      default: []
    },
    typeName: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { listData, type, total } = toRefs(props)

    const state = reactive({
      lists: [],
      loading: false,
      finished: false,
      refreshing: false
    })

    const onLoad = () => {
      // 异步更新数据
      emit('getNewData')
    }

    const setDataList = async (data) => {
      if (!data.length) {
        state.lists = []
        return
      }

      if (state.refreshing) {
        state.lists = []
        state.refreshing = false
      }
      state.lists = [...state.lists, ...data]
      // console.log(state.lists)
      // 加载状态结束
      state.loading = false

      // 数据全部加载完成
      if (state.lists.length >= total) {
        state.finished = true
      }
    }

    onMounted(() => {
      setDataList(listData.value)
    })

    watch(
      () => props.listData,
      (newVal, oldVal) => {
        // console.log('--',newVal)
        setDataList(newVal)
      }
    )

    const onRefresh = () => {
      state.finished = false
      state.loading = true
      emit('getNewData', true)
      //   onLoad();
    }
    const goDetail = async (id) => {
      router.push({
        path: '/gybDetail',
        query: {
          pid: id
        }
      })
    }

    return {
      ...toRefs(state),
      onRefresh,
      onLoad,
      goDetail,
      type
    }
  }
})
</script>
<style lang="scss">
.gyblist-wrap {
  // background: #f8f8f8;
  position: relative;
  .qy-list {
    width: 100%;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.21rem 0.3rem;
      margin-bottom: 0.2rem;
      background: #fff;
      border-radius: 0.1rem;
      .l {
        display: flex;
        align-items: center;
        .img {
          display: block;
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 0.1rem;
          margin-right: 0.23rem;
        }
        .des {
          // display: flex;
          // flex-direction: column;
          .type {
            border: 1px solid #f54029;
            border-radius: 0.06rem;
            background: #fff;
            @include fontSC(0.24rem, #fb3e28);
            margin-bottom: 0.53rem;
            padding: 0.1rem 0.12rem;
            display: inline-block;
            margin-top: 0.31rem;
          }
          .txt {
            font-weight: bold;
            @include fontSC(0.3rem, #333);
          }
          .memo {
            @include fontSC(0.2rem, #ccc);
          }
          .total {
            display: inline-block;
            @include fontSC(0.25rem, #f54029);
            margin-top: 0.25rem;
            .amt {
              font-size: 0.36rem;
            }
          }
        }
      }
      .r {
        position: absolute;
        width: 1.52rem;
        line-height: 0.56rem;
        text-align: center;
        background: #ffebe8;
        border-radius: 0.28rem;
        @include fontSC(0.26rem, #fb3e28);
        right: 0.3rem;
        bottom: 0.2rem;
      }
    }
  }
}
</style>
