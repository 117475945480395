import {
  post_http
} from './axios'

class LuckyService {
  static isPrize(params) {
    return post_http('/draw/isPrize', params)
  }
  static myDrawRecord(params) {
    return post_http('/draw/myDrawRecord', params)
  }

  static qryDrawing(params) {
    return post_http('/draw/qryDrawing', params)
  }
}

export default LuckyService