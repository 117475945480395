<template>
  <div class="myFlb-page">
    <div class="title">
      <div class="label">年度报表</div>
      <van-dropdown-menu>
        <van-dropdown-item v-model="year" :options="yearsArr" />
      </van-dropdown-menu>
    </div>
    <div class="count-wrap">
      <div class="count-item">
        <div class="unit">领取数量（个）</div>
        <div class="num">{{ publicNums }}</div>
      </div>
      <!-- <div class="count-item">
        <div class="unit">已领取数量（个）</div>
        <div class="num">{{ shopNums }}</div>
      </div> -->
      <div class="count-item">
        <div class="unit">已使用数量（个）</div>
        <div class="num">{{ usedNums }}</div>
      </div>
    </div>
    <div id="charts"></div>
    <div class="lengend-wrap">
      <div class="lengend-item"><i class="lengend-icon"></i>领取数量</div>
      <!-- <div class="lengend-item"><i class="lengend-icon i2"></i>已领取数量</div> -->
      <div class="lengend-item"><i class="lengend-icon i3"></i>已使用数量</div>
    </div>
    <div class="list-title">福利包明细</div>
    <div class="flb-list" v-for="list in productList" :key="list.productId">
      <div class="top">
        <div class="l">发放时间：{{ list.createTime }}</div>
        <div class="r" @click="goDetail(list)">查看详情</div>
      </div>
      <div class="bot">
        <span>福利包名称：{{ list.welfareSimpleName }}</span
        ><br />
        <span>适用人群： {{ list.memberLevel }}</span
        ><br />
        <span>领取数量： {{ list.total }}</span>
        <span>已使用：{{ list.usedNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import OrderService from '@/api/order.js'
import { DropdownMenu, DropdownItem } from 'vant'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid

    const state = reactive({
      year: '2022',
      yearsArr: [
        { text: '2022', value: '2022' },
        { text: '2021', value: '2021' },
        { text: '2020', value: '2020' }
      ],
      publicNums: 0,
      usedNums: 0,
      productList: [],
      chartsOptions: {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#989898' //x轴的颜色
            }
          },
          axisTick: {
            show: false
          },
          data: []
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#989898' //y轴的颜色
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: []
      }
    })

    onMounted(() => {
      initData()
    })

    const initData = async () => {
      const data = await OrderService.qryMyFlb({
        uid,
        validYear: state.year,
        year: state.year
      })
      console.log(data)
      if (!data) return
      const { publicWelfareHz, usedWelfareHz, welfareList, yearList } = data
      if (yearList?.length)
        state.yearsArr = yearList.map((item) => {
          return { text: item, value: item }
        })
      state.productList = welfareList
      for (const list of publicWelfareHz) {
        state.chartsOptions.xAxis.data.push(`${list.month}月`)
      }
      let publicArr = publicWelfareHz.map((item) => parseInt(item.num))
      let usedArr = usedWelfareHz.map((item) => parseInt(item.num))
      state.publicNums = publicWelfareHz.reduce(
        (total, curr) => total + parseInt(curr.num),
        0
      )
      state.usedNums = usedWelfareHz.reduce(
        (total, curr) => total + parseInt(curr.num),
        0
      )
      let seriesArr = [
        {
          name: '领取数量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#FB3E28' //折线的颜色
              }
            }
          },
          data: publicArr
        },
        {
          name: '已使用数量',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#2886FB' //折线的颜色
              }
            }
          },
          data: usedArr
        }
      ]
      seriesArr.forEach((obj) => state.chartsOptions.series.push(obj))
      var chartDom = document.getElementById('charts')
      var myChart = echarts.init(chartDom)
      myChart.setOption(state.chartsOptions)
    }

    const goDetail = (list) => {
      router.push({
        path: '/flbDetail',
        query: {
          id: list.welfareId,
          stock: list.total,
          selledStock: list.usedNum
        }
      })
    }
    return {
      ...toRefs(state),
      goDetail
    }
  }
})
</script>
<style lang="scss">
.myFlb-page {
  padding: 0 0 0.28rem;
  background: #f8f8f8;
  min-height: 100vh;
  .title {
    padding: 0 0.29rem;
    height: 1.18rem;
    display: flex;
    align-items: center;
    background: #fff;
    .label {
      @include fontSC(0.34rem, #333);
      margin-right: 0.42rem;
    }
    .van-dropdown-menu__bar {
      width: 1.02rem;
      height: 0.4rem;
      background: #fb3e28;
      border-radius: 0.2rem;
      .van-dropdown-menu__item {
        justify-content: start;
      }
      .van-dropdown-menu__title {
        font-size: 0.2rem;
        color: #fff;
        &::after {
          border-color: transparent transparent #fff #fff;
        }
      }
    }
  }
  .count-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    z-index: 3;
    position: relative;
    .unit {
      @include fontSC(0.26rem, #999);
      margin-bottom: 0.12rem;
    }
    .num {
      @include fontSC(0.36rem, #333);
      font-weight: bold;
    }
  }
  .lengend-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 0.4rem;
    padding-bottom: 0.45rem;
    .lengend-item {
      display: flex;
      align-items: center;
      @include fontSC(0.22rem, #333);
      margin-bottom: 0.12rem;
      margin-right: 0.4rem;
      .lengend-icon {
        margin-right: 0.14rem;
        width: 0.4rem;
        height: 0.14rem;
        background: #fb3e28;
        &.i2 {
          background: #fb9528;
        }
        &.i3 {
          background: #2886fb;
        }
      }
    }
  }
  #charts {
    width: 100%;
    height: 4rem;
    background: #fff;
    margin-top: -0.6rem;
  }
  .list-title {
    @include fontSC(0.34rem, #333);
    font-weight: bold;
    padding: 0.42rem 0.28rem 0;
    background: #fff;
    margin-top: 0.2rem;
  }
  .flb-list {
    background: #fff;
    margin-bottom: 0.2rem;
    padding: 0 0.3rem;
    .top {
      display: flex;
      height: 0.85rem;
      align-items: center;
      justify-content: space-between;
      @include fontSC(0.24rem, #999);
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #e6e6e6;
        transform: scaleY(0.5);
      }
    }
    .bot {
      @include fontSC(0.28rem, #333);
      line-height: 1.8em;
      padding: 0.2rem 0;
      span {
        margin-right: 0.3rem;
      }
    }
  }
}
</style>
