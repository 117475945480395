<template>
  <div class="soldierUsedFlb-page">
    <div class="con">
      <p class="title"><span class="bold">产品已使用</span><div class="close" @click="cancel"><img class="img" src="@/assets/images/close_sd.png" alt="" /></div></p>
      <div class="m">
        <span>每个用户产品使用数量不可超过一次<br />有疑惑可拨打电话<i style="color:#FB3E28;">“4000213030”</i><br />
          或点击在线客服链接<br /><a style="color:#FB3E28;" href="https://work.weixin.qq.com/kfid/kfc7034a89164cd771c">https://work.weixin.qq.com/kfid/kfc7034a89164cd771c</a></span>

      </div>
      
    </div>
    
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'

export default {
  name: 'soldierUsedFlb',
  components: {},
  emits: ['confirm', 'cancel'],
  setup(props, context) {
    const confirm = () => {
      context.emit('confirm')
    }
    const cancel = () => {
      context.emit('cancel')
    }
    return {
      confirm,
      cancel
    }
  }
}
</script>

<style lang="scss">
.soldierUsedFlb-page {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  padding-bottom: 2.71rem;
  @include fontSC(0.3rem, #333);
  padding: 0.97rem 0.3rem;
  .con {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6.9rem;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 0.2rem;
    padding-top: 1.27rem;
    text-align: center;
  }
  .title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-size: 0.38rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    padding: 0.48rem 0 0.42rem;
  }
  .bold {
    font-weight: bold;
  }
  .m {
    line-height: 0.48rem;
    margin: 0.06rem 0;
    padding: 0 0.6rem 0.5rem;
    overflow-y: scroll;
  }
  .tip {
    color: #FF2C01;
    margin-top: 0.5rem;
  }
  .close {
    position: absolute;
    width: 0.38rem;
    height: 0.38rem;
    top: 0.25rem;
    right: 0.25rem;
    .img {
      display: block;
      width: 100%;
    }
  }
}
</style>
