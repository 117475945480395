<template>
  <div class="lqSucc-page">   
    <div class="top-box">
        <p>订单编号：{{ orderInfo.orderNo }} <br>下单时间：{{ orderInfo.createTime }}</p>
    </div>
    <div class="pro-box">
      <div class="title">商品信息</div>
      <div class="con">
        <img class="l" :src="orderInfo.productUrl" alt="" />
        <div class="r">
          <p class="des">{{ orderInfo.productName }}</p>
          <p class="memo">
            <span class="total">{{ orderInfo.price }}公益豆</span>X1
          </p>
        </div>
      </div>
    </div>
    <div class="pro-box b-box">
      <div class="title">支付信息</div>
      <p>商品总额： {{orderInfo.orderAmount}}公益豆<br>交易数量： X1</p>
    </div>

    <div class="btn" @click="getedAction">用户已领取</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import UserService from "@/api/common.js";
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const no = route.query.no;
    const disabled = ref(false);

    const orderInfo = ref({});
    const { showAlert, showConfirm } = mixin();

    onMounted(() => {
      getOrder();
    });

    const getOrder = async () => {
      const data = await UserService.getScanDetailInfo({
        scanType: "order",
        uid: localStorage.uid,
        bizNo: no,
      });
      console.log(data);
      if (!data) return;
      orderInfo.value = data.order;
    };
    const getedAction = async () => {
      if (disabled.value) return;
      const data = await UserService.finishBiz({
        scanType: "order",
        uid: localStorage.uid,
        bizNo: no,
      });
      //   console.log(data);
      if (!data) return;
      disabled.value = true;
      showAlert({ content: "确认成功", confirmFn:()=>{
          router.go(-1);
      } });
    };
    // const gomall = async () => {
    //   router.go(-1);
    // };
    return {
      orderInfo,
      disabled,
      getedAction,
    };
  },
});
</script>
<style lang="scss">
.lqSucc-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-top: 0.21rem;
  .top-box {
    padding: 0.36rem 0.28rem;
    background: #fff;
    @include fontSC(0.28rem, #868686);
    p{
        line-height: 1.8em;
    }
  }
  .b-box {
    padding: 0.36rem 0.28rem;
    background: #fff;
    @include fontSC(0.28rem, #868686);
    p{
        line-height: 1.8em;
    }
  }
  .pro-box {
    margin: 0.21rem 0 0.2rem;
    padding: 0.26rem 0.25rem 0.26rem 0.21rem;
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      &::before {
        content: "";
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l {
        width: 1.85rem;
        height: 1.86rem;
        border: 1px solid #e6e6e6;
        margin-right: 0.33rem;
      }

      .r {
        flex: 1;
        @include fontSC(0.3rem, #5f5f5f);
        .des {
          @include fontSC(0.32rem, #282828);
          margin-bottom: 0.7rem;
          line-height: 1.3em;
        }
        .memo {
          @include fontSC(0.3rem, #5f5f5f);
          .total {
            @include fontSC(0.32rem, #fb3e28);
            margin-right: 0.22rem;
          }
        }
      }
    }
  }

  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.91rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 1.2rem auto 0.2rem;
    background: #f65341;
    text-align: center;
  }
}
</style>
