<template>
  <div class="lovesort-page">
    <van-swipe
      :autoplay="5000"
      class="swiper-box"
      lazy-render
      indicator-color=""
    >
      <van-swipe-item v-for="item in siwperData" :key="item.id">
        <a ><img :src="item" /></a>
      </van-swipe-item>
    </van-swipe>
    <div class="tab-list">
      <div
        @click="checkTab('1')"
        class="item"
        :class="{ curr: currTab === '1' }"
      >
        爱心企业
      </div>
      <div
        @click="checkTab('2')"
        class="item"
        :class="{ curr: currTab === '2' }"
      >
        爱心人士
      </div>
    </div>
    <div class="sort-list" v-show="currTab === '1'">
      <div
        class="item"
        v-for="(item, index) in enterpriseData"
        :key="item.sort"
        @click="goenterprise(item.uid)"
      >
        <div class="l">
          <p class="n">{{ index > 2 ? item.sort : "" }}</p>
          <!-- <img class="img" :src="item.headImage" alt="" /> -->
          <span class="txt">{{ item.name }}</span>
        </div>
        <div class="r">
          <span class="total">{{ item.balance }}</span
          >元
        </div>
      </div>
    </div>
    <div class="sort-list" v-show="currTab === '2'">
      <div
        class="item"
        v-for="(item, index) in persionData"
        :key="item.sort"
        @click="goPerson(item.uid)"
      >
        <div class="l">
          <p class="n">{{ index > 2 ? item.sort : "" }}</p>
          <!-- <img class="img" :src="item.headImage" alt="" /> -->
          <span class="txt">{{ item.name }}</span>
        </div>
        <div class="r">
          <span class="total">{{ item.balance }}</span
          >元
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { Swipe, SwipeItem } from "vant";
import UserService from "@/api/common.js";

export default defineComponent({
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  props: {},
  setup(props) {
    const images = ref([]);
    const store = useStore();
    const router = useRouter();

    const persionData = ref([]);
    const enterpriseData = ref([]);
    const currTab = ref("1");
    const siwperData = ref([require('@/assets/images/love/top-img.png')]);

    onMounted(() => {
      initInfo("persion");
      initInfo("enterprise");
    });

    const currListData = computed(() => {
      return currTab.value == "1" ? enterpriseData.value : persionData.value;
    });

    const initInfo = async (type) => {
      const data = await UserService.qryLoveSort({
        uid: localStorage.uid,
        userType: type,
      });
      console.log(data);
      if (!data) return;
      const { sortList, advertMap } = data;
    //   if (advertMap.LOVE_FIRST) siwperData.value = advertMap.LOVE_FIRST;
      if (type === "persion") {
        persionData.value = sortList ? sortList : [];
      } else {
        enterpriseData.value = sortList ? sortList : [];
      }
    };
    const checkTab = (n) => {
      if (currTab.value === n) return;
      currTab.value = n;
    };
    const goPerson = (id) => {
    //   router.push({
    //     path: "/loveMan",
    //     query: { id },
    //   });
    };
    const goenterprise = (id) => {
    //   router.push({
    //     path: "/loveCompany",
    //     query: { id },
    //   });
    };
    return {
      persionData,
      siwperData,
      currListData,
      enterpriseData,
      images,
      currTab,
      checkTab,
      goPerson,
      goenterprise,
    };
  },
});
</script>
<style lang="scss">
.lovesort-page {
  min-height: 100vh;
  padding-bottom: 1.2rem;
  .swiper-box {
  }
  .sort-list {
    .item {
      height: 1.68rem;
      @include border-1px(#e6e6e6, top);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.3rem;
      &:nth-child(-n + 3) .l .n::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0.4rem;
        height: 0.57rem;
        background: url(../../assets/images/love/s1.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(1) .l .n::before {
        background: url(../../assets/images/love/s1.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(2) .l .n::before {
        background: url(../../assets/images/love/s2.png) 100% 100% no-repeat;
        background-size: cover;
      }
      &:nth-child(3) .l .n::before {
        background: url(../../assets/images/love/s3.png) 100% 100% no-repeat;
        background-size: cover;
      }
      .l {
        display: flex;
        align-items: center;
        @include fontSC(0.34rem, #8d7575);
        .n {
          position: relative;
          width: 0.4rem;
          height: 0.57rem;
          @include fontSC(0.3rem, #999);
          display: flex;
          align-items: center;
          justify-content: center;  
        }
        .img {
          width: 1.1rem;
          height: 1.1rem;
          margin: 0 0.35rem 0 0.2rem;
          border-radius: 0.1rem;
        }
        .txt{
            margin-left: 0.5rem;
            width: 3.5rem;
            line-height: 1.3em;
        }
      }

      .r {
        @include fontSC(0.22rem, #fb3e28);
        .total {
          font-size: 0.34rem;
        }
      }
    }
  }
  .van-swipe-item {
    height: 4.16rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }
  .tab-list {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    .item {
      position: relative;
      @include fontSC(0.34rem, #333);
      padding: 0.36rem 0 0.19rem;
      text-align: center;
      margin-right: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
      &.curr::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1.34rem;
        height: 0.02rem;
        background-color: #fb3e28;
      }
      &.curr {
        color: #fb3e28;
      }
    }
  }
}
</style>
