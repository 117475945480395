<template>
  <router-view />
  <loading></loading>
  <Footer></Footer>
  <div class="app-dialog" v-if="showDialog">
    <div class="con">
      <img class="img" src="@/assets/images/home/dialog-icon.png" alt="" />
      <div class="top"></div>
      <div class="bot">
        <div class="title" v-if="showDialogTitle">兑换失败</div>
        <div class="memo">
          信息不完整，请完善<br />个人信息后完成兑换！
        </div>
        <div class="btn" @click="addInfo">马上完善</div>
      </div>
      <div class="close" @click="closeDialogAction">
        <img src="@/assets/images/close.png" alt="" />
      </div>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Footer from '@/components/Footer.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router";
import { defineComponent, computed, inject } from 'vue'
import { closeDialog } from '@/utils/popup.js'

export default {
  components: {
    Loading,
    Footer,
  },
  watch: {
    $route: {
      handler(newRouter) {
        closeDialog() // 关闭弹窗
      },
      immediate: true
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter();
    const route = useRoute();
    const dialogInfo = computed(() => store.state.dialogInfo)
    const showDialog = computed(() => store.state.showDialog)
    const showDialogTitle = computed(() => route.name.indexOf('Detail') > -1)

    const addInfo = () => {
      closeDialogAction();
      localStorage.userType === "persion"
        ? router.push("/addInfo")
        : router.push("/addInfoQy")
    };
    const closeDialogAction = () => {
      store.commit('setShowDialog', false)
    };
    return {
      dialogInfo,
      showDialog,
      showDialogTitle,
      addInfo,
      closeDialogAction,
    }

  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  font-size: 0.28rem;
  min-height: 100vh;
}

.app-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  .con {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
    width: 4.8rem;
    border-radius: 0.2rem;
    background: #fff;

    .drawimg {
      width: 4.8rem;
      height: 6.06rem;
    }

    .drawbot {
      .title {
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 100%;
        text-align-last: center;
        @include fontSC(0.36rem, #fff);
        line-height: 1.3em;
      }

      .btn {
        position: absolute;
        bottom: 0.52rem;
        left: 50%;
        width: 2.8rem;
        line-height: 0.88rem;
        transform: translateX(-50%);
        background: #fff;
        border-radius: 0.5rem;
        text-align: center;
        @include fontSC(0.48rem, #B16D24);
      }
    }

    .img {
      position: absolute;
      width: 2.94rem;
      height: 2.67rem;
      @include position-middle(-0.55rem);
      z-index: 1;
    }

    .top {
      position: relative;
      width: 100%;
      height: 2.71rem;
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
      overflow: hidden;
      background: url(./assets/images/home/dialog-bg.png) 100% 100% no-repeat;
      background-size: cover;
    }

    .bot {
      padding: 0.43rem 0.3rem 0.32rem;
      text-align: center;

      .title {
        @include fontSC(0.3rem, #333);
        line-height: 1.5em;
      }

      .memo {
        @include fontSC(0.25rem, #999);
        line-height: 1.5em;
      }

      .btn {
        margin: 0.38rem auto 0;
        width: 2.35rem;
        text-align: center;
        line-height: 0.68rem;
        @include fontSC(0.34rem, #fff);
        background: #fe5631;
        box-shadow: 0px 0.06rem 0px 0px rgba(253, 100, 76, 0.3);
        border-radius: 0.34rem;
      }
    }

    .close {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.74rem;
      width: 0.42rem;
      height: 0.42rem;

      img {
        width: 100%;
      }
    }
  }
}
</style>
