import {
  createStore
} from 'vuex'
import list from '../utils/whiteList'
export default createStore({
  state: {
    loading: false,
    userType: '',
    showDialog: false,
    whiteList: list,
    show:false
  },
  getters: {
    getUserType(state) {
      return state.userType
    }
  },
  mutations: {
    setLoading(state, val) {
      state.loading = val
    },
    setUserType(state, type) {
      state.userType = type
    },
    setShowDialog(state, flag) {
      state.showDialog = flag
    }
  },
  actions: {},
  modules: {}
})