import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from '@/utils'
// import popup from '@/utils/popup.js'
// import { Toast, Dialog } from 'vant';
import '@/style/app.scss'

import LuckDraw from 'vue-luck-draw/vue3'
// Vue.config.ignoredElements = ['wx-open-launch-weapp']

(function setDevice(deviceW) {
  var html = document.documentElement;
  var hWidth = html.getBoundingClientRect().width;
  html.style.fontSize = hWidth / deviceW * 100 + 'px';
})(750);

(function () {
  // 计算出放大后的字体
  var scaledFontSize = parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));

  // 计算原字体和放大后字体的比例
  var scaleFactor = parseInt(document.documentElement.style.fontSize) / scaledFontSize;

  var originRootFontSize = parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
  document.documentElement.style.fontSize = originRootFontSize * scaleFactor * scaleFactor + 'px';
})()

createApp(App)
  .use(utils)
  .use(store)
  .use(router)
  .use(LuckDraw)
  // .use(popup)
  .mount('#app')