<template>
  <div class="QrGybDetail-page">
    <div class="title">公益包详情</div>
    <div class="order-list">
      <div class="item">
        <div class="detail">
          <div class="l">
            <img class="img" :src="proInfo.productUrl" alt="" />
            <div></div>
          </div>
          <div class="r">
            <p class="memo">公益包名称：{{ proInfo.productName }}</p>
            <p class="memo" v-if="proInfo.stauts === 'CREATE'">
              领取时间：{{ proInfo.fetchTime }}
            </p>
            <p class="memo tag">
              领取人： {{ proInfo.name
              }}<span class="t">{{ proInfo.memberLevel }}</span>
            </p>
            <p class="memo">领取数量：{{ proInfo.num }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="btn" @click="goFillCheck">{{ btnTxt }}</div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import OrderService from '@/api/order.js'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid
    const route = useRoute()
    const no = route.query.no || ''
    const { showAlert } = mixin()
    const state = reactive({
      expire: false,
      proInfo: {},
      btnTxt: '确认使用'
    })

    onMounted(() => {
      getOrder()
    })
    const getOrder = async () => {
      const data = await OrderService.qryGybDetail({
        uid,
        orderNo: no
      })
      console.log(data)
      if (!data) return
      const { orderDetail, expire } = data
      state.proInfo = orderDetail
      state.expire = expire
      state.btnTxt = orderDetail.stauts === 'CREATE' ? '确认使用' : '用户已使用'
    }
    const goFillCheck = async () => {
      if (state.expire) {
        return showAlert({ content: '公益包已过期' })
      }
      if (state.proInfo.stauts === 'FETCHED') {
        return showAlert({ content: '公益包已使用' })
      }
      const data = await OrderService.fillCheck({
        uid,
        id: no,
        checkType: 'GYB' //"GYB-公益包|WELFARE-福利包"
      })
      console.log(data)
      if (!data) return
      showAlert({ content: '核销成功' })
      state.proInfo.stauts = 'FETCHED'
      state.btnTxt = '用户已使用'
    }

    return {
      ...toRefs(state),
      goFillCheck
    }
  }
})
</script>
<style lang="scss" scoped>
.QrGybDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-top: 0.18rem;
  .title {
    display: flex;
    align-items: center;
    height: 0.73rem;
    @include fontSC(0.3rem, #000);
    padding-left: 0.29rem;
    background: #fff;
    &::before {
      content: '';
      width: 0.08rem;
      height: 0.42rem;
      background: #f65341;
      margin-right: 0.21rem;
    }
  }

  .order-list {
    background: #ffffff;
    .item {
      padding: 0.26rem 0.3rem;
      margin-bottom: 0.2rem;

      .detail {
        display: flex;
        align-items: center;
        .l {
          width: 1.85rem;
          height: 1.85rem;
          margin-right: 0.29rem;
          border: 1px solid #e6e6e6;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .r {
          flex: 1;
          .title {
            margin-bottom: 0.18rem;
            @include fontSC(0.3rem, #333);
            font-weight: bold;
          }
          .memo {
            margin-bottom: 0.21rem;
            line-height: 1;
            @include fontSC(0.3rem, #333);
            &:last-child {
              margin-bottom: 0;
            }
            &.tag {
              display: flex;
              align-items: center;
              .t {
                margin-left: 0.1rem;
                @include fontSC(0.18rem, #f65341);
                width: 0.97rem;
                line-height: 0.34rem;
                border: 1px solid #fb3e28;
                border-radius: 0.16rem;
                text-align: center;
              }
            }
          }
          .total {
            @include fontSC(0.32rem, #5f5f5f);
            .t {
              color: #fb3e28;
            }
          }
        }
      }
    }
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.91rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 1.24rem auto;
    border: 1px solid #f65341;
    text-align: center;
    background: #f65341;
  }
}
</style>
