<template>
  <div class="lq-qrcode-page" style="text-align: center">
    <img :src="imageUrl" alt="Image" class="ImgFontSize">
    <div class="imgString">出示二维码核销套餐</div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";


export default {
  setup(props) {


    const base64Image = localStorage.userQrcode; // 替换为你的 base64 字符串

    const imageUrl = ref(null);

    // 将 base64 转换为图片
    const convertBase64ToImage = () => {
      const img = new Image();
      img.src = `data:image/png;base64,${base64Image}`;

      img.onload = () => {
        imageUrl.value = img.src;
      };
    };

    convertBase64ToImage();
    onMounted(() => {
      //   initData();
    });


    return {
      imageUrl
    };
  },
};
</script>

<style lang="scss">
.ImgFontSize{
  width: 80vw;
}
.imgString {
  text-align: center;
  font-weight: 400;
  color: #999999;
  font-size: 0.45rem;
  margin-top: 1rem;
}
</style>
