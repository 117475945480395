import { post_http } from './axios'

class CashierService {
  static superMarketpayMoneyToMember(params) {
    return post_http('/cashier/superMarketpayMoneyToMember', params)
  }
  static scanQrLogin(params) {
    return post_http('/cashier/scanQrLogin', params)
  }
  static addShopCard(params) {
    return post_http('/cashier/addShopCard', params)
  }

  static deleteShopCard(params) {
    return post_http('/cashier/deleteShopCard', params)
  }
  static shopCardCounter(params) {
    return post_http('/cashier/shopCardCounter', params)
  }
}

export default CashierService