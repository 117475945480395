<template>
  <div class="soldier-qrcode-page">
    <div class="pro-box">
      <!-- <img class="title" src="@/assets/images/soldier_qr_title.png" alt="" /> -->
      <div class="qr-box">
        <div class="qr-wrap" id="qrcode"></div>
        <img class="logo" src="@/assets/images/soldier_logo.png" alt="" />
      </div>
      <div class="des">退役军人会员码</div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const QRCode = require('../../utils/qrcode')

export default {
  components: {
    
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    // const no = route.query.no
    const logo = ref(require('@/assets/images/soldier_logo.png'))
    const uid = localStorage.uid
    const urlData = ref(`hy_${uid}`)
    const dialogInfo = computed(() => store.state.dialogInfo)
    const info = JSON.parse(sessionStorage.lqInfo || '{}')
    console.log(info)
    onMounted(() => {
      const dom = document.getElementById('qrcode')
      dom.innerHTML = ""
      const qrcode = new QRCode(dom, {
          text: urlData.value,
          width: 128,
          height: 128,
          colorDark : "transparent",
          colorLight : "#fff",
          correctLevel : QRCode.CorrectLevel.H
      });
    })

    return {
      dialogInfo,
      urlData,
      info,
      logo
    }
  }
}
</script>

<style lang="scss">
.soldier-qrcode-page {
  min-height: 100vh;
  // background: #cfc7bc;
  // background-image: url(../../assets/images/soldier_qr_bg1.png),
  //   url(../../assets/images/soldier_qr_bg2.png);
  background-position: top center, bottom center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;
  padding: 1.22rem 0 0;

  .pro-box {
    margin-bottom: 0.29rem;
    @include fontSC(0.34rem, #000);
    .title {
      margin: 0 auto 0.39rem;
      width: 4.47rem;
      height: 1.17rem;
      display: block;
    }
    .qr-box {
      position: relative;
      width: 4.78rem;
      margin: 0 auto;
      padding: 0.28rem;
      background-color: #fff;
      border-radius: 0.3rem;
    }
    .qr-wrap {
      width: 4.22rem;
      height: 4.22rem;
      background: url(../../assets/images/micai.png) no-repeat;
      background-size: cover;
      img {
        display: block;
        width: 4.22rem !important;
        height: 4.22rem !important;
      }
    }
    .logo{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
    }
    canvas {
      width: 4.22rem !important;
      height: 4.22rem !important;
    }
    .con {
      padding: 0.36rem 0.38rem;
      background: #f5f4f1;
      @include fontSC(0.34rem, #000);
      border-radius: 0.1rem;
      margin: 0.4rem 0.3rem 0.46rem;
      .txt {
        margin-bottom: 0.2rem;
      }
      .desc {
        font-size: 0.34rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #b40020;
        line-height: 0.48rem;
      }
    }
  }
  .des {
    font-size: 0.34rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-top: 0.8rem;
    text-align: center;
  }
}
</style>
