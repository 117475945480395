<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">猜你想了解</div>
            <div class="content-page">
                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleOne')">
                    <div>如何申领退役军人优待证？</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleLieSi')">
                    <div>烈士遗属抚恤优待政策问答</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleFX')">
                    <div>残疾军人抚恤优待政策问答</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleDBi')">
                    <div>带病回乡退伍军人补助优待政策问答</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleBG')">
                    <div>病故军人遗属抚恤优待政策问答</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                <div class="flex-title" @click="$router.push('consuleYG')">
                    <div>因公牺牲军人遗属抚恤优待政策问答</div><van-icon name="arrow" />
                </div>

                <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList6"></div>
                <div class="flex-title" @click="$router.push('consuleTwo')">
                    <div>在乡复员军人补助优待政策问答</div><van-icon name="arrow" />
                </div>

                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList7"></div> -->
                <!-- <div class="flex-title">
                    <div>带病回乡退伍军人补助优待政策问答</div><van-icon name="arrow" />
                </div> -->
            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()



        // const consult = () => {
        //     router.push('/testHome/123')
        // }



        return {

        }
    }
})
</script>
<style lang="scss" scoped>
.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.1rem;
    // width: 80vw;
    padding: 0.4rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  