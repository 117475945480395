<template>
  <teleport to='body'>
    <div class="popup_soldier" id="popup_soldier" ref="elRef">
      <div class="overlay"></div>
      <div class="wrap">
        <div class="wrap-section" :class="`wrap-section_${type}`">
          <div v-if="type=='1'" class="sec sec1">
            <div class="title">拥军优属，使命必达</div>
            <div class="memo">欢迎来到</div>
            <div class="sub-title">“新华迷彩公园” </div>
            <div class="sec1-btn1" @click="applyChannel">我是退役军人，进入申领权限通道</div>
            <div class="sec1-btn2" @click="anyway">我不是退役军人，随便逛逛</div>
          </div>
          <div v-if="type=='2'" class="sec sec2">
            <img class="status" src="@/assets/images/soldier_succ.png" alt="" />
            <div class="title">您是新华街道在册退役军人，<br/>马上进入迷彩公园申领福利</div>
            <div class="sec1-btn1" @click="enter">立即进入</div>
            <div class="sec1-btn2" @click="close">取消</div>
          </div>
          <div v-if="type=='3'" class="sec sec3">
            <img class="status" src="@/assets/images/soldier_fail.png" alt="" />
            <div class="title">经系统比对您不是新华街道<br/>在册退役军人，如有疑惑<br/>可点击我要申诉进行申诉</div>
            <div class="sec1-btn1" @click="appeal">我要申诉</div>
            <div class="sec1-btn2" @click="close">取消</div>
          </div>
          
        </div>
        <div v-if="type=='1'" class="close" @click="close">
          <img class="img" src="@/assets/images/soldier_close_w.png" alt="" />
        </div>
      </div>
      
    </div>
  </teleport>
</template>

<script>
import { defineComponent, ref, reactive, watch, toRefs } from 'vue'
import { useRouter } from "vue-router";

export default defineComponent({
  name: "popupSoldier",
  props: {
    modelValue: { type: Boolean, default: false },
    type: {
        type: String, default: '2'
    },
  },
  emits: [ 'confirm', 'cancel' ],
  setup(props, context) {
    const elRef = ref('')
    const router = useRouter()
    const data = reactive({
      opened: true,
    })

      // 监听弹层v-model
      // watch(type, (val) => {
          
      // })

    const close = ()=>{
      context.emit('cancel')
    }

    const applyChannel = () => {
      context.emit('confirm')
    }
    const enter = () => {
      localStorage.isSoldier = 'Y'
      router.push({path: '/soldierList'})
    }
    const appeal = () => {
      router.push({path: '/appeal'})
    }
    const anyway = () => {
      sessionStorage.btnToUnSolider = 'Y'
      router.push({path: '/soldierList'})
    }

    return {
      ...toRefs(data),
      elRef,
      close,
      applyChannel,
      anyway,
      enter,
      appeal,
    };
  }
});
</script>
<style lang="scss">
.popup_soldier {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  .overlay{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
    width: 6.14rem;
    height: 7.64rem;
    .wrap-section{
      &_1{
        padding-top: 1rem;
        width: 6.14rem;
        height: 7.64rem;
        background: url(../assets/images/soldier_bg3.png) 100% 100% no-repeat;
        background-size: cover;
        text-align: center;
        line-height: 1;
        .title, .memo, .sub-title {
          font-size: 0.36rem;
            font-family: Alibaba PuHuiTi;
            color: #FD5F07;
            background: linear-gradient(135deg, #FBE4B8 0%, #F2CB8A 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .memo {
          margin-top: 0.6rem;
          margin-bottom: 0.3rem;
        }
        .sub-title {
          font-size: 0.72rem;
          margin-bottom: 0.58rem;
          font-weight: 600;
        }
        .sec1-btn1 {
          width: 5.23rem;
          height: 0.9rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #892500;
          line-height: 0.9rem;
          background: url(../assets/images/soldier_btn1.png) 100% 100% no-repeat;
          background-size: cover;
          margin: 0 auto;
          margin-bottom: 0.28rem;
        }
        .sec1-btn2 {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #fff;
        }
      }
      &_2{
        padding-top: 0.6rem;
        width: 6.14rem;
        height: 6rem;
        background: url(../assets/images/soldier_bg1.png) 100% 100% no-repeat;
        background-size: cover;
        text-align: center;
        line-height: 1;
        .status {
          margin: 0 auto 0.53rem;
          width: 1.19rem;
          height: 1.19rem;
          display: block;
        }
        .title {
          font-size: 0.36rem;
            font-family: Alibaba PuHuiTi;
            color: #FD5F07;
            line-height: 0.54rem;
            background: linear-gradient(135deg, #FBE4B8 0%, #F2CB8A 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 0.47rem;
        }
        .sec1-btn1 {
          width: 3.12rem;
          height: 0.9rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #892500;
          line-height: 0.9rem;
          background: url(../assets/images/soldier_btn2.png) 100% 100% no-repeat;
          background-size: cover;
          margin: 0 auto;
          margin-bottom: 0.34rem;
        }
        .sec1-btn2 {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #fff;
        }
      }
      &_3{
        padding-top: 0.6rem;
        width: 6.14rem;
        height: 7.1rem;
        background: url(../assets/images/soldier_bg2.png) 100% 100% no-repeat;
        background-size: cover;
        text-align: center;
        line-height: 1;
        .status {
          margin: 0 auto 0.53rem;
          width: 1.19rem;
          height: 1.19rem;
          display: block;
        }
        .title {
          font-size: 0.36rem;
            font-family: Alibaba PuHuiTi;
            color: #FD5F07;
            line-height: 0.54rem;
            background: linear-gradient(135deg, #FBE4B8 0%, #F2CB8A 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 0.47rem;
        }
        .sec1-btn1 {
          width: 3.12rem;
          height: 0.9rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #892500;
          line-height: 0.9rem;
          background: url(../assets/images/soldier_btn2.png) 100% 100% no-repeat;
          background-size: cover;
          margin: 0 auto;
          margin-bottom: 0.34rem;
        }
        .sec1-btn2 {
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .close {
      position: absolute;
      left: 50%;
      bottom: -1.2rem;
      transform: translateX(-50%);
      overflow: hidden;
      width: 0.78rem;
      height: 0.78rem;
      font-size: 0;
      img{
        width: 0.78rem;
        height: 0.78rem;
      }
    }
  }
  
}
</style>
