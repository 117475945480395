<template>
  <div class="flb-qrcode-page">
    <qrcode-vue  v-if="scanType=='HTTP'"  @click="clickGet" :value="urlData"></qrcode-vue>
    <qrcode-vue  v-if="scanType=='DEFAUL'" :value="urlData"></qrcode-vue>
    <div v-if="scanType=='PIC'">
      <img class="qr-img" :src="scanUrl" alt="" />
    </div>
    <div class="des" v-if="scanType!=='HTTP'">请于指定商户出示二维码享受服务</div>
    <div class="des" v-if="welfareInfo.status!=='USED'&&scanType=='HTTP'">点击上方迷彩码进入订票选座通道</div>
    <div class="pro-box">
      <div class="title">福利包信息</div>
      <div class="con">
        <div class="txt">福利包名称： {{ welfareInfo.welfareName }}</div>
        <div class="txt">适用人群：{{ welfareInfo.memberLevel }}</div>
        <div class="txt">使用时间：{{ welfareInfo.lastUpdateTime }}</div>
      </div>
    </div>
    <div class="pro-box" v-if="supplierInfo.supplierName">
      <div class="title">商户信息</div>
      <div class="con">
        <div class="txt">商户名称： {{ supplierInfo.supplierName }}</div>
        <div class="txt">商户地址：{{ supplierInfo.address }}</div>
        <div class="txt">联系方式：{{ supplierInfo.linkPhone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import QrcodeVue from 'qrcode.vue'
import OrderService from '@/api/order.js'
import SoldierService from '@/api/soldier.js'
import { useRouter, useRoute } from 'vue-router'

export default {
  components: {
    QrcodeVue
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const no = route.query.no
    const urlData = ref(`${no}`)
    const scanType = ref('')
    const scanUrl = ref('')

    const dialogInfo = computed(() => store.state.dialogInfo)
    const uid = localStorage.uid
    const info = JSON.parse(sessionStorage.flbInfo)
    const supplierInfo = ref({})
    const welfareInfo = ref({})

    onMounted(() => {
      initData()
    })
    const initData = async () => {
      const data = await OrderService.qryWelfarePackageDetail({
        uid: uid,
        id: no
      })
      console.log(data)
      if (!data) return
      const { welfare, supplier } = data
      const { scanUrl: scanUrlData, scanType: scanTypeData } = welfare
      scanUrl.value = scanUrlData
      scanType.value = scanTypeData
      welfareInfo.value = welfare || {}
      supplierInfo.value = supplier || {}
    }
    const clickGet = async () => {
      if(welfareInfo.value.welfareName.includes('电影票')) {
        window.location.replace(scanUrl.value)
        return
      }
      if(welfareInfo.value.status=='USED') return
      const postData = {
        orderNo: no
      }
      const data = await SoldierService.camouflageParkUseOrder(postData)
      if (!data) return
      window.location.replace(scanUrl.value)
    }

    return {
      supplierInfo,
      welfareInfo,
      dialogInfo,
      urlData,
      info,
      scanType,
      scanUrl,
      clickGet,
    }
  }
}
</script>

<style lang="scss" scoped>
.flb-qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.35rem 0 0;
  .des {
    margin: 0.27rem 0 0.33rem;
    text-align: center;
    @include fontSC(0.28rem, #999);
  }
  canvas {
    display: block;
    margin: 0 auto;
    width: 4.5rem !important;
    height: 4.5rem !important;
  }
  .qr-img{
      display: block;
      width: 4.5rem;
      height: 4.5rem;
      margin: 0 auto;
    }
  .pro-box {
    background: #fff;
    margin-bottom: 0.29rem;
    @include fontSC(0.28rem, #000);
    .title {
      display: flex;
      align-items: center;
      height: 0.73rem;
      @include fontSC(0.3rem, #000);
      padding-left: 0.29rem;
      &::before {
        content: '';
        width: 0.08rem;
        height: 0.42rem;
        background: #f65341;
        margin-right: 0.21rem;
      }
    }
    .con {
      padding: 0.26rem 0.29rem 0.37rem 0.31rem;
      @include fontSC(0.28rem, #868686);
      .txt {
        margin-bottom: 0.14rem;
      }
    }
  }
}
</style>
