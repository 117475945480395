<template>
  <div class="timeBank-page">
    <div class="title">完善个人信息</div>
    <div class="cell">
      <div class="item">
        <div class="label">姓名*</div>
        <div class="right">
          <input v-model="name" type="text" placeholder="请输入您的姓名" />
        </div>
      </div>
      <div class="item">
        <div class="label">手机*</div>
        <div class="right">
          <input v-model="mobile" type="text" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="item">
        <div class="label">身份证号*</div>
        <div class="right">
          <input v-model="certid" type="text" placeholder="请输入您的身份证号" />
        </div>
      </div>
      <div class="item">
        <div class="label">紧急联系人*</div>
        <div class="right">
          <input v-model="linkPerson" type="text" placeholder="请输入联系人的姓名" />
        </div>
      </div>
      <div class="item">
        <div class="label">紧急联系人手机号*</div>
        <div class="right">
          <input v-model="linkMobile" type="text" placeholder="请输入联系人的手机号" />
        </div>
      </div>
      <div class="item">
        <div class="label">居住地址*</div>
        <div class="right">
          <input v-model="address" type="address" placeholder="请输入您的居住地址" />
        </div>
      </div>
      <div class="item">
        <div class="label">户籍地址*</div>
        <div class="right">
          <input v-model="residenceAddress" type="text" placeholder="请输入您的户籍地址" />
        </div>
      </div>
      <div class="item">
        <div class="label">开户网点</div>
        <div class="right">
          <input v-model="openAccountNet" type="text" placeholder="请输入您的开户网点" />
        </div>
      </div>
      <div class="item">
        <div class="label">账号</div>
        <div class="right">
          <input v-model="accountNo" type="text" placeholder="请输入您的账号" />
        </div>
      </div>
      <div class="item">
        <div class="label">健康证明</div>
        <div class="right check-box">
          <span class="checkbox" :class="{ checked: isHealth=='TRUE' }" @click="checkHealth"></span><span @click="checkHealth" class="t">有</span>
          <span class="checkbox" :class="{ checked: isHealth=='FALSE' }" @click="checkHealth"></span><span @click="checkHealth" class="t">无</span>
        </div>
      </div>
      <div class="item">
        <div class="label">相关的证书、资格证</div>
        <div class="right check-box">
          <span class="checkbox" :class="{ checked: isQualifications=='TRUE' }" @click="checkZS"></span><span @click="checkZS" class="t">有</span>
          <span class="checkbox" :class="{ checked: isQualifications=='FALSE' }" @click="checkZS"></span><span @click="checkZS" class="t">无</span>
        </div>
      </div>
     
    </div>
    <div class="title">请勾选以下您想参加的服务内容</div>
    <div class="cell">
      <!-- <div class="select-wrap">
        <div class="label">情感慰藉</div>
        <div class="select-box">
          <div class="check-item">
            <span class="checkbox" :class="{ checked }" @click="checkAction"></span><span class="t">聊天</span>
          </div>
        </div>
      </div> -->
      <div class="select-wrap" v-for="(item, i) in datas" :key="i">
        <div class="label">{{item.title}}</div>
        <div class="select-box">
          <div class="check-item" v-for="(data, k) in item.options" :key="k" @click="checkDuo(i, k)">
            <span class="checkbox" :class="{ checked: data.check }"></span><span class="t">{{data.txt}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="btn" @click="postData">提交</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "@/api/common.js";
import CenterService from "@/api/center.js";
import { Picker, Popup } from "vant";
import mixin from "@/mixin/mixin.js";
import uploadAction from "../userCenter/upload";
import { checkIDCard } from "../../utils/tools";

const dataList = [
  {
    title: '情感慰藉',
    key: 'emotionalSolace',
    options: [
      {check: false,txt: '聊天'},{check: false,txt: '读书'},{check: false,txt: '看报'},{check: false,txt: '散步等'},
    ]
  },
  {
    title: '协助服务',
    key: 'assistanceServices',
    options: [
      {check: false,txt: '整理物品'},{check: false,txt: '剪指甲'},{check: false,txt: '缴费等'},
    ]
  },
  {
    title: '出行陪伴',
    key: 'travelCompanion',
     options: [
      {check: false,txt: '陪同就医'},{check: false,txt: '银行类业务(仅限交通陪同)'},{check: false,txt: '生活用品购买'},
    ]
  },
  {
    title: '文体活动',
    key: 'recreationalActivities',
     options: [
      {check: false,txt: '茶艺'},{check: false,txt: '体育健身'},{check: false,txt: '琴、棋、书、画'},{check: false,txt: '手工艺制作等'},
    ]
  },
  {
    title: '健康科普',
    key: 'healthSciencePopularization',
     options: [
      {check: false,txt: '解读医院检查报告'},{check: false,txt: '养生类'},{check: false,txt: '健康操指导'},{check: false,txt: '心理健康辅导'},
    ]
  },
  {
    title: '法律援助',
    key: 'legalAid',
     options: [
      {check: false,txt: '法律咨询'},{check: false,txt: '家庭纠纷、遗产等'},
    ]
  },
  {
    title: '培训讲座',
    key: 'trainingLectures',
     options: [
      {check: false,txt: '智能手机使用'},{check: false,txt: '智能手环使用'},{check: false,txt: '聊天软件'},{check: false,txt: '小家电使用'},{check: false,txt: '打车软件'},{check: false,txt: '提升厨艺'},{check: false,txt: '购物软件'},{check: false,txt: '其他'},
    ]
  },
  {
    title: '防范金融网络风险',
    key: 'networkRisk',
     options: [
      {check: false,txt: '防范金融诈骗'},{check: false,txt: '防范网络诈骗'},{check: false,txt: '其他防范金融风险相关项目'},
    ]
  },
]

export default defineComponent({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin();
    const store = useStore();
    const router = useRouter();
    const uid = localStorage.uid;
    const datas = ref(dataList)

    const state = reactive({
      accountNo: "",
      address: "",
      linkPerson: "",
      linkMobile: "",
      certid: "",
      mobile: "",
      residenceAddress : "",
      openAccountNet : "",
      name: "",
      isHealth: "TRUE",
      isQualifications: "TRUE",
    });
    const selectInfo = reactive({
      sex: '',
      ownedStreet: '',
      ownedCommunity: '',
      curClickIput: "",
      pickerCloumns: [],
      showInputPicker: false,
      ownedStreetKey: "0",
      sexKey: "0",
      ownedStreetArr: [],
      ownedCommunityKey: "0",
      ownedCommunityArr: [],
      sexArr: ["男", "女"]
    });

    onMounted(() => {
      initData();
    });

    const initData = async () => {
      // const data = await CenterService.userDetail({
      const data = await UserService.qryLoveMemberInfo({
        uid
      });
      console.log(data);
      if (!data) return;
      // const { member } = data;
      const { mobile, name, sex, certId, ownedStreet, ownedCommunity } = data;
      if (mobile) state.mobile = mobile;
      if (name) state.name = name;
      // if (address) state.address = sex;
      if (certId) state.certid = certId;
      // if (ownedStreet) state.ownedStreet = ownedStreet;
      // if (ownedCommunity) state.ownedCommunity = ownedCommunity;
    };

    const postDataReady = async () => {
      const data = await UserService.timeBankAdd({
        uid
      });
      console.log(data);
      if (!data) return;
      const { communityList, streetList } = data;
      selectInfo.ownedStreetArr = streetList;
      selectInfo.ownedCommunityArr = communityList;
      initData();
    };
   
    const checkDuo = (i, k) => {
      // console.log('datas.value[i].options[k].check', datas.value[i].options[k].check)
      datas.value[i].options[k].check = !datas.value[i].options[k].check
    };
    const checkHealth = () => {
      state.isHealth = state.isHealth ==='TRUE' ? 'FALSE' : 'TRUE'
    };
    const checkZS = () => {
      state.isQualifications = state.isQualifications ==='TRUE' ? 'FALSE' : 'TRUE'
    };
    const postData = async() => {
      const arr = [ 'name','certid','mobile','linkPerson','linkMobile','address','residenceAddress']
      if (!arr.every(item => state[item])) {
        showAlert({ content: "必填项不能为空" });
        return;
      }
      
      const obj = datas.value.reduce((prev,curr)=>{
        const options = curr.options
        const arr = options.filter(item=>item.check).map(item=>item.txt)
        prev[curr.key] = '[' + arr.toString() + ']'
        return prev
      }, {})
      
      const { accountNo,address,linkPerson,linkMobile,certid,mobile,residenceAddress,name,isHealth,isQualifications,openAccountNet } = state;
      if(!checkIDCard(certId)) return showAlert({content: '请输入正确的身份证号码'})
      const postData = {
        accountNo,address,linkPerson,linkMobile,certid,mobile,residenceAddress,name,isHealth,isQualifications,openAccountNet,uid,
        ...obj,
      }
      console.log('------',postData)
      const data = await UserService.timeBankAdd(postData);
      console.log(data);
      if (!data) return;
      showAlert({
        content: "添加时间银行成功",
        confirmFn: () => {
          router.go(-1);
        }
      });
    };
    return {
      ...toRefs(state),
      ...toRefs(selectInfo),
      datas,
      postData,
      checkDuo,
      checkHealth,
      checkZS,
    };
  }
});
</script>
<style lang="scss">
.timeBank-page {
  padding: 0 0.26rem 0.28rem;
  background: #f8f8f8;
  min-height: 100vh;
  .title {
    padding: 0.21rem 0;
    @include fontSC(0.28rem, #828282);
    /* @include border-1px(#e9e9ff, bottom); */
  }
  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.9rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0.5rem auto 0;
    background: #F65341;
    text-align: center;
  }
  input {
    color: #333;
  }
  .cell {
    background: #fff;
    border-radius: 0.2rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 0.23rem;
    box-shadow: 0px 5px 5px 0px rgb(252 55 37 / 3%);
    margin-bottom: 0.2rem;
    .item {
      height: 0.97rem;
      position: relative;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include fontSC(0.3rem, #3F3F3F);
      .label{
        margin-left: 0;
        font-size: 0.28rem;
      }
      .left{
        width: 40%;
      }
       .right {
         width: 50%;
        position: relative;
        &.check-box{
          display: flex;
          align-items: center;
          .t{
            margin-right: 0.82rem;
            font-size: 0.28rem;
          }
        }
        input {
          text-align: left;
          font-size: 0.28rem;
        }
      }
    }
    .select-wrap{
      /* padding: 0 0.26rem 0; */
      .label{
        @include fontSC(0.3rem, #828282);
        margin: 0 0 0.23rem;
        padding-top: 0.45rem;
      }
      .select-box{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .check-item{
          display: flex;
          align-items: center;
          margin-bottom: 0.18rem;
          padding: 0.16rem;
          border: 1px solid #E7E7E7;
          border-radius: 0.05rem;
          margin-right: 0.2rem;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }

  .checkbox {
      display: inline-block;
      width: 0.28rem;
      height: 0.28rem;
      border: 1px solid #ddd;
      border-radius: 0.02rem;
      margin-right: 0.1rem;
      &.checked {
        background:  url(../../assets/images/checked.png) center no-repeat;
        background-size: cover;
      }
    }
}
</style>
