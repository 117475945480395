<template>
    <div class="contBox">
        <div style="padding: 0.3rem;">
            <div v-if="list && list.length>0">
                <div class="box" v-for="(item, index) in list" :key="index">
                    <div class="boxStrip">
                        <div>活动名称：{{ item.activityName }}</div>
                        <div>活动地点：{{ item.activyAddress }}</div>
                    </div>
                    <div class="boxStrip" style="margin-top: 0.4rem;">
                        <div>活动发起方：{{ item.ownedCommunity }}</div>
                        <div>活动时间：{{ item.activyStartTime }}</div>
                    </div>

                    <div style="margin-top: 0.4rem;">
                        <div>活动内容：</div>

                        <div style="margin-top: 0.2rem;border: 1px solid white;padding: 0.1rem;">{{ item.activyContent }}
                        </div>

                    </div>
                </div>
            </div>
            <div v-else class="nullActivity">
                暂无活动
            </div>

            <!-- <div class="box">
                <div class="boxStrip">
                    <div>活动名称：111</div>
                    <div>活动地点：1111111111111</div>
                </div>
                <div class="boxStrip" style="margin-top: 0.4rem;">
                    <div>活动发起方：111111111</div>
                    <div>活动时间：1111111111111</div>
                </div>

                <div style="margin-top: 0.4rem;">
                    <div>活动内容：</div>

                    <div style="margin-top: 0.2rem;border: 1px solid white;padding: 0.1rem;">
                        11111111111111ssssssssssssssssssssssssssssssssss</div>

                </div>
            </div> -->
        </div>

    </div>
</template>
  
<script>
import { useStore } from 'vuex'
import { defineComponent, computed, onMounted, ref } from 'vue'
import QrcodeVue from 'qrcode.vue'
import LuckyService from '@/api/lucky.js'
import { useRouter, useRoute } from 'vue-router'
import Clipboard from 'clipboard';
import { Toast } from 'vant'
import UserService from '@/api/common.js'

export default {
    components: {
        QrcodeVue
    },
    setup(props) {
        const store = useStore()
        const route = useRoute()
        const no = route.query.no
        const uid = localStorage.uid
        const list = ref([])

        onMounted(() => {
            getPayoutData();
        })

        const getPayoutData = async () => {
            const data = await UserService.myActivity({
                uid
            })
            console.log(data)
            if (!data) return
            list.value = data.activityList

        }
        return {
            getPayoutData
        }
    }
}
</script>
  
<style lang="scss">
.contBox {
    background-color: #f8f8f8;
    height: 100vh;
    font-size: 0.28rem;
    color: #706868;
    font-weight: bold;
}

.box {
    background-color: white;
    padding: 0.2rem;

    // height: 6vh;
    >div {

        // height: 0.55rem;
        // line-height: 1rem;
    }

    .boxStrip {
        display: flex;

        // justify-content: space-between;
        >div {
            flex: 1;
        }
    }
}
.nullActivity{
    display: flex;
    justify-content: center;
    font-size: 0.5rem;
    margin-top: 2rem;
}
</style>
  