import { ref, nextTick } from 'vue'
import UserService from '@/api/common.js'
import EXIF from 'exif-js'
const dfImg = require('@/assets/images/login/add.png')

export default function upload() {
  const imgBase64Data = ref('')
  const fileData = ref(dfImg)
  const liceseNo = ref('')
  const resetFlag = ref(true)
  let Orientation = ref(0)
  const fileChange = (e) => {
    console.log(e.target)
    let file = e.target.files[0]
    let reader = new FileReader()
    resetFlag.value = false
    nextTick(() => {
      resetFlag.value = true
    })
    reader.addEventListener(
      'load',
      () => {
        //   fileData.value = reader.result;
        let image = new Image()
        image.src = reader.result
        image.onload = function () {
          let imgWidth = this.width
          let imgHeight = this.height
          // 控制上传图片的宽高
          console.log('image onLoad w,h', imgWidth, imgHeight)
          if (imgWidth > imgHeight && imgWidth > 1500) {
            imgWidth = 1500
            imgHeight = Math.ceil((1500 * this.height) / this.width)
          } else if (imgWidth < imgHeight && imgHeight > 1500) {
            imgWidth = Math.ceil((1500 * this.width) / this.height)
            imgHeight = 1500
          }
          canvasDrawImage(this, imgWidth, imgHeight).then((canvas) => {
            const base64Img = canvas.toDataURL('image/jpeg') // 转base64
            imgBase64Data.value = base64Img
            upload() // 上传图片
          })
        }
      },
      false
    )

    if (file) {
      EXIF.getData(file, function () {
        // ios  判断图片旋转角度
        Orientation.value = EXIF.getTag(this, 'Orientation')
        console.log('Orientation', Orientation.value)

        var allMetaData = EXIF.getAllTags(this)
        console.log('allMetaData', allMetaData)
      })
      reader.readAsDataURL(file)
    }
  }

  const upload = async () => {
    const data = await UserService.fileUpload({
      base64String: imgBase64Data.value,
      fileType: '.jpg'
    })
    if (!data) return
    const { fileUrl } = data
    liceseNo.value = fileData.value = fileUrl
  }

  const canvasDrawImage = (img, targetWidth, targetHeight) => {
    console.log('图片原始宽度', targetWidth, '图片原始高度', targetHeight)
    return new Promise((resolve, reject) => {
      try {
        let canvas = document.createElement('canvas')
        let context = canvas.getContext('2d')
        canvas.width = targetWidth
        canvas.height = targetHeight
        // context.clearRect(0, 0, targetWidth, targetHeight); // 清除画布
        const isiOS = !!navigator.userAgent.match(
          /\(i[^;]+;( U;)? CPU.+Mac OS X/
        )
        if (isiOS) {
          context.drawImage(img, 0, 0, targetWidth, targetHeight) // 图片压缩
        } else {
          // ios下进行是否需要旋转的判断
          console.log('ios Orientation=', Orientation.value)
          switch (Orientation.value) {
            case 6:
              // 旋转90度
              canvas.width = targetHeight
              canvas.height = targetWidth
              context.rotate(Math.PI / 2)
              // (0,-targetHeight) 从旋转原理图那里获得的起始点
              context.drawImage(
                img,
                0,
                -targetHeight,
                targetWidth,
                targetHeight
              )
              break
            case 8:
              // 逆时针90°
              canvas.width = targetHeight
              canvas.height = targetWidth
              context.rotate((3 * Math.PI) / 2)
              context.drawImage(img, -targetWidth, 0, targetWidth, targetHeight)
              break
            case 3:
              // 旋转180°
              context.rotate(Math.PI)
              context.drawImage(
                img,
                -targetWidth,
                -targetHeight,
                targetWidth,
                targetHeight
              )
              break
            default:
              context.drawImage(img, 0, 0, targetWidth, targetHeight)
              break
          }
        }
        resolve(canvas)
      } catch (error) {
        reject(error)
      }
    })
  }

  return {
    fileChange,
    imgBase64Data,
    fileData,
    resetFlag,
    liceseNo,
    Orientation
  }
}
