<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">带病回乡退伍军人补助优待政策问答</div>
            <div class="content-page">
                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList"></div> -->

                <van-collapse v-model="activeNames" accordion>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="1">

                        <template #title>
                            <div class="titles">带病回乡退伍军人享受定期定量补助的条件？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            患慢性病的义务兵和初级士官退伍回乡生活困难的，按国家标准享受带病回乡退伍军人定期定量补助。丧失劳动能力的，按上海市标准享受定期定量补助。
                            带病回乡退伍军人“生活困难”一般是指：
                            1、退伍后从未安排过工作且未曾就业、无固定收入（含城镇职工养老保险收入），义务兵、初级士官退伍兵自主就业的，视作未安排工作。
                            2、退伍后虽曾安排工作或就业，但依法与所在单位解除劳动（聘用）合同及工作关系，并按规定已领完失业保险金后不再享受城镇职工养老保险待遇，且未再就业，无其他固定收入的。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="2">
                        <template #title>
                            <div class="titles">带病回乡退伍军人在什么情况下中止补助待遇？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            带病回乡退伍军人所患疾病治愈或生活状况发生变化，不再符合享受待遇条件的，中止其享受的带病回乡退伍军人相关待遇. </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="3">
                        <template #title>
                            <div class="titles">带病回乡退伍军人的医疗优待有哪些？ </div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            带病回乡退伍军人按照属地原则相应参加本市居民医疗保险和新型农村合作医疗，个人缴费部分由所在区县财政承担。其当年内发生的医疗费用经相应医疗保障体系报销，并已实施各类医疗救助、互助、减负后，其剩余医疗费用中符合城镇职工基本医疗保险报销范围，但个人负担部分仍较重的，可申请优抚对象医疗补助。医疗补助的给付比例应不低于50%。
                            带病回乡退伍军人到医疗机构就医时，可享受优先挂号、优先就诊、优先化验、优先付费、优先取药、优先住院等各项待遇。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="4">
                        <template #title>
                            <div class="titles">带病回乡退伍军人入住复退军人精神病院的条件是什么？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在部队服役期间患精神病的带病回乡退伍军人，需经精神病患者的监护人提出书面申请，经区县民政局审批同意，由监护人与市复退军人精神病院办理相关手续后才能入住。
                            经批准入住市复退军人精神病院后，原享受定期定量补助的，在院期间停止发给定期定量补助，出院后再予以恢复。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="5">
                        <template #title>
                            <div class="titles">享受定期定量补助的带病回乡退伍军人死亡后，丧葬补助费如何计发？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期定量补助的带病回乡退伍军人死亡后，增发6个月其原享受的定期定量补助，作为丧葬补助费。次月起停发定期定量补助。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="6">
                        <template #title>
                            <div class="titles">如何办理带病回乡退伍军人关系转移？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            享受定期定量补助的带病回乡退伍军人户籍发生变化，应办理关系转移。本人向原户籍所在街道（乡镇）书面提出申请，并提供迁入地户籍证明材料，按规定办理关系转移手续。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="7">
                        <template #title>
                            <div class="titles">带病回乡退伍军人补助是否计入家庭收入？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在建立和实施最低生活保障制度中，带病回乡退伍军人的补助不计入家庭收入。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="8">
                        <template #title>
                            <div class="titles">带病回乡退伍军人的法律责任有哪些？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            带病回乡退伍军人被判处有期徒刑、剥夺政治权利或者被通缉期间，中止其补助优待；被判处死刑、无期徒刑的，取消其补助优待资格。
                            有下列行为之一的，由区县民政局给予警告，限期退回非法所得；情节严重的，停止其享受的补助、优待；构成犯罪的，依法追究刑事责任：
                            （一）冒领抚恤金、优待金、补助金的；
                            （二）虚报病情骗取医药费的；
                            （三）出具假证明，伪造证件、印章骗取优待金、补助金的。</div>
                    </van-collapse-item>
                  
                </van-collapse>


            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon, Collapse, CollapseItem } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const ArrList = ref([
            {
                id: '1',
                title: '1',
                name: '2'
            }
        ])

        const activeNames = ref('1');


        return {
            ArrList,
            activeNames,
        }
    }
})
</script>
<style lang="scss" scoped>
.titles {
    height: 15vw;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.35rem;
    font-weight: 700;
}

.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.8rem;
    // width: 80vw;
    padding: 0.26rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  