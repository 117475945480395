<template>
  <div class="gyblist-page">
    <section class="sticky">
      <div class="tab-list list1">
        <div
          class="item"
          :class="{ curr: currOneTab === 'all' }"
          @click="checkOneTab('all')"
        >
          全部
        </div>
        <div
          @click="checkOneTab(index)"
          class="item"
          v-for="(item, index) in typeListData"
          :key="item.id"
          :class="{ curr: currOneTab === index }"
        >
          {{ item.typeName }}
        </div>
      </div>
    </section>
    <GybLists
      @getNewData="getData"
      :listData="productListData"
      class="list-box"
    ></GybLists>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs
} from 'vue'
import { useStore } from 'vuex'
import GybLists from '@/components/GybLists.vue'
import { Tab, Tabs, DropdownMenu, DropdownItem, Button, Cell } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import UserService from '@/api/common.js'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    GybLists
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const route = useRoute()
    const originalTypeId = route.query.id
    const isGyb = route.query.isGyb || 'false'
    const uid = localStorage.uid
    const typeId = ref(originalTypeId)
    const productListData = ref([])
    const typeListData = ref([])
    const active = ref('')
    const activeName = ref('1')

    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0,
      lowerPrice: '',
      hightPrice: '',
      salesSort: false
    })

    const currOneTab = ref('all')
    const currTwoTab = ref('')

    onMounted(() => {
      initInfo()
    })

    const resetState = () => {
      pageInfo.pagesize = 10
      pageInfo.startRow = 1
      pageInfo.totalPage = 1
      pageInfo.curr = 0
      pageInfo.hightPrice = ''
      pageInfo.lowerPrice = ''
      currTwoTab.value = ''
      pageInfo.salesSort = false
      // typeId.value = originalTypeId
      productListData.value = []
    }

    const initInfo = async () => {
      const postData = {
        hightPrice: pageInfo.hightPrice,
        lowerPrice: pageInfo.lowerPrice,
        salesSort: pageInfo.salesSort,
        typeId: typeId.value,
        uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize
      }
      const data = await UserService.qryProdcutByType(postData)
      if (!data) return
      const { productList, typeList, total } = data
      if (!typeListData.value.length) typeListData.value = typeList
      if (productList)
        productListData.value = [...productListData.value, ...productList]
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize)
    }

    const getData = async (isRefresh) => {
      pageInfo.curr += 1
      if (isRefresh) {
        productListData.value = []
        pageInfo.curr = 0
      }

      if (pageInfo.curr >= pageInfo.totalPage) return
      initInfo()
    }

    const checkOneTab = (n) => {
      if (currOneTab.value === n) return
      resetState()
      currOneTab.value = n
      if (n === 'all') {
        typeId.value = originalTypeId
        return initInfo()
      }
      typeId.value = typeListData.value[parseInt(n)].id

      initInfo()
    }
    const checkTwoTab = (n) => {
      if (currTwoTab.value === n) {
        currTwoTab.value = ''
      } else {
        currTwoTab.value = n
      }
      pageInfo.salesSort = !pageInfo.salesSort
      initInfo()
    }

    const tabChange = ({ name }) => {
      resetState()
      // console.log(productListData.value);
      if (activeName.value !== name) {
        activeName.value = name
        typeId.value = typeListData.value[parseInt(name)].id
        // initInfo()
      }
      initInfo()
    }

    const showPriceFlag = computed(() => {
      return (
        pageInfo.lowerPrice &&
        pageInfo.hightPrice &&
        pageInfo.lowerPrice < pageInfo.hightPrice
      )
    })

    const onConfirm = () => {
      if (pageInfo.lowerPrice >= pageInfo.hightPrice) {
        showAlert({ content: '开始价不能大于等于结束价' })
        return
      }
      initInfo()
    }
    const closeDropDown = () => {}

    return {
      ...toRefs(pageInfo),
      productListData,
      typeListData,
      activeName,
      active,
      isGyb,
      currOneTab,
      currTwoTab,
      showPriceFlag,
      getData,
      onConfirm,
      tabChange,
      checkOneTab,
      checkTwoTab,
      closeDropDown
    }
  }
})
</script>
<style lang="scss">
.gyblist-page {
  min-height: 100vh;
  // background: #f8f8f8;
  .tab-list {
    display: flex;
    // padding-bottom: 0.23rem;
    // background: #f8f8f8;
    padding-left: 0.3rem;
    // overflow-x: scroll;
    white-space: nowrap;
    &.list2 {
      display: flex;
      align-items: center;
      background: #fff;
      .price {
        margin-left: 0.2rem;
      }
      .dropdowm-item {
        padding: 0 0.5rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include border-1px(#eeeeee, bottom);
        input {
        }
      }
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
    .item {
      @include fontSC(0.3rem, #666);
      line-height: 0.9rem;
      margin-right: 0.67rem;
      &:last-of-type {
        margin-right: 0;
      }
      &.curr {
        font-weight: bold;
        color: #fb3e28;
      }
    }
  }
  .van-tabs__nav--line {
    &::after {
      content: '';
      width: 2.5rem;
    }
  }
  .list-box {
    .list-wrap {
      padding: 0.2rem 0.3rem;
    }
  }
  .dropdown {
    .van-dropdown-menu__bar {
      box-shadow: none;
      &::after {
        content: '';
        width: 3.8rem;
      }
      .van-dropdown-menu__item {
        &:first-child {
          .van-dropdown-menu__title {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    .van-cell {
      padding: 0.3rem;
      &::after {
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
