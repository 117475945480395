<template>
  <div class="wheel-page">
    <div class="tip">{{drawDetail.drawName}}</div>
    <div class="wheel-wrap">
        <!-- <img class="bg" src="@/assets/images/luckywheel/wheel1.png" alt=""> -->
      <LuckyWheel
        ref="LuckyWheel"
        width="6.6rem"
        height="6.6rem"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallBack"
        @end="endCallBack"
        class="wheel"
        :default-config="defaultConfig"
      />
    </div>
    <div class="award" @click="goAward">
      <img src="@/assets/images/luckywheel/award.png" alt="" />
    </div>
    <div class="rule-box">
      <img src="@/assets/images/luckywheel/rule.png" alt="" />
      <p v-html="drawDetail.drawRule"></p>
      <!-- <p>
        1、本次活动时间：2021年7月30日；<br />2、领奖地点：位于法华镇路712号新华社区慈善超市；<br />
        3、每次抽奖将扣减一个公益豆；<br />4、抽中后，凭借二维码可至慈善超市领取奖品。
      </p> -->
    </div>

    <div class="fixed" v-if="showDiaFlag">
      <div class="result-dialog" :class="{ succ: resType === 'SUCC' }">
        <img class="img" :src="resTypeData.src" alt="" />
        <div class="content">
          <div class="des" :class="{noprize: resType=='FAIL'}">
            {{ resTypeData.des
            }}<span v-if="resTypeData.prize">{{ resTypeData.prize }}</span>
          </div>
          <div v-if="resTypeData.memo" class="memo">{{ resTypeData.memo }}</div>
          <div v-if="resTypeData.prize" class="memo" @click="goAward">
            可在<span class="r">【我的奖品】</span>查看
          </div>
          <div class="btn" @click="closeDialog">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  reactive,
  toRefs,
} from "vue";
import LuckyService from "@/api/lucky.js";
import mixin from "@/mixin/mixin.js";

export default {
  components: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showAlert, showConfirm } = mixin();
    const wheelOptions = reactive({
      prizes: [
        // { name: "1", title:'10爱心积点' },
        // { name: "2", title:'5社区公益币'  },
        // { name: "3" , title:'5活力值' },
        // { name: "4" , title:'10枚鸡蛋' },
        // { name: "5", title:'5爱心积点'  },
        // { name: "6" , title:'5社区公益币' },
        // { name: "7", title:'5活力值'  },
        // { name: "0", title:'10枚鸡蛋'  },
      ],
      blocks: [
        {
          padding: "0.6rem",
          imgs: [
            {
              src: require("@/assets/images/luckywheel/wheel.png"),
              width: "100%",
              height: "100%",
              rotate: true,
            },
          ],
        },
      ],
      buttons: [
        {
          radius: "1rem",
          background: "#fff",
          imgs: [
            {
              src: require("@/assets/images/luckywheel/arrow.png"),
              width: "98%",
              top: "-174%",
            },
          ],
        },
        {
          fonts: [
            {
              text: "1次机会",
              top: "0.1rem",
              fontSize: "0.26rem",
              fontWeight: "600",
              fontColor: "#A6352E",
            },
          ],
        },
      ],
      defaultConfig: {
        gutter: "0px",
        offsetDegree: 22.5,
      },
    });
    const uid = localStorage.uid;
    const showDiaFlag = ref(false);
    const canPrize = ref(true);
    const prizeListData = ref([]);
    const prizeResult = ref({});
    const LuckyWheel = ref(null);
    const drawDetail = ref({});
    const resType = ref("SUCC");
    const prizeName = ref(""); // 奖品名称
    const resDatas = {
      SUCC: {
        src: require("@/assets/images/luckywheel/succ.png"),
        prize: "",
        des: `恭喜您，获得`,
        memo: "",
      },
      FAIL: {
        src: require("@/assets/images/luckywheel/fail.png"),
        des: "很遗憾这次你没有中奖~",
        memo: "",
      },
      END: {
        src: require("@/assets/images/luckywheel/end.png"),
        des: "本次抽奖活动已经结束了!",
        memo: "敬请期待下次活动吧!",
      },
    };

    const resTypeData = computed(() => {
      if (resType.value === "SUCC") {
        const data = resDatas[resType.value];
        data.prize = prizeName.value;
        return data;
      }
      return resDatas[resType.value];
    });

    onMounted(() => {
      qryDrawing();
    //   endCallBack({title: '221331'})
    });

    const qryDrawing = async () => {
      const data = await LuckyService.qryDrawing({
        uid,
      });
      if (!data) return;
      const { prizeList, draw, resultCode, errorCodeDes } = data;
      console.log(data);
      if(resultCode=='FAIL'){
          canPrize.value = false
          showAlert({content: errorCodeDes})
      }
      prizeListData.value = prizeList
      drawDetail.value = draw
      const prizes = []
      if(!prizeList) return
      prizeList.forEach((item, index) => {
        prizes.push({
          title: item.prizeName,
          background: index % 2 ? "#fff" : "#feb446",
          fonts: [
            {
              text: item.prizeName,
              top: "10%",
              fontSize: "0.3rem",
              fontColor: "#333",
            },
          ],
          imgs: [{ src: item.prizeIcon, width: "25%", top: "50%" }],
        });
        
      });
      wheelOptions.prizes = prizes
      console.log(prizes);
    };

    const startCallBack = async() => {
      if(!canPrize.value) return
      const data = await LuckyService.isPrize({
        uid,
        drawId: drawDetail.value.id
      });
      console.log(data); if (!data) return;
      if(!data) return
      const { prizeId } = data;
      prizeResult.value = data
      const n = prizeListData.value.findIndex(item => item.id === prizeId)
      if(n>-1){
          LuckyWheel.value.play();
        setTimeout(() => {
            // LuckyWheel.value.stop((Math.random() * 8) >> 0);
            LuckyWheel.value.stop(n);
        }, 3000);
      }
      
    };
    const endCallBack = (prize) => {
      
    //   console.log(prizeResult, '========');
      const {draw, prizeName: prizeName1} = prizeResult.value
      prizeName.value = prizeName1;
      if(draw){
          resType.value = 'SUCC'
      }else{
          resType.value = 'FAIL'
      }
      showDiaFlag.value = true
    };
    const closeDialog = () => {
       showDiaFlag.value = false
    };
    const goAward = (prize) => {
       router.push('award')
    };

    return {
      ...toRefs(wheelOptions),
      showDiaFlag,
      prizeListData,
      startCallBack,
      endCallBack,
      LuckyWheel,
      drawDetail,
      resType,
      prizeName,
      resTypeData,
      goAward,
      canPrize,
      prizeResult,
      closeDialog,
    };
  },
};
</script>

<style lang="scss">
.wheel-page {
  background: url(../../assets/images/luckywheel/bg.png) 100% 100% no-repeat;
  background-size: cover;
  height: 19.27rem;
  padding-top: 4rem;
  .tip {
    @include position-middle(0.94rem);
    width: 6.92rem;
    height: 0.69rem;
    border-radius: 0.35rem;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.4rem;
    color: #c5522a;
  }
  .wheel-wrap {
    position: relative;
    background: url(../../assets/images/luckywheel/wheel_bg.png) 100% 100%
      no-repeat;
    background-size: cover;
    width: 6.84rem;
    height: 6.84rem;
    margin: 0 auto;
    .wheel {
      margin: 0 auto;
    }
    .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 6.84rem;
        height: 6.84rem;
        z-index: 999;
    }
  }
  .award {
    @include position-middle(11.25rem);
    width: 3.74rem;
    height: 1.06rem;
    img {
      display: block;
      width: 100%;
    }
  }
  .rule-box {
    @include position-middle(13.21rem);
    width: 6.54rem;
    height: 5.28rem;
    background: #ffdfcd;
    padding: 0.71rem 0.3rem 0;
    border-radius: 0.08rem;
    p {
      font-size: 0.34rem;
      color: #462f2f;
      line-height: 1.5em;
      text-align: left;
    }
    img {
      @include position-middle(-0.41rem);
      width: 4.62rem;
      height: 0.77rem;
    }
  }
  .result-dialog {
    @include position-middle(50%);
    transform: translate(-50%, -46%);
    &.succ {
      img {
        top: -34%;
      }
      .content {
        padding-top: 1.85rem;
        padding-bottom: 0.84rem;
      }
    }
    .img {
      @include position-middle(-38%);
      width: 3.8rem;
      height: 3.81rem;
      z-index: 1;
    }
    .content {
      position: relative;
      width: 6.2rem;
      background: #fff;
      border-radius: 0.08rem;
      text-align: center;
      padding: 1.47rem 0.3rem 0.72rem;
      .des {
        @include fontSC(0.38rem, #333);
        &.noprize{
            margin-bottom: 1.17rem;
        }
      }
      .memo {
        @include fontSC(0.3rem, #999);
        margin: 0.28rem 0 0.62rem;
        .r {
          color: #fe5631;
          margin: 0 0.05rem;
        }
      }
      .btn {
        @include fontSC(0.36rem, #fe5631);
        width: 3.56rem;
        line-height: 0.94rem;
        border: 1px solid #fe5631;
        border-radius: 0.05rem;
        margin: 0 auto;
      }
    }
  }
}
</style>
