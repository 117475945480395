<template>
  <div class="appeal-page">
    <div class="cell">
      <div class="item">
        <div class="label">姓名*</div>
        <div class="right">
          <input v-model="name" type="text" placeholder="请输入您的姓名" />
        </div>
      </div>

      <div class="item">
        <div class="label">手机号*</div>
        <div class="right">
          <input v-model="mobile" type="number" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="item">
        <div class="label">身份证号码*</div>
        <div class="right">
          <input v-model="certId" type="text" placeholder="请输入您的身份证号" />
        </div>
      </div>
      <div class="item">
        <div class="label">所属居委*</div>
        <div class="right">
          <input class="Input" v-model="ownedCommunity" placeholder="请输入所属居委" />
        </div>
      </div>
      <div class="item">
        <div class="label">所属类别*</div>
        <div class="right">
          <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="item">
        <div class="label">就业状态*</div>
        <div class="right">
          <van-dropdown-menu>
            <van-dropdown-item v-model="value2" :options="option1" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="item">
        <div class="label">家庭住址*</div>
        <div class="right">
          <input v-model="address" type="text" placeholder="请输入您的家庭住址" />
        </div>
      </div>

    </div>

    <div class="btn" @click="submit">提交</div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SoldierService from '@/api/soldier.js'
import { Picker, Popup, ImagePreview } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant';
import axios from 'axios';
import mixin from '@/mixin/mixin.js'

export default defineComponent({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
    // [ImagePreview.name]: ImagePreview,
  },
  props: {},
  setup(props) {
    const { showAlert, showConfirm } = mixin()
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid
    const value1 = ref('CJ');
    const value2 = ref('ON');
    const option = ref([
      { value: 'CJ', text: 'CJ' },
      { value: 'LS', text: 'LS' },
      { value: 'YB', text: 'YB' },
      { value: 'BG', text: 'BG' },
      { value: 'NC', text: 'NC' },
      { value: 'YB', text: 'YB' },
    ]);
    const option1 = ref([
      { value: 'ON', text: '在职' },
      { value: 'OFF', text: '退休' },
      { value: 'LOSE', text: '失业' },

    ]);

    const state = reactive({
      name: '',
      address: '',
      mobile: '',
      certId: '',
      ownedCommunity: ''
    })

    onMounted(() => {
    })

    const submit = async () => {
      let { name, mobile, address, certId, ownedCommunity } = state;
      console.log(name);
      try {
        const response = await axios.post('http://aianna.net:8082/inner/member/complainRequest', {
          name: name,
          mobile: mobile,
          address: address,
          certId: certId,
          ownedCommunity: ownedCommunity,
          speciesBacteria: value1.value,
          job: value2.value,
        });
        const data = response;
        console.log(data);
        if (!data) return;
        showAlert({
          content: '申诉成功',
          confirmFn: () => {
            router.go('-1')
          }
        })
        // const { records } = data;
        // localStorage.id = records[0].id;
        // localStorage.userQrcode = records[0].qrcode;
        // console.log(records[0].age)
        // user.value = records || [];
      } catch (error) {
        console.error("Error fetching order:", error);
      }
      if (
        !['name', 'address', 'mobile', 'certId'].every(
          (item) => state[item]
        )
      ) {
        showAlert({ content: '选项不能为空' })
        return
      }
      // const { name, mobile, certId, address } = state
      // const data = await SoldierService.complainRequest({
      //   name,
      //   address,
      //   mobile,
      //   certId,
      //   uid: localStorage.uid
      // })
      // console.log(data)
      // if (!data) return
      // showAlert({
      //   content: '申诉成功',
      //   confirmFn: () => {
      //     router.go('-1')
      //   }
      // })
    }
    return {
      ...toRefs(state),
      submit,
      option,
      value1,
      value2,
      option1
    }
  }
})
</script>
<style lang="scss">
.triangle-right .triangle {
  left: auto;
  right: 0;
}

.van-dropdown-menu__bar {
  box-shadow: unset !important;
}

.appeal-page {
  padding: 0.3rem 0.29rem;
  min-height: 100vh;
  background: #F8F8F8;

  .cell {
    border-radius: 0.2rem;
    overflow: hidden;
    font-size: 0.29rem;

    .item {
      height: 0.97rem;
    }

    .item .label {
      font-size: 0.29rem;
    }
  }

  .btn {
    @include fontSC(0.36rem, #fff);
    background: #f65341;
    width: 6.91rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0.71rem auto 0.48rem;
    border: 1px solid #fd5431;
    text-align: center;
  }

  input {
    color: #333;
  }

  .cell .item .right {
    position: relative;

    input {
      padding-left: 0.5rem;
      text-align: left;
      font-size: 0.29rem;
    }
  }

  .cell .item.input-wrap {
    display: flex;
    height: 1.04rem;
    @include border-1px(#ddd, bottom);

    &.zhizhao {
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.21rem;

      img {
        width: 0.46rem;
        height: 0.42rem;
      }
    }

    .uploadInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .img {
      width: 0.32rem;
      height: 0.37rem;
      margin-right: 0.2rem;
    }

    .block {
      width: 0.32rem;
      height: 0.37rem;
    }

    input {
      flex: 1;
      height: 100%;
      padding-right: 0.32rem;
      @include fontSC(0.3rem, #333);

      &::-webkit-input-placeholder {
        color: #666;
      }
    }

    &.valid-item {
      input {
        padding-left: 1rem;
      }

      .get-code {
        width: 1.6rem;
        text-align: center;
        line-height: 0.58rem;
        border: 1px solid #fb3e28;
        border-radius: 0.45rem;
        @include fontSC(0.24rem, #fb3e28);
      }

      .sec {
        @include fontSC(0.24rem, #fb3e28);
      }
    }
  }

  .uploadImg {
    width: 0.46rem;
    height: 0.42rem;
  }

  .cell .item .img-box {
    position: relative;

    .img-wrap {
      width: 1.09rem;
      height: 0.82rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;

      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.3);
      }

      .big {
        position: absolute;
        width: 0.29rem;
        height: 0.29rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .img {
        display: block;
        width: 1.09rem;
        height: 0.82rem;
      }
    }
  }
}
</style>
