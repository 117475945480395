<template>
  <div class="home">
    <div class="input-box">
      <img class="img" src="@/assets/images/search.png" alt=""/>
      <input type="search" v-model="searchTxt" @keypress="searchAction" placeholder="请输入搜索内容"/>
    </div>
    <van-swipe :autoplay="5000" class="swiper-box" lazy-render indicator-color="">
      <!-- <van-swipe-item
        v-for="item in siwperData"
        :key="item.id"
        @click="goSwiper(item.id)"
      > -->
      <van-swipe-item v-for="item in siwperData" :key="item.id">
        <!-- <a :href="item.hrefUrl"><img :src="item.advertUrl" /></a> -->
        <img :src="item.advertUrl" @click="goCompany(item)"/>
      </van-swipe-item>
    </van-swipe>
    <div class="sec" v-if="advertData.length">
      <van-swipe :autoplay="5000" class="swiper-box" lazy-render indicator-color="">
        <van-swipe-item v-for="item in advertData" :key="item.id">
          <!-- <img :src="item.advertUrl" @click="goCompany(item.id)" /> -->
          <div class="con" @click="goDetail(item.id)">
            <div class="l">
              <img class="notice" :src="item.activityImg"/>
              <div class="title">{{ item.advertName }}</div>
            </div>
            <div class="r">查看详情</div>
          </div>
        </van-swipe-item>
      </van-swipe>

      <p class="more" @click="goMore">
        查看更多<img src="@/assets/images/right-arrow.png"/>
      </p>
    </div>
    <div class="type-list">
      <div class="item"  :class="[item.typeKey == 'SHUANGYONG' ? 'shuangyong' : '']" v-for="item in typeListData" :key="item.sort" @click="goTypePage(item)">
        <img :src="item.url" alt=""/>
        <div class="des">{{ item.typeName }}</div>
      </div>
      <!-- <div class="item">
        <img src="" alt="">
        <div class="des">公益商城</div>
      </div> -->
    </div>
    <div class="product-list" v-show="productListData.length">
      <div class="title">
        <img src="@/assets/images/home/tui.png" alt=""/>
        推荐商品
      </div>

      <ProductList @getNewData="getData" :listData="productListData"></ProductList>
    </div>
    <soldierAgreement v-if="showSoldierAgreement" @cancel="soldierAgreementCancel" @confirm="soldierAgreementConfirm">
    </soldierAgreement>
    <soldierPopup v-if="showSoldierPop" :type="soldierType" @cancel="soldierPopCancel" @confirm="soldierPopConfirm">
    </soldierPopup>
    <div class="dialog" v-if="showDiaFlag">
      <div class="con">
        <img class="img" src="@/assets/images/home/dialog-icon.png" alt=""/>
        <div class="top"></div>
        <div class="bot">
          <div class="title">请完善个人信息</div>
          <div class="memo">
            信息不完整，请完善<br/>个人信息，开启公益之旅！
          </div>
          <div class="btn" @click="addInfo">马上完善</div>
        </div>
        <div class="close" @click="closeDialogAction">
          <img src="@/assets/images/close.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="dialog" v-if="showDrawFlag">
      <div class="con" style="background: transparent">
        <img class="drawimg" src="@/assets/images/home/draw.png" alt=""/>
        <div class="drawbot">
          <div class="title">本次抽奖活动时间<br/>{{ drawTxtMsg }}</div>
          <div class="btn" @click="closeDialogAction">我知道了</div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" :style="{ height: '53%' }" style="border-radius: 5px;">
      <div class="windowAct">
        <div class="windowAct-title">举办活动通知！</div>
        <div class="windowAct-cont">
          <div>
            <div>活动名称：</div>
            <div>{{ pageHuo.activityName }}</div>
          </div>
          <div>
            <div>活动地点：</div>
            <div>{{ pageHuo.activyAddress }}</div>
          </div>
          <div>
            <div>活动时间：</div>
            <div>{{ pageHuo.activyStartTime }}</div>
          </div>

          <div>
            <div>活动发起方：</div>
            <div>{{ pageHuo.ownedCommunity }}</div>
          </div>

          <div>
            <div>活动内容：</div>
            <div class="tableg">
              {{ pageHuo.activyContent }}
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute;top: 10px;right: 10px;" @click="clickfalse()">
        <van-icon name="close" size="30" color="white"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import UserService from '@/api/common.js'
import {ref, reactive, onMounted, computed} from 'vue'
import {useRouter} from 'vue-router'
import ProductList from '@/components/ProductList.vue'
import soldierPopup from '@/components/soldierPopup.vue'
import soldierAgreement from '@/components/soldierAgreement.vue'
import {Swipe, SwipeItem, Popup, Icon} from 'vant'
import mixin from '@/mixin/mixin.js'
import {useStore} from 'vuex'

export default {
  name: 'Home',
  components: {
    ProductList,
    soldierPopup,
    soldierAgreement,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },
  setup() {
    const show = ref(false);
    const showList = ref({})

    const {showAlert} = mixin()
    const store = useStore()
    const showSoldierPop = ref(false)
    const routerMap = {
      GYSC: 'productList',
      ZLKT: 'productList',
      ZYZFW: 'productList',
      // LBSP: "productList",
      LBSP: 'linbaoList',
      //   YMP: "yimai",
      YMP: 'productList',
      csyy: 'csyy',
      CJT: 'luckyWheel',
      LQGYB: 'gybList'
    }
    const images = [
      'https://img.yzcdn.cn/vant/apple-1.jpg',
      'https://img.yzcdn.cn/vant/apple-2.jpg'
    ]
    const showDiaFlag = ref(false)
    const showDrawFlag = ref(false)
    const showSoldierAgreement = ref(false)
    const soldierType = ref('1')
    const infos = ref({})
    const searchTxt = ref('')
    const drawTxtMsg = ref('')
    const productListData = ref([])
    const typeListData = ref([])
    const advertMapData = ref([])
    const siwperData = ref([])
    const advertData = ref([])
    const router = useRouter()
    const uid = localStorage.uid
    const pageInfo = reactive({
      pagesize: 10,
      startRow: 0,
      totalPage: 1,
      curr: 0
    })


    const pageHuo = reactive({
      activityName: '',
      activyAddress: '',
      activyStartTime: '',
      ownedCommunity: '',
      activyContent: ""

    })
    const levelMes = localStorage.getItem('level');
    console.log(levelMes)

    const activity = async () => {

      if (levelMes == 2 || levelMes == 3) {
        const leveluid = localStorage.getItem('uid');
        const data = await UserService.getHD({
          "pagesize": 1,
          "startRow": 0,
          "uid": leveluid
        })
        console.log(data)
        show.value = data.showActivity;

        showList.value = data.activity;

        // show.value = true;
        pageHuo.activityName = showList.value.activityName
        pageHuo.activyAddress = showList.value.activyAddress
        pageHuo.activyStartTime = showList.value.activyStartTime
        pageHuo.ownedCommunity = showList.value.ownedCommunity
        pageHuo.activyContent = showList.value.activyContent

      }
    }
    const clickfalse = async () => {
      show.value = false;
    }

    const isComplete = localStorage.isComplete
    if (isComplete !== 'TRUE' && !sessionStorage.grdialog) {
      showDiaFlag.value = true
      sessionStorage.grdialog = 'y'
    }
    const getWxConfig = async () => {
      const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false
      const data = await UserService.getWxConfig({
        url: isIos ? localStorage.firstUrl : window.location.href
      })
      if (!data) return
      const {signature, timestamp, nonceStr} = data
      console.log(data)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: 'wx267b6224a7e9475c', // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
      })
    }

    const inWhiteList = computed(() => {
      return true
      // return store.state.whiteList.includes(uid)
    })

    const initInfo = async () => {
      const data = await UserService.homepage({
        uid: localStorage.uid,
        pagesize: pageInfo.pagesize,
        startRow: pageInfo.curr * pageInfo.pagesize
      })
      console.log(data)
      if (!data) return
      const {
        advertMap,
        productList,
        typeList,
        total,
        activityList,
        drawMessage
      } = data

      if (drawMessage) {
        showDrawFlag.value = true
        drawTxtMsg.value = drawMessage
      }
      if (productList)
        productListData.value = [...productListData.value, ...productList]
      typeListData.value = typeList
      if (!inWhiteList.value) {
        typeListData.value = typeList.filter((item) => {
          return item.typeKey !== 'LQGYB' && item.typeKey !== 'MCGY'
        })
      }
      typeListData.value = typeList.filter((item) => {
        return item.typeKey !== 'LQGYB'
      })
      inWhiteList.value &&
      typeListData.value.push({
        createTime: '2021-08-29 20:24:45',
        id: 999,
        isGyb: 'false',
        isSupportOnlinePay: 'false',
        lastUpdateTime: '2021-08-29 20:24:48',
        parentId: 0,
        showType: 'persion',
        sort: 7,
        stauts: 'VALID',
        typeKey: 'SCAN',
        typeName: '扫一扫',
        url: require('../assets/images/home/scan.png')
      })
      
      typeListData.value.push({
        createTime: '2022-08-29 20:24:45',
        id: 1000,
        isGyb: 'false',
        isSupportOnlinePay: 'false',
        lastUpdateTime: '2022-08-29 20:24:48',
        parentId: 0,
        showType: 'persion',
        sort: 8,
        stauts: 'VALID',
        typeKey: 'SHUANGYONG',
        typeName: '购买“新华·双拥花”',
        url: require('../assets/images/home/sy.png')
      })

      siwperData.value = advertMap.HOME_FIRST || []
      advertData.value = activityList || []
      pageInfo.totalPage = Math.ceil(total / pageInfo.pagesize)
    }

    const searchAction = async (e) => {
      if (e.keyCode !== 13) return

      if (!searchTxt.value) {
        showAlert({content: '搜索内容不能为空'})
        return
      }
      router.push({
        path: '/searchProduct',
        query: {
          q: searchTxt.value
        }
      })
      // const data = await CenterService.searchProduct({
      //   search: '',
      //   uid,
      // });

      //   const { advertMap, productList, typeList, total } = data;
      // console.log(data); if (!data) return;
    }
    const getData = async (isRefresh) => {
      pageInfo.curr += 1
      if (isRefresh) pageInfo.curr = 0
      if (pageInfo.curr > pageInfo.totalPage) return
      initInfo()
    }
    const soldierPopConfirm = () => {
      sessionStorage.btnToUnSolider = ''
      if (localStorage.hasConfirmAgree !== 'Y') {
        showSoldierAgreement.value = true
        // soldierPopCancel()
      } else {
        soldierType.value = localStorage.level == '3' ? '2' : '3'
      }
    }
    const soldierPopCancel = () => {
      showSoldierPop.value = false
    }
    const soldierAgreementConfirm = () => {
      showSoldierAgreement.value = false
      localStorage.hasConfirmAgree = 'Y'
      soldierType.value = localStorage.level == '3' ? '2' : '3'
    }
    const soldierAgreementCancel = () => {
      showSoldierAgreement.value = false
      showSoldierPop.value = false
      soldierType.value = '1'
    }
    const goSwiper = async (id) => {
      router.push({
        path: '/publicWelfareActivity',
        query: {
          id
        }
      })
    }
    const goCompany = (detail) => {
      const {advertUrl, advertDetail} = detail
      console.log(
          JSON.stringify({
            advertUrl: advertUrl,
            advertDetail: advertDetail
          })
      )
      sessionStorage.advertInfo = JSON.stringify({
        advertUrl: advertUrl,
        advertDetail: advertDetail
      })
      router.push({
        path: '/loveCompany'
      })
    }
    const goDetail = async (id) => {
      router.push({
        path: '/publicWelfareDetail',
        query: {
          id
        }
      })
    }
    const goMore = async () => {
      router.push({
        path: '/publicWelfareActivity'
      })
    }
    const goPublicWelfare = async (id) => {
      router.push({
        path: '/publicWelfareActivity',
        query: {
          id
        }
      })
    }
    const addInfo = () => {
      router.push('/addInfo')
    }
    const closeDialogAction = () => {
      showDiaFlag.value = false
      showDrawFlag.value = false
    }
    const goTypePage = (item) => {
      console.log(item.typeKey)
      const key = item.typeKey
      const query = {
        id: item.id,
        isGyb: item.isGyb
      }
      if (key === 'SCAN') {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: async function (res) {
            const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
            let data
            const [type, val] = result.split('_')
            if (type == 'act') {
              data = await UserService.activityScan({
                uid: localStorage.uid,
                scanVal: val,
              })
              if (!data) return
              showAlert({content: '扫码成功'})
              return
            }
            if (type == 'product') {
              data = await UserService.productScan({
                uid: localStorage.uid,
                productId: val,
              })
              if (!data) return
              showAlert({content: '扫码成功'})
              return
            }
            if (localStorage.level == '4') {
              // data = await UserService.activityScan({
              //   uid: localStorage.uid,
              //   scanVal: result
              // })

              if (type == 'hy') {
                showAlert({content: '暂不支持该类型二维码'})
                return
              }

              data = await UserService.volunteerActivityScan({
                uid: localStorage.uid,
                sweptUid: val,
              })
              if (!data) return
              showAlert({content: '扫码成功'})
            }

            // if (!data) return
            // showAlert({ content: '扫码成功' })
          }
        })
        return
      } else if (key === 'MCGY') { // 迷彩公园
        if (localStorage.isSoldier === 'Y') {
          router.push({
            path: '/soldierList',
            query
          })
        } else {
          showSoldierPop.value = true
          soldierType.value = '1'
        }
        return
      } else if (key === 'SHUANGYONG') { // 迷彩公园
        window.location.href = 'https://mall.metatai.net/arts?id=1727146060870022&back=1'
        return
      }
      if (key === 'ZLKT') query.isZulin = '1'
      if (key === 'LQGYB') query.isGyb = 'true'
      router.push({
        path: `/${routerMap[key]}`,
        query
      })
    }
    onMounted(() => {
      const isCashier = localStorage.isCashier
      const ua = window.navigator.userAgent.toLowerCase()
      if (isCashier === 'true' && ua.indexOf('micromessenger') === -1) {
        return router.replace('/hyCenter')
      }
      initInfo()
      getWxConfig()
      getLevel()
      activity()
    })

    const getLevel = async () => {
      const data = await UserService.queryMember({
        uid: localStorage.uid,
      })

      if (!data) return
      const {level} = data
      localStorage.level = level
    }

    return {
      pageHuo,
      clickfalse,
      activity,
      images,
      infos,
      productListData,
      typeListData,
      siwperData,
      advertData,
      searchTxt,
      showDiaFlag,
      showDrawFlag,
      drawTxtMsg,
      advertMapData,
      inWhiteList,
      showSoldierPop,
      showSoldierAgreement,
      soldierType,
      soldierPopConfirm,
      soldierPopCancel,
      soldierAgreementConfirm,
      soldierAgreementCancel,
      goTypePage,
      goPublicWelfare,
      goMore,
      goDetail,
      goSwiper,
      addInfo,
      initInfo,
      searchAction,
      getData,
      goCompany,
      closeDialogAction,
      show
    }
  }
}
</script>
<style lang="scss">
.windowAct {
  width: 6rem;
  height: 7.0rem;

  .windowAct-title {
    text-align: center;
    line-height: 2.5rem;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.4rem;
    height: 2.5rem;
    background: #FF512F;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FF512F #F09819);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FF512F, #F09819,);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .windowAct-cont {
    padding: 0.4rem;
    height: 200px;

    > div {
      height: 0.8rem;
      // line-height: 80px;
      // line-height: 2rem;
      display: flex;
      color: #333333;
      font-size: 0.33rem;

      > div:first-of-type {
        width: 2.08rem;
      }
    }


  }

}

.home {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.18rem 0.3rem 1rem;

  .input-box {
    padding-left: 0.36rem;
    height: 0.9rem;
    border-radius: 0.45rem;
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 0.2rem;

    .img {
      width: 0.25rem;
      height: 0.27rem;
      margin-right: 0.19rem;
    }

    .input {
      @include fontSC(0.28rem, #333);
      height: 100%;

      &::-webkit-input-placeholder {
        color: #999;
      }
    }
  }

  .swiper-box {
    border-radius: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }

  .van-swipe-item {
    height: 3.8rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .van-swipe__indicator {
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }

  .sec {
    position: relative;
    height: 1.56rem;
    border-radius: 0.04rem;
    overflow: hidden;
    margin-bottom: 0.22rem;
    padding: 0.22rem 0.37rem 0 0.19rem;
    background: #fff;

    .con {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        align-items: center;

        // width: 4.78rem;
        .notice {
          width: 0.73rem;
          height: 0.55rem;
          margin-right: 0.25rem;
        }

        .title {
          @include fontSC(0.28rem, #111);
          line-height: 1.3em;
          width: 3.1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .r {
        width: 1.56rem;
        line-height: 0.5rem;
        text-align: center;
        background: #fb3e28;
        border-radius: 0.05rem;
        @include fontSC(0.26rem, #fff);
      }
    }

    .img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .more {
      position: absolute;
      left: 50%;
      bottom: 0.23rem;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      @include fontSC(0.24rem, #666);

      img {
        margin-left: 0.09rem;
        width: 0.1rem;
        height: 0.18rem;
      }
    }
  }

  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
      width: 4.8rem;
      border-radius: 0.2rem;
      background: #fff;

      .drawimg {
        width: 4.8rem;
        height: 6.06rem;
      }

      .drawbot {
        .title {
          position: absolute;
          top: 3.5rem;
          left: 0;
          width: 100%;
          text-align-last: center;
          @include fontSC(0.36rem, #fff);
          line-height: 1.3em;
        }

        .btn {
          position: absolute;
          bottom: 0.52rem;
          left: 50%;
          width: 2.8rem;
          line-height: 0.88rem;
          transform: translateX(-50%);
          background: #fff;
          border-radius: 0.5rem;
          text-align: center;
          @include fontSC(0.48rem, #b16d24);
        }
      }

      .img {
        position: absolute;
        width: 2.94rem;
        height: 2.67rem;
        @include position-middle(-0.55rem);
        z-index: 1;
      }

      .top {
        position: relative;
        width: 100%;
        height: 2.71rem;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        overflow: hidden;
        background: url(../assets/images/home/dialog-bg.png) 100% 100% no-repeat;
        background-size: cover;
      }

      .bot {
        padding: 0.43rem 0.3rem 0.32rem;
        text-align: center;

        .title {
          @include fontSC(0.3rem, #333);
          line-height: 1.5em;
        }

        .memo {
          @include fontSC(0.25rem, #999);
          line-height: 1.5em;
        }

        .btn {
          margin: 0.38rem auto 0;
          width: 2.35rem;
          text-align: center;
          line-height: 0.68rem;
          @include fontSC(0.34rem, #fff);
          background: #fe5631;
          box-shadow: 0px 0.06rem 0px 0px rgba(253, 100, 76, 0.3);
          border-radius: 0.34rem;
        }
      }

      .close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.74rem;
        width: 0.42rem;
        height: 0.42rem;

        img {
          width: 100%;
        }
      }
    }
  }

  .type-list {
    @include w-box();
    padding: 0.43rem 0.21rem 0.47rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.64rem;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0.46rem;
      margin-top: 0.52rem;
      min-width: 1.26rem;

      &:nth-child(-n + 4) {
        margin-top: 0;
      }

      &:nth-child(-n+3) {
        margin-right: 0.24rem;
      }

      &:nth-child(n + 5) {
        margin-right: 0.21rem;

        img {
          // width: 1rem;
          // height: 0.9rem;
        }
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      img {
        display: block;
        width: 0.92rem;
        height: 0.83rem;
      }

      .des {
        margin-top: 0.25rem;
        @include fontSC(0.28rem, #666);
      }
      &.shuangyong {
        .des {
          @include fontSC(0.22rem, #666);
        }
      }
    }
  }

  .product-list {
    .title {
      display: flex;
      align-items: center;
      @include fontSC(0.34rem, #323232);
      margin-bottom: 0.42rem;

      img {
        width: 0.35rem;
        height: 0.33rem;
        margin-right: 0.16rem;
      }
    }
  }
}
</style>
