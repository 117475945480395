<template>
  <div class="updatePw-page">
    <div class="logo"><img src="@/assets/images/login/logo.png" alt="" /></div>
    <section>
      <div class="input-box">
        <div class="input-item">
          <img class="img" src="@/assets/images/login/password.png" alt="" />
          <input v-model="password" placeholder="请输入密码" />
        </div>
        <div class="input-item">
          <img class="img" src="@/assets/images/login/password.png" alt="" />
          <input v-model="confirmPassword" placeholder="请输入新密码" />
        </div>

      </div>

      <div class="btn" @click="updatePassword">确认</div>
    </section>


  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import { useRouter } from 'vue-router'
import mixin from "@/mixin/mixin.js";

export default defineComponent({
  components: {
  },
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const password = ref("");
    const confirmPassword = ref("");

    const { showAlert, showConfirm } = mixin();

    onMounted(() => { });

    const updatePassword = async () => {
      const state = [password.value, confirmPassword.value]
      if (!Object.keys(state).every(item => state[item])) {
        showAlert({ content: '必填项不能为空' })
        return
      }
      let postData = {
        uid: localStorage.uid,
        password: password.value,
        confirmPassword: confirmPassword.value
      };
      const data = await UserService.updatePassword(postData);
      if (!data) return;
      showAlert({
        content: "修改成功",
        confirmFn: () => {
          router.replace('/login')
        },
      });

    };

    return {
      password,
      confirmPassword,
      updatePassword,
    };
  }
});
</script>
<style lang="scss">
.updatePw-page {
  min-height: 100vh;

  .logo {
    margin: 0.62rem auto 0.72rem;
    width: 2rem;
    height: 2rem;

    img {
      display: block;
      width: 100%;
    }
  }

  .input-box {
    padding: 0 0.29rem;

    .input-item {
      display: flex;
      align-items: center;
      height: 1.04rem;
      padding-left: 0.1rem;
      @include border-1px (#ddd, bottom);

      .img {
        width: 0.32rem;
        height: 0.37rem;
        margin-right: 0.2rem;
      }

      .block {
        width: 0.32rem;
        height: 0.37rem;
      }

      input {
        flex: 1;
        height: 100%;
        padding-left: 0.55rem;
        padding-right: 0.32rem;
        @include fontSC(0.3rem, #333);

        //   text-align: center;
        &::-webkit-input-placeholder {
          //   text-align: center;
          color: #C4C4C4;
        }
      }

      &.valid-item {
        input {
          padding-left: 1rem;
        }

        .get-code {
          width: 1.6rem;
          text-align: center;
          line-height: 0.58rem;
          border: 1px solid #FB3E28;
          border-radius: 0.45rem;
          @include fontSC(0.24rem, #FB3E28);
        }

        .sec {
          @include fontSC(0.24rem, #FB3E28);
        }
      }
    }
  }

  .btn {
    margin: 1.53rem auto 0;
    width: 6.9rem;
    text-align: center;
    line-height: 0.9rem;
    @include fontSC(0.34rem, #fff);
    background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
    box-shadow: 0px 0.26rem 0.59rem 0px rgba(255, 54, 81, 0.22);
    border-radius: 0.45rem;
  }

}</style>
