<template>
  <div class="forget-page">
    <div class="logo"><img src="@/assets/images/login/logo.png" alt="" /></div>
    <section>
      <div class="input-box">

          <div class="input-item">
              <img class="img" src="@/assets/images/login/name.png" alt="" />
              <input v-model="entryName"  placeholder="请输入公司名称" />
          </div>
          <div class="input-item">
              <img class="img" src="@/assets/images/login/xy.png" alt="" />
              <input v-model="certId"  placeholder="请输入营业执照统一社会信用代码" />
          </div>
          <div class="input-item">
              <img class="img" src="@/assets/images/login/phone.png" alt="" />
              <input v-model="password"  placeholder="请输入新密码" />
          </div>
           <div class="input-item">
              <img class="img" src="@/assets/images/login/valid.png" alt="" />
              <input v-model="confirmPassword"  placeholder="请确认新密码" />         
          </div>    
      </div>
      <div class="btn" @click="findPassword">确认</div>
    </section>

  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import sms from "./smsCode.js";
import { useRouter } from 'vue-router'
import mixin from "@/mixin/mixin.js";


export default defineComponent({
  components: {
 
  },
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showAlert, showConfirm } = mixin();

    const mobile = ref("");
    const certId = ref("");
    const entryName = ref("");
    const password = ref("");
    const confirmPassword = ref("");

    const { getCode, auth_time, sendAuthCode, smscode } = sms(mobile);

    onMounted(() => {});

    const findPassword = async () => {
        const state = [entryName.value, certId.value, password.value, confirmPassword.value]
      if(!Object.keys(state).every(item=>state[item])){
          showAlert({content: '必填项不能为空'})
          return
      }
      let postData = {
         name: entryName.value,
        certId: certId.value,
          password: password.value,
          confirmPassword: confirmPassword.value,
        };
      const data = await UserService.findPassword(postData);
      if (!data) return;
       showAlert({
        content: "修改成功",
        confirmFn: () => {
          router.replace('/login')
        },
      });
    };

    const checkTab = type => {
      userType.value = type;
    };
    return {
      mobile,
      password,
      confirmPassword,
      checkTab,
        entryName,
        certId,
      findPassword,
      getCode, auth_time, sendAuthCode, smscode
    };
  }
});
</script>
<style lang="scss">
.forget-page {
  min-height: 100vh;
  .logo {
    margin: 0.62rem auto 0.72rem;
    width: 2rem;
    height: 2rem;
    img {
      display: block;
      width: 100%;
    }
  }
  .input-box{
      padding: 0 0.29rem;
      .input-item{
          display: flex;
          align-items: center;
          height: 1.04rem;
          padding-left: 0.1rem;
          @include border-1px (#ddd, bottom);
          .img{
              width: 0.32rem;
              height: 0.37rem;
              margin-right: 0.54rem;
          }
          .block{
            width: 0.32rem;
            height: 0.37rem; 
          }
          input{
              flex: 1;
              height: 100%;
              padding-right: 0.32rem;
              @include fontSC(0.3rem, #333);
            //   text-align: center;
              &::-webkit-input-placeholder { 
                //   text-align: center;
                color: #C4C4C4; 
            } 
          }
          &.valid-item{
              input{
                  padding-left: 1rem;
              }
              .get-code{
                 width: 1.6rem;
                text-align: center;
                line-height: 0.58rem;
                border: 1px solid #FB3E28;
                border-radius: 0.45rem;
                  @include fontSC(0.24rem, #FB3E28);
              }
              .sec{
                  @include fontSC(0.24rem, #FB3E28);
              }
          }
      }
  }
  .btn {
    margin: 1.53rem auto 0;
    width: 6.9rem;
    text-align: center;
    line-height: 0.9rem;
    @include fontSC(0.34rem, #fff);
    background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
    box-shadow: 0px 0.26rem 0.59rem 0px rgba(255, 54, 81, 0.22);
    border-radius: 0.45rem;
  }
 
}
</style>
