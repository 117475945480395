import Axios from 'axios'
import router from '@/router'
import { showAlert } from '@/utils/popup.js'
import store from '@/store'

/**
 * get status code
 * @param {AxiosResponse} response Axios  response object
 */
const getErrorCode2text = (response) => {
  /** http status code */
  const code = response.status
  /** notice text */
  let message = 'Request Error'
  switch (code) {
    case 400:
      message = 'Request Error'
      break
    case 401:
      message = 'Unauthorized, please login'
      break
    case 403:
      message = '拒绝访问'
      break
    case 404:
      message = '访问资源不存在'
      break
    case 408:
      message = '请求超时'
      break
    case 500:
      message = '位置错误'
      break
    case 501:
      message = '承载服务未实现'
      break
    case 502:
      message = '网关错误'
      break
    case 503:
      message = '服务暂不可用'
      break
    case 504:
      message = '网关超时'
      break
    case 505:
      message = '暂不支持的 HTTP 版本'
      break
    default:
      message = '位置错误'
  }
  return message
}

const service = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || 'https://www.shuzhixinhua.cn:8083',
  //   baseURL: process.env.VUE_APP_BASE_URL || 'http://aianna.net:8082',
  //   baseURL: process.env.VUE_APP_BASE_URL || 'http://10.241.80.49:8082',
  timeout: 10000
})

service.interceptors.request.use(async (config) => {
  const token = localStorage.tokenId
  const uid = localStorage.uid
  // console.log(config)
  if (config.url.indexOf('login') === -1 && token) {
    config.headers.token = token
    config.headers.uid = uid
  }
  return config
})

/**
 * @description 响应收到后的拦截器
 * @returns {}
 */
service.interceptors.response.use(
  async (response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      const __text = getErrorCode2text(response)
      return Promise.reject(__text)
    }
  },
  (error) => {
    let __emsg = error.message || ''

    if (error.message) {
      __emsg = error.message
    }

    if (error.response) {
      __emsg = error.response.data.errorCodeDes ?
        error.response.data.errorCodeDes :
        error.response.data.data
    }
    if (__emsg.indexOf('timeout') >= 0) {
      __emsg = 'timeout'
    }
    return Promise.reject(__emsg)
  }
)

export const get_http = async (url, params = {}) => {
  try {
    store.commit('setLoading', true)
    const {
      data
    } = await service(url, {
      method: 'get',
      responseType: 'json',
      params: params
    })
    store.commit('setLoading', false)
    if (data.resultCode === 'SUCCESS') {
      return Promise.resolve(data)
    } else {
      if (data.errorCode === 'TOKEN_INVALID') {
        localStorage.tokenId = ''
        return router.replace('/login')
      }
      if (data.errorCode === 'GREATE_LIMIT_GREATE ') {
        return Promise.resolve(data)
      }
      if (data.errorCode === 'USER_NEED_COMPLETE') {
        return store.commit('setShowDialog', true)
        // return showAlert({content: data.errorCodeDes,confirmFn: ()=>{
        //     const name = userType==='persion'?'addInfo' : 'addInfoQy'
        //     return router.replace({name})
        // } })
      }

      showAlert({
        content: data.errorCodeDes
      })
      return Promise.resolve('')
    }
  } catch (error) {
    store.commit('setLoading', false)
    showAlert({
      content: '网络超时，请稍后重试'
    })
    // return Promise.reject(new Error('401'))
  }
}
export const post_http = async (url, params) => {
  try {
    store.commit('setLoading', true)
    const {
      data
    } = await service(url, {
      method: 'post',
      responseType: 'json',
      data: params
    })
    store.commit('setLoading', false)
    if (data.resultCode === 'SUCCESS') {
      return Promise.resolve(data)
    } else {
      if (url.indexOf('draw/qryDrawing') > -1) return Promise.resolve(data)
      if (data.errorCode === 'TOKEN_INVALID') {
        localStorage.tokenId = ''
        return router.push('/login')
      }
      if (data.errorCode === 'GREATE_LIMIT_GREATE ') {
        return Promise.resolve(data)
      }
      if (data.errorCode === 'USER_NEED_COMPLETE') {
        return store.commit('setShowDialog', true)
        // return showAlert({content: data.errorCodeDes,confirmFn: ()=>{
        //     const name = userType==='persion'?'addInfo' : 'addInfoQy'
        //     return router.replace({name})
        // } })
      }

      showAlert({
        content: data.errorCodeDes
      })
      return Promise.resolve('')
    }
  } catch (error) {
    store.commit('setLoading', false)
    console.log(error)

    showAlert({
      content: '网络超时，请稍后重试'
    })
  }
}
export  const zucan='http://www.yingyuantech.com:10004';
export default service