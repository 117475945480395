<template>
  <div class="detail-page">
    <van-swipe :autoplay="5000" lazy-render indicator-color="">
      <van-swipe-item v-for="image in images" :key="image">
        <img :src="image" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import { Swipe, SwipeItem } from "vant";

import { setTimeout } from "timers";

export default defineComponent({
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  props: {
    images: {
      type: Array,
      default: []
    }
  },
  setup(props, { emit }) {
    const { listData } = toRefs(props);
    const listBoxL = ref(null);

    // const images = [
    //   'https://img.yzcdn.cn/vant/apple-1.jpg',
    //   'https://img.yzcdn.cn/vant/apple-2.jpg',
    // ];

    const state = reactive({
      listData1: [],
      listData2: [],
      loading: false,
      finished: false,
      refreshing: false
    });

    onMounted(() => {
      
    });


    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
    };



    return {
      state,
      listBoxL,
      onRefresh,
      // images,
    };
  }
});
</script>
<style lang="scss">
.detail-page{
  .van-swipe-item{
    height: 6.6rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .van-swipe__indicator{
    width: 0.14rem;
    height: 0.04rem;
    border-radius: 0;
    bottom: 0.35rem;
  }
}
</style>
