<template>
    <div class="soldierlist-page">
        <div class="page-cont">
            <div class="page-title">如何申领退役军人优待证？</div>
            <div class="content-page">
                <!-- <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);" @click="conList"></div> -->

                <van-collapse v-model="activeNames" accordion>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="1">

                        <template #title>
                            <div class="titles">什么是优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证是中华人民共和国退役军人优待证和中华人
                            民共和国烈士、因公牺牲军人、病故军人遗属优待
                            证的简称，由退役军人事务部统一制发，是持证人
                            彰显荣誉的载体、享受优待的凭证。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="2">
                        <template #title>
                            <div class="titles">优待证有几种类型？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证有两种类型，分别为中华人民共和国退役军人优待证和中华人民共和国烈士遗属、因公牺牲军人遗属、病故军人遗属优待证。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="3">
                        <template #title>
                            <div class="titles">哪些人可以申领优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            符合条件的退役军人和烈士、因公牺牲军人、病故军人遗属。其中：退役军人是指从中国人民解放军依法退出现役的军官、军士和义务兵等人员；中国人民武装警察部队依法退出现役的警官、警士和义务兵等人员符合申领条件；烈士、因公牺牲军人、病故军人遗属是指烈士、因公牺牲军人、病故军人的配偶、父母（抚养人）、子女，以及由其承担抚养义务的兄弟姐妹。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="4">
                        <template #title>
                            <div class="titles">申请人处于哪些情形下，不能申领优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            根据《退役军人、其他优抚对象优待证管理办法（试行）》中的相关规定：申请人有下列情形之一的，申请人的审核不予通过。1.服役期间被部队除名、开除军籍的；
                            2.处于剥夺政治权利期限内的；
                            3.处于服刑、羁押、通缉期间的。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="5">
                        <template #title>
                            <div class="titles">申请人存在违法犯罪、被开除中国共产党党籍、被开除公职或者存在严重影响身份荣誉等情形的，可以申请优待证吗？？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            可以提出申请。其优待证申请由上海市退役军人事务局综合考虑相关因素进行审核。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="6">
                        <template #title>
                            <div class="titles">已成为港、澳特别行政区居民的退役军人、其他优抚对象能否申请优待证？？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证的申领对象是中国境内户籍的退役军人或烈士遗属、因公牺牲军人遗属、病故军人遗属。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="7">
                        <template #title>
                            <div class="titles">长期居住在国外的退役军人、其他优抚对象能否申请优待证？？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            如仍保留中国国籍，可以申请优待证。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="8">
                        <template #title>
                            <div class="titles">凭优待证能享受哪些优待？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            目前，持证人凭优待证可以享受四方面的优待：
                            一是国家提供的面向全国持证人的优待服务，主要以退役军人事务部等20部门《关于加强军人军属、退役军人和其他优抚对象优待工作的意见》及基本优待目录清单中明确的可使用优待证的优待服务。
                            二是退役军人事务部与相关合作单位签署的各优待类协议所涉及服务。
                            三是上海市提供的面向本地或全国持证人的优待服务，主要以《上海市退役军人事务局等28部门关于进一步做好本市军人军属、退役军人和其他优抚对象优待工作的实施意见》及基本优待目录清单中所明确的优待服务。
                            四是社会各界主动提供的优待服务。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="9">
                        <template #title>
                            <div class="titles">非沪籍但常住地为上海的退役军人、其他优抚对象是否可以申领上海发放的优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            符合条件的非沪籍但常住地为上海的退役军人、其他优抚对象可以申领上海发放的优待证。具体申领条件正在研究中，适时向社会公布。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="10">
                        <template #title>
                            <div class="titles">非沪籍退役军人、其他优抚对象是否可以在上海申请户籍地发放的优待证吗？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            可以。有关时间及相关要求，请您关注上海市退役军人事务局官网和官微。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="11">
                        <template #title>
                            <div class="titles">上海市户籍的退役军人、其他优抚对象是否可以在上海申请外省市发放的优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            不可以。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="12">
                        <template #title>
                            <div class="titles">我市优待证申领有哪些方式？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            携带相关材料前往户籍地或常住地街道（乡镇）退役军人服务站提出申请。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="13">
                        <template #title>
                            <div class="titles">申请人为双重或多重身份，既是退役军人又是“三属”，是否可以同时申请两种优待证？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            两种优待证申领条件均符合的申请人，可根据意愿申领其中一种。具有双重或多重身份的对象，其相关身份均写入优待证芯片，按规定享受相应的优待服务。在后续使用过程中，持证人可根据自己意愿交回已领证件，换领另一种优待证。
                            十六、申领优待证必须由本人去申请吗？
                            建议最好由本人去申请。无民事行为能力或限制民事行为能力人，需由监护人提出申请。对于确有原因无法本人去申请的，可以委托他人申请。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="14">
                        <template #title>
                            <div class="titles">申领优待证必须由本人去申请吗？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            建议最好由本人去申请。无民事行为能力或限制民事行为能力人，需由监护人提出申请。对于确有原因无法本人去申请的，可以委托他人申请。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="15">
                        <template #title>
                            <div class="titles">优待证在申领前需做哪些准备？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            在优待证申领前，退役军人和其他优抚对象必须根据退役军人事务部的统一要求，先期完成个人的建档立卡工作，这是办理优待证必须完成的前置条件。为了节约时间，提高效率，在办理建档立卡时需要提前准备好相关材料。
                            退役军人建档立卡所需材料：
                            （1）身份证；
                            （2）户口簿；
                            （3）转业证、退伍证、军队离退休证、复员证等退役军人身份证明材料；
                            （4）中华人民共和国残疾军人证、立功受奖材料等其他所需材料或证明。
                            烈士、因公牺牲军人、病故军人遗属建档立卡所需材料，除身份证、户口簿还需准备：
                            （1）烈士证明书或烈士光荣证、军人因公牺牲证明书、军人病故证明书；
                            （2）与烈士、因公牺牲军人、病故军人关系的证明材料，包括本人档案、户籍证件或户籍档案、结婚证、出生证明、独生子女证等明显能够证明申请人与烈士、因公牺牲或病故军人关系的材料。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="16">
                        <template #title>
                            <div class="titles">委托他人申请需提供的材料</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            1.申请人身份证等相关材料；
                            2.监护人身份证及监护证明等。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="17">
                        <template #title>
                            <div class="titles">优待证的照片有哪些要求？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            申请人提供的优待证照片应符合以下要求：
                            1.近期1寸白底免冠电子相片；
                            2.白底、无边框；
                            3.照片内容为正面免冠彩色头像，人像轮廓及面部特征点清晰可见，着装颜色应区别于白色背景；
                            4.照片中无明显商标信息；
                            5.常戴眼镜者应佩戴眼镜，但必须将双眼清楚显现出来，眼镜不能反光或为有色镜片；
                            6.确保人像面部可见，不允许有遮挡面部的覆盖物、头发、头饰或脸部饰物（如头巾等），因宗教或医学因素不能摘除的，应保证人像面部特征可见；
                            7.照相者坐姿端正，人像露双肩和双耳，双肩等高平行，双眼睁开正视前方；
                            8.照片清晰、脸部布光均匀、不偏色。
                            9.如提供电子照片，请确保照片中人脸位置居中、人脸占比2/3左右。照片为24位RGB真彩色，大小20-50KB，DPI为300，分辨率为352*440像素。
                            10.不可使用制服照（如军装照，警察、消防等的制服照） </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="18">
                        <template #title>
                            <div class="titles">优待证制作好之后，需要本人去拿吗？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            制作好的优待证由受理申请的退役军人服务站通过主动送达、集体颁发以及双方约定的其他方式发放。 </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="19">
                        <template #title>
                            <div class="titles">退役军人优待证与烈士遗属、因公牺牲军人遗属、病故军人遗属优待证在功能上是否有区别？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            两种优待证在功能上没有区别，均是持证人彰显荣誉的载体、享受优待的凭证。按照《退役军人保障法》规定，退役军人的政治、生活等待遇与其服现役期间所做贡献挂钩。持有优待证的退役军人、其他优抚对象享受的优待服务按照贡献不同进行区分。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="20">
                        <template #title>
                            <div class="titles">申领优待证是否收费？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            首次申领优待证免费。因优待证卡片质量问题造成无法使用、金融功能有效期到期、证面信息需要变更、户籍地或常住地省份发生变化、符合申领两种优待证条件的需变更优待证类别的，可免费更换。如因遗失、人为损坏、逾期未领取等需要补领或更换的，相关费用按照制证银行有关规定执行。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="21">
                        <template #title>
                            <div class="titles">优待证申请之后多久能拿到？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            申请之后，需要一段时间才能拿到优待证。
                            从申请提交到收到优待证，有很多环节，包括建档立卡、申请信息材料核实、审核、制作，以及分送运输到退役军人服务站等，需要一定的时间。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="22">
                        <template #title>
                            <div class="titles">优待证如何激活使用？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            持证人携带本人有效身份证件和优待证到制证银行任意网点激活金融账户。如果持证人是未成年人或限制行为能力人，需由监护人携带本人与未成年人/限制行为能力人的身份证件以及监护关系证明等到柜面激活金融账户。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="23">
                        <template #title>
                            <div class="titles">优待证是否可以给其他人使用？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证仅限持证人本人使用，不得买卖、出租、出借。使用优待证享受优待服务时，应主动出示。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="24">
                        <template #title>
                            <div class="titles">优待证遗失了怎么办？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证遗失的，持证人应先联系合作银行进行挂失，并在挂失后第一时间告知受理申请的街道（乡镇）退役军人服务站。优待证挂失后，持证人可到街道（乡镇）退役军人服务站或原合作银行网点申请补领。
                        </div>
                        <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    </van-collapse-item> <van-collapse-item name="25">
                        <template #title>
                            <div class="titles">优待证能否更换？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证出现特定情形可以进行更换。《退役军人、其他优抚对象优待证管理办法（试行）》明确了可以更换的情形以及到哪里更换。持证人在申请更换优待证时，须交回原持有的优待证。</div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="26">
                        <template #title>
                            <div class="titles">出现哪些情形，优待证会被回收？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            出现以下几种情形优待证将会被回收：
                            1.伪造、变造、买卖、出租、出借优待证的；
                            2.使用虚假证明材料骗领优待证的；
                            3.户籍注销的；
                            4.被剥夺政治权利的；
                            5.处于服刑、羁押、通缉期间的；
                            6.被开除中国共产党党籍或者被开除公职的；
                            7.存在严重影响身份荣誉的其他情形的。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="27">
                        <template #title>
                            <div class="titles">之前领了地方退役军人事务部门发的拥军卡等，这次还可以申请优待证吗？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            可以申请。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="28">
                        <template #title>
                            <div class="titles">退伍证丢失是否影响申领？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            找到原部队进行补办；如在档案中能查询到其相关退役信息的可以申领。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="29">
                        <template #title>
                            <div class="titles">优待证申领期间，是否影响现有残疾军人证和优待证的使用？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证的发放不影响《中华人民共和国残疾军人证》的使用；现有《上海市烈属优待证》《上海市因公牺牲军人遗属优待证》《上海市病故军人遗属优待证》《上海市重点优抚对象医疗优待证》至2022年12月31日前继续有效。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="30">
                        <template #title>
                            <div class="titles">优待证是否需要进行年审？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证没有使用年限。但是，由于优待证以银行借记卡为载体，按照银行规定，借记卡有一定的有效期。有效期到期后，持证人应前往合作银行营业网点提出换领申请。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="31">
                        <template #title>
                            <div class="titles">使用优待证应该注意什么？</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            优待证是荣誉的象征，持证人应予以爱惜。对违规使用优待证的，或者做出有损退役军人和其他优抚对象形象的行为，且经批评、教育仍不改正的，我市退役军人事务部门可以依规取消其优待资格，收回优待证。发现涉嫌违法犯罪的，依法协调相关部门处理。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="32">
                        <template #title>
                            <div class="titles">本市退役军人、其他优抚对象优待证合作银行有哪些?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            本市首批合作银行包括中国工商银行、中国农业银行。
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="33">
                        <template #title>
                            <div class="titles">合作银行是否设置专门窗口及金融功能电话咨询服务?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            合作银行为持证退役军人、其他优抚对象提供全行网点优先服务，持证人可优先办理各类业务(包括激活业务)。同时，还提供优待证申领咨询、金融功能咨询服务，服务咨询电话是：
                            工商银行服务咨询电话：95588-8
                            农业银行服务咨询电话：95599-8
                        </div>
                    </van-collapse-item>
                    <div style="height: 1px;border: 1px solid #E5F0FF; transform: scaleY(0.5);"></div>
                    <van-collapse-item name="32">
                        <template #title>
                            <div class="titles">申请人选择制证银行时需要注意哪些事项?</div>
                        </template>
                        <div style="line-height: 0.55rem;font-size: 0.28rem;">
                            申请人应注意自己在准备选择的制证银行是否有4张及以上的借记卡。
                            对申请人已持有所选制证银行4张及以上借记卡的，需要由申请人自行联系相关银行，将现持有的借记卡数量减少至3张及以下，方可申请优待证成功。否则，将无法激活优待证金融账户功能或不能通过银行核验程序完成制证。
                            目前，中国工商银行、中国农业银行可先制卡，在持证人激活优待证金融账户功能时进行审核，如持证人持卡数量超过4张，可通过旧卡换新卡或将持卡数量减少至3张及以下，方可激活优待证金融账户功能。
                        </div>
                    </van-collapse-item>
                </van-collapse>


            </div>
        </div>
    </div>
</template>
  
<script>
import {
    defineComponent,
    computed,
    reactive,
    ref,
    onMounted,
    toRefs
} from 'vue'
import { useStore } from 'vuex'
import soldierlists from '@/components/soldierlists.vue'
import { NoticeBar, Icon, Collapse, CollapseItem } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import mixin from '@/mixin/mixin.js'

export default defineComponent({
    components: {
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    props: {},
    setup(props) {
        const { showAlert, showConfirm } = mixin()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()


        const activeNames = ref('1');


        return {
            activeNames,
        }
    }
})
</script>
<style lang="scss" scoped>
.titles {
    height: 15vw;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.35rem;
    font-weight: 700;
}

.soldierlist-page {
    min-height: 100vh;
    padding: 0.26rem;
    background: #F4F4F4;

    .page-cont {
        background-color: #FFFFFF;
        // height: 40vh;
        border-radius: 5px;


        .page-title {
            font-weight: 800;
            color: #FF4C3C;
            font-size: 0.38rem;
            padding: 0.26rem 0.26rem 0rem 0.26rem;

        }
    }
}

.content-page {
    margin-top: 0.4rem;
}

.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.8rem;
    // width: 80vw;
    padding: 0.26rem;

    >div {
        font-weight: 400;
        color: #333333;
        font-size: 0.35rem;
    }
}
</style>
  