<template>
  <div class="login-page">
    <div class="tabs">
      <div
        class="item"
        :class="{ curr: userType === 'persion' }"
        @click="checkTab('persion')"
      >
        个人用户
      </div>
      <div
        class="item"
        :class="{ curr: userType === 'enterprise' }"
        @click="checkTab('enterprise')"
      >
        单位用户
      </div>
      <!-- <div class="item" :class="{ curr: userType === 'special' }" @click="checkTab('special')">
        智慧站长
      </div> -->
    </div>

    <section v-show="userType === 'persion'">
      <div class="logo">
        <img src="@/assets/images/login/logo.png" alt="" />
      </div>
      <div class="input-box">
        <div class="input-item">
          <img class="img" src="@/assets/images/login/phone.png" alt="" />
          <input v-model="mobile" placeholder="手机号码" />
          <!-- <span class="block"></span> -->
        </div>
        <div class="input-item valid-item">
          <img class="img" src="@/assets/images/login/valid.png" alt="" />
          <input v-model="smscode" placeholder="请输入验证码" />
          <span v-show="sendAuthCode" @click="getCode" class="get-code"
            >发送验证码</span
          >
          <span v-show="!sendAuthCode" class="sec">{{ auth_time }}s后获取</span>
        </div>
      </div>
      <!-- <van-field v-model="mobile" required label="手机号" placeholder="请输入手机号" error-message="手机号格式错误" />
        <van-field v-model="smscode" required label="验证码" placeholder="请输入验证码" error-message="验证码格式错误" />
         <span v-show="sendAuthCode" @click="getCode" class="get-code">获取验证码</span>
         <span v-show="!sendAuthCode" class="get-code">{{auth_time}}s后获取</span>
      </van-cell-group> -->
      <div class="btn" @click="login">立即登录</div>
    </section>
    <section v-show="userType === 'enterprise'">
      <div class="logo">
        <img src="@/assets/images/login/logo.png" alt="" />
      </div>
      <div class="input-box">
        <div class="input-item">
          <img class="img" src="@/assets/images/login/phone.png" alt="" />
          <input v-model="username" placeholder="请输入账户" />
        </div>
        <div class="input-item">
          <img class="img" src="@/assets/images/login/password.png" alt="" />
          <input v-model="password" placeholder="请输入您的账户密码" />
        </div>
      </div>
      <div class="forget" @click="goForget">忘记密码？</div>

      <div class="btn" @click="login">立即登录</div>
      <div class="register">
        没有账户，点击<span class="t" @click="goRegister">去注册</span>
      </div>
    </section>
    <!-- <section v-show="userType === 'special'">
      <div class="Index">

        <div class="Headerimg"><img src="../../assets/images/mima(3).png" alt=""></div>
        <div class="HeaderPhone">
          <div class="HeaderPhone1">
            <div><img src="../../assets/images/mima(2).png" alt=""></div>
            <div><input type="text" v-model="username" placeholder="请输入账号"></div>
          </div>
          <div class="HeaderPhone1">
            <div><img src="../../assets/images/mima(1).png" alt=""></div>
            <div><input type="text" v-model="password" placeholder="请输入密码"></div>
          </div>
        </div>
        <div class="Headerbut">
          <button id="login" @click="login2">登录</button>
        </div>
      </div>

    </section> -->
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import sms from "./smsCode.js";
import { useRouter, useRoute } from "vue-router";
import Validator from "@/utils/validate.js";
import mixin from "@/mixin/mixin.js";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant";
// import { Field, CellGroup, Checkbox, CheckboxGroup } from "vant";

export default defineComponent({
  components: {
    // [Field.name]: Field,
    // [CellGroup.name]: CellGroup,
  },
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const type = route.query.type == "2" ? "enterprise" : "persion";

    const checked = ref(true);
    const userType = ref(type);
    const mobile = ref("");
    const password = ref("");
    const username = ref("");

    const { showAlert, showConfirm } = mixin();

    const { getCode, auth_time, sendAuthCode, smscode } = sms(mobile);
    sessionStorage.jumpWindow = "";
    onMounted(() => {
      // logReuse()
      if (sessionStorage.linkeyUserId)
        swapUserData(sessionStorage.linkeyUserId);
      // if (localStorage.token1 != undefined) {
      //   router.push("/reuseLogin");
      // }
    });
    // const logReuse = async () => {

    //   let leave = moment().diff(moment(localStorage.getItem('time'), 'YYYY-MM-DD HH:mm:ss'), 'day');
    //   // var leave = moment().diff(moment('2023-06-01 00:00:00', 'YYYY-MM-DD HH:mm:ss'), 'day');
    //   let usname = localStorage.getItem('username');
    //   let word = localStorage.getItem('password');
    //   if (leave <= 7) {
    //     axios.post('https://www.shuzhixinhua.cn:8083/member/login', {
    //       username: usname,
    //       password: word,
    //       "roleManager": "Y",
    //       "userType": "enterprise",
    //     })
    //       .then(res => {
    //         console.log(res)
    //         if (res.data.errorCodeDes == '用户名不存在或者用户密码不匹配') {
    //           console.log("不存此账号密码")
    //           router.push({ path: '/blank' })
    //         } else {
    //           localStorage.setItem('time', moment().format('YYYY-MM-DD HH:mm:ss'));
    //           setTimeout(() => {
    //             router.push('/reuseLogin')

    //           }, 1000);

    //         }

    //         // 将token存储到本地
    //         localStorage.setItem('res', JSON.stringify(res.data));
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });

    //   }
    //   console.log(usname, word, leave)
    // }
    // const login2 = async () => {

    //   console.log(username.value)
    //   if (username.value == '') {
    //     Toast.fail('请输入账号');
    //     return
    //   } else if (password.value == '') {
    //     Toast.fail('请输入密码');
    //     return
    //   }
    //   axios.post('https://www.shuzhixinhua.cn:8083/member/login', {
    //     username: username.value,
    //     password: password.value,
    //     "roleManager": "Y",
    //     "userType": "enterprise",
    //   })
    //     .then(res => {

    //       console.log(res)
    //       if (res.data.errorCodeDes == '用户名不存在或者用户密码不匹配') {
    //         console.log("不存此账号密码")
    //       } else {
    //         localStorage.setItem('username', username.value);
    //         localStorage.setItem('password', password.value);
    //         localStorage.setItem('time', moment().format('YYYY-MM-DD HH:mm:ss'));

    //         setTimeout(() => {
    //           console.log('555')
    //           router.push('/reuseLogin')
    //           Toast.success('登录成功');
    //         }, 1000);

    //         // router.go(0)
    //       }

    //       // 将token存储到本地
    //       localStorage.setItem('token1', res.data.token);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    // }
    const swapUserData = async (id) => {
      console.log(id);
      const { token, uid, isComplete, headImage, isCashier } =
        await UserService.swapUserData({
          linkeyUserId: id,
          level: localStorage.level ?? "",
        });
      if (!token) return;
      localStorage.tokenId = token;
      localStorage.uid = uid;
      localStorage.isComplete = isComplete;
      localStorage.userType = userType.value;
      localStorage.headImage = headImage;
      localStorage.isCashier = isCashier;


      store.commit("setUserType", userType.value);
      if (userType.value !== "persion") {
        sessionStorage.jumpWindow = "true";
        return router.push({ path: "/blank" });
      }
      if (isCashier === "true") return router.replace("/hyCenter");
      sessionStorage.jumpWindow = "true";
      router.push({ path: "/blank" });
    };

    const login = async () => {
      localStorage.phone = mobile.value;
      const validAction = function () {
        const validator = new Validator();
        if (userType.value === "persion") {
          validator.add(mobile.value, [
            {
              ruleName: "isNonEmpty",
              errMsg: "手机号不能为空",
            },
            {
              ruleName: "isMobile",
              errMsg: "手机号格式不正确",
            },
          ]);
          validator.add(smscode.value, [
            {
              ruleName: "isNonEmpty",
              errMsg: "验证码不能为空",
            },
          ]);
        } else {
          validator.add(username.value, [
            {
              ruleName: "isNonEmpty",
              errMsg: "用户名不能为空",
            },
          ]);
          validator.add(password.value, [
            {
              ruleName: "isNonEmpty",
              errMsg: "密码不能为空",
            },
            {
              ruleName: "minLength:6",
              errMsg: "密码长度不能小于6位",
            },
            //   {
            //     ruleName: "isPassWord",
            //     errMsg: "密码格式不正确",
            //   },
          ]);
        }

        return validator.start();
      };
      let errMsg = validAction();
      if (errMsg) {
        showAlert({ content: errMsg });
        return;
      }
      if (!checked.value) {
        return showAlert({ content: "请确认阅读协议" });
      }

      let postData;
      if (userType.value === "persion") {
        postData = {
          username: mobile.value,
          password: smscode.value,
        };
      } else {
        postData = {
          username: username.value,
          password: password.value,
        };
      }
      const {
        token,
        uid,
        isComplete,
        headImage,
        isCashier,
        isTodayFirst,
        level,
      } = await UserService.login({
        userType: userType.value,
        ...postData,
      });
      if (!token) return;
      localStorage.tokenId = token;
      localStorage.uid = uid;
      localStorage.isComplete = isComplete;
      localStorage.userType = userType.value;
      localStorage.headImage = headImage;
      localStorage.isCashier = isCashier;
      localStorage.level = level;

      //   const todayFirst = localStorage.isTodayFirst
      //   if(!todayFirst&&isTodayFirst=='true'&&isCashier!=='true') {
      //       localStorage.isTodayFirst = isTodayFirst
      //       return router.push({path: "/blank"});
      //   }

      store.commit("setUserType", userType.value);
      if (userType.value !== "persion") {
        sessionStorage.jumpWindow = "true";
        return router.push({ path: "/blank" });
      }
      if (isCashier === "true") return router.replace("/hyCenter");
      sessionStorage.jumpWindow = "true";
      router.push({ path: "/blank" });
      //   userType.value === "persion"? router.push("/Home"): router.push("/QYHome");
    };

    const goPage = (name) => {
      router.push({ name });
    };
    const goForget = () => {
      router.push("/forget");
    };
    const goRegister = () => {
      router.push("/register");
    };
    const checkTab = (type) => {
      userType.value = type;
    };
    const checkAction = () => {
      checked.value = !checked.value;
    };
    return {
      mobile,
      checked,
      userType,
      username,
      password,
      checkTab,
      login,

      goPage,
      checkAction,
      goForget,
      goRegister,
      getCode,
      auth_time,
      sendAuthCode,
      smscode,
    };
  },
});
</script>
<style lang="scss">
#myIframe {
  // position: fixed;
  //   top: 0;
  //   left: 0;
  width: 100%;
  height: 100vh;
  border: none;
  // margin-top: -10px;
}

.logo {
  margin: 0.62rem auto 0.72rem;
  width: 2rem;
  height: 2rem;

  img {
    display: block;
    width: 100%;
  }
}

.login-page {
  min-height: 100vh;
  padding-top: 0.6rem;

  .tabs {
    margin: 0 1.04rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include fontSC(0.36rem, #999);

    .item {
      &.curr {
        color: #fb3e28;
      }
    }
  }

  .input-box {
    padding: 0 0.29rem;

    .input-item {
      display: flex;
      align-items: center;
      height: 1.04rem;
      padding-left: 0.1rem;
      @include border-1px(#ddd, bottom);

      .img {
        width: 0.32rem;
        height: 0.37rem;
        margin-right: 0.2rem;
      }

      .block {
        width: 0.32rem;
        height: 0.37rem;
      }

      input {
        flex: 1;
        height: 100%;
        padding-right: 0.72rem;
        @include fontSC(0.3rem, #333);
        text-align: center;

        &::-webkit-input-placeholder {
          text-align: center;
          color: #c4c4c4;
        }
      }

      &.valid-item {
        input {
          padding-left: 1rem;
          padding-right: 0;
        }

        .get-code {
          width: 1.6rem;
          text-align: center;
          line-height: 0.58rem;
          border: 1px solid #fb3e28;
          border-radius: 0.45rem;
          @include fontSC(0.24rem, #fb3e28);
        }

        .sec {
          @include fontSC(0.24rem, #fb3e28);
        }
      }
    }
  }

  .forget {
    margin-top: 0.27rem;
    padding-left: 0.33rem;
    @include fontSC(0.3rem, #fb3e28);
  }

  .register {
    margin-top: 0.5rem;
    text-align: center;
    @include fontSC(0.34rem, #999);

    .t {
      color: #fb3e28;
    }
  }

  .btn {
    margin: 1.53rem auto 0;
    width: 6.9rem;
    text-align: center;
    line-height: 0.9rem;
    @include fontSC(0.34rem, #fff);
    background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
    box-shadow: 0px 0.26rem 0.59rem 0px rgba(255, 54, 81, 0.22);
    border-radius: 0.45rem;
  }

  .footer {
    margin-top: 2.2rem;
    // position: absolute;
    // left: 50%;
    // bottom: 0.48rem;
    // transform: translateX(-50%);
    min-width: 5.7rem;
    @include flexCenter();
    @include fontSC(0.22rem, #969696);

    .checkbox {
      width: 0.22rem;
      height: 0.22rem;
      border: 1px solid #ddd;
      border-radius: 0.02rem;
      margin: 0.1rem;

      &.checked {
        background: url(../../assets/images/login/checked.png) center no-repeat;
        background-size: contain;
      }
    }

    .cb {
      width: 0.22rem;
      height: 0.22rem;
      margin-right: 0.14rem;
    }

    b {
      font-weight: 500;
      color: #fb3e28;
    }
  }
}

.Index {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
}

.Header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

  padding-left: 2vw;
  padding-top: 2vw;
}

.HeaderText {
  color: #999999;
  font-weight: 400;
  font-size: 4.8vw;
  /* margin-left: 20vw; */
  margin-top: 0.5vw;
}

.Headerimg {
  text-align: center;
}

.Headerimg img {
  width: 27vw;
  margin-top: 12vw;
  /* margin-left: 6vw; */
}

.HeaderPhone {
  /* padding; */
  padding-top: 15vw;
  /* padding: 15vw 0vw; */
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

.HeaderPhone1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c2c2;
}

.HeaderPhone1 input {
  width: 80vw;
  margin-left: 3vw;
  height: 8vw;
  outline: none;
  border: none;
  // background-color: #f8f8f8;
  color: #1b1a1a;
  font-size: 4vw;
  /* text-indent: 2em; */
}

.HeaderPhone1 ::-webkit-input-placeholder {
  /* color:red; */
  text-indent: 25vw;
}

.HeaderPhone2 input {
  width: 80vw;
  margin-left: 10vw;
  height: 8vw;
  outline: none;
  border: none;
  // background-color: #f8f8f8;
  /* text-align: center; */
  /* margin-left: 20vw; */
  color: #1b1a1a;
  font-size: 4vw;
}

.HeaderPhone2 ::-webkit-input-placeholder {
  /* color:red; */
  text-indent: 17vw;
}

.HeaderPhone > div {
  margin-bottom: 8vw;
  height: 15vw;
}

.HeaderPhone2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c2c2;
}

.Headerbut {
  text-align: center;
  margin-top: 12vw;
}

.Headerbut > button {
  width: 90vw;
  height: 15vw;
  border: none;
  background: linear-gradient(90deg, #fb2e21 0%, #fe6538 100%);
  box-shadow: 0px 26px 59px 0px rgba(255, 54, 81, 0.22);
  border-radius: 45px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  font-size: 5vw;
}

.HeaderPhone img {
  width: 22px;
  margin-left: 25px;
}
</style>
