<template>
  <div class="publicWelfareDetail-page">
     <img class="topimg" v-if="proInfo.activityImg" :src="proInfo.activityImg" alt="">
     <div class="info-box">
      <div class="title">{{proInfo.advertName}}</div>
      <div class="amt">人数限制<span class="t">{{proInfo.persionLimit}}</span></div>
      <!-- <div class="memo"><div class="l">已兑换<span class="t">{{proInfo.selledStock}}</span>份</div><div class="r">剩余<span class="t">{{proInfo.validStock}}</span>份</div></div> -->
    </div>
    <div class="detail-box">
        
      <div class="title">活动详情</div>
      <div class="item" v-html="proInfo.advertContent"></div>
      <!-- <div class="item">
        <b>商品详情：</b><br />
        物品详情物品详情物品详情物品详情物品详情物品详情
        物品详情物品详情物品详情物品详情物品详情物品详情 物品详情物品详
      </div>
      <div class="item">
        <b>保质期时长： </b><br />
        2021-9-15日过期
      </div> -->
      <!-- <div class="item">
        <b>温馨提示： </b><br />
        前往慈善超市购买物品
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import Detail from "@/components/Detail.vue";
import UserService from "@/api/common.js";
import OrderService from "@/api/order.js";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id
    const proInfo = ref({});
    const getData = async() => {

     const data = await UserService.qryActivityDetail({
        uid: localStorage.uid,
        activityId: parseInt(id),
      });
      console.log(data); if (!data) return;
      const { activity } = data;
      proInfo.value = activity
    };

    onMounted(() => {
        getData()
    });

   
    return {
      proInfo,
    };
  }
});
</script>
<style lang="scss">
.publicWelfareDetail-page {
    .topimg{
        width: 100%;
    }
     .info-box {
    padding: 0.45rem 0.31rem 0.45rem;
    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.24rem;
    }
    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;
      .t {
        @include fontSC(0.5rem, #f54029);
      }
    }
    .memo {
        display: flex;
      .l{
          margin-right: 0.54rem;
      }
      .t {
        // @include fontSC(0.5rem, #f54029);
      }
      @include fontSC(0.24rem, #999);
    }
  }
  .detail-box {
    padding: 0 0.3rem 0.52rem;
    @include border-1px(#f2f2f2, bottom);
    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }
    .item {
      @include fontSC(0.28rem, #333);
      line-height: 1.5em;
      margin-bottom: 0.15rem;
      &>p{
          line-height: 1.5em;
      }
    }
  }
  .btn{
      @include fontSC(0.36rem, #fff);
        width: 6.91rem;
        line-height: 1rem;
        border-radius: 0.5rem;
        margin: 0.25rem auto 0.2rem;
        background: #F65341;
        text-align: center;
  }
}
</style>
