<template>
  <div class="publicWelfareRecord-page">
    <div class="top-box">
      <img class="bg" src="@/assets/images/wave-bg.png" alt="" />
      <div class="avatar">
        <img class="img" :src="headImg" />
      </div>
      <div class="sort">
        <div class="l">第{{ userSort }}名</div>
        <div class="m"></div>
        <div class="r">{{ 1319 + totalSort }}个</div>
      </div>
    </div>
    <div class="tab-list">
      <div
        @click="checkTab('1')"
        class="item"
        :class="{ curr: currTab === '1' }"
      >
        收入
      </div>
      <div
        @click="checkTab('2')"
        class="item"
        :class="{ curr: currTab === '2' }"
      >
        支出
      </div>
    </div>

    <section class="sort-list" v-show="currTab === '1'">
      <div class="item" v-for="item in srData" :key="item.id">
        <div class="l">
          <div class="l-f">
            <p class="title">{{ item.recordDesc }}</p>
            <p
              class="name"
              v-if="
                item.instuctType === 'CASH_DONATION_PERSION' ||
                item.instuctType === 'CASH_DONATION_ENTRY'
              "
            >
              活动名称：{{ item.activityName }}
            </p>
            <p class="memo">{{ item.createTime }}</p>
          </div>
        </div>
        <div class="r">
          <span class="total">+{{ item.amount }}</span
          >公益豆
        </div>
      </div>
    </section>
    <section
      class="sort-list"
      v-show="currTab === '2'"
      style="padding-top: 0.2rem"
    >
      <div class="zc-item" v-for="item in zcData" :key="item.id">
        <div class="l">
          <div class="l-f">
            <p class="title">{{ item.recordDesc }}</p>
            <p class="memo">{{ item.createTime }}</p>
          </div>
        </div>
        <div class="r">
          <p class="des">-{{ item.amount }}公益豆</p>
          <p
            class="total"
            v-if="item.instuctType === 'ORDER_SHOP'"
            @click="goDetail(item.orderNo)"
          >
            订单详情
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import UserService from '@/api/common.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const headImg = ref(
      localStorage.headImg || require('@/assets/images/gongyi/avatar.png')
    )
    const store = useStore()
    const router = useRouter()

    const state = reactive({
      srData: [],
      zcData: [],
      currTab: '1',
      total: '-',
      totalSort: '-',
      userSort: '-'
    })

    onMounted(() => {
      initInfo()
    })

    const initInfo = async () => {
      const data = await UserService.welfareRecord({
        uid: localStorage.uid,
        direct: state.currTab == '1' ? 'in' : 'out'
      })
      console.log(data)
      if (!data) return
      const { recordList, userSort, totalSort, total } = data
      state.total = total
      state.totalSort = totalSort
      state.userSort = userSort
      if (state.currTab === '1') {
        state.srData = recordList ? recordList : []
      } else {
        state.zcData = recordList ? recordList : []
      }
    }

    const goDetail = (no) => {
      router.push({
        path: '/exchangeSucc',
        query: {
          no
        }
      })
    }
    const checkTab = (n) => {
      if (state.currTab === n) return
      state.currTab = n
      initInfo()
    }
    const tabChange = ({ name }) => {}
    return {
      ...toRefs(state),
      checkTab,
      headImg,
      goDetail
    }
  }
})
</script>
<style lang="scss" scoped>
.publicWelfareRecord-page {
  min-height: 100vh;
  padding-bottom: 0.2rem;
  .top-box {
    position: relative;
    margin-bottom: 0.31rem;
    .bg {
      display: block;
      width: 100%;
      height: 4.39rem;
    }
    .avatar {
      position: absolute;
      top: 0.96rem;
      left: 50%;
      transform: translateX(-50%);
      width: 1.19rem;
      height: 1.19rem;
      border-radius: 0.6rem;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 0.76rem;
        height: 0.83rem;
      }
    }
    .sort {
      position: absolute;
      bottom: 1.53rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      @include fontSC(0.36rem, #fff);
      width: 90%;
      justify-content: center;
      .m {
        margin: 0 0.64rem;
        width: 1px;
        height: 0.36rem;
        background: #fff;
      }
    }
  }
  .tab-list {
    display: flex;
    padding-left: 0.48rem;
    .item {
      position: relative;
      @include fontSC(0.32rem, #333);
      padding-bottom: 0.3rem;
      margin-right: 0.98rem;
      &.curr::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.04rem;
        background-color: #fb3e28;
      }
    }
  }

  .sort-list {
    @include border-1px(#eeeeee, top);
    padding-top: 0.23rem;
    .item {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.23rem 0.3rem 0.31rem;
      .l {
        display: flex;
        align-items: center;
        @include fontSC(0.34rem, #8d7575);
        width: 75%;
        .img {
          width: 0.65rem;
          height: 0.69rem;
          margin-right: 0.18rem;
        }
        .l-f {
          width: 100%;
        }
        .title {
          @include fontSC(0.32rem, #282828);
          margin-bottom: 0.18rem;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .name {
          @include fontSC(0.24rem, #333);
          margin-bottom: 0.18rem;
        }
        .memo {
          @include fontSC(0.24rem, #bcbcbc);
        }
      }

      .r {
        @include fontSC(0.22rem, #fb3e28);
        .total {
          font-size: 0.34rem;
          text-align-last: right;
        }
      }
    }
    .zc-item {
      height: 1.28rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.3rem;
      .l {
        display: flex;
        align-items: center;
        @include fontSC(0.34rem, #8d7575);
        width: 75%;
        .l-f {
          width: 100%;
        }
        .title {
          @include fontSC(0.32rem, #282828);
          margin-bottom: 0.2rem;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .memo {
          @include fontSC(0.24rem, #bcbcbc);
        }
      }

      .r {
        .des {
          @include fontSC(0.24rem, #666);
          margin-bottom: 0.21rem;
        }
        .total {
          @include fontSC(0.24rem, #fb3e28);
          text-align: right;
        }
      }
    }
  }
}
</style>
