<template>
  <div class="productDetail-page">
    <Detail :images="imagesList"></Detail>
    <div class="info-box">
      <div class="title">{{ proInfo.productName }}</div>

      <div v-if="!limitCondition.length" class="memo">
        <div class="amt">
          <span class="t">{{ proInfo.price }}</span>{{ proInfo.isSupportOnlinePay == 'true' ? '公益豆' : '元'
          }}{{ unitData }}
        </div>
        <div class="des">
          <div class="l">
            已兑换<span class="t">{{ proInfo.selledStock }}</span>份
          </div>
          <div class="r">
            剩余<span class="t">{{ proInfo.validStock }}</span>份
          </div>
        </div>
      </div>
      <div v-else class="memo memo-gyb">
        <div class="amt amt-gyb">
          剩余<span class="t">{{ proInfo.validStock }}</span>份
          <span class="m">已领取{{ proInfo.selledStock }}份</span>
        </div>
        <div class="description">
          <!-- <span v-for="item in limitCondition" :key="item.desc" class="t"
            >{{ item.desc }}：{{ item.val }}</span> -->
          <span v-if="limitCondition[0]" class="t">
            适用人群：{{ limitCondition[0].desc }}</span>
          <span v-if="limitCondition[1]" class="t">
            使用时间：{{ limitCondition[1].desc }}</span>
          <span v-if="proInfo.supplier" class="t">
            发起商户：{{ proInfo.supplier }}</span>
          <span v-if="proInfo.supplyAddress" class="t">
            商户地址：{{ proInfo.supplyAddress }}</span>
          <span v-if="proInfo.linkPhone" class="t">
            联系方式：{{ proInfo.linkPhone }}</span>
        </div>
      </div>
    </div>
    <div class="detail-box">
      <div class="title">{{proInfo.id == 200 ? '奖项介绍' :'商品详情'}}</div>
      <div class="item" v-html="proInfo.productDetail"></div>

      <div v-if="proInfo.id == 98">

        <div class="item">
          <b>停车券使用流程： </b><br />1、每个账户限兑换2次，公益豆不退、不换、不可兑现；<br />
          2、使用公益豆兑换停车券后，复制停车券券码，并前往i
          百联微信小程序（需先在i百联微信小程序授权注册/登录）
          在指定页面输入券码进行兑换，兑换成功后，点击右上角
          “我的优惠券”，即可在i百联账户的优惠券卡包中找到
          该停车券。
        </div>


        <div class="item">
          <b>停车券如何使用： </b><br />方法① 长按识别本页面商品图片上的二维码进入i百联停
          车小程序，使用领券手机号登录并绑定车牌后，即可在车
          辆离开停车场，支付停车费时进行抵扣。<br />
          方法② 登录i百联微信小程序或者i百联APP，点击首页
          “停车”频道，绑定车牌后，即可在车辆离开停车场，
          支付停车费时进行抵扣。
        </div>



        <div class="item">
          <b>停车券使用规则： </b><br />1、本券适用于i百联停车频道线上支付停车费使用，停车
          券不找零，不兑现，不可叠加使用；<br />

          2、参与活动门店为：百联中环购物广场、百联西郊购物
          中心、第一百货商业中心、百联又一城购物中心、百联金
          山购物中心、百联滨江购物中心、第一八佰伴，百联南方
          购物中心、世博源、嘉定购物中心、百联曲阳购物中心、
          百联南桥购物中心。<br />
          3、停车券使用如有问题,可联系i百联客服：<span style="color: #F65341 ;">400900800</span>
        </div>
        <div>

        </div>

      </div>
      <div v-if="proInfo.id == 200">
        <div class="item">
          <b>活动参与说明： </b><br />
          1、使用公益豆兑换抽奖券后，按系统提示进行抽奖券兑换，奖券名称为“公益新华-慈善节专属奖券”<br />
          2、点击抽奖券券面“立即抽奖”文字，进入抽奖转盘界面<br />
          3、抽奖得到优惠券后，点击“使用规则”，可查看说明以及进行相应折扣进行购买优惠<br />
          （若兑换后找不到抽奖券以及抽奖得到的优惠券，可进入i百联APP/小程序（我的--优惠券）查看；也按识别本页面商品图上的二维码在“我的优惠券”页面找到中奖权益）
        </div>
      </div>
      <div class="item" v-else-if="proInfo.id == 201 || proInfo.id == 202">
        <b>使用说明： </b><br />1、公益豆不退、不换、不可兑现;<br />
        2、使用公益豆兑换成功后凭兑换二维码到无碍理想（新华路店）兑换并激活使用; <br />
        3、抵用券兑换时间为周一至周日11:00-17:00，请在兑换时间内使用;<br />
        4、抵用券使用有效期即日直到2024年9月6日，请在有效期内使用;<br />
        5、抵用券不找零，不兑换现金、不退款，不与店内其它优惠同享。
      </div>
      <div class="item" v-else-if="proInfo.id == 100">
        <b>使用说明： </b><br />1、每个账户限兑换1次，公益豆不退、不换、不可兑现；<br />
        2、使用公益豆兑换成功后凭兑换二维码到墨笛植造所门店兑换并激活使用； <br />
        3、该抵用券20元/张，可在墨笛植造所所有门店营业时段堂食使用，所在门店包括：M.LIFE店（浦东新区成山路1088号）；国际舞蹈中心店（长宁区虹桥路1650号上海国际舞蹈中心A栋南门一层）；南京东路店（黄浦区南京东路139号美伦大楼M402）；诺丁花园店（徐汇区建国西路581弄小区西北门旁）；外滩源店（黄浦区南苏州路76号全幢）；武康大楼店（长宁区兴国路408号）；武康路密丹店（徐汇区武康路115号）；长风大悦城店（普陀区云岭东路88-2号东区(长风公园地铁站3号口步行160米）;<br />
        4、该抵用券不适用于墨笛植造所所有套餐产品，仅可单点；<br />
        5、抵用券使用有效期2023.08.01-2024.07.31，请在有效期内使用；<br />
        6、抵用券不找零，不兑换现金、不退款，不与店内其它优惠同享，不参与墨笛植造所会员积分；<br />
        7、周末、法定节假日通用；<br />
        8、该券不可点外卖。
      </div>
      <div class="item" v-else-if="proInfo.id == 105">
        <b>使用说明： </b><br />1、每个账户限兑换2次，公益豆不退、不换、不可兑现；<br />
        2、使用公益豆兑换成功后凭兑换二维码到bake no title（番禺路232号）兑换并激活使用； <br />
        3、抵用券兑换时间为周二至周日 10：00 — 17：00（周一店休），请在兑换时间内使用；<br />
        4、抵用券使用有效期即日直到2023年12月31日，请在有效期内使用；<br />
        5、抵用券不找零，不兑换现金、不退款，不与店内其它优惠同享；<br />
        6、周末、法定节假日通用<br />
        7、该券不可点外卖；<br />
      </div>
      <div class="item" v-else>
        <b>使用说明： </b><br />1、每个账户限兑换一次，公益豆不退、不换、不可兑现；<br />
        2、使用公益豆兑换成功后凭兑换商品二维码在新华慈善超市，每兑换一个商品需任意金额（本次消费额不计入公益豆）现金消费一次，即可兑换。购买成功后，需1个月内到慈善超市领取，过期自动作废。

        <!-- ，慈善超市地址：<span
          style="color: #fb3e28"
          >法华镇路712号新华社区慈善超市；</span
        > -->
        <!-- <template v-if="isZulin"><br />3、租赁商品凭身份证付押金才可使用。</template> -->
      </div>

      <div class="item" v-if="proInfo.id != 98 && proInfo.id != 100&& proInfo.id != 105 && proInfo.id != 200 && proInfo.id != 201&& proInfo.id != 202">
        <b>温馨提示： </b><br />
        前往“<span style="color: #fb3e28">我的善城-我的订单- 查看领取二维码</span>”后即可在慈善超市领取物品，如有问题可前往“<span
          style="color: #fb3e28">我的善城-留言板</span>”进行留言。
      </div>
    </div>

    <div class="btn-wrap">
      <div class="btn" v-if="proInfo.isSupportOnlinePay == 'true'" @click="exchange">
        我要{{ isGyb === 'true' ? '领取' : '兑换' }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Detail from '@/components/Detail.vue'
import UserService from '@/api/common.js'
import OrderService from '@/api/order.js'
import mixin from '@/mixin/mixin.js'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore()
    const { showAlert, showConfirm } = mixin()
    const isZulin = ref(false)
    const router = useRouter()
    const route = useRoute()
    const id = route.query.pid
    const isGyb = route.query.isGyb
    isZulin.value = route.query.isZulin == '1'
    const proInfo = ref({})
    const unitData = ref('')
    const limitCondition = ref([]) // 公益包限制使用条件

    const imagesList = ref([])
    const getData = async () => {
      const data = await UserService.qryProductDetail({
        productId: id
      })
      console.log(data)
      if (!data) return
      const { product, unit } = data
      proInfo.value = product
      unitData.value = unit
      imagesList.value = [product.bigPicUrl]
      limitCondition.value = JSON.parse(product?.limitCondition || [])
      console.log('======', limitCondition.value)
    }

    const exchange = () => {
      if (proInfo.value.validStock <= 0) {
        return showAlert({ content: '库存不足' })
      }
      if (localStorage.userType === 'persion') {
        showConfirm({
          title: '确认兑换',
          content: `本次购买需要消耗${proInfo.value.price}公益豆<p style="color:#F65341;margin-top:0.2rem;padding-bottom:0.23rem;">一经购买，不予退回</p>`,
          confirmFn: createOrder,
          cancelFn: () => { },
          confirmTxt: '确认',
          cancelTxt: '取消'
        })
      } else {
        showConfirm({
          title: '',
          content:
            '请先拨打联系电话确认<p style="margin-top:0.2rem;padding-bottom:0.38rem;">场地空余情况再支付公益豆</p>',
          confirmFn: createOrder,
          cancelFn: () => { },
          confirmTxt: `<span style="color:#F54029;">已联系</span>`,
          cancelTxt: `<a class="tel" style="color:#F54029; position: absolute;top: 0;left: 0; width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;" href="tel:${proInfo.value.linkPhone}">去拨打</a>`

        })
      }
    }
    const createOrder = async () => {
      const data = await OrderService.createOrder({
        productId: id,
        num: 1
      })
      console.log(data)
      if (!data) return
      const { orderNo } = data

      router.replace({
        path: './exchangeSucc',
        query: {
          no: orderNo,
          isGyb: isGyb.value
        }
      })
    }
    onMounted(() => {
      getData()
    })

    return {
      proInfo,
      unitData,
      isZulin,
      isGyb,
      limitCondition,
      exchange,
      imagesList
    }
  }
})
</script>
<style lang="scss">
.productDetail-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 1.85rem;

  .info-box {
    background: #fff;
    padding: 0.45rem 0.31rem 0.45rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.24rem;
    }

    .amt {
      @include fontSC(0.24rem, #f54029);
      margin-bottom: 0.25rem;

      &.amt-gyb {
        display: flex;
        align-items: center;
      }

      .t {
        @include fontSC(0.5rem, #f54029);
      }

      .m {
        margin-left: 0.25rem;
        @include fontSC(0.18rem, #999);
      }
    }

    .description {
      @include fontSC(0.18rem, #999);
      display: flex;
      flex-wrap: wrap;

      .t {
        margin-right: 0.3rem;
        margin-bottom: 0.2rem;
        // width: 50%;
      }
    }

    .memo {
      display: block;

      &.memo-gyb {
        display: block;
      }

      .des {
        display: flex;
        align-items: center;
      }

      .l {
        margin-right: 0.54rem;
      }

      .t {
        // @include fontSC(0.5rem, #f54029);
      }

      @include fontSC(0.24rem, #999);
    }
  }

  .detail-box {
    background: #fff;
    margin-top: 0.21rem;
    padding: 0.49rem 0.3rem 0.52rem;

    .title {
      @include fontSC(0.38rem, #333);
      margin-bottom: 0.26rem;
      font-weight: bold;
    }

    .item {
      @include fontSC(0.28rem, #333);
      line-height: 1.5em;
      margin-bottom: 0.35rem;

      &>p {
        line-height: 1.5em;
      }
    }
  }

  .btn-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    @include border-1px(#f2f2f2, top);
    padding: 0.25rem 0 0.2rem;
    position: fixed;
  }

  .btn {
    @include fontSC(0.36rem, #fff);
    width: 6.9rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    background: #f65341;
    text-align: center;
  }
}
</style>
