import { post_http } from './axios'

class CenterService {
  static myCenter(params) {
    return post_http('/center/myCenter', params)
  }
  static userDetail(params) {
    return post_http('/center/userDetail', params)
  }
  static perfectInformation(params) {
    return post_http('/center/perfectInformation', params)
  }
  static perfectInformationReady(params) {
    return post_http('/center/perfectInformationReady', params)
  }
  static queryAllSort(params) {
    return post_http('/center/queryAllSort', params)
  }
  static updateJoinLoveSort(params) {
    return post_http('/center/updateJoinLoveSort', params)
  }
  static kfqryTitle(params) {
    return post_http('/kf/qryTitle', params)
  }
  static kfQuestionDetail(params) {
    return post_http('/kf/kfQuestionDetail', params)
  }
}

export default CenterService