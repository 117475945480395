<template>
  <div class="soldierAgreement-page" @touchmove.native.stop.prevent>
    <p class="title"><span class="bold">退役军人优待、服务协议</span></p>
    <p class="m">
      <span
        >（一）“新华迷彩公园”是新华慈善服务社开发的退役军人（优抚对象）社会优待、服务平台，在平台上呈现的双拥公益产品（内容包括服务、权限和实物）非新华慈善服务社所有，产品质量由产品提供者负责。<br/>
        （二）退役军人根据自身意愿提取产品、服务，发生意外事件导致财产损失或发生人生伤亡的，新华新华慈善服务社不承担责任。<br/>
        （三）已经领取退役军人优待证且户籍在新华路街道的退役军人，经身份比对成功后，可以在迷彩公园内申领双拥公益产品。如退役军人户籍地适出新华路街道的，将停止申领资格。<br/>
        （四）所有产品均为社会企业、社会组织提供，除另有说明外，均为免费申领。<br/>
        （五）所有产品申领成功后，处置权归退役军人本人所有，但不得对外有偿转让，如发现有偿转让的，将取消申领资格。<br/>
        （六）申领产品时，先到先得，领完即止。<br/>
        （七）为发挥产品的社会效益，申领规则会动态调整，具体由新华社区公益银行负责解释。</span
      >
    </p>

    <div class="btn-box">
      <div class="btn confirm" @click="confirm">同意</div>
      <div class="btn cancel" @click="cancel">不同意</div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'

export default {
  name: 'soldierAgreement',
  components: {},
  emits: ['confirm', 'cancel'],
  setup(props, context) {
    const confirm = () => {
      context.emit('confirm')
    }
    const cancel = () => {
      context.emit('cancel')
    }
    return {
      confirm,
      cancel
    }
  }
}
</script>

<style lang="scss">
.soldierAgreement-page {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
  background: #fff;
  left: 0;
  top: 0;
  padding-bottom: 2.71rem;
  @include fontSC(0.28rem, #666);
  font-size: 0.25rem;
  padding: 0 0.2rem;
  .title{
        margin: 0.7rem 0 0.6rem;
        text-align: center;
        font-size: 0.36rem;
  }
  .bold {
    font-weight: bold;
  }
  .m {
        line-height: 0.48rem;
    margin: 0.06rem 0;
  }
  .btn-box {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 0.3rem 0.7rem;
    .btn {
      width: 6.9rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      font-size: 0.36rem;
      color: #ffffff;
    }
    .confirm {
      background: #f65341;
      border-radius: 0.5rem;
    }
    .cancel {
      color: #f65341;
    }
  }
}
</style>
