<template>
  <div class="myOrder-page">
    <div class="input-box">
      <img class="img" src="@/assets/images/search.png" alt="" />
      <input type="search" v-model="searchTxt" @keypress="searchAction" @change="inputChange" placeholder="请输入关键字" />
    </div>
    <div class="tab-list">
      <div @click="checkTab('1')" class="item" :class="{ curr: currTab === '1' }">
        全部
      </div>
      <div @click="checkTab('2')" class="item" :class="{ curr: currTab === '2' }">
        已领取
      </div>
      <div @click="checkTab('3')" class="item" :class="{ curr: currTab === '3' }">
        未领取
      </div>
    </div>
    <!-- <div class="tips">
      凭兑换记录于慈善超市领取物品，7天内不去兑换，订单失效。
    </div> -->
    <div class="order-list">
      <div class="item" v-for="item in listData" :key="item.id">
        <div class="detail" @click="goDetail(item.orderNo)">
          <div class="l">
            <img class="img" :src="item.productUrl" alt="" />
            <div></div>
          </div>
          <div class="r">
            <p class="title">{{ item.productName }}</p>
            <!-- <p class="memo">{{ item.name }}</p> -->
            <p class="total">
              实付：<span class="t">{{ item.orderAmount }}公益豆</span>
            </p>
          </div>
        </div>
       
        <div class="btn" v-if="item.productId==98 || item.productId == 200"  @click="goQrcode(item)">
          查看领取方式
        </div>
        <div class="btn" v-else v-show="item.stauts !== 'FETCHED'" @click="goQrcode(item)">
          查看领取二维码
        </div>
      </div>
      <!-- <div class="item">
        <div class="detail">
          <div class="l">
            <img class="img" src="" alt="" />
            <div>
             
            </div>
          </div>
          <div class="r">
               <p class="title">豆浆机家用小型实用豆浆机家</p>
              <p class="memo">商品描述商品描述商品描述商品描 述商品描述商品描述商品描述</p>
              <p class="total">实付：<span class="t">300公益豆</span></p></div>
        </div>
        <div class="btn">查看领取二维码</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import OrderService from '@/api/order.js'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid
    const route = useRoute()
    const queryType = route.query.type || 'false'
    const orderInfo = ref([])
    const currTab = ref('1')

    const state = reactive({
      listData: [],
      searchTxt: ''
    })

    onMounted(() => {
      getOrder(queryType)
    })
    const getOrder = async (type) => {
      const data = await OrderService.queryOrder({
        uid,
        isGyb: type
      })
      console.log(data)
      if (!data) return
      const { orderList } = data
      console.log(orderList)
      state.listData = orderInfo.value = orderList || []
    }
    const unGetData = computed(() => {
      return orderInfo.value.filter((item) => item.stauts === 'CREATE')
    })
    const getedData = computed(() => {
      return orderInfo.value.filter((item) => item.stauts === 'FETCHED')
    })
    const checkTab = (n) => {
      if (currTab.value === n) return
      currTab.value = n
      switch (n) {
        case '1':
          state.listData = orderInfo.value
          break
        case '2':
          state.listData = orderInfo.value.filter(
            (item) => item.stauts === 'FETCHED'
          )
          break
        case '3':
          state.listData = orderInfo.value.filter(
            (item) => item.stauts === 'CREATE'
          )
          break
      }
    }
    const goDetail = (no) => {
      router.push({
        path: '/orderDetail',
        query: {
          no
        }
      })
    }
    const goQrcode = (item) => {
      let data = JSON.stringify({
        num: item.orderAmount,
        time: item.createTime,
        name: item.name,
        productName: item.productName,
        scanUrl:item.scanUrl,
        productId:item.productId,
        productUrl:item.productUrl
      })
      sessionStorage.lqInfo = data
      
      // 是否为公益包订单
      const path = queryType === 'true' ? '/gybQrcode' : '/lqQrCode'
      console.log(item)
      console.log(data,path)
      if (queryType === 'true') {
        data = JSON.stringify(item)
        sessionStorage.lqInfo = data
       
      }
      router.push({
        path,
        query: {
          no: item.orderNo
        }
      })
    }

    const searchAction = async (e) => {
      if (e.keyCode !== 13) return
      state.listData = orderInfo.value.filter(
        (item) => item.productName.indexOf(state.searchTxt) > -1
      )
      //   const data = await CenterService.searchProduct({
      //     search: "",
      //     uid,
      //   });
      //   console.log(data);
      //   if (!data) return;
    }
    const inputChange = async () => {
      if (!state.searchTxt) state.listData = orderInfo.value
    }

    return {
      ...toRefs(state),
      orderInfo,
      currTab,
      unGetData,
      getedData,
      checkTab,
      inputChange,
      searchAction,
      goDetail,
      goQrcode
    }
  }
})
</script>
<style lang="scss">
.myOrder-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.2rem 0.3rem;

  .input-box {
    padding-left: 0.36rem;
    height: 0.9rem;
    border-radius: 0.45rem;
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 0.33rem;

    .img {
      width: 0.25rem;
      height: 0.27rem;
      margin-right: 0.19rem;
    }

    .input {
      @include fontSC(0.28rem, #333);
      height: 100%;

      &::-webkit-input-placeholder {
        color: #999;
      }
    }
  }

  .tab-list {
    display: flex;
    padding-bottom: 0.23rem;

    .item {
      @include fontSC(0.3rem, #666);
      margin-right: 0.49rem;

      &.curr {
        font-weight: bold;
        color: #fb3e28;
      }
    }
  }

  .tips {
    display: flex;
    align-items: center;
    padding: 0.39rem;
    margin-bottom: 0.29rem;
    @include fontSC(0.24rem, #fd5431);
    line-height: 1.3em;
    height: 0.82rem;
    background: #ffeeeb;
    box-shadow: 0px 0.05rem 0.05rem 0px rgba(252, 55, 37, 0.03);
    border-radius: 0.41rem;
  }

  .order-list {
    .item {
      background: #fff;
      padding: 0.26rem 0.3rem;
      margin-bottom: 0.2rem;
      border-radius: 0.05rem;

      .detail {
        display: flex;
        align-items: center;

        .l {
          width: 1.85rem;
          height: 1.85rem;
          margin-right: 0.29rem;
          border: 1px solid #e6e6e6;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .r {
          flex: 1;

          .title {
            margin-bottom: 0.18rem;
            @include fontSC(0.3rem, #333);
            font-weight: bold;
          }

          .memo {
            margin-bottom: 0.3rem;
            line-height: 1.3em;
            @include fontSC(0.24rem, #999);
          }

          .total {
            @include fontSC(0.32rem, #5f5f5f);

            .t {
              color: #fb3e28;
            }
          }
        }
      }

      .btn {
        // @include fontSC(0.36rem, #fd5431);
        width: 6.22rem;
        line-height: 1rem;
        border-radius: 0.5rem;
        margin: 0.4rem auto 0.48rem;
        border: 1px solid #fd5431;
        text-align: center;
      }
    }
  }
}
</style>
