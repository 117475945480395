<template>
  <!-- <van-tabbar v-model="active" active-color="#F54029" inactive-color="#999" v-show="show">
    <van-tabbar-item icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item icon="search">赚豆攻略</van-tabbar-item>
    <van-tabbar-item icon="friends-o">爱心榜单</van-tabbar-item>
    <van-tabbar-item to="/userCenter" icon="setting-o">我的善城</van-tabbar-item>
  </van-tabbar> -->
  <div class="footer-wrap" v-show="show">
    <div class="sb-footer">
      <div v-for="(item, key) in lists" :class="{
        'box-item': true,
        usercenter: true,
        active: item.routerName === $route.name,
      }" @click="itemClick(item.routerName)" :key="key">
        <div class="fi-icon-wrap">
          <img class="img" :src="item.icon" />
          <img class="img-act" :src="item.iconActive" />
        </div>
        <div class="fi-text">{{ item.cnName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { useStore } from "vuex";
import { defineComponent, computed, ref } from "vue";

const arr1 = [
  {
    routerName: "Home",
    icon: require("@/assets/images/footer/home.png"),
    iconActive: require("@/assets/images/footer/home-act.png"),
    cnName: "首页",
  },
  {
    routerName: "beanStrategy",
    icon: require("@/assets/images/footer/method.png"),
    iconActive: require("@/assets/images/footer/method-act.png"),
    cnName: "赚豆攻略",
  },
  {
    routerName: "loveSort",
    icon: require("@/assets/images/footer/love.png"),
    iconActive: require("@/assets/images/footer/love-act.png"),
    cnName: "爱心榜单",
  },
  {
    routerName: "userCenter",
    icon: require("@/assets/images/footer/user.png"),
    iconActive: require("@/assets/images/footer/user-act.png"),
    cnName: "我的善城",
  },
]
const arr2 = [
  {
    routerName: "QYHome",
    icon: require("@/assets/images/footer/home.png"),
    iconActive: require("@/assets/images/footer/home-act.png"),
    cnName: "首页",
  },
  {
    routerName: "beanStrategy",
    icon: require("@/assets/images/footer/method.png"),
    iconActive: require("@/assets/images/footer/method-act.png"),
    cnName: "赚豆攻略",
  },
  {
    routerName: "loveSort",
    icon: require("@/assets/images/footer/love.png"),
    iconActive: require("@/assets/images/footer/love-act.png"),
    cnName: "爱心榜单",
  },
  {
    routerName: "userCenter",
    icon: require("@/assets/images/footer/user.png"),
    iconActive: require("@/assets/images/footer/user-act.png"),
    cnName: "我的善城",
  },
]

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  props: {
    routerName: {
      type: String,
      default: null,
    },
    navItems: {
      type: [Array],
      default: [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    itemClick(name) {
      this.$router.push({ name });
    }
  },
  setup(props) {
    const store = useStore();
    // console.log(store.getters.getUserType)
    const userType = store.getters.getUserType || localStorage.userType
    const lists = ref([])

    lists.value = userType === 'persion' ? arr1 : arr2

    // const lists = computed(()=>{
    //   return userType==='persion' ? arr1 : arr2
    // })

    return { lists };
  },
  watch: {
    $route: {
      handler(newRouter) {

        this.show =
          this.lists.some((item) => item.routerName === newRouter.name);

      },
      immediate: true,
    },
    '$store.getters.getUserType': {
      handler(newVal) {
        this.lists = newVal === 'persion' ? arr1 : arr2
      },
    }
  },
};
</script>

<style lang="scss">
.footer-wrap {
  border-top: thin solid #f3f1f1;
  box-sizing: border-box;
  height: 0.98rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
}

.sb-footer {
  width: 100%;
  height: 100%;
  //   padding: 0.1rem 0 0 0;
  display: flex;
  box-align: center;
  align-items: center;

  .box-item {
    flex: 1;
    text-align: center;

    &:first-child {

      &.active {
        .fi-text {
          display: none;
        }

        .fi-icon-wrap {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }

    &.active {
      .img {
        display: none;
      }

      .img-act {
        display: inline-block;
      }

      .fi-text {
        color: #F54029;
      }
    }
  }

  .fi-icon-wrap {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 auto;
    text-align: center;

    img {
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }

    .img-act {
      display: none;
    }
  }

  .fi-text {
    font-size: 0.24rem;
    color: #999;
    margin-top: 0.08rem;
  }
}

.van-tabbar {
  height: 0.98rem;

  .van-tabbar-item {

    /* margin: 0 0.18rem; */
    &:nth-child(1) {
      flex: 1.2;
    }

    &:nth-child(2) {
      flex: 2;
    }

    &:nth-child(3) {
      flex: 2;
    }

    &:nth-child(4) {
      flex: 1.5;
    }
  }
}</style>
