<template>
  <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">
    <van-list :finished="state.finished" finished-text="已经到底了" class="list-page" @load="onLoad">
      <div class="list-wrap">
        <section class="list" ref="listBoxL">
          <div class="item" v-for="data in state.listData1" :key="data.title" @click="goDetail(data.id)">
            <img class="img" :src="data.picUrl" alt="">
            <div class="content">
              <div class="title" style="line-height: 1.3em;">{{data.productName}}</div>
              <div class="memo">{{data.price}}元</div>
              <div class="detail">
                <!-- <span class="l">已兑换{{data.selledStock}}份</span> -->
                <span class="r">剩余{{data.validStock}}份</span>
              </div>
            </div>
          </div>
        </section>
        <section class="list" ref="listBoxR">
          <div class="item" v-for="data in state.listData2" :key="data.title" @click="goDetail(data.id)">
            <img class="img" :src="data.picUrl" alt="">
            <div class="content">
              <div class="title" style="line-height: 1.3em;">{{data.productName}}</div>
              <div class="memo">{{data.price}}元</div>
              <div class="detail">
                <!-- <span class="l">已兑换{{data.selledStock}}份</span> -->
                <span class="r">剩余{{data.validStock}}份</span>
              </div>
            </div>
          </div>

        </section>
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import {
  defineComponent,
  watch,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { List, PullRefresh } from "vant";


export default defineComponent({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  props: {
    listData: {
      type: Array,
      default: []
    },
    total: {
        type: Number,
        default: 1
    }
  },
  setup(props, { emit }) {
      const router = useRouter()
    const { listData, total } = toRefs(props);
    const listBoxL = ref(null);
    const listBoxR = ref(null);

    const state = reactive({
      listData1: [],
      listData2: [],
      loading: false,
      finished: false,
      refreshing: false
    });

    const onLoad = () => {
      // 异步更新数据
      emit('getNewData');
    };

    const setDataList = async data => {
        if(!data.length) {
            state.listData1 = [];
            state.listData2 = [];
            return;
        }
       if (!data) return;
      let h = getHigh();
      if (state.refreshing) {
        state.listData1 = [];
        state.listData2 = [];
        state.refreshing = false;
      }
      for (let i = 0; i < data.length; i++) {
        await nextTick(() => {
          //重新计算高度
          h = getHigh();
        });
        if (h) {
          // console.log(state.listData2);
          state.listData2.push(data[i]);
        } else {
          // console.log(state.listData1);
          state.listData1.push(data[i]);
        }
      }

      // 加载状态结束
      state.loading = false;

      // 数据全部加载完成
      if (state.listData1.length + state.listData2.length >= total) {
        state.finished = true;
      }
    };

    onMounted(() => {
      setDataList(listData.value);
    });

    watch(
      () => props.listData,
      (newVal, oldVal) => {
        setDataList(newVal);
      }
    );

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      emit('getNewData', true);
    //   onLoad();
    };
    const goDetail =  async(id) => {
        router.push({
            path: './productDetail',
            query: {
                pid: id
            }
        })
    };

    const getHigh = () => {
      const listsL = Array.from(listBoxL.value.querySelectorAll(".item"));
      const listsR = Array.from(listBoxR.value.querySelectorAll(".item"));

      const listsLH = listsL.reduce((total, curr) => {
        return total + curr.offsetHeight;
      }, 0);
      const listsRH = listsR.reduce((total, curr) => {
        return total + curr.offsetHeight;
      }, 0);
      // console.log(listsLH, listsRH);
      return listsLH > listsRH;
    };

    return {
      state,
      listBoxL,
      listBoxR,
      onRefresh,
      onLoad,
      goDetail,
    };
  }
});
</script>
<style lang="scss">
.list-page {
  background: #f8f8f8;
  position: relative;
  .list-wrap {
    display: flex;
    justify-content: space-between;
  }
  .list {
    text-align: left;
    width: 3.34rem;
    .item {
      box-sizing: border-box;
      position: relative;
      background: #fff;
      width: 3.34rem;
      margin-bottom: 0.2rem;
      border-radius: 0.1rem;
      overflow: hidden;
      .img {
        display: block;
        width: 3.34rem;
        height: 2.89rem;
      }
      .content {
        padding: 0 0.15rem 0.24rem;
      }
      .title {
        margin: 0.24rem 0 0.28rem;
        font-weight: bold;
        @include fontSC(0.28rem, #404040);
      }
      .memo {
        font-weight: bold;
        @include fontSC(0.3rem, #f54029);
      }
      .detail {
        margin-top: 0.13rem;
        @include fontSC(0.18rem, #999999);
        .l {
          margin-right: 0.55rem;
        }
      }
    }
  }
}
</style>
