import {
  post_http
} from './axios'

class SoldierService {
  static queryCamouflageParkProduct(params) {
    return post_http('/center/queryCamouflageParkProduct', params)
  }
  static queryCamouflageParkProductDetail(params) {
    return post_http('/center/queryCamouflageParkProductDetail', params)
  }
  static complainRequest(params) {
    return post_http('/member/complainRequest', params)
  }

  static createCamouflagePark(params) {
    return post_http('/order/createCamouflagePark', params)
  }
  static camouflageParkQueryRights(params) {
    return post_http('/order/camouflageParkQueryRights', params)
  }
  static camouflageParkUseOrder(params) {
    return post_http('/order/camouflageParkUseOrder', params)
  }
}

export default SoldierService