<template>
  <div class="myWelFare-page">
    <div class="tab-list">
      <div
          @click="checkTab('1')"
          class="item"
          :class="{ curr: currTab === '1' }"
      >
        全部
      </div>
      <div
          @click="checkTab('2')"
          class="item"
          :class="{ curr: currTab === '2' }"
      >
        已使用
      </div>
      <div
          @click="checkTab('3')"
          class="item"
          :class="{ curr: currTab === '3' }"
      >
        未使用
      </div>
      <div
          @click="checkTab('4')"
          class="item"
          :class="{ curr: currTab === '4' }"
      >
        已退订
      </div>
    </div>
    <div>
      <div class="comboList" v-for="item in listData" :key="item.id" v-if="listData.length>0">
        <div class="comboBox">
          <div style="font-size: 0.45rem;font-weight: 500;color: #333333;">{{ item.name }}</div>
          <div
              style="background: rgba(110,110,110,0.15);color: #999999;border-radius: 5px;width: 1.2rem;padding: 0.1rem;text-align: center;"
              v-if="item.orderFormState===1">
            已核销
          </div>
          <div
              style="background: rgba(251,62,40,0.15);color: #FB3E28;border-radius: 5px;width: 1.2rem;padding: 0.1rem;text-align: center;"
              v-else-if="item.orderFormState===3">
            已退订
          </div>
          <div
              style="background: rgba(251,62,40,0.15);color: #FB3E28;border-radius: 5px;width: 1.2rem;padding: 0.1rem;text-align: center;"
              v-else>
            未核销
          </div>
        </div>
        <div class="comboBox1" style="margin-top: 0.3rem;">
          <div class="comboBoxLeft">订购时间：</div>
          <div class="comboBoxRight">{{ item.placeAnOrderDateTime }}</div>
        </div>
        <div class="comboBox1">
          <div class="comboBoxLeft">实付款：</div>
          <div class="comboBoxRight" style="color: #FB3E28;">￥{{ item.truePayment }}</div>
        </div>
        <div class="comboBox1">
          <div class="comboBoxLeft">套餐名称：</div>
          <div class="comboBoxRight">{{ item.orderDoubleEmbraceName }}</div>
        </div>
        <div class="comboBox" style="margin-top: 0.2rem;" v-if="item.orderFormState===0">
          <div class="comboCheck">
            <div style="margin-right: 0.2rem;" @click="goInfo('tcQrCode')">查看核销二维码</div>
            <div style="margin-top: 0.08rem;"><img src="../../assets/images/tcRight.png" alt=""></div>
          </div>
          <div class="comboCheck" @click="callOff(item.id,item.truePayment,item.userName,item.transactionId,item.id)">
            取消订单
          </div>
        </div>
      </div>
      <div v-else class="NoOrder">暂无订单</div>
    </div>

  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  toRefs,
} from "vue";
import mixin from "@/mixin/mixin.js";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import axios from 'axios';
import OrderService from "@/api/order.js";
import {Toast} from 'vant'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const uid = localStorage.uid;
    const route = useRoute();
    const orderInfo = ref([]);
    const currTab = ref("1");
    const user = ref({});
    const {showAlert} = mixin();
    const state = reactive({
      listData: [],
      searchTxt: "",
    });

    onMounted(() => {
      getUser();
      getOrder();
    });
    // 获取用户信息
    const getUser = async (type = "") => {
      try {
        const response = await axios.post('http://www.yingyuantech.com:10004/mananger/person/personBaseInfo/page', {
          "pagesize": 1,
          "startRow": 1000,
          "phone": localStorage.phone
        });
        const data = response.data.data;
        console.log(data);
        if (!data) return;

        const {records} = data;
        const recordData = records[0] || {}
        localStorage.id = recordData.id || "";
        localStorage.userQrcode = recordData.qrcode;
        console.log(recordData.age)
        user.value = records || [];
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };
    // 获取所有订单
    const getOrder = async (type = "") => {
      try {
        const response = await axios.post('http://www.yingyuantech.com:10004/mananger/order/orderManagement/page', {
          "pagesize": 1,
          "startRow": 1000,
          "userId": localStorage.uid,
          reservationType: 2
        });
        const data = response.data.data;
        console.log(data);
        if (!data) return;
        const {records} = data;
        console.log(records)
        state.listData = orderInfo.value = records || [];
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };
    const unGetData = computed(() => {
      return orderInfo.value.filter((item) => item.orderFormState === 1);
    });
    const getedData = computed(() => {
      return orderInfo.value.filter((item) => item.orderFormState === 0);
    });
    // 退订双拥套餐
    const callOff = async (type = "", truePayment = "", userName = "", transactionId = "", id = "") => {

      console.log(type, truePayment);
      try {
        const response = await axios.post('http://www.yingyuantech.com:10004/mananger/order/orderManagement/unsubscribeDoubleEmbrace', {
          "userId": type,
          "price": truePayment,
          "userName": userName,
          "transactionId": transactionId,
          "id": id
          // reservationType: 2
        });
        const data = response.data.data;
        console.log(response);
        if (response.data.status==900){
          Toast.success(response.data.message);
        }else {
          Toast.success('退订成功');
        }

        getOrder()
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };
    const checkTab = (n) => {
      if (currTab.value === n) return;
      currTab.value = n;
      state.searchTxt = n;
      switch (n) {
        case "1":
          state.listData = orderInfo.value;
          break;
        case "2":
          state.listData = orderInfo.value.filter(
              (item) => item.orderFormState === 1
          );
          break;
        case "3":
          state.listData = orderInfo.value.filter(
              (item) => item.orderFormState === 0
          );
          break;
        case "4":
          state.listData = orderInfo.value.filter(
              (item) => item.orderFormState === 3
          );
          break;
      }
    };
    const goInfo = (name) => {
      router.push({
        name: name
      })
    }
    const goQrcode = (item) => {
      if (item.expireDay <= 0) {
        return showAlert({content: "已过期"});
      }
      sessionStorage.flbInfo = JSON.stringify(item);
      router.push({
        path: item.memberLevel == "3" ? "/soldierOrderQrCode" : "/flbQrCode",
        query: {
          no: item.id,
          from: "myFlb",
        },
      });
    };

    const searchAction = async (e) => {
      if (e.keyCode !== 13) return;
      state.listData = orderInfo.value.filter(
          (item) => item.productName.indexOf(state.searchTxt) > -1
      );
    };
    const inputChange = async () => {
      if (!state.searchTxt) state.listData = orderInfo.value;
    };

    return {
      state,
      ...toRefs(state),
      orderInfo,
      currTab,
      unGetData,
      getedData,
      checkTab,
      inputChange,
      searchAction,
      goQrcode,
      goInfo,
      callOff
    };
  },
});
</script>
<style lang="scss">
.myWelFare-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.2rem 0.3rem;

  .tab-list {
    display: flex;
    padding-bottom: 0.23rem;

    .item {
      @include fontSC(0.3rem, #666);
      margin-right: 0.49rem;

      &.curr {
        font-weight: bold;
        color: #fb3e28;
      }
    }
  }

  .comboList {
    background-color: white;
    padding: 0.2rem;

    > div {
      // line-height: 1rem;
      height: 0.5rem;
    }

    .comboBoxLeft {
      color: #999999;
      font-size: 0.35rem;
    }

    .comboBoxRight {
      color: #333333;
      font-size: 0.35rem;
    }

    .comboBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .comboCheck {
        display: flex;
        align-items: center;
        color: #FB3E28;
        font-size: 0.35rem;
      }
    }

    .comboBox1 {
      display: flex;
      align-items: center;
    }

  }

  .NoOrder {
    text-align: center;
    font-size: 0.4rem;
    margin-top: 1rem;
  }
}
</style>
