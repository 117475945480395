<template>
  
</template>

<script>
import { useStore } from 'vuex'
import { defineComponent, computed } from 'vue'

export default {
  components: {

  },
  setup(props) {
    const store = useStore()
    const dialogInfo = computed(() => store.state.dialogInfo)

    return {
      dialogInfo
    }
  }
}
</script>

<style lang="scss">

</style>
