<template>
  <div class="loveMan-page">
     <div class="top-box">
        <img
          class="bg"
          src="@/assets/images/wave-bg.png"
          alt=""
        />
        <div class="avatar">
          <img class="img" :src="headImg" />
        </div>
        <div class="sort">
            {{name}}
        </div>
    </div>
    <div class="tab-list">
      <div class="item curr">公益记录</div>
    </div>

    <div class="sort-list">
      <div class="item" v-for="item in listData" :key="item.id">
        <div class="l">
          <img class="img" src="" alt="" />
          <div>
              <p class="title">{{item.recordDesc}}</p>
              <p class="memo">{{item.createTime}}</p>
          </div>
        </div>
        <div class="r"><span class="total">+{{item.amount}}</span>公益豆</div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import UserService from "@/api/common.js";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
  },
  props: {},
  setup(props) {

    const store = useStore();
    const route = useRoute();
    const id = route.query.id

    const listData = ref([])
    const name = ref('')
    const headImg = ref(localStorage.headImg||require('@/assets/images/gongyi/avatar.png'))

    onMounted(() => {
        initInfo()
    });

     const initInfo = async () => {
      const data = await UserService.qryLoveMemberInfo({
        uid: id,
      });
      console.log(data); if (!data) return;
      const { recordList } = data;
      if(data.headImg) headImg.value = data.headImg
      name.value = data.name
      listData.value = recordList ? recordList : [] 
    };

    return {
      listData,
      name,
      headImg,
    };
  },
});
</script>
<style lang="scss">
.loveMan-page {
  min-height: 100vh;
  padding-bottom: 1.2rem;
  .top-box{
      position: relative;
      margin-bottom: 0.31rem;
      .bg{
          display: block;
          width: 100%;
          height: 4.39rem;
      }
      .avatar{
          position: absolute;
          top: 0.96rem;
          left: 50%;
          transform: translateX(-50%);
          width: 1.19rem;
          height: 1.19rem;
          border-radius: 0.6rem;
          background: #fff;
           display: flex;
          align-items: center;
          justify-content: center;
          .img{
              width: 0.76rem;
              height: 0.83rem;
          }
      }
      .sort{
          position: absolute;
          bottom: 1.63rem;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
         @include fontSC(0.28rem, #fff);
         .m{
             margin: 0 0.64rem;
             width: 1px;
             height: 0.36rem;
             background: #fff;
         }
      }
  }
   .tab-list {
      display: flex;
      padding-left: 0.47rem;
    .item {
      position: relative;
      @include fontSC(0.32rem, #333);
      width: 1.63rem;
      padding-bottom: 0.3rem;
      &.curr::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1.63rem;
        height: 0.04rem;
        background-color: #fb3e28;
      }
    }
  }
 
  .sort-list {
    @include border-1px(#EEEEEE, top);
    .item {
      height: 1.68rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.3rem;
      .l {
          display: flex;
          align-items: center;
        @include fontSC(0.34rem, #8d7575);
        .img{
            width: 0.65rem;
            height: 0.69rem;
            margin-right: 0.18rem;
        }
        .title{
            @include fontSC(0.32rem, #282828);
            margin-bottom: 0.2rem;
        }
        .memo{
            @include fontSC(0.22rem, #999);
        }
      }

      .r {
        @include fontSC(0.22rem, #FB3E28);
        .total{
            font-size: 0.34rem;
        }
        
      }
    }
  }

}
</style>
