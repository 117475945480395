<template>
  <div class="lq-qrcode-page">
    <qrcode-vue :value="urlData"></qrcode-vue>
    <div class="des"><span class="l">中奖物品：</span> <span class="r">{{info.prizeName}}</span></div>
    <!-- <div class="des"><span class="l">中奖者人姓名：</span><span class="r">{{info.name}}</span></div> -->
    <div class="des"><span class="l">中奖时间：</span><span class="r">{{info.createTime}}</span></div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import QrcodeVue from 'qrcode.vue'
import LuckyService from "@/api/lucky.js";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    QrcodeVue
  },
  setup(props) {
    const store = useStore();
    
    const dialogInfo = computed(() => store.state.dialogInfo);
    const uid= localStorage.uid
    const recordListData = ref([]);
    
    const info= JSON.parse(sessionStorage.ljInfo)
    const no = info.recordId;
    const urlData = ref(no)
    onMounted(() => {
    //   initData();
    });
    const initData = async () => {
      const data = await LuckyService.myDrawRecord({
        uid: localStorage.uid,
        pagesize: 100,
        startRow: 0,

      });
      console.log(data); if (!data) return;
      const { recordList } = data;
      recordListData.value = recordList
    };

    return {
      recordListData,
      dialogInfo,
      urlData,
      info,
    };
  },
};
</script>

<style lang="scss">
.lq-qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.93rem 0.68rem 0;
  .des{
      margin-bottom: 0.3rem;
      @include fontSC(0.34rem, #999);
      display: flex;
    //   justify-content: space-between;
      .l{
          width: 45%;
      }
       .r{
          flex: 1;
          line-height: 1.2em;
      }
  }
  canvas{
      display: block;
      margin: 0 auto 0.65rem;
      width: 4.5rem!important;
      height: 4.5rem!important;
  }

}
</style>
