<template>
  <div class="kefu-page">
    <div class="top">
      <img class="l" src="@/assets/images/gongyi/avatar.png" />
      <div class="r">
        <div class="title">智能客服</div>
        <div class="memo">智能客服欢迎您~</div>
      </div>
      <img class="ly" @click="goLy" src="@/assets/images/ly_icon.png" />
    </div>
    <!-- <div class="tip">您好？您想咨询什么问题呢？请点击下 方按钮，大白来帮您解决疑惑。</div> -->
    <div class="qa-wrap">
      <div class="item a">
        您好！您想咨询什么问题呢？请点击下方按钮，来帮您解决疑惑。
      </div>
      <!-- <div class="item" :class="{q: index%2==0, a:index%2!=0}" v-for="(item,index) in qaList"
        :key="index"><i></i>{{item}}</div> -->
      <!-- <div class="item q"><i></i>什么是善城</div> -->
    </div>
    <div class="qa-wrap" style="justify-content: flex-end">
      <div v-if="qtxt" class="item q">
        <i></i><span style="line-height: 1.3em" v-html="qtxt"></span>
      </div>
    </div>
    <div class="qa-wrap">
      <div v-if="atxt" class="item a">
        <i></i><span style="line-height: 1.3em" v-html="atxt"></span>
      </div>
    </div>
    <div class="q-box">
      <div class="list">
        <div
          class="item"
          :class="{ curr: currId === item.id }"
          v-for="item in qList"
          :key="item.id"
          @click="qAction(item)"
        >
          {{ item.questTitle }}
        </div>
        <!-- <div class="item">如何购买公益产品？</div>
        <div class="item">如何赚取公益币？</div>
        <div class="item">慈善超市地址？</div> -->
      </div>
      <p class="more" @click="checkQ">
        <img class="l" src="@/assets/images/center/refresh.png" />换一批
      </p>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import CenterService from '@/api/center.js'
import { Picker, Popup } from 'vant'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const uid = localStorage.uid

    const state = reactive({
      listDatas: [],
      qaList: [],
      // qList: [],
      currId: '',
      pageSize: 4,
      currPage: 0,
      totalPage: 1,
      qtxt: '',
      atxt: ''
    })

    onMounted(() => {
      kfqryTitle()
    })

    const kfqryTitle = async () => {
      const data = await CenterService.kfqryTitle({
        uid,
        pagesize: 1000,
        startRow: 0
      })

      if (!data) return
      const { requestList } = data
      console.log(requestList)
      state.listDatas = requestList
      state.totalPage = Math.ceil(state.listDatas.length / state.pageSize)
      // state.qList = kfList.map(item => item.question)
    }

    const qList = computed(() => {
      const start = state.currPage * state.pageSize
      return state.listDatas.slice(start, start + state.pageSize)
    })

    const qAction = (data) => {
      state.currId = data.id
      state.qtxt = data.questTitle
      state.atxt = ''
      //   state.qaList.push(data.questTitle)
      setTimeout(() => {
        //  state.qaList.push(data.questAnswer)
        state.atxt = data.questAnswer
      }, 300)
    }
    const checkQ = (data) => {
      console.log(state.currPage, state.totalPage)
      if (state.currPage >= state.totalPage - 1) {
        state.currPage = 0
      } else {
        state.currPage += 1
      }
    }
    const goLy = () => {
      router.push({
        path: '/message'
      })
    }

    return {
      ...toRefs(state),
      qList,
      goLy,
      qAction,
      checkQ
    }
  }
})
</script>
<style lang="scss">
.kefu-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 4.6rem;
  p {
    line-height: 1.3em;
  }
  .top {
    position: relative;
    background: #fff;
    height: 1.6rem;
    display: flex;
    align-items: center;
    padding-left: 0.32rem;
    .l {
      width: 0.91rem;
      height: 0.99rem;
      margin-right: 0.29rem;
    }
    .r {
      .title {
        margin-bottom: 0.26rem;
        @include fontSC(0.4rem, #fb3e28);
        // @include border-1px(#e9e9ff, bottom);
      }
      .memo {
        @include fontSC(0.28rem, #666);
      }
    }
    .ly {
      position: absolute;
      width: 0.66rem;
      height: 0.62rem;
      top: 50%;
      right: 0.31rem;
      transform: translateY(-50%);
    }
  }
  .qa-wrap {
    padding: 0 0.35rem;
    display: flex;
    .item {
      position: relative;
      margin-top: 0.5rem;
      @include fontSC(0.32rem, #333);
      border-radius: 0.1rem;
      background: #fff;
      min-width: 2.34rem;
      min-height: 0.88rem;
      line-height: 1.5em;
      display: flex;
      align-items: center;
      padding: 0.23rem 0.27rem;

      &.q {
        background: #ffe0d5;
        max-width: 90%;
        justify-content: flex-end;
        &::after {
          content: '';
          width: 0;
          height: 0;
          border: 0.1rem solid;
          position: absolute;
          right: -0.1rem;
          transform: rotate(45deg);
          top: 30%;
          background: #ffe0d5;
          border-color: transparent transparent transparent #ffe0d5;
          border-radius: 3px;
        }
      }
      &.a {
        background: #fff;
        &::after {
          content: '';
          width: 0;
          height: 0;
          border: 0.1rem solid;
          position: absolute;
          left: -0.1rem;
          transform: rotate(45deg);
          top: 30%;
          background: #fff;
          border-color: transparent #fff transparent transparent;
        }
      }
    }
  }
  .q-box {
    background: #f8f8f8;
    padding: 0 0.3rem 0.52rem;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        @include fontSC(0.36rem, #666);
        border-radius: 0.05rem;
        border: 1px solid #e7e7e7;
        padding: 0 0.2rem;
        text-align: center;
        line-height: 0.9rem;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        min-width: 40%;
        max-width: 58%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 2%;
        text-align: center;
        margin-bottom: 0.12rem;
        &.curr {
          color: #fff;
          background: #fb3e28;
          border: 1px solid #fb3e28;
        }
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      @include fontSC(0.3rem, #999);
      margin: 0.31rem;
      img {
        width: 0.22rem;
        height: 0.24rem;
        margin-right: 0.15rem;
      }
    }
  }
}
</style>
