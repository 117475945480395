<template>
  <div class="productDetail-page">
    <Detail></Detail>
  </div>
</template>

<script>
import {
  defineComponent,
  createApp,
  computed,
  reactive,
  ref,
  onMounted,
  nextTick
} from "vue";
import { useStore } from "vuex";
import Detail from "@/components/Detail.vue";

export default defineComponent({
  components: {
    Detail
  },
  props: {},
  setup(props) {
    const store = useStore();

    const state = reactive({
      priceRange: '',
      priceTitle: '价格区间',
    });

    onMounted(() => {});

    const tabChange = ({ name }) => {
     
    };
    return {
      state,
    };
  }
});
</script>
<style lang="scss">
.productDetail-page {

}
</style>
