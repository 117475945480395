<template>
  <div class="qrcode-page">
    <qrcode-vue :value="urlData"></qrcode-vue>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import QrcodeVue from 'qrcode.vue'
import LuckyService from "@/api/lucky.js";

export default {
  components: {
    QrcodeVue
  },
  setup(props) {
    const store = useStore();
    const dialogInfo = computed(() => store.state.dialogInfo);
    const recordListData = ref([]);
    const urlData = ref('')
    onMounted(() => {
      initData();
    });
    const initData = async () => {
      const data = await LuckyService.myDrawRecord({
        uid: localStorage.uid,
        pagesize: 100,
        startRow: 0,
      });
      console.log(data); if (!data) return;
      const { recordList } = data;
      recordListData.value = recordList
    };

    return {
      recordListData,
      dialogInfo,
      urlData,
    };
  },
};
</script>

<style lang="scss">
.qrcode-page {
  min-height: 100vh;
  background: #f8f8f8;
  padding: 0.21rem 0.3rem;
  .award-box {
    background: #fff;
    margin-bottom: 0.2rem;
    border-radius: 0.05rem;
    font-size: 0.32rem;
    color: #333;
    .con {
      padding: 0.45rem 0.52rem;
      .title {
        margin-bottom: 0.3rem;
      }
      .time {
        .t {
          color: #999;
        }
      }
    }
    &.geted {
      position: relative;
      overflow: hidden;
      .title {
        color: #d3d3d3;
      }
      .time {
        color: #d3d3d3;
        .t {
          color: #d3d3d3;
        }
      }
      .btn {
        display: none;
      }
      &::before {
        content: "已领取";
        position: absolute;
        top: -0.13rem;
        right: -0.9rem;
        background: #ececec;
        @include fontSC(0.3rem, #999);
        transform: rotate(45deg);
        width: 2.5rem;
        height: 0.9rem;
        text-align: center;
        padding-top: 0.46rem;
        box-sizing: border-box;
      }
    }
    .btn {
      @include border-1px(#e7e7e7, top);
      @include fontSC(0.36rem, #fe5631);
      line-height: 1.08rem;
      text-align: center;
    }
  }
}
</style>
